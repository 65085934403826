<template>
  <v-card min-height="90vh" class="ModalVersionDiff" max-width="600px">
    <div class="ModalVersionDiff__title">
      <div class="ModalVersionDiff__title-wrapper">
        Version #{{ version.scopedId }} changes
      </div>
      <v-btn x-small icon dark @click="close"
        ><the-icon class="ModalVersionDiff__close-icon" icon="close"
      /></v-btn>
    </div>

    <div class="ModalVersionDiff__content">
      <v-list-group :value="true" prepend-icon="mdi-menu-right" sub-group eager>
        <template v-slot:activator>
          <v-list-item-title class="files_title">
            {{ version.diff.added.length }} files added
          </v-list-item-title>
        </template>

        <SiteVersionDiffTree :tree="version.added" />
      </v-list-group>

      <v-list-group :value="true" prepend-icon="mdi-menu-right" sub-group eager>
        <template v-slot:activator>
          <v-list-item-title class="files_title">
            {{ version.diff.deleted.length }} files deleted
          </v-list-item-title>
        </template>

        <SiteVersionDiffTree :tree="version.removed" :deleted="true" />
      </v-list-group>

      <v-list-group :value="true" prepend-icon="mdi-menu-right" sub-group eager>
        <template v-slot:activator>
          <v-list-item-title class="files_title">
            {{ version.diff.modified.length }} files modified
          </v-list-item-title>
        </template>

        <SiteVersionDiffTree :tree="version.modified" />
      </v-list-group>
    </div>
  </v-card>
</template>

<script>
import SiteVersionDiffTree from '@/components/TheSite/SiteVersion/SiteVersionDiffTree'
export default {
  name: 'ModalVersionDiff',
  components: { SiteVersionDiffTree },
  props: {
    version: Object,
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    close() {
      this.$emit('close')
    },
    isFolder(object) {
      return typeof object == 'object'
    },
  },
}
</script>

<style lang="scss">
$style: ModalVersionDiff;
#autoScrollBlock {
  justify-content: right;
}
.#{$style} {
  min-height: 100vh !important;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 12px 0px 0px 12px;

  &.theme--dark.v-card {
    background: $N5;
    border: 1px solid $N7;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    border-radius: 12px 0px 0px 12px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: $H16;
    color: $white;
    padding: 30px 34px !important;
    border-bottom: 1px solid $N5;
    position: sticky;
  }
  &__title-wrapper {
    display: flex;
    align-items: center;
  }
  &__console-icon {
    fill: $white;
    margin-right: 10px;
  }
  &__close-icon {
    fill: $N3;
    width: 18px;
    height: 18px;
  }
  &__content {
    padding-top: 0px !important;
    padding: 24px 36px;
    overflow: auto;
    max-height: calc(100vh - 143px);
    overscroll-behavior: contain;
    .v-list-group--sub-group.v-list-group--active
      .v-list-item__icon.v-list-group__header__prepend-icon
      .v-icon {
      transform: rotate(90deg) !important;
      color: white;
    }
    .v-list-item--link:before {
      background-color: unset !important;
    }
    .files_title {
      font-family: 'Instrument Sans', sans-serif;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: white;
    }
  }
  &__top-text {
    margin-bottom: 12px;
    color: $N2;
    font-weight: 500;
    font-size: $H14;
    span:last-child {
      display: inline-block;
      margin-left: 20px;
      font-weight: 400;
    }
  }

  &__progress {
    margin-bottom: 16px;
  }
  &__footer {
    display: flex;
    align-items: center;
  }
  &__check-icon {
    color: $N2;
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
  &__error-icon {
    color: $N2;
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
  &__label {
    color: $G1;
    margin-left: 8px !important;
    font-weight: 400;
    font-size: $H14;
    &._error {
      color: $E1;
    }
  }
  @media (min-height: 1000px) {
    &__console {
      height: 80vh !important;
    }
  }
  &__console {
    height: 68vh;
    overflow-y: scroll;
    background: #181729;
    border: 1px solid $N5;
    border-radius: 8px;
    padding: 32px;
  }
  &__console-text {
    font-size: $H14;
    color: $white;
    .ok {
      color: $G1;
    }
    .fail {
      color: $R1;
    }

    .notice {
      color: $Y1;
    }
    &._grey {
      color: $N2;
    }
  }
  &__console-item {
    width: 100%;
  }
}
</style>
