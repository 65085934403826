<template>
  <div class="SiteSettings">
    <div
      class="d-flex justify-space-between align-center"
      id="project-settings"
    >
      <h4 class="SiteSettings__title">Build & Deploy</h4>
      <span
        @click="onCopyBlockLink('project-settings')"
        class="d-flex align-center copy-block-link"
      >
        <the-icon icon="link" color="Y1" class="mr-1" />
        Copy link to block
      </span>
    </div>
    <h2 class="SiteSettings__grey-text">
      How would you like to deploy to {{ activeSite.url }}?
    </h2>
    <div class="SiteSettings__deploy-row">
      <div class="SiteSettings__deploy-col">
        <div
          @click="deployMode = DEPLOY_MODE_DRAG"
          v-ripple
          class="SiteSettings__deploy-card"
          :class="{
            _active: deployMode == DEPLOY_MODE_DRAG,
          }"
          :elevation="deployMode == DEPLOY_MODE_DRAG ? 3 : 0"
        >
          <the-icon
            class="SiteSettings__deploy-icon"
            icon="using-drag-and-drop"
          />
          <span v-if="!cloudConnected" class="SiteSettings__deploy-text"
            >Using Drag + Drop</span
          >
          <span v-else class="SiteSettings__deploy-text">Use Drag + Drop</span>
        </div>
      </div>
      <div class="SiteSettings__deploy-col">
        <div
          v-ripple
          @click="deployMode = DEPLOY_MODE_DROPBOX"
          class="SiteSettings__deploy-card"
          :class="{
            _active: deployMode == DEPLOY_MODE_DROPBOX,
          }"
          :elevation="deployMode == DEPLOY_MODE_DROPBOX ? 3 : 0"
        >
          <the-icon class="SiteSettings__deploy-icon" icon="dropbox" />
          <span v-if="!dropboxPath" class="SiteSettings__deploy-text"
            >Sync with Dropbox</span
          >
          <span v-else class="SiteSettings__deploy-text"
            >Syncing with Dropbox</span
          >
        </div>
      </div>
      <div class="SiteSettings__deploy-col">
        <div
          v-ripple
          @click="deployMode = DEPLOY_MODE_GITHUB"
          class="SiteSettings__deploy-card"
          :class="{
            _active: deployMode == DEPLOY_MODE_GITHUB,
          }"
          :elevation="deployMode == DEPLOY_MODE_GITHUB ? 3 : 0"
        >
          <div class="SiteSettings__deploy-select-wrapper">
            <v-select
              class="SiteSettings__deploy-select"
              v-model="selectedGit"
              :items="gitResources"
              item-text="name"
              item-value="value"
              return-object
              dark
              single-line
            >
              <template #selection="{ item }">
                <span
                  class="SiteSettings__deploy-select-item"
                  style="width: 100%; font-size: 14px"
                >
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </div>
          <img class="SiteSettings__deploy-icon" :src="gitIcon" />
          <span
            v-if="
              (selectedGit.value == 'github' && githubPath) ||
              (selectedGit.value == 'bitbucket' && bitbucketPath)
            "
            class="SiteSettings__deploy-text"
          >
            Syncing with {{ selectedGit.name }}
          </span>
          <span v-else class="SiteSettings__deploy-text"
            >Sync with {{ selectedGit.name }}</span
          >
        </div>
      </div>
    </div>

    <div class="SiteSettings__deploy-col _big">
      <div class="SiteSettings__deploy-card _last">
        <div v-if="deployMode == DEPLOY_MODE_DRAG">
          <div v-if="cloudConnected" class="SiteSettings__last-card-wrapper">
            To switch back to Drag & Drop you need first stop syncing with
            {{ syncService }} <br />
            <v-btn @click="stopSyncing" class="mt-4 mb-8" color="error">
              Stop Syncing with {{ syncService }}
            </v-btn>
          </div>
          <div v-else class="SiteSettings__last-card-wrapper">
            <the-icon class="SiteSettings__deploy-icon" icon="zip" />
            <span class="SiteSettings__deploy-text _last">
              {{ activeSite.url }} is using Drag & Drop.
            </span>
            <span class="SiteSettings__deploy-small-text">
              To create and deploy a new version of this site, just drag and
              drop a Zip file of your build onto the Versions timeline, or use
              the Upload button.
            </span>
          </div>
        </div>

        <div style="width: 100%" v-if="deployMode == DEPLOY_MODE_DROPBOX">
          <div
            v-if="!dropboxHash.token"
            class="SiteSettings__last-card-wrapper"
          >
            <v-btn
              @click="authDropbox"
              class="SiteSettings__deploy-btn _dropbox"
              color="#3762FF"
            >
              <v-icon left>mdi-dropbox</v-icon>
              Authorize Dropbox
            </v-btn>
            <span class="SiteSettings__deploy-text _last">
              Forge will only have access to the Dropbox folders you give us
              access to.
            </span>
          </div>
          <div v-else>
            <DropboxBrowser :savedData="dropboxHash" :url="dropboxApiUrl" />
          </div>
        </div>

        <div style="width: 100%" v-if="deployMode == DEPLOY_MODE_GITHUB">
          <div
            class="SiteSettings__last-card-wrapper"
            v-if="
              (selectedGit.value == 'github' && !githubHash.token) ||
              (selectedGit.value == 'bitbucket' && !bitbucketHash.token)
            "
          >
            <v-btn
              @click="authGithub"
              class="SiteSettings__deploy-btn _github"
              color="white"
              dark
            >
              <v-icon left>{{
                selectedGit.value == 'github' ? 'mdi-github' : 'mdi-bitbucket'
              }}</v-icon>
              Authorize {{ selectedGit.name }}
            </v-btn>
            <span class="SiteSettings__deploy-text _last">
              Forge will only have access to the
              {{ selectedGit.name }} repositories you give us access to.
            </span>
          </div>
          <div v-else-if="selectedGit.value == 'github' && githubHash.token">
            <GithubBrowser
              :token="githubHash.token"
              :branch="githubHash.branch"
              :path="githubHash.path"
              :url="githubApiUrl"
              :autoDeploy="githubHash.autoDeploy"
            />
          </div>
          <div
            v-else-if="selectedGit.value == 'bitbucket' && bitbucketHash.token"
          >
            <BitbucketBrowser
              :savedData="bitbucketHash"
              :url="bitbucketApiUrl"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="activeSite.kind == 'plugin'">
      <div
        class="d-flex justify-space-between align-center mt-8"
        id="plugin-configuration"
      >
        <h4 class="SiteSettings__title">Plugin Configuration</h4>
        <span
          @click="onCopyBlockLink('plugin-configuration')"
          class="d-flex align-center copy-block-link"
        >
          <the-icon icon="link" color="Y1" class="mr-1" />
          Copy link to block
        </span>
      </div>
      <v-row>
        <v-col cols="12" sm="6">
          <div class="SiteSettings__section">
            <div class="d-flex justify-space-between align-center">
              <h6 class="SiteSettings__section-title">Plugin Submission</h6>
              <span class="SiteSettings__plugin-status">{{
                siteAppStatus
              }}</span>
            </div>
            <p class="SiteSettings__section-text">
              <span
                >Publish your plugin to the marketplace by submitting plugin
                development information.</span
              >
            </p>
            <div class="d-flex justify-space-between">
              <v-btn
                @click="startRegistrationFlow"
                class="mt-4 pt-6 pb-6"
                color="white"
                text
                dark
              >
                Documentation
                <the-icon class="ml-2" icon="arrow-up-and-right" />
              </v-btn>
              <v-btn
                @click="startRegistrationFlow"
                class="mt-4 pt-6 pb-6"
                color="n5"
                dark
              >
                Submit Plugin
              </v-btn>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="6"
          v-if="siteApp && siteApp.Slug && siteAppStatus !== 'Unpublished'"
        >
          <div class="SiteSettings__section">
            <h6 class="SiteSettings__section-title">Plugin Install Params</h6>
            <p class="SiteSettings__section-text">
              <span
                >Your plugin was registered as a <b>{{ siteApp.Name }}</b
                >. Add different form elements to collect needed data from the
                end user.
              </span>
            </p>
            <div class="d-flex justify-end">
              <v-btn
                @click="onOpenParamsForm"
                class="mt-4 pt-6 pb-6"
                color="n5"
                dark
              >
                Open Params Form
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <PluginPublisherFlowDrawer />
    <PluginInstallParamsDrawer />

    <div
      class="d-flex justify-space-between align-center mt-8"
      id="site-configuration"
    >
      <h2 class="SiteSettings__title">Domain Management</h2>
      <span
        @click="onCopyBlockLink('site-configuration')"
        class="d-flex align-center copy-block-link"
      >
        <the-icon icon="link" color="Y1" class="mr-1" />
        Copy link to block
      </span>
    </div>
    <v-row>
      <v-col>
        <div class="SiteSettings__section" v-if="isProduction">
          <h6 class="SiteSettings__section-title">Custom domain</h6>

          <p class="SiteSettings__section-text">
            You can use any unique subdomain name with <br />
            {{ activeSite.url }}.
          </p>

          <div class="SiteSettings__notice">
            <span class="SiteSettings__notice-text">
              <span>Note:</span> If you're using your own domain, you'll need an
              A record pointing to 107.20.213.245 first!
            </span>
          </div>
          <span class="SiteSettings__input-label">URL</span>
          <v-text-field
            v-model="url"
            class="SiteSettings__input"
            filled
            dense
            dark
            hide-details
          >
            <template v-slot:append>
              <v-btn
                text
                @click="checkURL"
                :disabled="isSaving"
                small
                color="y1"
              >
                Check
              </v-btn>
            </template>
          </v-text-field>
          <div
            v-if="urlCheck.valid == false"
            class="SiteSettings__error-message"
          >
            {{ urlCheck.errorMessage }}
          </div>
          <div
            v-if="urlCheck.valid == true"
            class="SiteSettings__success-message"
          >
            URL is valid
          </div>

          <div class="SiteSettings__controls">
            <v-btn
              @click="cancelURL"
              :disabled="url == activeSite.url"
              dark
              color="n5"
            >
              Cancel
            </v-btn>
            <v-btn
              @click="saveURL"
              :disabled="saveDisabled || isSaving"
              color="y1"
              dark
            >
              Save
            </v-btn>
          </div>
        </div>

        <div class="SiteSettings__section">
          <h6 class="SiteSettings__section-title">Web Hooks</h6>

          <p class="SiteSettings__section-text">
            Incoming Hooks
            <span>
              You can use the following webhook to trigger a re-deployment of
              your site.
            </span>
          </p>
          <span class="SiteSettings__input-label">URL</span>
          <v-text-field
            :value="incomingHook"
            dark
            readonly
            filled
            dense
            hide-details
            class="SiteSettings__input"
          >
            <template v-slot:append>
              <v-btn text color="y1" @click="copyHook" small> Copy </v-btn>
            </template>
          </v-text-field>
          <div class="SiteSettings__controls">
            <v-btn @click="addHook" color="y1" dark> New Hook </v-btn>
          </div>
          <p class="SiteSettings__section-text _margin-none">Outgoing Hooks</p>
          <div v-if="webhookTriggers.length > 0">
            <SiteSettingsOutgoingHooks
              :outgoingHooks="webhookTriggers"
            ></SiteSettingsOutgoingHooks>
          </div>
          <p class="SiteSettings__section-text _margin-none" v-else>
            <span> You haven't created any outgoing webhooks yet. </span>
          </p>
        </div>

        <div class="SiteSettings__section">
          <h6 class="SiteSettings__section-title">Cloud Services</h6>

          <p class="SiteSettings__section-text">
            You can upload a valid Hammer for Mac, Jekyll or Middleman project
            and build it via our cloud services and deploy to Forge.
            <br />
            Try out our Contentful integration for building a new kind of CMS.
          </p>

          <v-radio-group v-model="compiler" class="mt-0" hide-details dark>
            <v-radio
              v-for="option in compilerOptions"
              :key="option.value"
              v-bind="option"
              dense
              color="y1"
            />
            <div v-if="compiler == 'webpack'">
              <span class="SiteSettings__input-label">Build Command</span>
              <v-text-field
                v-model="buildCommand"
                placeholder="npm run build"
                filled
                dark
                dense
                hide-details
                class="SiteSettings__input"
              >
              </v-text-field>
              <span class="SiteSettings__input-label">Build Folder</span>
              <v-text-field
                v-model="buildFolder"
                placeholder="/dist"
                filled
                dense
                dark
                hide-details
                class="SiteSettings__input"
              >
              </v-text-field>
            </div>
          </v-radio-group>
          <div class="SiteSettings__controls _with-padding">
            <v-btn dark @click="changeCompiler" :disabled="isSaving" color="y1">
              Save
            </v-btn>
          </div>

          <div class="SiteSettings__notice">
            <span class="SiteSettings__notice-text">
              <span>Note:</span> Cloud Services are currently in BETA. Check out
              our support section and blog for more information on how to make
              the most of this exciting new feature.
            </span>
          </div>
        </div>
      </v-col>

      <v-col>
        <div class="SiteSettings__section">
          <h6 class="SiteSettings__section-title">SSL</h6>
          <p class="SiteSettings__section-text">
            Free SSL / HTTPS for your Forge Site powered by
            <a
              class="SiteSettings__link"
              target="_blank"
              href="https://letsencrypt.org/"
              >Let's Encrypt</a
            >.
          </p>

          <v-switch
            color="y1"
            v-model="sslEnabled"
            @change="changeSslEnabled"
            dense
            hide-details
            inset
            dark
          >
            <template v-slot:label>
              <span>{{
                sslEnabled ? 'SSL is enabled' : 'SSL is disabled'
              }}</span>
            </template>
          </v-switch>
          <div v-if="sslEnabled">
            <h6 class="SiteSettings__section-title mt-4">FORCE SSL</h6>
            <v-switch
              color="y1"
              v-model="forceSSLEnabled"
              @change="changeforceSSLEnabled"
              dense
              hide-details
              inset
              dark
            >
              <template v-slot:label>
                <span>{{
                  forceSSLEnabled
                    ? 'Force SSL is enabled'
                    : 'Force SSL is disabled'
                }}</span>
              </template>
            </v-switch>
          </div>
        </div>

        <div class="SiteSettings__section">
          <h6 class="SiteSettings__section-title">Site Token</h6>

          <p class="SiteSettings__section-text">
            You can use the following Site Token to access your site via our
            REST API.
          </p>

          <v-text-field
            v-model="token"
            readonly
            :type="showingSiteToken ? 'text' : 'password'"
            dark
            filled
            dense
            hide-details
            class="SiteSettings__input"
          >
            <template #append>
              <v-btn
                class="mr-2"
                @click="showingSiteToken = !showingSiteToken"
                icon
                small
              >
                <v-icon>{{
                  showingSiteToken ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
                }}</v-icon>
              </v-btn>

              <v-btn text @click="regenToken" color="y1" class="mb-2" small>
                Regen token
              </v-btn>
            </template>
          </v-text-field>

          <span class="SiteSettings__section-small-text">
            You can regenerate your Site Token if your believe your old Site
            Token is compromised.
          </span>
        </div>

        <div class="SiteSettings__section">
          <h6 class="SiteSettings__section-title">Turbo JS</h6>

          <p class="SiteSettings__section-text">
            TurboJS is a fantastic JavaScript plugin for speeding up your site,
            specially designed to work with Forge.
            <br />
            By stashing your site's content in JavaScript, it allows us to load
            your site's pages super-quickly, and lets your users navigate around
            your site with no delays.
            <br />
            However, if your site is already a JavaScript application, you can
            disable TurboJS to enjoy standard Forge hosting.
          </p>
          <v-switch
            v-model="squish"
            @change="changeSquish"
            color="y1"
            dense
            hide-details
            dark
            inset
          >
            <template #label>
              <span>{{
                squish ? 'TurboJS is enabled' : 'TurboJS is disabled'
              }}</span>
            </template>
          </v-switch>
          <div class="SiteSettings__notice _mt">
            <span class="SiteSettings__notice-text">
              <span>Note:</span> TurboJS is still in BETA. If you experience any
              bugs or unexpected behaviour, please
              <a class="SiteSettings__link" href="mailto:contact@getforge.com"
                >email us</a
              >
              and we'll help out.
            </span>
          </div>
        </div>

        <div class="SiteSettings__section">
          <h6 class="SiteSettings__section-title">Environment Variables</h6>

          <OptionsList
            :options="environments"
            name-label="Key"
            value-label="Value"
          />
        </div>

        <div class="SiteSettings__section" v-if="isProduction">
          <h6 class="SiteSettings__section-title">Delete Site</h6>
          <div class="SiteSettings__error-message _last">
            Note: This action is not reversable. <br />
            The site will be immediately deleted and unrecoverable.
          </div>
          <div class="SiteSettings__controls">
            <v-btn
              class="SiteSettings__delete-btn"
              id="no-background-hover"
              @click="deleteSite"
              color="transparent"
              depressed
            >
              <v-icon>mdi-close-circle-outline</v-icon>
              <span class="ml-2">Delete {{ activeSite.url }}</span>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Parse from 'parse'
import { mapState, mapMutations } from 'vuex'
import {
  FORGE_ROOT_URL,
  GITHUB_CLIENT_ID,
  DROPBOX_KEY,
  BITBUCKET_KEY,
} from '@/env'
import {
  COMPILER_DISABLED,
  COMPILER_HAMMER,
  COMPILER_JEKYLL,
  COMPILER_MIDDLEMAN,
  COMPILER_WEBPACK,
} from '@/models/Site'
import { copyToClipboard } from '@/utils/common'
import { initParse } from '@/utils/parse'
import { MODAL_ALERT, MODAL_SITE_HOOK } from '@/components/Modals'
import OptionsList from '@/components/elements/OptionsList'
import GithubBrowser from '@/components/TheSite/SiteSettings/GithubBrowser'
import BitbucketBrowser from '@/components/TheSite/SiteSettings/BitbucketBrowser'
import DropboxBrowser from '@/components/TheSite/SiteSettings/DropboxBrowser'
import SiteSettingsOutgoingHooks from '@/components/TheSite/SiteSettings/SiteSettingsOutgoingHooks'
import PluginPublisherFlowDrawer from '@/components/ThePlugin/PublishFlow/Drawer'
import PluginInstallParamsDrawer from '@/components/ThePlugin/PluginInstallParams/PluginInstallParamsDrawer'
export const DEPLOY_MODE_DRAG = 'DEPLOY_MODE_DRAG'
export const DEPLOY_MODE_DROPBOX = 'DEPLOY_MODE_DROPBOX'
export const DEPLOY_MODE_GITHUB = 'DEPLOY_MODE_GITHUB'

const NO_APP_DEFINED_YET = 'NO_APP_DEFINED_YET'

export default {
  name: 'SiteSettings',
  components: {
    OptionsList,
    SiteSettingsOutgoingHooks,
    GithubBrowser,
    BitbucketBrowser,
    DropboxBrowser,
    PluginPublisherFlowDrawer,
    PluginInstallParamsDrawer,
  },
  data() {
    const {
      url,
      sslEnabled,
      forceSSLEnabled,
      token,
      compiler,
      squish,
      deployToken,
      buildCommand,
      buildFolder,
    } = this.$store.state.sites.active
    return {
      options: [
        {
          value: 'Option1',
          label: 'Option1',
        },
        {
          value: 'Option2',
          label: 'Option2',
        },
        {
          value: 'Option3',
          label: 'Option3',
        },
        {
          value: 'Option4',
          label: 'Option4',
        },
        {
          value: 'Option5',
          label: 'Option5',
        },
      ],
      value1: [],
      value2: [],
      DEPLOY_MODE_DRAG,
      DEPLOY_MODE_DROPBOX,
      DEPLOY_MODE_GITHUB,
      showingSiteToken: false,

      compilerOptions: [
        {
          label: 'Disabled',
          value: COMPILER_DISABLED,
        },
        {
          label: 'Hammer Cloud Service',
          value: COMPILER_HAMMER,
        },
        {
          label: 'Jekyll Cloud Service',
          value: COMPILER_JEKYLL,
        },
        {
          label: 'Middleman Cloud Service',
          value: COMPILER_MIDDLEMAN,
        },
        {
          label: 'Javascript Bundlers',
          value: COMPILER_WEBPACK,
        },
      ],
      gitResources: [
        {
          name: 'Github',
          value: 'github',
        },
        {
          name: 'Bitbucket',
          value: 'bitbucket',
        },
      ],
      selectedGit: { name: 'Github', value: 'github' },
      deployMode: DEPLOY_MODE_DRAG,
      urlCheck: {
        valid: null,
        errorMessage: '',
      },
      url,
      sslEnabled,
      forceSSLEnabled,
      token,
      deployToken,
      compiler,
      squish,
      buildCommand,
      buildFolder,
      githubApiUrl: 'https://api.github.com',
      bitbucketApiUrl: 'https://api.bitbucket.org/2.0',
      dropboxApiUrl: 'https://api.dropbox.com/2/files/list_folder',
      isSaving: false,
    }
  },

  computed: {
    ...mapState({
      publisherAppMap: (state) => state.publisher.publisherAppMap,
      publishers: (state) => state.publisher.publishers,
      siteApp: (state) => state.plugin_publishing.app, // plugin represented by the current site
      siteAppStatus: (state) =>
        state.plugin_publishing?.appData?.Status || 'Unpublished',
      activePublisher: (state) => state.sites.active.publisherInfo,
    }),
    saveDisabled() {
      return !this.url || this.url == this.activeSite.url
    },
    isProduction() {
      return this.activeSite.mode == 'production'
    },
    syncService() {
      if (this.bitbucketPath) return 'Bitbucket'
      else if (this.githubPath) return 'Github'
      else return 'Dropbox'
    },
    incomingHook() {
      return (
        FORGE_ROOT_URL +
        'webhook?type=redeploy&url=' +
        this.url +
        '&token=' +
        this.deployToken
      )
    },
    environments() {
      return this.activeSite.environments
    },
    webhookTriggers() {
      return this.activeSite.webhookTriggers
    },
    gitIcon() {
      return this.selectedGit.value == 'github'
        ? require('@/components/TheSite/SiteSettings/deploy-github.png')
        : require('@/components/TheSite/SiteSettings/deploy-bitbucket.png')
    },
    githubPath() {
      return this.activeSite.githubPath
    },
    bitbucketPath() {
      return this.activeSite.bitbucketPath
    },
    dropboxPath() {
      return this.activeSite.dropboxPath
    },
    cloudConnected() {
      return this.dropboxPath || this.githubPath || this.bitbucketPath
    },
    githubHash() {
      return {
        token: this.$store.state.user.current.githubToken,
        path: this.activeSite.githubPath,
        branch: this.activeSite.githubBranch,
        autoDeploy: this.activeSite.githubAutodeploy,
      }
    },
    bitbucketHash() {
      return {
        token: this.$store.state.user.current.bitbucketToken,
        path: this.activeSite.bitbucketPath,
        branch: this.activeSite.bitbucketBranch,
        autoDeploy: this.activeSite.bitbucketAutodeploy,
      }
    },
    dropboxHash() {
      return {
        token: this.$store.state.user.current.dropboxToken,
        path: this.activeSite.dropboxPath,
        cursor: this.activeSite.dropboxCursor,
        autoDeploy: this.activeSite.dropboxAutodeploy,
      }
    },
  },

  watch: {
    deployMode() {
      //TODO
    },
    envVars() {
      //TODO
    },
    publisherAppMap(newMap) {
      if (newMap) this.setupPublisher()
    },
  },
  mounted() {
    if (this.githubPath || this.bitbucketPath) {
      this.deployMode = this.DEPLOY_MODE_GITHUB
      this.selectedGit = this.bitbucketPath
        ? { name: 'Bitbucket', value: 'bitbucket' }
        : { name: 'Github', value: 'github' }
    } else {
      if (this.dropboxPath) this.deployMode = this.DEPLOY_MODE_DROPBOX
      else this.deployMode = this.DEPLOY_MODE_DRAG
    }

    this.setupPublisher()
    this.setPublishingDrawerOpened(false)
  },
  methods: {
    ...mapMutations('publishers', ['setActivePublisher']),
    ...mapMutations('plugin_publishing', [
      'setApp',
      'setAppContent',
      'setAppData',
      'setDeveloper',
    ]),
    ...mapMutations('plugin_publishing', {
      setPublishingDrawerOpened: 'setDrawerOpened',
      setPluginInstallParamsDrawerOpened: 'setSettingsDrawerOpened',
    }),
    stopSyncing() {
      let params = {
        site: {
          github_autodeploy: false,
          github_path: null,
          github_branch: null,
          dropbox_autodeploy: null,
          dropbox_cursor: null,
          dropbox_path: null,
          bitbucket_path: null,
          bitbucket_autodeploy: false,
          bitbucket_webhook_id: null,
          bitbucket_branch: null,
        },
      }
      this.$store.dispatch('sites/update', params)
    },
    authDropbox() {
      let oauthUrl =
        'https://www.dropbox.com/oauth2/authorize?client_id=' +
        DROPBOX_KEY +
        '&response_type=token&redirect_uri=' +
        document.location.protocol +
        '//' +
        document.location.host +
        '/oauth/dropbox'
      window
        .open(
          oauthUrl,
          'name',
          'height=1200,width=1200,scrollable=yes,menubar=yes,resizable=yes,location=yes'
        )
        .focus()
    },
    authGithub() {
      let oauthUrl = ''
      if (this.selectedGit.value == 'github') {
        const callbackDomain =
          document.location.protocol + '//' + document.location.host
        oauthUrl =
          'http://github.com/login/oauth/authorize?client_id=' +
          GITHUB_CLIENT_ID +
          '&scope=repo&redirect_uri=' +
          callbackDomain +
          '/oauth/github'
      } else
        oauthUrl =
          'https://bitbucket.org/site/oauth2/authorize?client_id=' +
          BITBUCKET_KEY +
          '&response_type=code'
      window
        .open(
          oauthUrl,
          'name',
          'height=1200,width=1200,scrollable=yes,menubar=yes,resizable=yes,location=yes'
        )
        .focus()
    },
    checkURL() {
      this.isSaving = true
      this.$store.dispatch('sites/checkURL', this.url).then((res) => {
        this.urlCheck.valid = res.body.valid
        let errorMain = ''
        res.body.errors.forEach((message) => {
          errorMain = errorMain + message + '. '
        })
        this.urlCheck.errorMessage =
          errorMain.substr(0, errorMain.length - 2) + '.'
        this.isSaving = false
      })
    },
    saveURL() {
      this.isSaving = true
      this.$store
        .dispatch('sites/update', { site: { url: this.url } })
        .then((res) => {
          if (res.body.errors != undefined && res.body.errors.url.length > 0) {
            let errorMain = ''
            res.body.errors.url.forEach((message) => {
              errorMain = errorMain + message + '. '
            })
            this.urlCheck.valid = false
            this.urlCheck.errorMessage =
              errorMain.substr(0, errorMain.length - 2) + '.'
          } else {
            this.url = res.body.site.url
            this.urlCheck.valid = null
            this.urlCheck.errorMessage = ''
          }
          this.isSaving = false
        })
    },
    cancelURL() {
      this.url = this.activeSite.url
      this.urlCheck.valid = null
      this.urlCheck.errorMessage = ''
    },
    regenToken() {
      this.$store
        .dispatch('sites/regenerateToken', this.$store.state.user.current.id)
        .then((res) => {
          this.token = res.body.site_token
        })
    },
    copyHook() {
      copyToClipboard(this.incomingHook)
    },
    addHook() {
      this.$store.commit('application/openModal', {
        component: MODAL_SITE_HOOK,
      })
    },

    changeSslEnabled(sslEnabled) {
      this.$store
        .dispatch('sites/update', { site: { ssl_enabled: sslEnabled } })
        .then(() => {
          this.sslEnabled = this.activeSite.sslEnabled
          this.forceSSLEnabled = this.activeSite.forceSSLEnabled
        })
    },
    changeforceSSLEnabled(forceSslEnabled) {
      this.$store
        .dispatch('sites/update', {
          site: { force_ssl_enabled: forceSslEnabled },
        })
        .then(() => {
          this.forceSSLEnabled = this.activeSite.forceSSLEnabled
        })
    },
    changeCompiler() {
      this.isSaving = true
      let params = {
        compiler: this.compiler,
      }
      if (this.compiler == 'webpack') {
        params['build_command'] = this.buildCommand
        params['build_folder'] = this.buildFolder
      }
      this.$store.dispatch('sites/update', { site: params }).then(() => {
        this.isSaving = false
        this.compiler = this.activeSite.compiler
        this.buildCommand = this.activeSite.buildCommand
        this.buildFolder = this.activeSite.buildFolder
      })
    },
    changeSquish(squish) {
      this.$store
        .dispatch('sites/update', { site: { squish: squish } })
        .then(() => {
          this.squish = squish
        })
    },

    deleteSite() {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Site deletion',
          text: 'Type site URL to delete it:',
          confirmString: this.activeSite.url,
          actionBtnLabel: 'Delete',
          showCancelBtn: true,
          action: () => {
            this.$store
              .dispatch('sites/delete', this.activeSite.id)
              .then(() => {
                return true
              })
          },
        },
      })
    },

    save(data) {
      this.$store.dispatch('sites/update', {
        id: this.activeSite.id,
        ...data,
      })
    },
    // called from mounted and watcher of publicAppMap.
    // - Search to which publisher this app has been submitted
    // - Find the publisher and go through initializing.
    async setupPublisher() {
      if (!this.activePublisher) return
      const { parse_server_url, parse_server_app_id } = this.activePublisher
      if (parse_server_url && parse_server_app_id) {
        initParse(parse_server_url, parse_server_app_id)
        await this.searchDeveloperAppBySiteURL()
      }
    },
    // - Search develoeprApp by the site url and init store if existing developerApp found
    // Only being called after publisher selected, as publisher selection means the clarification of parse-server to connect
    async searchDeveloperAppBySiteURL() {
      try {
        const result = await Parse.Cloud.run('searchAppByURL', {
          url: this.activeSite.url,
          parseServerSiteId: this.activePublisher?.parse_server_site_id,
        })
        if (result.appDetail && result.appDetail.slug) {
          this.setApp({
            id: result.appDetail.id,
            Name: result.appDetail.name,
            Slug: result.appDetail.slug,
            URL: result.appDetail.url,
            Dev_URL: result.appDetail.devURL,
            Dev_Mode: result.appDetail.devMode,
            Kind: result.appDetail.kind,
          })
          const { developerContent, developerData, developer } =
            result.appDetail
          if (developerContent) {
            this.setAppContent({
              id: developerContent.id,
              Short_Name: developerContent.shortName,
              Description: developerContent.description,
              Screenshots: developerContent.screenshotObjects,
              Terms_URL: developerContent.termsURL,
              Privacy_URL: developerContent.privacyURL,
              Categories: developerContent.categories.map(
                (category) => category.id
              ),
              Filters: developerContent.filters,
              Listing: developerContent.listing,
              keyImage: developerContent.keyImage,
              Icon: developerContent.icon,
            })
          }

          if (developerData) {
            const Capabilities =
              developerData.capabilities && developerData.capabilities[0]
                ? developerData.capabilities[0].id
                : ''
            this.setAppData({
              id: developerData.id,
              Data_Name: developerData.dataName,
              Capabilities,
              Fee_Type: developerData.feeType,
              Fee_Amount: developerData.feeAmount,
              Permissions: developerData.permissions,
              Sandbox_Permissions: developerData.sandboxPermissions,
              Is_Paid_: developerData.isPaid,
              Status: developerData.status,
              installParams: developerData.installParams,
            })
          }

          if (developer) {
            this.setDeveloper({
              Name: developer.name,
              Company: developer.company,
              Website: developer.website,
              Email: developer.email,
              Country: developer.country,
              IsActive: developer.isActive,
              Verified: developer.verified,
            })
          }
        }
      } catch (error) {
        console.error('Error in searchDeveloperAppBySiteURL', error)
      }
    },
    onOpenParamsForm() {
      this.setPluginInstallParamsDrawerOpened(true)
    },
    startRegistrationFlow() {
      this.setPublishingDrawerOpened(true)
    },
    onCopyBlockLink(blockLink) {
      const currentUrl = window.location.href
      const urlWithoutHash = currentUrl.split('#')[0]
      const url = `${urlWithoutHash}#${blockLink}`
      navigator.clipboard.writeText(url).then(() => {
        window.location.href = url
      })
    },
  },
}
</script>
<style lang="scss">
$style: SiteSettings;
.#{$style} {
  padding: 42px 24px 24px;
  & .v-btn {
    border-radius: 8px;
  }
  &__title {
    font-size: $H20;
    font-weight: 500;
    color: $white;
    margin-bottom: 32px;
  }
  &__grey-text {
    font-size: $H18;
    font-weight: 500;
    color: $N2;
    margin-bottom: 28px;
  }
  &__deploy-text {
    font-size: $H14;
    font-weight: 500;
    color: $N3;
    width: 100px;
    text-align: center;
    &._last {
      color: $white;
      width: 100%;
      margin: 12px;
    }
  }
  &__deploy-small-text {
    font-size: $H12;
    font-weight: 500;
    color: $N2;
    text-align: center;
    letter-spacing: 0.02em;
    line-height: 1.5;
  }
  &__deploy-row {
    display: flex;
    justify-content: stretch;
    align-items: start;
    margin: 0 -12px 40px;
  }
  &__deploy-col {
    width: 100%;
    max-width: 33.3%;
    flex: 1 1 20%;
    padding: 0 12px;
    &._big {
      max-width: 99.9%;
      flex-basis: unset;
      padding: 0;
      padding-right: 19px;
    }
  }
  &__deploy-card {
    border: 1px solid $N5;
    border-radius: 8px;
    padding: 42px 16px 46px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    cursor: pointer;
    transition: border-color 0.2s ease;
    &:not(._last) {
      &:hover {
        border-color: $N2;
      }
    }
    &._last {
      padding: 18px 24px;
      padding-top: 40px;
    }
    &._active {
      background: $selected-area;
      border: 1px solid $selected-border;
      .#{$style}__deploy-text {
        color: $white;
      }
    }
  }
  &__deploy-select-wrapper {
    position: absolute;
    top: 0;
    left: 40px;
    right: 16px;
  }
  &__deploy-select {
    margin: 0 auto;
    padding: 0;
    max-width: 100px;
    & .v-input__slot {
      &:after {
        content: none !important;
      }
    }
  }
  &__deploy-icon {
    margin-bottom: 20px;
  }
  &__last-card-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__deploy-btn {
    border-radius: 8px;
    margin-bottom: 32px;
    &._dropbox {
      &.v-btn {
        color: $white;
      }
    }
    &._github {
      &.v-btn {
        color: $black;
      }
    }
  }
  &__section {
    @extend %section-styles;
    margin-bottom: 24px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  &__section-title {
    color: $white;
    font-weight: 500;
    font-size: $H16;
    margin-bottom: 14px;
    &._margin-more {
      margin-bottom: 28px;
    }
  }
  &__section-text {
    color: $white;
    font-weight: 400;
    font-size: $H14;
    line-height: 1.7;
    span {
      display: block;
      color: $N2;
      b {
        color: $white;
      }
    }
    &._margin-none {
      margin: 0;
    }
    & .#{$style}__link {
      color: $Y1;
      text-decoration: none;
    }
  }
  &__notice {
    background: $NNotice;
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 26px;
    &._mt {
      margin-top: 24px;
      margin-bottom: 0;
    }
  }
  &__notice-text {
    color: $N2;
    font-size: $H14;
    line-height: 1.7;
    span {
      color: $white;
      margin-right: 4px;
      display: inline-block;
    }
    & .#{$style}__link {
      color: $Y1;
      text-decoration: none;
    }
  }
  &__input-label {
    font-size: $H14;
    color: $N2;
    font-weight: 400;
    display: inline-block;
    margin-bottom: 4px;
  }
  &__input {
    &.v-text-field.v-text-field--enclosed {
      margin-bottom: 16px;
    }
    &.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot {
      @extend %input-styles;
    }
  }
  &__success-message {
    background: rgba($G1, 0.3);
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 26px;
  }
  &__error-message {
    background: rgba($R1, 0.3);
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 26px;
    &._last {
      color: $N2;
    }
  }
  &__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    &._with-padding {
      padding: 12px 0;
    }
  }
  &__section-small-text {
    font-size: $H12;
    font-weight: 500;
    letter-spacing: 0.02em;
    line-height: 1.5;
    color: $N3;
  }
  &__delete-btn {
    &.v-btn {
      color: $R1;
    }
  }
  &__plugin-status {
    background: $B4;
    color: $B1;
    border-radius: 8px;
    padding: 4px 8px;
  }
}
.error-main {
  background: #e94a53;
  border-radius: 2px;
}

.success-main {
  background: #51d084;
  border-radius: 2px;
}

.deploy-caption {
  color: white;
  text-align: center;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  background: #44464f;
}

.small-text {
  color: #888888;
  font-style: italic;
}

.notice {
  background: #2f2e41;
  border-radius: 2px;
  color: #a4a4ae;
}

.warning-msg {
  background: rgba(233, 74, 83, 0.3);
  border-radius: 8px;
  color: #a4a4ae;
}

#no-background-hover::before {
  background-color: transparent !important;
}

.copy-block-link {
  color: $Y1;
  fill: $Y1;
  cursor: pointer;
}
</style>
