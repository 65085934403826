import { PublisherInfo } from '@/models/PublisherInfo'
import { Organisation as OrganisationResource } from '@/resources/forge'

const store = {
  namespaced: true,
  state: {
    list: [],
    current: {},
    allPlugins: [],
    installedPlugins: [],
  },
  mutations: {
    /**
     * Sets the list of publisher information in the state.
     * @param {Array} list - The list of publisher information.
     */
    setList(state, list) {
      state.list = list
    },
    /**
     * Sets the current publisher information in the state.
     * @param {Object} params - The parameters for setting the current publisher information.
     */
    setCurrent(state, params) {
      if (params == {}) state.current = new PublisherInfo()
      else state.current = new PublisherInfo(params)
    },
    /**
     * Deletes publisher information from the state.
     * @param {string} id - The ID of the publisher information to be deleted.
     */
    delete(state, id) {
      state.list = state.list.filter((f) => id != f.id)
    },
    /**
     * Adds a new publisher information to the state.
     * @param {Object} form - The publisher information to be added.
     */
    create(state, form) {
      state.list = [...state.list, form]
    },
    /**
     * Updates publisher information in the state.
     * @param {Object} params - The parameters for updating the publisher information.
     */
    update(state, params) {
      const form = state.list.find((f) => f.id == params.id)
      if (!form) return

      Object.assign(form, params)
    },
    /**
     * Sets all available plugins in the state.
     * @param {Array} plugins - The list of all available plugins.
     */
    setAllPlugins(state, plugins) {
      state.allPlugins = plugins
    },
    /**
     * Sets the installed plugins in the state.
     * @param {Array} plugins - The list of installed plugins.
     */
    setInstalledPlugins(state, plugins) {
      state.installedPlugins = plugins
    },
  },
  actions: {
    /**
     * Loads publisher information.
     * @param {Object} load_params - The parameters for loading publisher information.
     */
    async load({ commit }, load_params) {
      const res = await OrganisationResource.getPublisherInfo({
        id: load_params.id,
      })
      const publisher_info = new PublisherInfo(res.body)
      commit('setList', [publisher_info])
      commit('setCurrent', res.body)
    },
  },
  getters: {
    /**
     * Finds publisher information by ID.
     * @param {String} id -  Target project user id
     * @returns {Object|null} The found project user or null if not found.
     */
    findById: (state) => (id) => {
      return state.list.find((c) => c.id == id)
    },
  },
}

export default store
