<template>
  <div class="DashboardMetrics">
    <v-row no-gutters>
      <v-col sm="5" cols="12" class="DashboardMetrics__col">
        <h6 class="DashboardMetrics__title">Build minutes used</h6>
        <v-row no-gutters>
          <v-col cols="auto"
            ><div class="DashboardMetrics__icon-wrapper">
              <the-icon icon="pie-chart" class="DashboardMetrics__icon" /></div
          ></v-col>
          <v-col>
            <span class="DashboardMetrics__value"
              >{{ `${buildMinutes} /` }} <span>{{ buildMax }}</span>
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col sm="6" cols="12" class="DashboardMetrics__col">
        <h6 class="DashboardMetrics__title">Bandwidth used</h6>
        <v-row no-gutters>
          <v-col cols="auto"
            ><div class="DashboardMetrics__icon-wrapper">
              <the-icon
                icon="trending-up"
                class="DashboardMetrics__icon"
              /></div
          ></v-col>
          <v-col>
            <span class="DashboardMetrics__value"
              >{{ `${bandwidth} /` }} <span>{{ bandwidthMax }}</span>
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="DashboardMetrics__divider" />
    <v-row no-gutters>
      <v-col
        sm="5"
        cols="12"
        class="DashboardMetrics__col"
        v-if="isOrganisation"
      >
        <h6 class="DashboardMetrics__title">Members</h6>
        <v-row no-gutters>
          <v-col cols="auto"
            ><div class="DashboardMetrics__icon-wrapper">
              <the-icon
                icon="trending-down"
                class="DashboardMetrics__icon"
              /></div
          ></v-col>
          <v-col>
            <span class="DashboardMetrics__value">{{ members }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        sm="6"
        cols="12"
        class="DashboardMetrics__col"
        :class="{ without_border: !isOrganisation }"
      >
        <h6 class="DashboardMetrics__title">Concurrent builds</h6>
        <v-row no-gutters>
          <v-col cols="auto"
            ><div class="DashboardMetrics__icon-wrapper">
              <the-icon icon="bar-chart" class="DashboardMetrics__icon" /></div
          ></v-col>
          <v-col>
            <span class="DashboardMetrics__value"
              >{{ `${concurentCurrent} /` }} <span>{{ concurentMax }}</span>
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { parseBandwidth } from '@/utils/common'
export default {
  name: 'DashboardMetrics',
  props: {
    buildCurrent: {
      type: [String, Number],
      default: 0,
    },
    buildMax: {
      type: [String, Number],
      default: 200,
    },
    bandwidthCurrent: {
      type: [String, Number],
      default: '100 KB',
    },
    bandwidthMax: {
      type: [String, Number],
      default: '100 GB',
    },
    concurentCurrent: {
      type: [String, Number],
      default: 6,
    },
    concurentMax: {
      type: [String, Number],
      default: 100,
    },
  },
  computed: {
    isOrganisation() {
      return this.$store.state.organisations.current.id != 0
    },
    bandwidth() {
      let objectBandwidth = !this.isOrganisation
        ? this.$store.state.user.current.bandwidth
        : this.$store.state.organisations.current.bandwidth
      return parseBandwidth(objectBandwidth)
    },
    buildMinutes() {
      return !this.isOrganisation
        ? this.$store.state.user.current.buildMinutes
        : this.$store.state.organisations.current.buildMinutes
    },
    members() {
      return this.$store.state.organisations.current.members
    },
  },
}
</script>
<style lang="scss">
$style: DashboardMetrics;
.#{$style} {
  border: 1px solid $N5;
  border-radius: 8px;
  padding: 30px 24px;
  flex: 1;
  display: flex;
  flex-direction: column;
  &__title {
    color: $N2;
    font-size: $H14;
    margin-bottom: 20px;
    font-weight: 400;
  }
  &__col {
    &:first-child {
      border-bottom: 1px solid $N5;
      padding-bottom: 24px !important;
      margin-bottom: 24px;
      @media screen and (min-width: 600px) {
        border-right: 1px solid $N5;
        margin-right: 24px;
        border-bottom: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
    }
    &.without_border {
      border-right: unset !important;
    }
  }
  &__icon-wrapper {
    width: 40px;
    height: 38px;
    border-radius: 8px;
    background-color: $N5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  &__value {
    color: $white;
    font-weight: 600;
    font-size: $H24;
    span {
      color: $N2;
    }
  }
  &__divider {
    width: 100%;
    background-color: $N5;
    margin: 24px 0;
  }
}
</style>
