<template>
  <div class="MiroPicker">
    <v-alert
      v-if="error"
      :value="true"
      severity="error"
      class="miro-picker-error"
    >
      {{ error }}
    </v-alert>
    <v-row>
      <v-col
        v-for="(board, index) in boards"
        :key="index"
        cols="12"
        sm="6"
        md="4"
        lg="3"
      >
        <miro-card :board="board" :onSelect="onSelect" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Parse from 'parse'
import MiroCard from './MiroCard'
export default {
  props: {
    token: String,
    onSelect: Function,
  },
  components: {
    MiroCard,
  },
  data() {
    return {
      boards: [],
      error: '',
    }
  },
  created() {
    this.onInitLoad()
  },
  methods: {
    async onInitLoad() {
      try {
        const res = await Parse.Cloud.run('getAllBoards', { token: this.token })
        if (res.status === 'success') {
          this.boards = res.result.data
          this.isLoading = false
        }
      } catch (e) {
        console.error('Error retrieving workspaces.', e)
      }
    },
  },
}
</script>
