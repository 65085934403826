<template>
  <component :is="getIcon"></component>
</template>
<script>
const getName = (name) => `icon-${name}`
export default {
  name: 'Icon',
  props: {
    icon: {
      type: String,
      default: 'logo',
    },
  },
  components: {
    [getName('logo')]: () => import('../Icons/IconLogo.vue'),
    [getName('blue-logo')]: () => import('../Icons/IconBlueLogo.vue'),
    [getName('logo-small')]: () => import('../Icons/IconLogoSmall.vue'),
    [getName('blue-logo-small')]: () =>
      import('../Icons/IconBlueLogoSmall.vue'),
    [getName('arrow-upin-circle')]: () =>
      import('../Icons/IconArrowUpinCircle.vue'),
    [getName('fill')]: () => import('../Icons/IconFill.vue'),
    [getName('home')]: () => import('../Icons/IconHome.vue'),
    [getName('plugins')]: () => import('../Icons/IconPlugins.vue'),
    [getName('developers')]: () => import('../Icons/IconDevelopers.vue'),
    [getName('report')]: () => import('../Icons/IconReport.vue'),
    [getName('log-out')]: () => import('../Icons/IconLogOut.vue'),
    [getName('bar-chart')]: () => import('../Icons/IconBarChart.vue'),
    [getName('pie-chart')]: () => import('../Icons/IconPieChart.vue'),
    [getName('trending-down')]: () => import('../Icons/IconTrendingDown.vue'),
    [getName('trending-up')]: () => import('../Icons/IconTrendingUp.vue'),
    [getName('column')]: () => import('../Icons/IconColumn.vue'),
    [getName('filter')]: () => import('../Icons/IconFilter.vue'),
    [getName('comment')]: () => import('../Icons/IconComment.vue'),
    [getName('comment-with-dot')]: () =>
      import('../Icons/IconCommentWithDot.vue'),
    [getName('edit')]: () => import('../Icons/IconEdit.vue'),
    [getName('lanch')]: () => import('../Icons/IconLanch.vue'),
    [getName('plus')]: () => import('../Icons/IconPlus.vue'),
    [getName('trash')]: () => import('../Icons/IconTrash.vue'),
    [getName('reset')]: () => import('../Icons/IconReset.vue'),
    [getName('upload')]: () => import('../Icons/IconUpload.vue'),
    [getName('close')]: () => import('../Icons/IconClose.vue'),
    [getName('minus')]: () => import('../Icons/IconMinus.vue'),
    [getName('expand')]: () => import('../Icons/IconExpand.vue'),
    [getName('expand-down')]: () => import('../Icons/IconExpandDown.vue'),
    [getName('drag-and-drop')]: () => import('../Icons/IconDragAndDrop.vue'),
    [getName('shape')]: () => import('../Icons/IconShape.vue'),
    [getName('console')]: () => import('../Icons/IconConsole.vue'),
    [getName('check-in-circle')]: () =>
      import('../Icons/IconCheckInCircle.vue'),
    [getName('close-in-circle')]: () =>
      import('../Icons/IconCloseInCircle.vue'),
    [getName('dropbox')]: () => import('../Icons/IconDropbox.vue'),
    [getName('github')]: () => import('../Icons/IconGitHub.vue'),
    [getName('google')]: () => import('../Icons/IconGoogle.vue'),
    [getName('using-drag-and-drop')]: () =>
      import('../Icons/IconUsingDragAndDrop.vue'),
    [getName('zip')]: () => import('../Icons/IconZip.vue'),
    [getName('folder')]: () => import('../Icons/IconFolder.vue'),
    [getName('settings')]: () => import('../Icons/IconSettings.vue'),
    [getName('arrow-up-and-right')]: () =>
      import('../Icons/IconArrowUpAndRight.vue'),
    [getName('save')]: () => import('../Icons/IconSave.vue'),
    [getName('radio-on')]: () => import('../Icons/IconRadioOn.vue'),
    [getName('radio-off')]: () => import('../Icons/IconRadioOff.vue'),
    [getName('drag-handler')]: () => import('../Icons/IconDragHandler.vue'),
    [getName('square-edit')]: () => import('../Icons/IconSquareEdit.vue'),
    [getName('arrow-left')]: () => import('../Icons/IconArrowLeft.vue'),
    [getName('more-vert')]: () => import('../Icons/IconMoreVert.vue'),
    [getName('download')]: () => import('../Icons/IconDownload.vue'),
    [getName('link')]: () => import('../Icons/IconLink.vue'),
    [getName('verified')]: () => import('../Icons/IconVerified.vue'),
    [getName('warn-verified')]: () => import('../Icons/IconWarnVerified.vue'),
    [getName('action-vertical')]: () =>
      import('../Icons/IconActionVertical.vue'),
    [getName('hide')]: () => import('../Icons/IconHide.vue'),
    [getName('trash-filled')]: () => import('../Icons/IconTrashFilled.vue'),
    [getName('email')]: () => import('../Icons/IconEmail.vue'),
    [getName('download')]: () => import('../Icons/IconDownload.vue'),
    [getName('document')]: () => import('../Icons/IconDocument.vue'),
    [getName('new-upload')]: () => import('../Icons/IconNewUpload.vue'),
    [getName('warn')]: () => import('../Icons/IconWarn.vue'),
    [getName('eye-on')]: () => import('../Icons/IconEyeOn.vue'),
    [getName('review')]: () => import('../Icons/IconReview.vue'),
    [getName('policy')]: () => import('../Icons/IconPolicy.vue'),
    [getName('SDK')]: () => import('../Icons/IconSDK.vue'),
  },
  computed: {
    getIcon() {
      return getName(this.icon)
    },
  },
}
</script>
