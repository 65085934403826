import { render, staticRenderFns } from "./TheProject.vue?vue&type=template&id=f920a03c&scoped=true&"
import script from "./TheProject.vue?vue&type=script&lang=js&"
export * from "./TheProject.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f920a03c",
  null
  
)

export default component.exports