<template>
  <div class="narrow-container mx-2" v-if="!loading">
    <h4 class="SiteSettings__title mt-4 mx-4">Members</h4>

    <v-dialog
      v-model="changeOwnerFailed"
      max-width="400"
      v-if="changeOwnerEnabled"
    >
      <v-card class="changeOwnerFailedCard">
        <v-card-title> Change site owner </v-card-title>
        <v-card-text> {{ changeOwnerMessage }} </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="y1" @click="changeOwnerFailed = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <div
      class="d-flex flex-column flex-sm-row align-sm-center justify-space-between gap-3 pa-0 mt-4 mx-4"
    >
      <div class="d-flex flex-column flex-sm-row gap-3">
        <v-text-field
          v-model="emailFilter"
          @input="filterActions"
          prepend-inner-icon="mdi-magnify"
          filled
          dark
          dense
          hide-details
          placeholder="Search by email or username"
          class="flex-grow-1 pa-0 inheritInput"
        />

        <v-select
          :value="filterRoleInput"
          @change="setNewRoleInput"
          :items="rolesForFilter"
          item-text="name"
          item-value="value"
          :menu-props="{
            contentClass: 'AccessTable__select_filter_menu',
            bottom: true,
            offsetY: true,
          }"
          class="AccessTable__select_filter"
          style="display: inline-block; border-color: #1f1e31"
          color="#5e636d"
          return-object
          outlined
          hide-details
          dense
        />

        <v-select
          :value="filterStatusInput"
          @change="setNewStatusInput"
          :items="statusFilter"
          item-text="name"
          item-value="value"
          class="AccessTable__select_filter"
          attach
          auto
          :menu-props="{
            contentClass: 'AccessTable__select_filter_menu',
            bottom: true,
            offsetY: true,
          }"
          style="display: inline-block; border-color: #1f1e31"
          color="#5e636d"
          return-object
          outlined
          hide-details
          dense
        />
      </div>
      <v-btn
        size="large"
        class="AccessTable__new_users_btn"
        @click="addNewUsers"
      >
        <v-icon> mdi-plus </v-icon>
        Invite members
      </v-btn>
    </div>

    <div class="members_table mx-4 mt-6">
      <v-data-table
        :headers="headers"
        :items="accessList"
        item-key="user_id"
        class="organisations_access_members_table"
        :sort_by="['name']"
      >
        <template v-slot:item.role="{ item }">
          <span
            v-if="!isOwner() || !canBeRemoved(item.user_id)"
            :style="{ 'text-transform': 'capitalize' }"
          >
            {{ userRole(item) }}
          </span>
          <v-select
            v-else-if="memberAccessTypeProp != 3"
            :value="userRole()"
            :ref="memberAccessType + 'owner_change_' + item.user_id"
            @change="
              makeAnOwner(
                item,
                memberAccessType + 'owner_change_' + item.user_id
              )
            "
            :menu-props="{
              contentClass: 'AccessTable__table_role_select',
              bottom: true,
              offsetY: true,
            }"
            :items="userRoles()"
            style="max-width: 100px; display: inline-block"
            :disabled="!changeOwnerEnabled || item.invited"
            return-object
            hide-details
            dense
          />
          <v-select
            v-else
            v-model="item.role"
            :ref="'org_role_change_' + item.user_id"
            @change="
              changeRole(item, 'org_role_change_' + item.user_id, item.role)
            "
            :items="roleOptions"
            item-text="label"
            item-value="value"
            :menu-props="{
              contentClass: 'AccessTable__table_role_select',
              bottom: true,
              offsetY: true,
            }"
            :readonly="userIsOwner(item.user_id) || !canManage()"
            style="max-width: 100px; display: inline-block"
            return-object
            hide-details
            dense
          />
        </template>

        <template v-slot:item.name="{ item }">
          <div>
            <div class="d-inline-block">
              <img
                v-if="!avatarEmpty(item)"
                :src="item.avatar"
                alt=""
                class="the-app-bar__image-wrapper"
              />
              <avatar
                v-else
                :rounded="true"
                :initials="initials(item)"
                username="name"
                :size="26"
              />
            </div>
            <div class="member_table_name">
              {{ item.name }} {{ currentUser(item.user_id) ? ' (You)' : '' }}
            </div>
          </div>
        </template>

        <template v-slot:item.invited="{ item }">
          <span
            class="user_status"
            :class="item.invited ? 'invited' : 'active_user'"
          >
            {{ item.invited ? 'Pending invite' : 'Active' }}
          </span>
        </template>

        <template v-slot:item.last_active="{ item }">
          <span v-if="item.last_active != null">
            {{ item.last_active }}
          </span>
          <hr v-else style="width: 15px" />
        </template>

        <template v-slot:item.actions="{ item }">
          <span
            class="remove"
            @click="removeObject(item)"
            v-if="canBeRemoved(item.user_id) && isOwner()"
          >
            <v-icon dark right color="red" class="ml-0" medium>
              mdi-delete-outline
            </v-icon>
          </span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { MODAL_ALERT, MODAL_INVITE_MEMBERS } from '@/components/Modals'
import { Site as SiteResource } from '@/resources/forge'
import Avatar from 'vue-avatar'
export default {
  name: 'MemberAccess',
  components: { Avatar },
  props: {
    usersProp: Array,
    invitedProp: Array,
    roles: Array,
    loadingProp: {
      type: Boolean,
      default: false,
    },
    changeOwnerEnabledProp: {
      type: Boolean,
      default: false,
    },
    memberAccessTypeProp: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      email: '',
      loading: this.loadingProp,
      users: this.usersProp,
      invited: this.invitedProp,
      memberAccessType: this.memberAccessTypeProp,
      changeOwnerEnabled: this.changeOwnerEnabledProp,
      emailFilter: '',
      filterRoleInput: 'all_roles',
      filterStatusInput: 'all_statuses',
      organisationRolesFilter: [
        { name: 'All Roles', value: 'all_roles' },
        { name: 'Owner', value: 'owner' },
        { name: 'Admin', value: 'admin' },
        { name: 'Editor', value: 'editor' },
      ],
      rolesFilter: [
        { name: 'All Roles', value: 'all_roles' },
        { name: 'Owner', value: 'owner' },
        { name: 'Editor', value: 'editor' },
      ],
      statusFilter: [
        { name: 'All Statuses', value: 'all_statuses' },
        { name: 'Active', value: 'active' },
        { name: 'Pending Invite', value: 'pending' },
      ],
      roleOptions: [
        {
          label: 'Admin',
          value: 'admin',
        },
        {
          label: 'Editor',
          value: 'editor',
        },
      ],
      accessList: [],
      changeOwnerFailed: false,
      changeOwnerMessage: '',
      headers: [
        {
          text: 'USERNAME',
          value: 'name',
        },
        {
          text: 'EMAIL',
          value: 'email',
        },
        {
          text: 'ROLE',
          value: 'role',
          sortable: false,
          width: '140px',
          cellClass: 'AccessTable__data_table_td',
        },
        {
          text: 'STATUS',
          value: 'invited',
          sortable: 'true',
          width: '151px',
        },
        {
          text: 'LAST ACTIVE',
          value: 'last_active',
          width: '131px',
        },
        { text: '', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {
    rolesForFilter() {
      if (this.memberAccessType != 3) return this.rolesFilter
      else return this.organisationRolesFilter
    },
  },
  methods: {
    removeUser(user) {
      if (this.memberAccessType == 1)
        this.$store.dispatch('site_user/delete', user.id)
      else if (this.memberAccessType == 2)
        this.$store.dispatch('project_user/delete', user.id)
      else if (this.memberAccessType == 3)
        this.$store.dispatch('organisation_user/delete', user.id)
    },
    removeInvitation(invitation) {
      this.$store.dispatch('invitation/delete', invitation.id)
    },
    userIsOwner(user_id) {
      return user_id == this.ownerId()
    },
    currentUser(user_id) {
      return user_id == this.$store.state.user.current.id
    },
    isOwner() {
      return this.$store.state.user.current.id == this.ownerId()
    },
    userRole(item) {
      if (item && item.user_id == this.ownerId()) {
        return 'Owner'
      }
      if (this.memberAccessType === 3) {
        return item.role
      }
      return 'Editor'
    },
    canBeRemoved(user_id) {
      return user_id != this.ownerId()
    },
    canManage() {
      if (this.memberAccessType != 3)
        return this.ownerId() == this.$store.state.user.current.id
      else return this.$store.state.organisations.current.role == 'admin'
    },
    initials(user) {
      if (user.name != null && user.name.length > 0)
        return user.name[0].toUpperCase()
      else return user.email[0].toUpperCase()
    },
    avatarEmpty(user) {
      return user.avatar == null || user.avatar.length == 0
    },
    filterActions() {
      this.accessList = this.users.concat(this.invited)
      this.filterEmailOrUsername()
      this.filterStatus()
      this.filterRole()
    },
    filterEmailOrUsername() {
      if (this.emailFilter.length != 0)
        this.accessList = this.accessList.filter(
          (user) =>
            user.name.includes(this.emailFilter) ||
            user.email.includes(this.emailFilter)
        )
    },
    setNewStatusInput(a) {
      this.filterStatusInput = a.value
      this.filterActions()
    },
    filterStatus() {
      if (this.filterStatusInput != 'all_statuses') {
        if (this.filterStatusInput == 'active')
          this.accessList = this.accessList.filter((object) => !object.invited)
        else
          this.accessList = this.accessList.filter((object) => object.invited)
      }
    },
    setNewRoleInput(a) {
      this.filterRoleInput = a.value
      this.filterActions()
    },
    ownerId() {
      let owner_id = 0
      if (this.memberAccessType == 1) owner_id = this.activeSite.userId
      else if (this.memberAccessType == 2)
        owner_id = this.$store.state.projects.active.user_id
      else if (this.memberAccessType == 3)
        owner_id = this.$store.state.organisations.current.user_id
      return owner_id
    },
    filterRole() {
      const owner_id = this.ownerId()
      if (this.filterRoleInput != 'all_roles') {
        if (this.filterRoleInput == 'owner')
          this.accessList = this.accessList.filter(
            (object) => object.user_id == owner_id
          )
        else if (this.memberAccessType != 3)
          this.accessList = this.accessList.filter(
            (object) => object.user_id != owner_id
          )
        else if (this.memberAccessType == 3)
          this.accessList = this.accessList.filter(
            (object) =>
              object.role == this.filterRoleInput && object.user_id != owner_id
          )
      }
    },
    addNewUsers() {
      let rolesArray =
        this.memberAccessType == 3 ? ['Editor', 'Admin'] : ['Editor']
      this.$store.commit('application/openModal', {
        component: MODAL_INVITE_MEMBERS,
        props: {
          inviteAction: this.memberAccessType,
          rolesDisabled: this.memberAccessType != 3,
          roles: rolesArray,
        },
      })
    },
    removeObject(object) {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Remove Member',
          text: 'Do you really want to remove access for this member?',
          actionBtnLabel: 'Remove',
          showCancelBtn: true,
          action: () => {
            if (object.invited) this.removeInvitation(object)
            else this.removeUser(object)
          },
        },
      })
    },
    makeAnOwner(user, ref_name, prev_role) {
      if (!this.changeOwnerEnabled) return
      let app_url = location.protocol + '//' + location.host
      let success = null
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Change site owner',
          text: 'Are you sure? To this user will be send an email to confirm change.',
          actionBtnLabel: 'Change',
          showCancelBtn: true,
          action: () => {
            SiteResource.changeSiteOwner(
              { id: this.activeSite.id },
              { new_owner_id: user.user_id, app_url: app_url }
            ).catch((e) => {
              setTimeout(() => {
                this.changeOwnerMessage = e.body.message
                this.changeOwnerFailed = true
              }, 1800)
            })
          },
        },
      })
      setTimeout(() => {
        this.$refs[ref_name].selectedItems[0] = 'Editor'
        this.$refs[ref_name].initialValue = 'Editor'
        this.$refs[ref_name].lazyValue = 'Editor'
      }, 150)
    },
    changeRole(obj, ref_name, old_role) {
      if (this.memberAccessType != 3) return
      let role = 'editor'
      if (obj.invited)
        role = this.$store.getters['invitation/findById'](obj.id).role
      else role = this.$store.getters['organisation_user/findById'](obj.id).role
      const obj_type = obj.invited ? 'invitation' : 'organisation_user'
      let params = {
        id: obj.id,
      }
      params[obj_type] = { role: obj.role.value }
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Change user Role',
          text: `Are you sure? Role will be changed to ${obj.role.value}`,
          actionBtnLabel: 'Confirm',
          showCancelBtn: true,
          action: () => {
            this.$store.dispatch(obj_type + '/changeRole', params)
          },
        },
      })
      setTimeout(() => {
        this.$refs[ref_name].selectedItems[0] = role.toLowerCase()
        this.$refs[ref_name].initialValue = role.toLowerCase()
        this.$refs[ref_name].lazyValue = role.toLowerCase()
      }, 150)
    },
    userRoles() {
      return ['Owner', 'Editor']
    },
  },
  watch: {
    usersProp: {
      handler(newVal, oldVal) {
        this.users = _.cloneDeep(newVal)
        this.accessList = this.users.concat(this.invited)
        this.filterActions()
        this.loading = false
      },
      deep: true,
    },
    invitedProp: {
      handler(newVal, oldVal) {
        this.invited = _.cloneDeep(newVal)
        this.accessList = this.users.concat(this.invited)
        this.filterActions()
        this.loading = false
      },
      deep: true,
    },
    loadingProp: {
      handler(newVal, oldVal) {
        this.loading = newVal
      },
    },
    changeOwnerEnabledProp: {
      handler(newVal, oldVal) {
        this.changeOwnerEnabled = newVal
      },
    },
    memberAccessTypeProp: {
      handler(newVal, oldVal) {
        this.memberAccessType = newVal
      },
    },
  },
}
</script>

<style lang="scss" scoped>
$style: AccessTable;
.#{$style} {
  &__new_users_btn {
    // position: absolute;
    height: 40px !important;
    min-width: 189px !important;
    background-color: $N5 !important;
    // right: 26px;
  }

  &__select_filter {
    max-width: 168px;
    @media screen and (max-width: 600px) {
      max-width: inherit;
    }
    fieldset {
      border-color: $N6;
    }
    .v-select__selection {
      color: $N2;
    }
    &.v-select .v-select__selections input {
      display: none !important;
    }
  }

  &__select_filter_menu {
    margin-top: 44px !important;
    .v-list {
      background: $N4 !important;
      min-width: 118px !important;
      border-radius: 8px;
    }
  }
  &__table_role_select {
    .v-list {
      background: $N4 !important;
    }
  }
}
.changeOwnerFailedCard {
  background: rgba(37, 36, 55, 0.9) !important;
  border: 1px solid #2e2c42;
  box-shadow: 0px 20px 60px rgb(0 0 0 / 25%);
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 8px !important;
  padding: 17px 0px 0;
  width: 100%;

  .v-card__title {
    font-weight: 500 !important;
    font-size: 16px !important;
    color: #fff !important;
  }
  .v-card__text {
    display: block;
    font-size: 14px;
    color: #a4a4ae;
    font-weight: 400;
    margin-bottom: 4px;
  }
  .v-card__actions {
    button {
      margin-bottom: 4px;
    }
  }
}
.owner {
  background: orange;
  color: white;
  border-radius: 4px;
}
.user_status {
  padding: 8px;
  border-radius: 8px;
  &.active_user {
    background: green;
    color: lightgreen;
  }

  &.invited {
    background: rgba(255, 126, 33, 0.2);
    color: $Y1;
  }
}
.organisations_access_members_table {
  background: #1f1e31 !important;
  border: 1px solid #2e2c42;
  header {
    background-color: #1f1e31 !important;
  }
}

.emailFilterBlock {
  max-width: 30%;

  .v-input__slot {
    background: inherit !important;
    border: 1px solid $N6;
  }

  i {
    color: grey !important;
  }
}

.member_table_name {
  width: 70%;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
}
.SiteAccess__Select {
  color: white;
  cursor: pointer;
}
.remove {
  cursor: pointer;
  color: #e94a53;
  border: none;
}
.access-tab {
  background: #1c1b2e;
}

.avatar-placeholder-wrapper {
  height: 26px;
  width: 26px;
  border-radius: 100%;
  border: 1px solid #b3b3b3;
  overflow: hidden;

  .avatar-placeholder {
    height: 24px;
  }
}
</style>
