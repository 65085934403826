export class ParseCloud {
  id = null
  parse_setting_id = null
  custom_code = null
  current_version_id = false
  templates_json = null

  constructor(origin) {
    if (!origin) return
    this.id = origin.id
    this.parse_setting_id = origin.parse_setting_id
    this.custom_code = origin.custom_code
    this.templates_json = origin.templates_json
    this.current_version_id = origin.current_version_id
  }

  getServerObject() {
    return {
      site_id: this.site_id,
      user_id: this.user_id,
      templates_json: this.templates_json,
      custom_code: this.custom_code,
    }
  }
}
