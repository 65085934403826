<template>
  <v-card
    :loading="loading"
    :class="{ loading }"
    class="modal_window ModalCreateOrganisation"
    max-width="600px"
  >
    <h4 class="ModalCreateOrganisation__title">Create Organisation</h4>
    <div v-if="!loading">
      <span class="ModalCreateOrganisation__label">Choose a name</span>
      <v-text-field
        v-model="name"
        :disabled="loading"
        class="ModalCreateOrganisation__input"
        autofocus
        hide-details
        filled
        dense
      />
      <div v-if="errorMain" class="error-main mt-4 pa-4">
        {{ errorMain }}
      </div>
      <div class="ModalCreateOrganisation__footer">
        <v-btn @click="close" :disabled="loading" color="n5" class=""
          >Cancel</v-btn
        >
        <v-btn @click="onCreate" :disabled="loading" color="y1">Create</v-btn>
      </div>
    </div>
    <div v-else class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
  </v-card>
</template>

<script>
import { guid } from '@/utils/common'
import { IntersectingCirclesSpinner } from 'epic-spinners'

export default {
  name: 'ModalCreateOrganisation',
  components: { IntersectingCirclesSpinner },
  data() {
    return {
      name: '',

      loading: false,
      errorMain: '',
    }
  },
  methods: {
    async onCreate() {
      if (!this.name) return

      const organisationData = {
        name: this.name,
      }

      this.loading = true
      await this.$store
        .dispatch('organisations/create', organisationData)
        .then(() => {
          location = '/'
          this.loading = false
          this.$emit('close')
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          if (!error) return
          let errorMain = ''
          error.body.errors.forEach((message) => {
            errorMain = errorMain + message + '. '
          })
          this.errorMain = errorMain
        })
    },
    close() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped lang="scss">
$style: ModalCreateOrganisation;

.#{$style} {
  &.theme--dark.v-card {
    @extend %modal-body-styles;
    min-width: 600px;
    padding: 24px 24px 0;
  }
  &__title {
    font-weight: 500;
    font-size: $H16;
    color: $white;
    margin-bottom: 32px;
  }
  &__label {
    @extend %label-styles;
  }
  &__input {
    &.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot {
      @extend %input-styles;
    }
    &.v-input input {
      color: $white;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0;
    gap: 16px;
    & > .theme--dark.v-btn {
      color: $white;
    }
  }
  .error-main {
    background: #e94a53;
    border-radius: 2px;
  }

  .spinner {
    display: flex;
    width: 100%;
    min-width: 150px;
    height: 100%;
    align-items: center;
    margin-bottom: 15px;
    justify-content: center;
  }
}
.loading {
  pointer-events: none;
}
.error-main {
  background: #e94a53;
  border-radius: 2px;
}
</style>
