<template>
  <div>
    <div class="ProfileBilling" v-if="!user.isPaid && !enterClicked">
      <div class="ProfileBilling__card">
        <h3 class="ProfileBilling__title">Enter billing details</h3>

        <div class="ProfileBilling__texts">
          <p>
            <span>Ready to take Forge to the next level?</span>
          </p>
          <p>Set up your billing information to get started.</p>
          <p>
            Plans start from only $4 / month, and you can cancel at any time.
          </p>
        </div>

        <v-btn @click="enter" color="y1" class="ProfileBilling__btn" dark>
          Enter billing details
        </v-btn>
      </div>
    </div>
    <div v-else-if="user.isPaid">
      <PaidBilling
        :baseFeatures="baseFeatures"
        :plans="plans"
        :currentPlan="user.plan_id"
        :userId="user.id"
        :sitesCount="sites.length"
      />
    </div>
  </div>
</template>

<script>
import { MODAL_ENTER_BILLING } from '@/components/Modals'
import { mapState } from 'vuex'
import PaidBilling from '@/components/TheProfile/PaidBilling'
export default {
  name: 'ProfileBilling',
  components: { PaidBilling },
  data() {
    return {
      enterClicked: false,
      baseFeatures: [
        'Custom Domains',
        'TurboJS Enabled',
        'Global CDN',
        '24/7 Support',
      ],
      plans: [
        {
          name: 'Lead',
          cost: '$4 / month',
          sitesCount: '1 site',
          bandwidth: '2GB',
          extra: 25,
        },
        {
          name: 'Copper',
          cost: '$10 / month',
          sitesCount: '10 sites',
          bandwidth: '10GB',
          extra: 20,
        },
        {
          name: 'Bronze',
          cost: '$50 / month',
          sitesCount: 'Unlimited sites',
          bandwidth: '100GB',
          extra: 18,
        },
      ],
    }
  },
  methods: {
    enter() {
      this.$store.commit('application/openModal', {
        component: MODAL_ENTER_BILLING,
        props: {
          baseFeatures: this.baseFeatures,
          plans: this.plans,
          currentPlan: this.user.plan_id,
          userId: this.user.id,
        },
      })
    },
  },
  computed: {
    ...mapState({
      sites: (state) => state.sites.list,
      user: (state) => state.user.current,
    }),
  },
}
</script>

<style lang="scss">
$style: ProfileBilling;
.#{$style} {
  width: 100%;
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  &__card {
    background: $N4;
    border: 1px solid $N5;
    border-radius: 8px;
    padding: 32px 24px 40px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__title {
    font-weight: 500;
    font-size: $H20;
    color: $white;
    margin-bottom: 24px;
  }
  &__texts {
    text-align: center;
    p {
      font-size: $H14;
      font-weight: 300;
      span {
        font-weight: 500;
      }
    }
  }
}
</style>
