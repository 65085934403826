<template>
  <div class="SiteVersions" @dragenter.prevent="onDragover">
    <v-fade-transition>
      <div
        class="SiteVersions__drag-and-drop-mask"
        v-if="isDropdownMaskActive"
        @dragleave.prevent="onDragend"
        v-cloak
        @drop.prevent="onFileChanged"
        @dragover.prevent
      >
        <div class="SiteVersions__drag-and-drop-mask-wrapper">
          <p class="SiteVersions__drag-and-drop-mask-title">
            <the-icon
              icon="drag-and-drop"
              class="SiteVersions__drag-and-drop-mask-icon"
            />
            Drop your Zip. file here to create a new version
          </p>
        </div>
      </div>
    </v-fade-transition>
    <v-row no-gutters class="SiteVersions__row">
      <v-col sm="4" cols="12">
        <div class="SiteVersions__upload-card">
          <h6 class="SiteVersions__upload-title" v-if="!siteNameEdit">
            <span> {{ site_name }} </span>
            <v-icon
              small
              color="#d6d3d3"
              class="list-item-icon mr-4"
              @click="startSiteEdit"
            >
              mdi-square-edit-outline
            </v-icon>
          </h6>
          <h6 class="SiteVersions__upload-title" v-else>
            <v-text-field
              v-on:keyup.enter="saveSiteName"
              @blur="saveSiteName"
              autofocus
              v-model="siteName"
              class="mt-1"
              placeholder=""
              hide-details="auto"
              filled
              dark
              dense
            />
          </h6>
          <p class="SiteVersions__upload-link" @click="openSiteUrl">
            <span class="SiteVersions__upload-dot" />
            {{ activeSite.url }}
            <the-icon icon="lanch" class="SiteVersions__upload-lanch" />
          </p>
          <div v-if="!serviceConnected">
            <input
              id="uploader-input"
              accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
              class="SiteVersions__upload-input"
              type="file"
              @change="onFileChanged"
            />
            <v-btn
              class="SiteVersions__upload-btn"
              @click="chooseFile"
              v-cloak
              light
              @drop.prevent="onFileChanged"
              @dragover.prevent
            >
              <the-icon icon="upload" class="SiteVersions__upload-icon" />
              Upload new version
            </v-btn>
            <p v-if="uploadError" class="SiteVersions__upload-error">
              {{ uploadError }}
            </p>
          </div>
          <div v-else>
            <v-btn dark class="deployBtn" @click="deployFromSource">
              <v-icon left> {{ serviceName.icon }}</v-icon> Deploy From
              {{ serviceName.name }}
            </v-btn>
            <div class="pt-6">
              Synced with:
              <v-btn
                text
                class="synced-btn orange--text"
                :to="{ name: 'site_settings' }"
              >
                {{ serviceName.path }}
              </v-btn>
            </div>
          </div>
          <p class="SiteVersions__upload-text">
            Or deploy a new version of the site. Just
            <span>Drag & Drop a Zip file</span> of your build onto the Version
            Timeline
          </p>
        </div>
      </v-col>
      <v-col sm="8" cols="12">
        <div
          class="SiteVersions__window"
          :class="{ _expanded: windowExpanded }"
        >
          <div class="SiteVersions__window-header">
            <div class="SiteVersions__window-buttons">
              <span class="SiteVersions__window-button _red">
                <the-icon icon="close" />
              </span>
              <span class="SiteVersions__window-button _orange">
                <the-icon icon="minus" />
              </span>
              <span
                class="SiteVersions__window-button _green"
                @click="windowExpanded = !windowExpanded"
              >
                <the-icon icon="expand" />
              </span>
              <v-spacer />
              <v-btn-toggle
                v-model="isMobileView"
                dense
                class="view-mode-toggle"
              >
                <v-btn :value="false" small class="px-2">
                  <v-icon small>mdi-desktop-mac</v-icon>
                </v-btn>
                <v-btn :value="true" small class="px-2">
                  <v-icon small>mdi-cellphone</v-icon>
                </v-btn>
              </v-btn-toggle>
            </div>
          </div>
          <div class="SiteVersions__window-progress" v-if="showUploader">
            <div class="SiteVersions__window-progress-content">
              <div class="SiteVersions__window-progress-controls">
                <div class="SiteVersions__window-progress-wrapper">
                  <h6 class="SiteVersions__window-progress-title">
                    Uploading...
                  </h6>
                  <span class="SiteVersions__window-progress-percents"
                    >{{ percents }} %</span
                  >
                </div>
                <v-btn
                  @click="stopUploading"
                  color="n5"
                  class="SiteVersions__window-progress-btn"
                  >Cancel</v-btn
                >
              </div>
              <v-progress-linear
                height="4"
                color="y1"
                background-color="n5"
                rounded
                class="SiteVersions__window-progress-progress"
                :value="percents"
              ></v-progress-linear>
            </div>
          </div>
          <iframe
            :class="['SiteVersions__preview-iframe', iframeClass]"
            :src="iframeUrl"
            id="preview"
            target="view"
            v-else
          />
          <div class="SiteVersions__mask" />
        </div>
      </v-col>
    </v-row>

    <div class="SiteVersions__versions" v-if="!loading">
      <div v-cloak @drop.prevent="onFileChanged" @dragover.prevent>
        <SiteVersionElement
          v-for="version in versions"
          :key="version.id"
          :version="version"
        />
      </div>
    </div>

    <v-dialog
      v-model="showCelebration"
      persistent
      max-width="600"
      content-class="SiteVersions__celebration-modal"
    >
      <div class="SiteVersions__celebration-content">
        <h2 class="SiteVersions__celebration-title">Your site is ready!</h2>
        <p class="SiteVersions__celebration-text">
          Congratulations on creating your first site! Now you're ready to start
          building something amazing.
        </p>

        <div class="SiteVersions__celebration-links">
          <a
            href="https://docs.getforge.com"
            target="_blank"
            class="SiteVersions__celebration-link"
          >
            <v-icon small class="mr-2">mdi-book-open-page-variant</v-icon>
            Read the docs
          </a>
          <a
            href="https://community.beach.io/c/products/forge"
            target="_blank"
            class="SiteVersions__celebration-link"
          >
            <v-icon small class="mr-2">mdi-account-group</v-icon>
            Join our community
          </a>
        </div>

        <div class="SiteVersions__celebration-cta">
          <v-btn
            color="y1"
            class="SiteVersions__celebration-button mb-4"
            to="/profile/billing"
          >
            Select a site plan
            <v-icon small class="ml-2">mdi-arrow-right</v-icon>
          </v-btn>

          <v-btn
            text
            @click="dismissCelebration"
            class="SiteVersions__celebration-button"
          >
            Continue to my site
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import SiteVersionElement from '@/components/TheSite/SiteVersion/SiteVersionElement'
import { MODAL_NEW_VERSION_DEPLOYED, MODAL_COMMENTS } from '@/components/Modals'
import { AWS_BUCKET } from '@/env'
import confetti from 'canvas-confetti'

export default {
  name: 'SiteVersions',
  components: { SiteVersionElement },
  data() {
    return {
      windowExpanded: false,
      loading: true,
      current_site_id: '',
      uploadError: '',
      isDropdownMaskActive: false,
      percents: 0,
      siteNameEdit: false,
      siteName: '',
      saving: false,
      showUploader: false,
      uploadPromise: null,
      previousRequest: null,
      isMobileView: false,
      showCelebration: false,
    }
  },
  created() {
    this.$store.dispatch('sites/getPublicFileUrl', this.activeSite.id)
    this.fetchVersions()
    this.siteName = this.activeSite.site_name
    if (this.$route.query.celebrate === 'firstsite') {
      this.showCelebration = true
      this.$nextTick(() => {
        this.triggerCelebration()
      })
    }
  },
  mounted() {},
  computed: {
    versions() {
      if (this.current_site_id != this.activeSite.id) {
        this.$store.dispatch('sites/getPublicFileUrl', this.activeSite.id)
        this.fetchVersions()
      }
      if (this.loading) return []
      else
        return this.activeSite.versionIds
          .map((id) => this.$store.getters['versions/findById'](id))
          .sort((a, b) => b.scopedId - a.scopedId)
    },
    site_name() {
      return this.saving ? this.siteName : this.activeSite.site_name
    },
    siteURL() {
      let url = 'http://' + this.activeSite.url
      if (
        this.activeSite.use_ssl ||
        this.activeSite.url.includes('.getforge.io')
      )
        url = 'https://' + this.activeSite.url
      return url
    },
    iframeUrl() {
      if (this.activeSite && this.activeSite.url) {
        return `https://${this.activeSite.url}?slug=${this.$store.state.sites.slug}`
      } else {
        return (
          this.$store.state.sites.publicFileUrl +
          '?slug=' +
          this.$store.state.sites.slug
        )
      }
    },
    iconSize() {
      return this.windowExpanded
        ? require('@/assets/icon-collapse.svg')
        : require('@/assets/icon-expand.svg')
    },
    serviceConnected() {
      return (
        this.activeSite.githubPath ||
        this.activeSite.dropboxPath ||
        this.activeSite.bitbucketPath
      )
    },
    serviceName() {
      if (this.activeSite.githubPath)
        return {
          name: 'Github',
          icon: 'mdi-github',
          path: this.activeSite.githubPath,
        }
      else if (this.activeSite.bitbucketPath)
        return {
          name: 'Bitbucket',
          icon: 'mdi-bitbucket',
          path: this.activeSite.bitbucketPath,
        }
      else
        return {
          name: 'Dropbox',
          icon: 'mdi-dropbox',
          path: this.activeSite.dropboxPath,
        }
    },
    iframeClass() {
      return this.isMobileView ? 'mobile-view' : 'browser-view'
    },
  },
  methods: {
    ...mapMutations('sites', ['setPreviousKind']),
    stopUploading() {
      this.previousRequest.abort()
    },
    startSiteEdit() {
      this.siteName = this.site_name
      this.siteNameEdit = true
    },
    saveSiteName() {
      if (this.siteName != this.site_name) {
        this.saving = true
        this.$store
          .dispatch('sites/update', { site: { site_name: this.siteName } })
          .then((res) => {
            if (res.body.errors != undefined) {
              this.$store.commit('notifications/addToMessages', {
                text: 'Your site name should be unique',
                status: 'error',
                id: Math.random().toString(16).slice(2),
              })
              this.siteName = this.activeSite.site_name
            } else
              this.$store.commit('notifications/addToMessages', {
                text: 'Your site name was successfully updated',
                status: 'success',
                id: Math.random().toString(16).slice(2),
              })
            this.saving = false
          })
      }
      this.siteNameEdit = false
    },
    openCommentsModal() {
      this.$store.commit('application/openModal', {
        component: MODAL_COMMENTS,
        props: {
          version: this.$store.getters['versions/findById'](
            this.$route.query.versionId
          ),
          cloudCode: false,
        },
      })
    },
    openSiteUrl() {
      let site_url = ''
      if (this.activeSite.use_ssl) site_url = 'https://' + this.activeSite.url
      else site_url = 'http://' + this.activeSite.url
      window.open(site_url, '_blank')
    },
    async onFileChanged(event) {
      if (this.isDropdownMaskActive) this.isDropdownMaskActive = false
      let files =
        event.dataTransfer == undefined
          ? event.target.files
          : event.dataTransfer.files
      if (files.length > 1)
        return (this.uploadError = 'You can upload only one ZIP archive')
      const selectedFile = files[0]
      if (selectedFile.name.search(/\.zip/) <= 0)
        return (this.uploadError = 'You can upload only ZIP archive!')
      if ((selectedFile.size / 1024 / 1024).toFixed(4) > 200)
        return (this.uploadError = 'Project should be less than 200 MB')
      this.uploadError = ''
      this.$store.dispatch('versions/createTmpVersion', {
        description: selectedFile.name,
        site_id: this.activeSite.id,
        percent_deployed: 0,
      })

      this.$store
        .dispatch('sites/getPolicy')
        .then((res) => {
          const formData = new FormData()

          for (let param in res.body) formData.append(param, res.body[param])

          formData.append('file', selectedFile)
          this.showUploader = true
          this.uploadPromise = this.$http
            .post(AWS_BUCKET, formData, {
              progress: (e) => {
                if (e.lengthComputable) {
                  this.percents = Math.round((e.loaded / e.total) * 100)
                }
              },
              before(request) {
                this.previousRequest = request
              },
            })
            .then(() => {
              let versionParams = {
                site_id: this.activeSite.id,
                remote_upload_url: AWS_BUCKET + '/' + res.body.key,
                description: selectedFile.name,
              }

              this.$store.dispatch('versions/create', versionParams)
            })
            .catch((e) => {
              console.log(e)
            })
            .finally(() => {
              this.showUploader = false
              this.percents = 0
            })
        })
        .catch((e) => {
          console.log(e)
        })
    },
    chooseFile() {
      document.getElementById('uploader-input').click()
    },
    fetchVersions() {
      this.loading = true
      this.current_site_id = this.activeSite.id
      this.$store
        .dispatch('versions/load', this.activeSite.id)
        .then(() => {
          this.loading = false
          if (this.$route.query.versionId && this.$route.query.commentId)
            this.openCommentsModal()
        })
        .catch((error) => {
          console.log(error)
        })
    },
    deployFromSource() {
      let versionParams = {
        site_id: this.activeSite.id,
        remote_upload_url: null,
        description: `deployed from ${this.serviceName.name}`,
      }
      this.$store.dispatch('versions/createTmpVersion', {
        description: `deployed from ${this.serviceName.name}`,
        site_id: this.activeSite.id,
        percent_deployed: 0,
      })
      this.$store.dispatch('versions/create', versionParams)
    },
    onDragover() {
      this.isDropdownMaskActive = true
    },
    onDragend() {
      const { isDropdownMaskActive } = this
      if (isDropdownMaskActive)
        this.isDropdownMaskActive = !isDropdownMaskActive
    },
    onDragoverZone() {
      console.log('drager')
    },
    toggleViewMode() {
      this.isMobileView = !this.isMobileView
    },
    triggerCelebration() {
      const defaults = {
        startVelocity: 30,
        spread: 360,
        ticks: 60,
        zIndex: 0,
        particleCount: 150,
      }

      const fire = (particleRatio, opts) => {
        confetti({
          ...defaults,
          ...opts,
          origin: { y: 0.7 },
          particleCount: Math.floor(defaults.particleCount * particleRatio),
          colors: ['#FF6B00', '#FFB800', '#FF3D00'],
          shapes: ['square'],
          gravity: 0.8,
          scalar: 1.2,
        })
      }

      fire(0.25, {
        spread: 26,
        startVelocity: 55,
      })

      fire(0.2, {
        spread: 60,
      })

      fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
      })

      fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
      })

      fire(0.1, {
        spread: 120,
        startVelocity: 45,
      })
    },
    dismissCelebration() {
      this.showCelebration = false
      // Remove the query parameter
      const query = { ...this.$route.query }
      delete query.celebrate
      this.$router.replace({ query })
    },
  },
  watch: {
    '$store.state.site.current.site_name': {
      handler(newVal, oldVal) {
        this.siteName = newVal
      },
      deep: true,
    },
    '$store.state.sites.currentVersionStatus': {
      handler(newVal, oldVal) {
        if (oldVal === 'deploying' && newVal === 'deployed') {
          this.setPreviousKind(this.activeSite.kind)
        }
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
$style: SiteVersions;
.#{$style} {
  padding: 42px 24px 24px;
  &__upload-card {
    padding: 24px;
    background-color: $N4;
    border: 1px solid $N5;
    border-radius: 8px;
    margin-right: 12px;
  }
  &__upload-title {
    font-size: $H20;
    color: $white;
    font-weight: 500;
    margin-bottom: 8px;
  }
  &__upload-link {
    color: $white;
    font-size: $H14;
    font-weight: normal;
    margin-bottom: 27px;
    cursor: pointer;
    transition: color 0.2s ease;
    &:hover {
      color: $N2;
    }
  }
  &__upload-dot {
    display: inline-block;
    margin-right: 16px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: $G1;
  }
  &__upload-lanch {
    margin-left: 10px;
    fill: currentColor;
    transition: fill 0.2s ease;
  }
  &__upload-btn {
    &.theme--dark.v-btn.v-btn--has-bg {
      background-color: transparent;
      color: $Y1;
      border: 1px solid $Y1;
      border-radius: 8px;
      font-size: 14px;
    }
    margin-bottom: 24px;
  }
  &__upload-input {
    display: none;
  }
  &__upload-text {
    color: $N2;
    font-weight: 300;
    font-size: $H14;
    line-height: 1.7;
    span {
      color: $white;
    }
  }
  &__upload-icon {
    margin-right: 8px;
  }
  &__window {
    background-color: $N4;
    border: 1px solid $N5;
    border-radius: 8px;
    margin-left: 12px;
    position: relative;
    height: 100%;
    overflow: hidden;
    min-height: initial;
    transition: padding 0.2s ease;
    &._expanded {
      padding-bottom: 500px;
    }
    @media screen and (max-width: 600px) {
      min-height: 200px;
    }
  }
  &__window-header {
    background: rgba(46, 44, 66, 0.7);
    backdrop-filter: blur(8px);
    padding: 8px 22px;
    z-index: 1;
    position: relative;
  }
  &__window-buttons {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__window-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    cursor: pointer;
    transition: transform 0.2s ease;

    &._red {
      background-color: $R1;
    }
    &._orange {
      background-color: $Y1;
    }
    &._green {
      background-color: $G1;
    }
    &:hover {
      transform: scale(1.1);
    }
  }
  &__preview-iframe {
    border: none;
    width: 100%;
    height: 100%;
    margin-top: 30px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: contain;
    z-index: 0;
    &.mobile-view {
      width: 375px; // Example width for mobile view
      height: 667px; // Example height for mobile view
      margin: auto;
    }
    &.browser-view {
      width: 100%;
      height: 100%;
    }
  }
  &__drag-and-drop-mask {
    background: rgba(37, 36, 55, 0.9);
    border: 1px solid $N5;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(10px);
    border-radius: 8px;
    position: fixed;
    top: 106px;
    left: 312px;
    right: 24px;
    bottom: 24px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 112px 130px;
  }
  &__drag-and-drop-mask-wrapper {
    border: 1px dashed $N3;
    border-radius: 8px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
  }
  &__drag-and-drop-mask-title {
    display: flex;
    align-items: center;
    color: $white;
    font-size: $H16;
    font-weight: 500;
  }
  &__drag-and-drop-mask-icon {
    margin-right: 8px;
  }
  &__row {
    margin-bottom: 24px !important;
  }
  &__versions {
    background: $N4;
    height: 74vh;
    overflow-y: auto;
    border: 1px solid $N5;
    border-radius: 8px;
  }
  &__window-progress {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__window-progress-content {
    width: 70%;
    border: 1px dashed $N3;
    padding: 32px;
    display: block;
    flex-direction: column;
    border-radius: 8px;
  }
  &__window-progress-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 330px;
    margin: 0 auto 22px;
  }
  &__window-progress-title {
    color: $white;
    font-weight: 500;
    font-size: $H16;
  }
  &__window-progress-percents {
    color: $N2;
    font-weight: 400;
    font-size: $H14;
  }
  &__window-progress-btn {
    &.v-btn {
      color: $white;
    }
  }
  &__window-progress-progress {
    max-width: 330px;
    margin: auto;
  }
  .view-mode-toggle {
    margin-left: 8px;

    .v-btn {
      height: 28px !important;
      background-color: $N5 !important;

      &.v-item--active {
        background-color: $Y1 !important;

        .v-icon {
          color: $N4;
        }
      }

      .v-icon {
        color: $N2;
      }
    }
  }
  &__celebration-modal {
    background: transparent !important;
    box-shadow: none !important;

    .v-card {
      background: transparent !important;
      box-shadow: none !important;
    }
  }

  &__celebration-content {
    text-align: center;
    padding: 48px;
    border-radius: 8px;
    background: rgba(46, 44, 66, 0.95);
    backdrop-filter: blur(10px);
    border: 1px solid $N5;
  }

  &__celebration-title {
    color: $white;
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 16px;
  }

  &__celebration-text {
    color: $N2;
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 32px;
  }

  &__celebration-links {
    display: flex;
    justify-content: center;
    gap: 24px;
    margin-bottom: 32px;
  }

  &__celebration-link {
    display: flex;
    align-items: center;
    color: $N2;
    text-decoration: none;
    font-size: 14px;
    transition: color 0.2s ease;

    &:hover {
      color: $Y1;
    }
  }

  &__celebration-cta {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__celebration-button {
    min-width: 200px !important;
    text-transform: none !important;
    font-weight: 500 !important;
    letter-spacing: 0.25px !important;

    &.v-btn--text {
      color: $N2 !important;

      &:hover {
        color: $white !important;
      }
    }
  }
}
</style>
