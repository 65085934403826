<template>
  <v-btn
    class="button"
    @mouseover="handleMouseOver"
    @mousemove="handleMouseMove"
    @mouseleave="handleMouseLeave"
  >
    <slot></slot>
  </v-btn>
</template>

<script>
export default {
  data() {
    return {
      config: {
        colors: [
          '#eddc01',
          '#f2b125',
          '#fd9407',
          '#ff7308',
          '#eb5508',
          '#fe1a17',
          '#e93702',
        ],
        sizes: [4, 6, 8],
        minimalDistance: 20,
        gravitation: 0.2,
        airResistance: 0.98,
        shrink: 0.1,
      },
      prev: { x: 0, y: 0 },
      last: { x: 0, y: 0 },
      isHovering: false,
    }
  },
  methods: {
    rand(min, max) {
      return Math.floor(Math.random() * (max - min + 1) + min)
    },
    pickRandom(arr) {
      return arr[this.rand(0, arr.length - 1)]
    },
    calcDistance(a, b) {
      return Math.floor(Math.sqrt((a.x - b.x) ** 2 + (a.y - b.y) ** 2))
    },
    calcAngleRadians(startPoint, endPoint) {
      return Math.atan2(startPoint.y - endPoint.y, endPoint.x - startPoint.x)
    },
    updateCoords(position) {
      this.prev.x = this.last.x
      this.prev.y = this.last.y
      this.last.x = position.x
      this.last.y = position.y
    },
    generateSpeed() {
      var angle = (Math.random() * 360 * Math.PI) / 180
      var speed = Math.random() * 2 + 1
      var addSpeed =
        (this.calcDistance(this.prev, this.last) /
          this.config.minimalDistance) *
        2
      var addSpeedY =
        addSpeed * Math.sin(this.calcAngleRadians(this.prev, this.last))
      var addSpeedX =
        addSpeed * Math.cos(this.calcAngleRadians(this.prev, this.last))
      var speedY = speed * Math.sin(angle) + addSpeedY
      var speedX = speed * Math.cos(angle) + addSpeedX

      return { speedX, speedY }
    },
    animateSpark(spark) {
      var { speedX, speedY } = this.generateSpeed()
      const gravitation = this.config.gravitation
      const airResistance = this.config.airResistance
      const shrink = this.config.shrink

      const animate = () => {
        spark.style.top = parseFloat(spark.style.top) - speedY + 'px'
        spark.style.left = parseFloat(spark.style.left) + speedX + 'px'
        spark.style.width = parseFloat(spark.style.width) - shrink + 'px'
        spark.style.height = parseFloat(spark.style.height) - shrink + 'px'
        speedY -= gravitation
        speedX *= airResistance

        if (
          spark.getBoundingClientRect().top > window.innerHeight ||
          spark.getBoundingClientRect().left < 0 ||
          parseFloat(spark.style.width) <= 0
        ) {
          spark.remove()
        } else {
          requestAnimationFrame(animate)
        }
      }
      animate()
    },
    createSpark(position) {
      const spark = document.createElement('div')
      const size = this.pickRandom(this.config.sizes)
      spark.className = 'spark'
      const sparkGlow = document.createElement('div')
      sparkGlow.className = 'spark-glow'
      spark.appendChild(sparkGlow)
      spark.style.position = 'absolute'

      // Adjusting position to account for scroll offset
      const scrollTop = window.scrollY || document.documentElement.scrollTop
      const scrollLeft = window.scrollX || document.documentElement.scrollLeft

      spark.style.left = position.x + scrollLeft + 'px'
      spark.style.top = position.y + scrollTop + 'px'
      spark.style.background = this.pickRandom(this.config.colors)
      spark.style.width = size + 'px'
      spark.style.height = size + 'px'

      this.animateSpark(spark)
      document.body.appendChild(spark)

      this.updateCoords(position)
    },
    handleMouseOver(e) {
      this.isHovering = true
      e.target.classList.add('glow')
    },
    handleMouseMove(e) {
      if (!this.isHovering) return
      const position = {
        x: e.clientX,
        y: e.clientY,
      }
      if (this.calcDistance(this.last, position) > this.config.minimalDistance)
        this.createSpark(position)
    },
    handleMouseLeave(e) {
      this.isHovering = false
      e.target.classList.remove('glow')
    },
  },
}
</script>

<style scoped>
.button {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  z-index: 10;
  transition: box-shadow 0.3s;
}

.button.glow {
  box-shadow: 0 0 20px red;
  background: rgb(255, 115, 0);
}

.spark {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
}

.spark-glow {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: radial-gradient(circle, white, transparent);
}
</style>
