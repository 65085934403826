export class ProjectUser {
  id = null
  user_id = null
  project_id = null
  email = ''
  name = null
  role = null
  invited = false
  avatar = null

  constructor(origin) {
    if (!origin) return

    this.id = origin.id
    this.email = origin.email
    this.avatar = origin.avatar_url
    this.user_id = origin.user_id
    this.project_id = origin.project_id
    this.name = origin.name
  }

  getServerObject() {
    return {
      email: this.email,
      project_id: this.project_id,
      user_id: this.user_id,
    }
  }
}
