<template>
  <ParentAuthForm subtitle="Log in" :loading="loading" :type="0">
    <div>
      <CloudAuth :loading="loading" />

      <v-form @submit.prevent="login" class="form mt-14 mx-auto">
        <div>Work Email</div>
        <v-text-field
          v-model="email"
          @input="onInputEmail"
          :error-messages="errorVisibleEmail"
          :disabled="loading"
          type="email"
          class="mt-1"
          autofocus
          filled
          dense
        />

        <div>Password</div>
        <v-text-field
          v-model="password"
          @input="onInputPassword"
          :error-messages="errorVisiblePassword"
          :disabled="loading"
          :type="showingPassword ? 'text' : 'password'"
          class="mt-1"
          filled
          dense
        >
          <template v-slot:append>
            <v-btn @click="showingPassword = !showingPassword" icon small>
              <v-icon>{{
                showingPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
              }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <div v-if="errorMain" class="error-main pa-4">
          {{ errorMain }}
          <div v-if="showResend" @click="resend" class="mt-2">
            <a>Resend verification email</a>
          </div>
        </div>

        <div v-if="notify" class="notify pa-4">
          {{ notify }}
        </div>

        <v-btn
          class="mt-8 mx-auto d-block login-btn y1"
          style="width: 100%"
          :disabled="!dirtyForm || loading"
          type="submit"
        >
          Log in
        </v-btn>
      </v-form>

      <div class="mt-12">
        <router-link
          to="/forgot-password"
          class="d-block text-center mt-4"
          :class="{ 'link-disabled': loading }"
        >
          Forgot password?
        </router-link>
      </div>
    </div>
  </ParentAuthForm>
</template>

<script>
import ParentAuthForm from '@/components/AuthForms/ParentAuthForm'
import CloudAuth from '@/components/AuthForms/CloudAuth'
export default {
  name: 'LoginForm',
  components: { ParentAuthForm, CloudAuth },
  data() {
    return {
      email: '',
      password: '',

      errorVisibleEmail: null,
      errorVisiblePassword: null,

      errorMain: null,
      notify: null,

      dirtyForm: false,
      showingPassword: false,
      loading: false,
      showResend: false,
    }
  },
  computed: {
    errorEmail() {
      if (!this.email) return 'Email can not be empty'
      if (!this.email.match(/.+@.+/)) return 'Email must be valid'
      return null
    },
    errorPassword() {
      if (!this.password) return 'Password can not be empty'
      return null
    },
  },
  methods: {
    onInputEmail() {
      this.errorVisibleEmail = null
      this.dirtyForm = true
      this.notify = null
    },
    onInputPassword() {
      this.errorVisiblePassword = null
      this.dirtyForm = true
      this.notify = null
    },
    login() {
      this.errorMain = null
      this.dirtyForm = false
      this.showResend = false

      if (this.errorEmail || this.errorPassword) {
        this.errorVisibleEmail = this.errorEmail
        this.errorVisiblePassword = this.errorPassword
        return
      }

      this.loading = true
      this.$store
        .dispatch('user/authorize', {
          email: this.email,
          password: this.password,
        })
        .then(() => this.$store.dispatch('application/load'))
        .then(() => {
          if (this.$route.query.redirect) {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            this.$router.push({ name: 'dashboard' })
          }
        })
        .catch((error) => {
          this.loading = false
          if (!error) return
          console.log(error)

          // Check for 401 Unauthorized status
          if (
            error.status === 401 ||
            (error.response && error.response.status === 401)
          ) {
            this.errorVisiblePassword = 'Incorrect email or password'
            return
          }

          // Handle other types of errors
          if (
            error.body &&
            error.body.error &&
            Array.isArray(error.body.error)
          ) {
            let errorMain = ''
            error.body.error.forEach((message) => {
              errorMain = errorMain + message + '. '
            })
            this.errorMain = errorMain
            this.showResend = errorMain.indexOf('account is not verified') != -1
          } else {
            // Fallback error message
            this.errorMain = 'An error occurred during login. Please try again.'
          }
        })
    },

    resend() {
      this.errorMain = null

      this.loading = true

      //todo

      this.notify = 'Email was resended!'
      this.loading = false
    },
  },
}
</script>

<style lang="scss" scoped>
.error-main {
  background: #e94a53;
  border-radius: 2px;
}

.cloudAuthBtn {
  width: 100%;
  background-color: #232235 !important;
  border: 1px solid #36344c;
}

.notify {
  background: #d3fae9;
  border-radius: 2px;
}

.link {
  text-decoration: underline;
  cursor: pointer;
}

.link-disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.26);
}
</style>
