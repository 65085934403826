<template>
  <v-card class="VulcanPickerForm">
    <v-row class="flex-grow-0">
      <v-col cols="6">
        <span class="ModalCardData__label">Organisations</span>
        <v-select
          @change="onChangeOrganisation"
          v-model="activeOrganisation"
          :items="organisations"
          item-text="name"
          item-value="id"
          :disabled="loading"
          outlined
          hide-details
          dense
        >
        </v-select>
      </v-col>
    </v-row>
    <div v-if="loading" class="spinner flex-grow-1" align="center">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
    <VulcanProjectList :projects="projects" :openChart="openChart" v-else />
  </v-card>
</template>

<script>
import { VULCAN_API_URL } from '@/env'
import { IntersectingCirclesSpinner } from 'epic-spinners'

import VulcanProjectList from './ProjectList'
export default {
  name: 'VulcanPickerForm',
  components: {
    IntersectingCirclesSpinner,
    VulcanProjectList,
  },
  props: {
    authToken: String,
    openChart: Function,
  },
  data() {
    return {
      activeOrganisation: null,
      organisationsList: [],
      projects: [],
      individual: {
        id: 0,
        name: 'Individual Account',
      },
      loading: false,
    }
  },
  async mounted() {
    this.loading = true
    await this.getOrganisations()
    await this.getActiveOrganisation()
    await this.getProjects()
    this.loading = false
  },
  computed: {
    organisations() {
      return [this.individual, ...this.organisationsList]
    },
  },
  methods: {
    async getOrganisations() {
      const res = await this.$http.get(`${VULCAN_API_URL}/v1/organisations`, {
        headers: {
          Authorization: `Bearer ${this.authToken}`,
        },
      })
      this.organisationsList = res.body
    },
    async getActiveOrganisation() {
      const res = await this.$http.get(
        `${VULCAN_API_URL}/v1/organisations/get_current`,
        {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        }
      )
      this.activeOrganisation = res.body || this.individual
    },
    async setActiveOrganisation() {
      await this.$http.put(
        `${VULCAN_API_URL}/v1/organisations/${this.activeOrganisation}/current`,
        null,
        {
          headers: {
            Authorization: `Bearer ${this.authToken}`,
          },
        }
      )
    },
    async getProjects() {
      const res = await this.$http.get(`${VULCAN_API_URL}/vulcan/v1/projects`, {
        headers: {
          Authorization: `Bearer ${this.authToken}`,
        },
      })
      this.projects = res.body
    },
    async onChangeOrganisation() {
      this.loading = true
      await this.setActiveOrganisation()
      await this.getProjects()
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
$style: VulcanPickerForm;
.#{$style} {
  background-color: $N4;
  border: 1px solid $N5;
  display: flex;
  flex-direction: column;
  margin: 30px;
  padding: 20px;
  min-height: calc(100vh - 150px);
}
</style>
