<template>
  <div class="PluginPublishFlowDeveloperStep">
    <h6 class="mb-4">Developer Information</h6>
    <vue-form-generator
      :schema="schema"
      :model="developer"
      :options="formOptions"
    ></vue-form-generator>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import VueFormGenerator from 'vue-form-generator'
import countries from '@/_countries'

export default {
  name: 'PluginPublishFlowDeveloperStep',
  components: {
    'vue-form-generator': VueFormGenerator.component,
  },
  data() {
    return {
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            name: 'Name',
            label: 'Name',
            model: 'Name',
          },
          {
            type: 'input',
            inputType: 'text',
            name: 'Company',
            label: 'Company',
            model: 'Company',
          },
          {
            type: 'input',
            inputType: 'text',
            name: 'website',
            label: 'Website',
            model: 'Website',
          },
          {
            type: 'input',
            inputType: 'text',
            name: 'email',
            label: 'Email',
            model: 'Email',
          },
          {
            type: 'select-reset',
            label: 'Country',
            model: 'Country',
            name: 'Country',
            options: countries,
            placeholder: 'Select Country',
          },
        ],
        styleClasses: 'rows',
        labelClasses: 'label',
        fieldClasses: 'boxed elem mb-15',
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
      },
    }
  },

  computed: {
    ...mapState('plugin_publishing', {
      developer: (state) => state.developer,
    }),
  },
  methods: {},
  mounted() {
    this.model = this.developer
  },
  methods: {
    ...mapMutations('plugin_publishing', ['setDeveloper']),
  },
  watch: {
    'developer.name': function (val) {
      this.developer.slug = this.slug
    },
    developer: {
      handler() {
        if (JSON.stringify(this.developer) != JSON.stringify(this.model)) {
          this.model = this.developer
        }
      },
      deep: true,
    },
    model: {
      handler(newValue) {
        this.setDeveloper({ ...newValue })
      },
      deep: true,
    },
  },
}
</script>

<style scoped lang="scss">
$style: PluginPublishFlowDeveloperStep;
.#{$style} {
  h6 {
    font-size: 14px;
    font-weight: normal;
  }
}
</style>
