<template>
  <v-expansion-panels accordion>
    <v-expansion-panel>
      <v-expansion-panel-header>
        <div class="heading">
          {{ headerText }}
          <v-icon class="float-right" @click="$emit('removeItem')"
            >mdi-close</v-icon
          >
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <slot></slot>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import VueFormGenerator from 'vue-form-generator'
export default {
  name: 'FieldArrayAccordionItem',
  mixins: [VueFormGenerator.abstractField],
  props: [
    'model',
    'index',
    'schema',
    'id',
    'parentId',
    'removeElementButtonLabel',
    'itemContainerHeader',
  ],
  data() {
    return {
      hasError: false,
    }
  },
  computed: {
    headerText() {
      if (typeof this.itemContainerHeader === 'function') {
        return this.itemContainerHeader(this.model, this.schema, this.index)
      } else if (
        typeof this.itemContainerHeader === 'string' &&
        this.model &&
        this.model.hasOwnProperty(this.itemContainerHeader)
      ) {
        return this.model[this.itemContainerHeader]
      }
      return 'New Param'
    },
  },
  methods: {
    validate() {
      if (this.$children.length < 1) {
        console.warn(
          'The accordion item of the array field seam to be empty. Could not validate'
        )
        return false
      }
    },
    validated(isValid, errors) {
      this.hasError = !isValid
    },
  },
  mounted() {
    if (
      !this.$slots.default ||
      typeof this.$slots.default[0] !== 'object' ||
      typeof this.$slots.default[0].componentInstance !== 'object' ||
      typeof this.$slots.default[0].componentInstance.$on !== 'function'
    ) {
      return
    }
    this.$slots.default[0].componentInstance.$on('validated', this.validated)
  },
}
</script>

<style scoped></style>
