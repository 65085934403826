<template>
  <div>
    <div class="action-toolbar" :class="{ 'toolbar-shadow': isScrolled }">
      <div class="toolbar-content narrow-container">
        <div class="toolbar-left" v-if="parseSetting != null">
          <v-switch
            color="orange"
            v-model="new_activated_value"
            dark
            dense
            hide-details
            inset
            class="mt-0 mr-4"
          >
            <template v-slot:label>
              <span>{{
                parseSetting.activated ? 'Activated' : 'Deactivated'
              }}</span>
            </template>
          </v-switch>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                @click="save"
                :disabled="btnDisabled"
                v-bind="attrs"
                v-on="on"
                color="white"
              >
                <v-icon>mdi-content-save</v-icon>
              </v-btn>
            </template>
            <span>Save Changes</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-if="parseSetting.activated"
                @click="redeploy"
                :disabled="btnDisabled"
                v-bind="attrs"
                v-on="on"
                color="white"
                class="ml-2"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Start a new deployment</span>
          </v-tooltip>
        </div>

        <div class="toolbar-actions">
          <v-tooltip bottom v-if="cloudEnabled">
            <template v-slot:activator="{ on, attrs }">
              <router-link :to="{ name: 'parse_cloud' }">
                <v-btn icon v-bind="attrs" v-on="on" color="orange">
                  <v-icon>mdi-code-braces</v-icon>
                </v-btn>
              </router-link>
            </template>
            <span>Cloud Code Editor</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-if="cloudEnabled"
                @click="showServiceLogs"
                :disabled="btnDisabled"
                v-bind="attrs"
                v-on="on"
                color="orange"
                class="ml-2"
              >
                <icon-console />
              </v-btn>
            </template>
            <span>View Logs</span>
          </v-tooltip>
        </div>
      </div>
    </div>
    <div class="narrow-container main-content" v-if="!loading">
      <!-- <div class="">
        <v-btn v-if="dashBoardEnabled" color="primary" class="mt-6 mr-2" dark @click="OpenDashboard">
          Open Dashboard <v-icon small class="ml-2"> mdi-arrow-top-right </v-icon>
        </v-btn>

        <router-link v-if="cloudEnabled" :to="{ name: 'parse_cloud' }" class=" pa-2">
          <v-btn dark color="primary" class="mt-6">
            <v-icon small color="#d6d3d3" class="list-item-icon">mdi-square-edit-outline</v-icon>
            <div class="ml-2">Edit Cloud Code</div>
          </v-btn>
        </router-link>
      </div> -->
      <div class="mt-6">Server URL</div>
      <v-text-field
        dark
        :value="parseSetting.parse_server_app_url"
        class="mt-1"
        filled
        readonly
        dense
        hide-details
      />

      <div class="mt-4">Application Id</div>
      <v-text-field
        dark
        readonly
        v-model="parseSetting.appId"
        class="mt-1"
        filled
        dense
        hide-details
      />

      <div class="mt-4">Master Key</div>
      <v-text-field
        dark
        v-model="parseSetting.masterKey"
        class="mt-1"
        filled
        dense
        hide-details
        autocomplete="off"
        :type="masterKeyType"
      >
        <template v-slot:append>
          <v-btn
            text
            @click="hide_master_key = !hide_master_key"
            small
            class="mb-2"
            color="orange"
          >
            {{ hide_master_key ? 'Show' : 'Hide' }}
          </v-btn>
        </template>
      </v-text-field>

      <div class="mt-4">Client Key</div>
      <v-text-field
        dark
        v-model="parseSetting.clientKey"
        class="mt-1"
        filled
        dense
        hide-details
        autocomplete="off"
        :type="clientKeyType"
      >
        <template v-slot:append>
          <v-btn
            text
            @click="hide_client_key = !hide_client_key"
            small
            class="mb-2"
            color="orange"
          >
            {{ hide_client_key ? 'Show' : 'Hide' }}
          </v-btn>
        </template>
      </v-text-field>

      <div class="mt-4">Chisel CMS URL</div>
      <div class="my-2 note pl-2">
        Import credentials from your Chisel CMS app using "Import" button.
      </div>
      <v-text-field
        dark
        v-model="parseSetting.chiselUrl"
        class=""
        filled
        dense
        autocomplete="off"
        hide-details
      >
        <template v-slot:append>
          <v-btn text @click="copyChiselUrl" small class="mb-2" color="orange">
            Import
          </v-btn>
        </template>
      </v-text-field>

      <div class="dashboard-section mt-8" v-if="dashBoardEnabled">
        <div class="section-title">
          <v-icon small color="orange" class="mr-2">mdi-view-dashboard</v-icon>
          Parse Dashboard
        </div>

        <div class="dashboard-content mt-4">
          <div class="mb-4">
            <div class="dashboard-subtitle subtitle-2 mb-1">
              Dashboard Access
            </div>
            <div class="dashboard-caption caption text-grey-lighten-1 mb-2">
              Access your data using your Forge account email and the password
              below
            </div>
            <v-text-field
              dark
              v-model="parseSetting.dashboardPassword"
              autocomplete="off"
              filled
              id="dashboard_password"
              name="dashboard_password"
              :type="passType"
              dense
              hide-details
              placeholder="Dashboard Password"
            >
              <template v-slot:append>
                <v-btn
                  text
                  @click="hide_pass = !hide_pass"
                  small
                  class="mb-2"
                  color="orange"
                >
                  {{ passLabel }}
                </v-btn>
              </template>
            </v-text-field>
          </div>

          <v-btn color="primary" dark @click="OpenDashboard" class="mt-2">
            Open Dashboard
            <v-icon small class="ml-2">mdi-arrow-top-right</v-icon>
          </v-btn>
        </div>
      </div>

      <div class="mt-4">Environments</div>
      <OptionsList
        class="mt-2"
        :appsTab="true"
        :options="environments"
        name-label="KEY"
        value-label="VALUE"
      />

      <div class="mt-8 section-title">Custom Github Repository</div>
      <div class="instructions-container mt-4">
        <div class="instructions-title">
          <v-icon small color="orange" class="mr-2">mdi-information</v-icon>
          Setup Instructions
        </div>
        <div class="instructions-content">
          <div class="instruction-step">
            <div class="step-number">1</div>
            <div class="step-text">Copy the SSH key provided by Forge</div>
            <v-btn text small color="orange" class="" @click="copySSH">
              <v-icon small left>mdi-content-copy</v-icon>
              Copy SSH Key
            </v-btn>
          </div>

          <div class="instruction-step">
            <div class="step-number">2</div>
            <div class="step-text">
              Add the SSH key to your git provider account
            </div>
          </div>

          <div class="instruction-step">
            <div class="step-number">3</div>
            <div class="step-text">Deploy and activate Forge Ignite</div>
          </div>

          <div class="warning-note mt-2">
            <v-icon small color="#9b7406" class="mr-1">mdi-alert</v-icon>
            Important: Complete these steps before deploying and activating
            Forge Ignite. This is an experimental feature.You are ejecting from
            our managed service and will be responsible for the maintenance of
            your app code.
          </div>
        </div>
      </div>

      <div class="mt-4">Repository URL</div>
      <v-text-field
        dark
        v-model="parseSetting.github_repository"
        class=""
        filled
        autocomplete="off"
        dense
        hide-details
      />

      <div class="mt-4">Target Branch</div>
      <v-text-field
        dark
        v-model="parseSetting.github_branch"
        class=""
        autocomplete="off"
        id="github_branch"
        name="github_branch"
        filled
        dense
        hide-details
      />
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { MODAL_FORGE_CONSOLE } from '@/components/Modals'
import OptionsList from '@/components/elements/OptionsList'
import IconConsole from '@/components/Icons/IconConsole.vue'

export default {
  name: 'SiteAppParseServer',
  components: { OptionsList, IconConsole },
  data() {
    return {
      parseSetting: null,
      loading: true,
      hide_pass: true,
      saving: false,
      new_activated_value: false,
      isScrolled: false,
      hide_master_key: true,
      hide_client_key: true,
    }
  },
  computed: {
    passLabel() {
      return this.hide_pass == true ? 'Show' : 'Hide'
    },
    passType() {
      return this.hide_pass == true ? 'password' : 'text'
    },
    dashBoardEnabled() {
      return this.parseSetting.deployed
    },
    btnDisabled() {
      return (
        this.saving || this.parseSetting.deploying || this.parseSetting.redeploy
      )
    },
    environments() {
      if (this.$store.state.site_app.parseSetting != null) {
        return this.$store.state.site_app.parseSetting.environments
      } else return {}
    },
    cloudEnabled() {
      if (this.$store.state.site_app.parseSetting != null) {
        return this.$store.state.site_app.parseSetting.cloud_code_enabled
      } else return false
    },
    serviceLogMessages() {
      if (this.$store.state.site_app.parseSetting != null) {
        return this.$store.state.site_app.parseSetting.messages
      } else return []
    },
    masterKeyType() {
      return this.hide_master_key ? 'password' : 'text'
    },
    clientKeyType() {
      return this.hide_client_key ? 'password' : 'text'
    },
  },
  watch: {
    '$store.state.site_app.parseSetting': {
      handler(newVal, oldVal) {
        this.parseSetting = _.cloneDeep(newVal)
        this.new_activated_value = this.parseSetting.activated
        this.loading = false
      },
      deep: true,
    },
  },
  methods: {
    save() {
      this.saving = true
      let parse_params = {
        id: this.parseSetting.site_app_id,
        site_app: {
          new_activated_value: this.new_activated_value,
          site_id: this.$store.state.sites.active.id,
          all_app_id: 1,
        },
      }
      parse_params['site_app'] = Object.assign(
        parse_params['site_app'],
        this.parseSetting.getServerObject()
      )
      this.$store
        .dispatch('site_app/update', parse_params)
        .then(() => {
          this.parseSetting = _.cloneDeep(
            this.$store.state.site_app.parseSetting
          )
          this.new_activated_value = this.parseSetting.activated
          this.saving = false
        })
        .catch((e) => {
          console.log(e)
          this.saving = false
        })
    },
    async copySSH() {
      try {
        await navigator.clipboard.writeText(this.parseSetting.parse_c66_ssh_key)
      } catch (e) {
        console.log('Cannot copy')
      }
    },
    redeploy() {
      this.saving = true
      let parse_params = {
        id: this.parseSetting.site_app_id,
        site_app: {
          app_redeploy: true,
          site_id: this.$store.state.sites.active.id,
          all_app_id: 1,
        },
      }
      this.$store
        .dispatch('site_app/update', parse_params)
        .then(() => {
          this.parseSetting = _.cloneDeep(
            this.$store.state.site_app.parseSetting
          )
          this.saving = false
        })
        .catch((e) => {
          console.log(e)
          this.saving = false
        })
    },
    OpenDashboard() {
      window.open(this.parseSetting.parse_dashboard_url, '_blank')
    },
    copyChiselUrl() {
      if (
        this.$store.state.site_app.chiselApp != null &&
        this.$store.state.site_app.chiselApp.url.length != 0
      )
        this.parseSetting.chiselUrl = `https://${this.$store.state.site_app.chiselApp.url}`
    },
    showServiceLogs() {
      this.$store.commit('site_app/setServiceMessages', {
        message: [],
        id: this.parseSetting.appId,
      })
      this.$store.dispatch(
        'site_app/checkParseLogs',
        this.parseSetting.site_app_id
      )
      this.$store.commit('application/openModal', {
        component: MODAL_FORGE_CONSOLE,
        props: {
          app: true,
          appType: 1,
        },
      })
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)

    if (this.$store.state.site_app.parseSetting != null) {
      this.parseSetting = _.cloneDeep(this.$store.state.site_app.parseSetting)
      this.new_activated_value = this.parseSetting.activated
      this.loading = false
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
}
</script>

<style lang="scss" scoped>
.action-toolbar {
  position: sticky;
  top: 82px;
  z-index: 100;
  background: #1c1b2e;
  padding: 12px 0;
  transition: box-shadow 0.3s ease;

  &.toolbar-shadow {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  }
}

.toolbar-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toolbar-left {
  display: flex;
  align-items: center;
}

.toolbar-actions {
  display: flex;
  align-items: center;
}

.main-content {
  padding-top: 24px;
}

.narrow-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 24px;
}

.narrow-container {
  // padding: 42px 24px 24px;
  max-width: 800px;
  margin: auto;
}

.dashboard-section {
  font-family: 'Instrument Sans', sans-serif !important;
  margin-top: 24px;
  margin-bottom: 24px;
}

.section-title {
  font-weight: 500;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.dashboard-content {
  padding: 8px;
}

.dashboard-subtitle {
  font-family: 'Instrument Sans', sans-serif !important;
  font-weight: 500;
  font-size: 1.1em;
}

.dashboard-caption {
  font-family: 'Instrument Sans', sans-serif !important;
  font-size: 0.9em;
}

.note {
  background: #fcf4d4;
  color: #9b7406;
  border: 1px solid #f7e9ab;
  border-radius: 3px;
  font-weight: 500;
}

.instructions-container {
  background: #1e1e1e57;
  border-radius: 4px;
  padding: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.instructions-title {
  font-weight: 500;
  font-size: 1.1em;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
}

.instructions-content {
  padding-left: 8px;
}

.instruction-step {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;

  .step-number {
    background: #333;
    color: #fff;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.9em;
    margin-right: 12px;
    flex-shrink: 0;
  }

  .step-text {
    padding-top: 2px;
  }
}

.warning-note {
  background: rgba(252, 244, 212, 0.1);
  color: #fcf4d4;
  padding: 8px 12px;
  border-radius: 4px;
  font-size: 0.9em;
  display: flex;
  align-items: center;
}

// :deep(.v-btn--icon) {
//   .icon-console {
//     width: 20px;
//     height: 20px;

//     path {
//       fill: currentColor;
//     }
//   }
// }
</style>
