<template>
  <div class="field-drag-drop">
    <div
      class="preview-container mt-4"
      v-if="model && model[schema.model] && model[schema.model].length"
    >
      <div
        v-for="file in model[schema.model]"
        :key="getFileName(file)"
        class="preview-card"
      >
        <div class="preview-img-wrapper">
          <img class="preview-img" :src="getFileURL(file)" />
          <div class="preview-action">
            <span @click="remove(file)" title="Remove file">
              <v-icon small>delete</v-icon> Delete
            </span>
          </div>
        </div>
        <label
          class="d-flex align-center key-image-selector"
          @click="onSelectKeyImage(file)"
        >
          <the-icon
            class="mr-2"
            :icon="
              model.keyImage === getFileURL(file) ? 'radio-on' : 'radio-off'
            "
          />
          Key Image
        </label>
      </div>
    </div>

    <div
      class="drag-dropzone-container"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        multiple
        name="dragDropFileInput"
        id="dragDropFileInput"
        class="hidden-input"
        @change="onChange"
        ref="dragDropFileInput"
        accept=".svg,.jpg,.jpeg,.png"
      />

      <label for="dragDropFileInput" class="file-label">
        <div>
          <div class="upload-icon mt-2">
            <the-icon icon="upload" />
          </div>
          <div class="drag-dropzone__message-main-label" v-if="isDragging">
            Release to drop files here.
          </div>
          <div v-else>
            <div class="drag-dropzone__message-main-label">
              {{ schema.mainLabel || 'Drag &amp; Drop files here' }}
            </div>
            <div
              v-if="schema.additionalLabel"
              class="drag-dropzone__message-additional-label"
            >
              or drag &amp; drop your files here
            </div>
          </div>
        </div>
      </label>
    </div>
    <div v-if="loading" class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="30"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>

<script>
import Parse from 'parse'
import VueFormGenerator from 'vue-form-generator'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import { fileToBase64 } from '@/utils/common'
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'FieldDragDropUploader',
  mixins: [VueFormGenerator.abstractField],
  components: { IntersectingCirclesSpinner },
  props: ['schema', 'model', 'formOptions', 'required', 'disabled'],
  data() {
    const files = this.model[this.schema.model] || []
    return {
      isDragging: false,
      files,
      loading: false,
    }
  },
  mounted() {
    const files = this.model[this.schema.model] || []
    this.files = files
  },
  methods: {
    ...mapMutations('plugin_publishing', ['setKeyImage']),
    async onChange(e) {
      this.loading = true
      const promises = []
      for (let i = 0; i < this.$refs.dragDropFileInput.files.length; i++) {
        promises.push(this.uploadFile(this.$refs.dragDropFileInput.files[i]))
      }
      const urls = await Promise.all(promises)
      let files = this.model[this.schema.model] || []
      files = [...files, ...urls]
      this.model[this.schema.model] = files
      this.loading = false
    },

    async uploadFile(file) {
      try {
        const base64 = await fileToBase64(file)
        const res = await Parse.Cloud.run('uploadFile', {
          fileName: file.name,
          base64,
        })
        if (res && res.parseFile) return res.parseFile
      } catch (error) {
        console.error('Error in uploadFile', error)
      }
      return null
    },

    onSelectKeyImage(file) {
      this.setKeyImage(this.getFileURL(file))
    },

    getFileURL(file) {
      return file.fileURL || file._url || file.url
    },

    getFileName(file) {
      return file.fileName || file._name || file.name
    },

    // Screenshot hover trash icon click handler
    // Remove from screenshots array, destroy parse file and update key image if affected
    async remove(file) {
      const files = [...(this.model[this.schema.model] || [])]
      const index = files.indexOf(file)
      let shouldUpdateKeyImage = this.getFileURL(file) === this.model.keyImage
      files.splice(index, 1)
      if (file instanceof Parse.File)
        await Parse.Cloud.run('destroyFile', { file })
      this.model[this.schema.model] = files
      if (shouldUpdateKeyImage) this.setKeyImage(this.getFileURL(files[0]))
    },

    dragover(e) {
      e.preventDefault()
      if (this.loading) return
      this.isDragging = true
    },

    dragleave() {
      this.isDragging = false
    },

    drop(e) {
      if (this.loading) return
      e.preventDefault()
      this.$refs.dragDropFileInput.files = e.dataTransfer.files
      this.onChange()
      this.isDragging = false
    },
  },
  computed: {
    ...mapState('plugin_publishing', {
      keyIamge: (state) => state.appContent.keyImage,
    }),
  },
}
</script>

<style lang="scss" scoped>
.field-drag-drop {
  position: relative;
}

.drag-dropzone-container {
  padding: 4rem;
  border: 1px dashed $N3;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  min-height: 144px;
  border-radius: 8px;
  position: relative;
}

.drag-dropzone__message i {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.drag-dropzone__message-main-label {
  color: $Y1;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.drag-dropzone__message-additional-label {
  color: $N2;
  font-size: 12px;
}

.drag-dropzone__filename {
  font-weight: 700;
  margin-right: 0.5rem;
}

.drag-dropzone__remove-link {
  color: #f44336;
}

.upload-icon {
  background: $N6;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: $Y1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.hidden-input {
  opacity: 0;
  // overflow: ;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}
.preview-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 2rem;
}
.preview-card {
  width: 33.33%;
  padding: 5px 10px;
}
.preview-img-wrapper {
  position: relative;
  text-align: center;
  width: 120px;
  height: 80px;
  margin-bottom: 10px;
}
.preview-img {
  width: auto;
  height: 80px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
.preview-action {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: none;
}
.preview-img-wrapper:hover .preview-action {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}
.key-image-selector {
  font-size: 12px;
  cursor: pointer;
}
</style>

<style scoped>
.spinner {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
</style>
