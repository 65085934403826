<template>
  <div
    class="PluginInstallParamsElementDetail"
    :class="settingsDrawerOpened ? 'settings-drawer' : ''"
  >
    <div class="PluginInstallParamsElementDetail__header">
      <div class="d-flex align-center v-label cursor-pointer" @click="onCancel">
        <the-icon icon="arrow-left" class="mr-2" />
        Back
      </div>
      <div class="d-flex">
        <h6>{{ element.name || 'Undefined' }}</h6>
      </div>
      <v-btn icon text color="n2" @click="onClose" v-if="settingsDrawerOpened">
        <v-icon>close</v-icon>
      </v-btn>
      <v-btn @click="onUpdate" color="y1" v-else>Update</v-btn>
    </div>
    <div class="PluginInstallParamsElementDetail__body">
      <div class="app-builder">
        <div class="panel-body">
          <vue-form-generator
            :schema="schema"
            :model="model"
            :options="formOptions"
          />
        </div>
      </div>
    </div>
    <v-footer
      class="PluginInstallParamsElementDetail__footer"
      app
      v-if="settingsDrawerOpened"
    >
      <div>
        <div class="d-flex flex-row-reverse">
          <v-btn @click="onSaveElementDetails" color="y1"
            >Save element details</v-btn
          >
          <v-btn @click="onCancel" text color="white" class="mr-2"
            >Cancel</v-btn
          >
        </div>
      </div>
    </v-footer>
    <div v-if="loading" class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="30"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import VueFormGenerator from 'vue-form-generator'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import { cloneDeep, sortBy } from 'lodash'
import FIELDS_DEFINITION from '../FormBuilder/data/fieldDefinition'

export default {
  name: 'PluginInstallParamsElementDetail',
  components: {
    'vue-form-generator': VueFormGenerator.component,
    IntersectingCirclesSpinner,
  },
  data() {
    return {
      model: {
        id: 'FIELD_ID',
        type: null,
        domID: '',
      },
      schema: {
        fields: [],
        app: {},
        styleClasses: 'rows',
        labelClasses: 'label',
        fieldClasses: 'boxed elem mb-15',
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
      },
      loading: false,
    }
  },
  computed: {
    ...mapGetters({
      elementIDs: 'formBuilder/elementIDs',
    }),
    ...mapState({
      element: (state) => state.formBuilder.editingElement,
      settingsDrawerOpened: (state) =>
        state.plugin_publishing.settingsDrawerOpened,
    }),
  },
  mounted() {
    const field = cloneDeep(FIELDS_DEFINITION[this.element.type])
    if (!field) return
    let that = this
    let keys = Object.keys(field['fields'])
    let fields = [
      {
        type: 'input',
        inputType: 'text',
        label: 'Type',
        model: 'type',
        readonly: true,
        featured: false,
        order: 1,
        disabled: true,
      },
    ]

    const model = {
      type: field.type,
    }

    keys.forEach(function (key) {
      fields.push(field['fields'][key])
      model[key] = field['fields'][key]['default']
    })

    fields = sortBy(fields, 'order')

    this.schema = {
      fields,
      app: field,
      styleClasses: 'rows',
      labelClasses: 'label',
      fieldClasses: 'boxed elem mb-15',
    }
    this.model = { ...model, ...this.element }
  },
  methods: {
    ...mapMutations('plugin_publishing', ['setSettingsDrawerOpened']),
    ...mapMutations('formBuilder', [
      'setEditingElement',
      'updateElementOnly',
      'setIsInPreview',
    ]),

    onSaveElementDetails() {
      this.updateElementOnly(this.model)
      this.setEditingElement(null)
    },
    onCancel() {
      this.setEditingElement(null)
    },
    onClose() {
      this.setEditingElement(null)
      this.setSettingsDrawerOpened(false)
      this.setIsInPreview(false)
    },
    // On Plugin publishing flow only
    onUpdate() {
      this.updateElementOnly(this.model)
    },
  },
  watch: {
    model(newValue, oldValue) {
      this.setEditingElement(newValue)
    },
    deep: true,
    immediate: true,
  },
}
</script>
<style scoped lang="scss">
$style: PluginInstallParamsElementDetail;
.#{$style} {
  height: 100%;
  background: $N5;
  position: relative;
  &.settings-drawer {
    .#{$style}__header {
      padding: 20px;
      &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: -1px;
        left: 0px;
        background: $Y1;
        width: 96px;
        height: 2px;
      }
    }
    .#{$style}__body > div {
      padding: 28px;
      height: calc(100vh - 150px);
    }
  }
  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $N6;
    padding-bottom: 10px;
    h6 {
      font-size: 16px;
      font-weight: normal;
    }
  }
  &__status {
    background: $B4;
    color: $B1;
    padding: 3px 8px;
    border-radius: 6px;
    margin-left: 4px;
    font-size: 12px;
  }

  &__body {
    position: relative;
    & > div {
      position: absolute; /* change position to absolute */
      top: 0px; /* add top, left, right, bottom properties to position the element */
      left: 0px;
      right: 0px;
      bottom: 0px;
      overflow-y: auto;
      height: calc(100vh - 240px);
    }
  }
  &__footer {
    padding: 0px;
    > div {
      border-top: 1px solid $N6;
      background: $N5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px;
      width: 100%;
    }
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-out;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-leave,
.slide-enter-to {
  transform: translateX(100%);
  opacity: 0;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(200, 200, 200, 0.3);
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 80;
}
</style>

<style lang="scss">
.PluginInstallParamsElementDetail .form-group label {
  display: block;
  margin-bottom: 6px;
  color: $N2;
}

.app .PluginInstallParamsElementDetail .vue-form-generator .form-control {
  border: 1px solid $N3;
  background: transparent;
  border-radius: 8px;
}
</style>
