import {
  EXTENSIONS_MARKETPLACE_PARSE_SERVER_URL,
  EXTENSIONS_MARKETPLACE_PARSE_SERVER_APP_ID,
  EXTENSIONS_MARKETPLACE_PARSE_SERVER_SITE_ID,
} from '@/env'
import {
  initParse,
  getReadyForSalePlugins,
  getUserInstalledApps,
  getSiteInstalledApps,
  findDeveloperByEmail,
  getMyPluginsInDevelopment,
  getRolesList,
} from '@/utils/parse'

const initialPublisher = {
  name: '',
  id: '',
  parse_server_url: '',
  parse_server_app_id: '',
  parse_server_site_id: '',
}

const store = {
  namespaced: true,
  state: {
    activePublisher: initialPublisher,
    publishers: [],
    allSiteApps: [],
    allUserApps: [],
    publisherAppMap: [],
    userInstalledApps: [],
    siteInstalledApps: [],
    SDKRoles: [],
  },
  mutations: {
    /**
     * Mutation to set the active publisher.
     * @param {Object} publisher - The active publisher object.
     */
    setActivePublisher(state, publisher) {
      state.activePublisher = publisher
    },
    /**
     * Mutation to set the list of publishers.
     * @param {Array} publishers - The list of publishers.
     */
    setPublisherList(state, publishers) {
      state.publishers = publishers
    },
    /**
     * Mutation to set all apps.
     * @param {Object} param - The parameter object containing all apps information.
     */
    setAllApps(state, param) {
      const { allSiteApps, allUserApps, publisherAppMap } = param
      state.allSiteApps = allSiteApps
      state.allUserApps = allUserApps
      state.publisherAppMap = publisherAppMap
    },
    /**
     * Mutation to set user installed apps.
     * @param {Array} apps - The list of user installed apps.
     */
    setUserInstalledApps(state, apps) {
      state.userInstalledApps = apps
    },
    /**
     * Mutation to set site installed apps.
     * @param {Array} apps - The list of site installed apps.
     */
    setSiteInstalledApps(state, apps) {
      state.siteInstalledApps = apps
    },
    /**
     * Mutation to set SDK roles.
     * @param {Array} roles - The list of SDK roles.
     */
    setRoles(state, roles) {
      state.SDKRoles = roles
    },
  },
  actions: {
    /**
     * Action to load the list of publishers.
     */
    loadPublisherList({ commit }) {
      const publishersList = [
        {
          name: 'forge',
          id: 'forge',
          parse_server_url: EXTENSIONS_MARKETPLACE_PARSE_SERVER_URL,
          parse_server_app_id: EXTENSIONS_MARKETPLACE_PARSE_SERVER_APP_ID,
          parse_server_site_id: EXTENSIONS_MARKETPLACE_PARSE_SERVER_SITE_ID,
        },
      ]
      // Mock data for now.
      commit('setPublisherList', publishersList)
    },
    /**
     * Action to load all apps.
     */
    async loadAllApps({ commit, state, rootState }) {
      let allSiteApps = [],
        allUserApps = []
      let publisherAppMap = {}
      const email = rootState.user.current.email
      const promises = state.publishers.map(async (publisher) => {
        const { parse_server_url, parse_server_app_id, parse_server_site_id } =
          publisher
        initParse(parse_server_url, parse_server_app_id)
        const developer = await findDeveloperByEmail(
          parse_server_site_id,
          email
        )
        const { siteApps, userApps } =
          await getReadyForSalePlugins(parse_server_site_id)
        const [mySiteApps, myUserApps] = await getMyPluginsInDevelopment(
          parse_server_site_id,
          developer?.id
        )
        allSiteApps = [...allSiteApps, ...siteApps, ...mySiteApps]
        allUserApps = [...allUserApps, ...userApps, ...myUserApps]
        publisherAppMap[publisher.id] = [
          ...siteApps,
          ...userApps,
          ...mySiteApps,
          myUserApps,
        ]
      })
      await Promise.all(promises)
      commit('setAllApps', { allSiteApps, allUserApps, publisherAppMap })
    },
    /**
     * Action to load user installed apps.
     */
    async loadUserInstalledApps({ rootState, state, commit }) {
      const userId = rootState.user.current.id
      if (userId) {
        const promises = state.publishers.map(async (publisher) => {
          const {
            parse_server_url,
            parse_server_app_id,
            parse_server_site_id,
          } = publisher
          initParse(parse_server_url, parse_server_app_id)
          const apps = await getUserInstalledApps(parse_server_site_id, userId)
          return apps
        })

        const appsArray = await Promise.all(promises)
        const apps = appsArray.flat()
        commit('setUserInstalledApps', apps)
      }
    },
    /**
     * Action to load site installed apps.
     * @param {string} siteId - The ID of the site.
     */
    async loadSiteInstalledApps({ state, commit }, siteId) {
      const promises = state.publishers.map(async (publisher) => {
        const { parse_server_url, parse_server_app_id, parse_server_site_id } =
          publisher
        initParse(parse_server_url, parse_server_app_id)
        const apps = await getSiteInstalledApps(parse_server_site_id, siteId)
        return apps
      })

      const appsArray = await Promise.all(promises)
      const apps = appsArray.flat()
      commit('setSiteInstalledApps', apps)
    },
    /**
     * Action to load roles.
     */
    async loadRoles({ state, commit }) {
      const promises = state.publishers.map(async (publisher) => {
        const { parse_server_url, parse_server_app_id, parse_server_site_id } =
          publisher
        initParse(parse_server_url, parse_server_app_id)
        const apps = await getRolesList(parse_server_site_id)
        return apps
      })
      const rolesArray = await Promise.all(promises)
      const roles = rolesArray.flat()
      commit('setRoles', roles)
    },
    /**
     * Action to load site information.
     */
    async loadSite({ rootState, commit }) {
      const publisher = rootState.sites.active?.publisherInfo
      commit('setActivePublisher', publisher)
    },
    /**
     * Action to load all necessary data.
     * @param {string} id - The ID of the site.
     */
    async load({ dispatch }, id) {
      dispatch('loadPublisherList')
      dispatch('loadSite')
      dispatch('loadAllApps')
      dispatch('loadUserInstalledApps')
      dispatch('loadSiteInstalledApps', id)
      dispatch('loadRoles')
    },
  },
  getters: {
    /**
     * Getter to find an app by its slug.
     * @param {String} slug - Target app slug
     * @returns {Object|null} The found app or null if not found.
     */
    findAppBySlug: (state) => (slug) => {
      return [...state.allSiteApps, ...state.allUserApps].find(
        (app) => app.slug == slug
      )
    },
    /**
     * Getter to find a publisher by its ID.
     * @param {String} id -  Target publisher id
     * @returns {Object|null} The found publisher or null if not found.
     */
    findPublisherById: (state) => (id) => {
      return state.publishers.find((publisher) => publisher.id == id)
    },
    /**
     * Getter to get the publisher by app ID.
     * @param {String} appId -  Target app id
     * @returns {Object|null} The found publisher by the given appId or null if not found.
     */
    getPublisherByAppId: (state, getters) => (appId) => {
      if (state.publisherAppMap) {
        const publisherId = Object.keys(state.publisherAppMap).find((id) => {
          const apps = state.publisherAppMap[id]
          const app = apps.find((ap) => ap.id == appId)
          return !!app
        })
        if (publisherId) return getters['findPublisherById'](publisherId)
      }
      return null
    },
  },
}

export default store
