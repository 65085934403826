<template>
  <div class="narrow-container ChiselSettings" v-if="!loading">
    <div class="mt-2 note pl-2">
      Add credentials from your Parse Server app using "Import" button.
    </div>

    <div class="mt-6">Server URL</div>
    <v-text-field
      dark
      v-model="chiselConfig.parse_url"
      class=""
      filled
      dense
      hide-details
    >
      <template v-slot:append>
        <v-btn text @click="copyParseUrl" small class="mb-2" color="orange">
          Import
        </v-btn>
      </template>
    </v-text-field>

    <div class="mt-4">Application Id</div>
    <v-text-field
      dark
      v-model="chiselConfig.parse_app_id"
      class="mt"
      filled
      dense
      hide-details
    >
      <template v-slot:append>
        <v-btn text @click="copyParseAppId" small class="mb-2" color="orange">
          Import
        </v-btn>
      </template>
    </v-text-field>

    <div class="mt-4">REST key</div>
    <v-text-field
      dark
      v-model="chiselConfig.restkey"
      class="mt-1"
      :placeholder="restKey"
      filled
      dense
      hide-details
    />

    <div class="mt-4">JS key</div>
    <v-text-field
      dark
      v-model="chiselConfig.jskey"
      class="mt-1"
      :placeholder="jsKey"
      filled
      dense
      hide-details
    />

    <div class="mt-4">Your Chisel CMS is available at</div>
    <v-text-field
      dark
      v-model="chiselConfig.url"
      class="mt-1"
      filled
      dense
      hide-details
    />

    <v-switch
      color="orange"
      class="mt-4"
      v-model="chiselConfig.activated"
      dark
      dense
      hide-details
      inset
    >
      <template v-slot:label>
        <span style="margin-bottom: 2px"> {{ status }}</span>
      </template>
    </v-switch>

    <div
      class="mt-4"
      v-if="defaultConfig.activated && !chiselDeployed && !redeploy_clicked"
    >
      <div>
        Deploying
        <v-icon class="ml-3" color="grey" dark @click="showDeployLogs()"
          >mdi-console</v-icon
        >
        <v-progress-linear
          class="mt-2"
          height="2"
          :value="chiselConfig.percent_deployed"
        ></v-progress-linear>
      </div>
    </div>

    <v-btn
      @click="save"
      class="mt-7"
      color="orange"
      :disabled="!changed || !chiselDeployed"
      dark
    >
      Save changes
    </v-btn>

    <v-btn
      @click="redeploy"
      class="mt-7 ml-4"
      color="orange"
      dark
      :disabled="redeploy_clicked"
      v-if="defaultConfig.activated"
    >
      Redeploy
    </v-btn>
  </div>
</template>

<script>
import _ from 'lodash'
import { AppChiselCMS, APP_CHISEL_CMS } from '@/models/siteApp'
import { MODAL_FORGE_CONSOLE } from '@/components/Modals'

export default {
  name: 'SiteAppChiselCms',
  data() {
    return {
      chiselConfig: null,
      loading: true,
      defaultConfig: null,
      redeploy_clicked: false,
    }
  },
  methods: {
    save() {
      if (
        this.chiselConfig.restkey == undefined ||
        this.chiselConfig.restkey.length == 0
      )
        this.chiselConfig.restkey = this.restKey
      if (
        this.chiselConfig.jskey == undefined ||
        this.chiselConfig.jskey.length == 0
      )
        this.chiselConfig.jskey = this.jsKey
      let chisel_params = {
        id: this.chiselConfig.site_app_id,
        site_app: {
          activated: this.chiselConfig.activated,
          all_app_id: 2,
          chisel_config: this.chiselConfig.getServerObject(),
        },
      }
      this.$store.dispatch('site_app/update', chisel_params).then(() => {
        this.chiselConfig = _.cloneDeep(this.$store.state.site_app.chiselApp)
        this.defaultConfig = _.cloneDeep(this.$store.state.site_app.chiselApp)
      })
    },
    redeploy() {
      this.redeploy_clicked = true
      let chisel_params = {
        id: this.chiselConfig.site_app_id,
        site_app: {
          activated: this.chiselConfig.activated,
          all_app_id: 2,
          app_redeploy: true,
          chisel_config: this.chiselConfig.getServerObject(),
        },
      }
      this.$store
        .dispatch('site_app/update', chisel_params)
        .then(() => {
          this.redeploy_clicked = false
          this.chiselConfig = _.cloneDeep(this.$store.state.site_app.chiselApp)
          this.defaultConfig = _.cloneDeep(this.$store.state.site_app.chiselApp)
        })
        .catch(() => {
          this.redeploy_clicked = false
        })
    },
    setBaseChiselUrl() {
      if (this.chiselConfig.url.length == 0) {
        this.chiselConfig.url = `chisel-cms-${this.chiselConfig.site_app_id}.getforge.io`
      }
    },
    copyParseUrl() {
      this.chiselConfig.parse_url = this.parseServerUrl
    },
    copyParseAppId() {
      if (this.$store.state.site_app.parseSetting != null)
        this.chiselConfig.parse_app_id =
          this.$store.state.site_app.parseSetting.appId
    },
    showDeployLogs() {
      this.$store.commit('application/openModal', {
        component: MODAL_FORGE_CONSOLE,
        props: {
          app: true,
          appType: 2,
        },
      })
    },
  },
  computed: {
    status() {
      if (this.chiselConfig.activated && this.chiselConfig.deployed)
        return 'Activated and Deployed'
      if (this.chiselConfig.activated && !this.chiselConfig.deployed)
        return 'Activated'
      if (!this.chiselConfig.activated) return 'Deactivated'
    },
    chiselDeployed() {
      return (
        !this.defaultConfig.activated ||
        this.chiselConfig.percent_deployed >= 100
      )
    },
    restKey() {
      return this.$store.state.site_app.list[0].restKey
    },
    jsKey() {
      return this.$store.state.site_app.list[0].jsKey
    },
    parseServerUrl() {
      if (this.$store.state.site_app.parseSetting != undefined)
        return this.$store.state.site_app.parseSetting.parse_server_app_url
    },
    changed() {
      return (
        JSON.stringify(this.defaultConfig) != JSON.stringify(this.chiselConfig)
      )
    },
  },
  watch: {
    '$store.state.site_app.chiselApp': {
      handler(newVal, oldVal) {
        if (oldVal == null) {
          this.chiselConfig = _.cloneDeep(newVal)
          this.defaultConfig = _.cloneDeep(newVal)
          this.setBaseChiselUrl()
          this.loading = false
        }
        if (oldVal != null && oldVal.deployed == false) {
          this.chiselConfig = _.cloneDeep(newVal)
          this.defaultConfig = _.cloneDeep(newVal)
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.$store.state.site_app.chiselApp != undefined) {
      this.chiselConfig = _.cloneDeep(this.$store.state.site_app.chiselApp)
      this.defaultConfig = _.cloneDeep(this.$store.state.site_app.chiselApp)
      this.setBaseChiselUrl()
      this.loading = false
    }
  },
}
</script>

<style lang="scss" scoped>
.ChiselSettings {
  padding: 42px 24px 24px;
  max-width: 800px;
  margin: auto;
}
.note {
  background: #fcf4d4;
  color: #9b7406;
  border: 1px solid #f7e9ab;
  border-radius: 3px;
  font-weight: 500;
}
</style>
