<template>
  <div class="PluginPublishFlowPanel">
    <div class="PluginPublishFlowPanel__header">
      <div class="d-flex">
        <h6>Plugin Publishing ({{ step }} / 6)</h6>
        <span class="PluginPublishFlowPanel__status">{{
          appData?.Status || 'Unpublished'
        }}</span>
      </div>
      <v-btn icon text color="n2" @click="onClose">
        <v-icon>close</v-icon>
      </v-btn>
    </div>
    <div class="PluginPublishFlowPanel__body">
      <transition name="slide" appear>
        <div :key="step">
          <PluginPublishFlowAppStep v-if="step == 1" class="slide" />
          <PluginPublishFlowAppContentStep
            v-if="step == 2"
            :categories="categories"
            class="slide"
          />
          <PluginPublishFlowAppDataStep
            v-if="step == 3"
            :capabilities="capabilities"
            class="slide"
          />
          <PluginPublishFlowDeveloperStep v-if="step == 4" class="slide" />
          <PluginInstallParamsBuilder
            v-if="step == 5"
            :categories="categories"
          />
          <PluginPublishFlowPreviewStep
            v-if="step == 6"
            class="slide"
            :categories="categories"
          />
        </div>
      </transition>
    </div>
    <v-footer class="PluginPublishFlowPanel__footer" app>
      <div>
        <v-btn
          color="y1"
          text
          @click="onSaveDraft"
          :disabled="isReadyToSave"
          v-if="activeSite.mode === 'development'"
        >
          <the-icon class="mr-2" icon="save" />
          Dev Build
        </v-btn>
        <div v-else></div>
        <div class="d-flex flex-row-reverse">
          <v-btn
            @click="onNext"
            color="y1"
            v-if="hasNextStep"
            :disabled="notReadyToMoveOn"
            >Next</v-btn
          >
          <v-btn @click="onSubmit" color="y1" :disabled="isReadyToSave" v-else
            >Submit</v-btn
          >
          <v-btn
            @click="onBack"
            text
            color="white"
            class="mr-2"
            :disabled="!hasPreviousStep"
            >Back</v-btn
          >
        </div>
      </div>
    </v-footer>
    <div v-if="loading" class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="30"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>
<script>
import Parse from 'parse'
import { mapState, mapMutations } from 'vuex'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import { initParse } from '@/utils/parse'
import PluginPublishFlowAppStep from './Steps/AppStep'
import PluginPublishFlowAppDataStep from './Steps/AppDataStep'
import PluginPublishFlowAppContentStep from './Steps/AppContentStep'
import PluginPublishFlowDeveloperStep from './Steps/DeveloperStep'
import PluginPublishFlowPreviewStep from './Steps/PreviewStep'
import PluginInstallParamsBuilder from '../PluginInstallParams/PluginInstallParamsBuilder'
import { MODAL_INFORMATION } from '@/components/Modals'
const PLUGIN_PUBLISH_APP_STEP = 1
const PLUGIN_PUBLISH_APP_CONTENT_STEP = 2
const PLUGIN_PUBLISH_APP_DATA_STEP = 3
const PLUGIN_PUBLISH_DEVELOPER_STEP = 4
const PLUGIN_PARAMS_BUILDER = 5
const PLUGIN_PUBLISH_PREVIEW_STEP = 6

export default {
  name: 'PluginPublishFlowPanel',
  components: {
    PluginPublishFlowAppStep,
    PluginPublishFlowAppDataStep,
    PluginPublishFlowAppContentStep,
    PluginPublishFlowDeveloperStep,
    PluginPublishFlowPreviewStep,
    PluginInstallParamsBuilder,
    IntersectingCirclesSpinner,
  },
  data() {
    return {
      step: PLUGIN_PUBLISH_APP_STEP,
      categories: [],
      capabilities: [],
      loading: false,
    }
  },

  computed: {
    ...mapState('plugin_publishing', {
      app: (state) => state.app,
      appData: (state) => state.appData,
      appContent: (state) => state.appContent,
      developer: (state) => state.developer,
    }),
    ...mapState({
      user: (state) => state.user.current,
      elements: (state) => state.formBuilder.elements,
      activeSite: (state) => state.sites.active,
      publisher: (state) => state.sites.active.publisherInfo,
      editingElement: (state) => state.formBuilder.editingElement,
      isInPreview: (state) => state.formBuilder.isInPreview,
    }),
    hasNextStep() {
      return this.step < PLUGIN_PUBLISH_PREVIEW_STEP
    },
    hasPreviousStep() {
      return this.step > PLUGIN_PUBLISH_APP_STEP
    },
    isReadyToSave() {
      return !(this.app?.Name && this.app?.Slug && this.appContent?.Short_Name)
    },
    notReadyToMoveOn() {
      if (this.step === PLUGIN_PUBLISH_APP_STEP)
        return !(this.app?.Name && this.app?.Slug)
      if (this.step === PLUGIN_PUBLISH_APP_CONTENT_STEP)
        return !this?.appContent.Short_Name
      if (this.step === PLUGIN_PUBLISH_DEVELOPER_STEP)
        return !(this.developer?.Name && this.developer?.Email)
      if (this.step === PLUGIN_PARAMS_BUILDER)
        return !!this.editingElement || !!this.isInPreview
      return false
    },
  },
  watch: {
    publisher: {
      async handler(newVal) {
        await this.init()
      },
      deep: true,
      immediate: true,
    },
  },
  async mounted() {
    await this.init()
  },
  methods: {
    ...mapMutations('plugin_publishing', [
      'setDrawerOpened',
      'setApp',
      'setAppData',
      'setAppContent',
      'setDeveloper',
    ]),
    ...mapMutations('formBuilder', ['setEditingElement']),
    // - Find the developer information by the current logged in user email
    // - Get Categories, Capabilities
    async init() {
      if (
        !this.publisher ||
        !this.publisher.parse_server_url ||
        !this.publisher.parse_server_app_id
      )
        return
      const { parse_server_url, parse_server_app_id } = this.publisher
      initParse(parse_server_url, parse_server_app_id)

      if (!this.developer || this.developer?.Email !== this.user.email) {
        await this.findDeveloperByEmail()
      }
      await this.getCategories()
      await this.getCapabilities()
    },

    // - Find the developer information by the current logged in user email, on initialization
    async findDeveloperByEmail() {
      const res = await Parse.Cloud.run('findDeveloperByEmail', {
        email: this.user.email,
        parseServerSiteId: this.publisher?.parse_server_site_id,
      })
      if (res.developer) {
        const developer = res.developer
        this.setDeveloper({
          Name: developer.name,
          Company: developer.company,
          Website: developer.website,
          Email: developer.email,
          IsActive: developer.isActive,
          Verified: developer.verified,
          Country: developer.country,
        })
      } else {
        this.setDeveloper({
          Name: this.user.name,
          Email: this.user.email,
        })
      }
    },
    // - Get Categories on initialization
    async getCategories() {
      const res = await Parse.Cloud.run('getCategories', {
        parseServerSiteId: this.publisher?.parse_server_site_id,
      })
      if (res.categories) {
        this.categories = res.categories.map((category) => ({
          text: category.name,
          value: category.id,
        }))
      }
    },
    // - Get Capabilities on initialization
    async getCapabilities() {
      const res = await Parse.Cloud.run('getCapabilities', {
        parseServerSiteId: this.publisher?.parse_server_site_id,
      })
      if (res.capabilities) {
        this.capabilities = res.capabilities.map((capability) => ({
          text: capability.name,
          value: capability.id,
        }))
      }
    },
    getKeyImageIndex() {
      const { Screenshots: screenshots, keyImageURL } = this.appContent
      if (!screenshots) return -1
      const keyImageIndex = screenshots.findIndex((screenshot) => {
        let url = screenshot.fileURL || screenshot.url || screenshot._url
        if (screenshot instanceof Parse.File) {
          const obj = new Parse.File(screenshot)
          url = obj.url()
        }
        return url === keyImageURL
      })
      return keyImageIndex
    },
    // Submit with Status `Wating for Review`
    // Show information modal upon success
    async onSubmit() {
      this.loading = true
      const keyImageIndex = this.getKeyImageIndex()
      const res = await Parse.Cloud.run('buildApp', {
        parseServerSiteId: this.publisher?.parse_server_site_id,
        app: this.app,
        appContent: { ...this.appContent, keyImageIndex },
        appData: {
          ...this.appData,
          Status: 'Waiting for Review',
          Data_Name: this.appData.Data_Name || `${this.app.Name} Data`,
          InstallParams: JSON.stringify(this.elements),
        },
        developer: this.developer,
        appSecurity: {
          Name: this.activeSite?.url || `${this.app.Slug}-security`,
          Forge_API_Key: this.activeSite?.token || '',
        },
      })
      this.loading = false
      if (res) {
        if (res.status === 'success') {
          const appId = res.appObject.id || res.appObject.objectId
          this.setApp({ ...this.app, id: appId })
          this.setAppData({
            ...this.appData,
            Status: 'Waiting for Review',
            installParams: JSON.stringify(this.elements),
          })
          this.$store.commit('application/openModal', {
            component: MODAL_INFORMATION,
            props: {
              title: 'Plugin submitted for review',
              text: 'Thank you for creating and submitting your plugin! <br /> It usually takes us up to a couple of business days to review it. We will <br /> notify you about any updates in the review process.',
              showCancelBtn: false,
              actionBtnLabel: 'Got it',
              action: () => {
                this.setDrawerOpened(false)
                this.step = PLUGIN_PUBLISH_APP_STEP
              },
            },
          })
        } else {
          this.$store.commit('application/openModal', {
            component: MODAL_INFORMATION,
            props: {
              title: 'Server Error while submitting plugin',
              text: 'Please try again it later or contact administrator.',
              showCancelBtn: false,
              actionBtnLabel: 'OK',
              action: () => {
                this.step = PLUGIN_PUBLISH_APP_STEP
              },
            },
          })
        }
      }
    },
    // Save as draft and show the confirmation dialog to the user.
    async onSaveDraft() {
      this.loading = true
      const keyImageIndex = this.getKeyImageIndex()
      const res = await Parse.Cloud.run('buildApp', {
        parseServerSiteId: this.publisher?.parse_server_site_id,
        app: this.app,
        appContent: { ...this.appContent, keyImageIndex },
        appData: {
          ...this.appData,
          Status: 'Pending Developer Release',
          InstallParams: JSON.stringify(this.elements),
        },
        developer: this.developer,
        appSecurity: {
          Name: this.activeSite?.url || `${this.app.Slug}-security`,
          Forge_API_Key: this.activeSite?.token || '',
        },
      })
      this.loading = false
      if (res && res.status === 'success') {
        const appId = res.appObject.id || res.appObject.objectId
        this.setApp({ ...this.app, id: appId })
        this.setAppData({
          ...this.appData,
          Status: 'Pending Developer Release',
          installParams: JSON.stringify(this.elements),
        })
        this.$store.commit('application/openModal', {
          component: MODAL_INFORMATION,
          props: {
            title: 'Successfully saved as a Development Build',
            showCancelBtn: false,
            actionBtnLabel: 'OK',
            action: () => {
              this.setDrawerOpened(false)
              this.step = PLUGIN_PUBLISH_APP_STEP
            },
          },
        })
      }
    },
    onNext() {
      this.step += 1
    },
    onBack() {
      if (this.step === PLUGIN_PARAMS_BUILDER) {
        this.setEditingElement(null)
      }
      this.step -= 1
    },
    onClose() {
      this.setDrawerOpened(false)
    },
  },
}
</script>
<style scoped lang="scss">
$style: PluginPublishFlowPanel;
.#{$style} {
  height: 100%;
  background: $N5;
  position: relative;
  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid $N6;
    h6 {
      font-size: 16px;
      font-weight: normal;
    }
    &:after {
      content: '';
      display: block;
      position: absolute;
      bottom: -1px;
      left: 0px;
      background: $Y1;
      width: 96px;
      height: 2px;
    }
  }
  &__status {
    background: $B4;
    color: $B1;
    padding: 3px 8px;
    border-radius: 6px;
    margin-left: 4px;
    font-size: 12px;
  }

  &__body {
    position: relative;
    & > div {
      position: absolute; /* change position to absolute */
      top: 0px; /* add top, left, right, bottom properties to position the element */
      left: 0px;
      right: 0px;
      bottom: 0px;
      padding: 28px;
      overflow-y: auto;
      height: calc(100vh - 150px);
    }
  }
  &__footer {
    padding: 0px;
    > div {
      border-top: 1px solid $N6;
      background: $N5;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      width: 100%;
    }
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-out;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-leave,
.slide-enter-to {
  transform: translateX(100%);
  opacity: 0;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(200, 200, 200, 0.3);
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 80;
}
</style>

<style lang="scss">
.PluginPublishFlowPanel .form-group label {
  display: block;
  margin-bottom: 6px;
  color: $N2;
}

.app .PluginPublishFlowPanel .vue-form-generator .form-control {
  border: 1px solid $N3;
  background: transparent;
  border-radius: 8px;
  &:disabled {
    opacity: 0.7;
  }
}
</style>
