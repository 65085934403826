<template>
  <div class="RangePicker d-flex">
    <v-menu
      v-model="menuStartDate"
      :close-on-content-click="false"
      :readonly="readOnly"
      transition="scale-transition"
      compact
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="formattedStartDate"
          placeholder="Start Date"
          readonly
          dense
          hide-details
          class="date-picker"
          v-on="!readOnly && on"
          @click="click"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="localStartDate"
        @input="onStartDateChange"
        :allowed-dates="allowOnlyMondays"
      ></v-date-picker>
    </v-menu>
    <v-menu
      v-model="menuEndDate"
      :close-on-content-click="false"
      :readonly="readOnly"
      transition="scale-transition"
      compact
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="formattedEndDate"
          placeholder="End Date"
          dense
          readonly
          hide-details
          class="date-picker"
          v-on="!readOnly && on"
          @click="click"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="localEndDate"
        @input="onEndDateChange"
        :allowed-dates="allowOnlySundays"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: 'RangePicker',
  props: {
    readOnly: Boolean,
    startDate: String,
    endDate: String,
    click: Function,
  },
  data() {
    return {
      localStartDate: this.startDate,
      localEndDate: this.endDate,
      menuStartDate: false,
      menuEndDate: false,
    }
  },
  computed: {
    formattedStartDate() {
      return this.localStartDate ? this.formatDate(this.localStartDate) : ''
    },
    formattedEndDate() {
      return this.localEndDate ? this.formatDate(this.localEndDate) : ''
    },
  },
  methods: {
    /* Report DateRange Related methods*/
    // Report Range Select Start Date to allow only Monday, start of the week
    allowOnlyMondays(dateString) {
      const date = new Date(dateString)
      return date.getDay() === 1
    },
    // Report Range Select End Date to allow only Sunday, end of the week
    allowOnlySundays(dateString) {
      const date = new Date(dateString)
      return date.getDay() === 0
    },
    onStartDateChange(value) {
      this.localStartDate = value
      this.$emit('update:startDate', value)
      if (this.localEndDate && value > this.localEndDate) {
        this.localEndDate = null
        this.$emit('update:endDate', null)
      }
      this.menuStartDate = false // Close the menu after selection
    },
    onEndDateChange(value) {
      this.localEndDate = value
      this.$emit('update:endDate', value)
      if (this.localStartDate && value < this.localStartDate) {
        this.localStartDate = null
        this.$emit('update:startDate', null)
      }
      this.menuEndDate = false // Close the menu after selection
    },
    formatDate(date) {
      return date.toString()
    },
  },
  watch: {
    startDate: {
      handler() {
        this.localStartDate = this.startDate
      },
    },
    endDate: {
      handler() {
        this.localEndDate = this.endDate
      },
    },
  },
}
</script>
<style lang="scss">
$style: RangePicker;
.#{$style} {
  .date-picker {
    border-radius: 8px;
    padding: 8px 10px;
    background-color: hsla(0, 0%, 100%, 0.08);
    border: 1px solid #5e636d;
    margin-right: 10px;
  }
}
</style>
