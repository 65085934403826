<template>
  <div class="PublisherDashboardMetrics">
    <v-row no-gutters>
      <v-col cols="12" sm="6" class="PublisherDashboardMetrics__col">
        <h6 class="PublisherDashboardMetrics__title">Plugin Installs</h6>
        <v-row no-gutters>
          <v-col cols="auto"
            ><div class="PublisherDashboardMetrics__icon-wrapper">
              <the-icon
                icon="pie-chart"
                class="PublisherDashboardMetrics__icon"
              /></div
          ></v-col>
          <v-col>
            <span class="PublisherDashboardMetrics__value">
              {{ pluginInstallsCount }}
            </span>
            <span class="PublisherDashboardMetrics__value d-none"
              >{{ `${buildMinutes} /` }} <span>{{ buildMax }}</span>
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="PublisherDashboardMetrics__col">
        <h6 class="PublisherDashboardMetrics__title">Published Plugins</h6>
        <v-row no-gutters>
          <v-col cols="auto"
            ><div class="PublisherDashboardMetrics__icon-wrapper">
              <the-icon
                icon="trending-up"
                class="PublisherDashboardMetrics__icon"
              /></div
          ></v-col>
          <v-col>
            <span class="PublisherDashboardMetrics__value"
              >{{ `${bandwidth} /` }} <span>{{ bandwidthMax }}</span>
            </span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider class="PublisherDashboardMetrics__divider d-none d-sm-block" />
    <v-row no-gutters>
      <v-col cols="12" sm="6" class="PublisherDashboardMetrics__col">
        <h6 class="PublisherDashboardMetrics__title">Plugin Developers</h6>
        <v-row no-gutters>
          <v-col cols="auto"
            ><div class="PublisherDashboardMetrics__icon-wrapper">
              <the-icon
                icon="trending-down"
                class="PublisherDashboardMetrics__icon"
              /></div
          ></v-col>
          <v-col>
            <span class="PublisherDashboardMetrics__value">{{
              developersCount
            }}</span>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="6" class="PublisherDashboardMetrics__col">
        <h6 class="PublisherDashboardMetrics__title">SDK Version</h6>
        <v-row no-gutters>
          <v-col cols="auto"
            ><div class="PublisherDashboardMetrics__icon-wrapper">
              <the-icon
                icon="bar-chart"
                class="PublisherDashboardMetrics__icon"
              /></div
          ></v-col>
          <v-col>
            <span class="PublisherDashboardMetrics__value">{{
              sdkVersion
            }}</span>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { parseBandwidth } from '@/utils/common'
export default {
  name: 'PublisherDashboardMetrics',
  props: {
    buildCurrent: {
      type: [String, Number],
      default: 0,
    },
    buildMax: {
      type: [String, Number],
      default: 200,
    },
    pluginInstallsCount: {
      type: [String, Number],
      default: 200,
    },
    bandwidthCurrent: {
      type: [String, Number],
      default: '100 KB',
    },
    bandwidthMax: {
      type: [String, Number],
      default: '100 GB',
    },
    concurentCurrent: {
      type: [String, Number],
      default: 6,
    },
    concurentMax: {
      type: [String, Number],
      default: 100,
    },
    developersCount: {
      type: [String, Number],
      default: 6,
    },
    sdkVersion: {
      type: [String, Number],
      default: 1.1,
    },
  },
  computed: {
    isOrganisation() {
      return this.$store.state.organisations.current.id != 0
    },
    bandwidth() {
      let objectBandwidth = !this.isOrganisation
        ? this.$store.state.user.current.bandwidth
        : this.$store.state.organisations.current.bandwidth
      return parseBandwidth(objectBandwidth)
    },
    buildMinutes() {
      return !this.isOrganisation
        ? this.$store.state.user.current.buildMinutes
        : this.$store.state.organisations.current.buildMinutes
    },
    members() {
      return this.$store.state.organisations.current.members
    },
  },
}
</script>
<style lang="scss">
$style: PublisherDashboardMetrics;
.#{$style} {
  display: flex;
  flex-direction: column;
  &__section_title_row {
    margin-bottom: 30px;
  }
  &__section_title {
    color: $white;
    font-weight: 500;
    font-size: $H20;
    margin-top: 10px;
  }
  &__title {
    color: $N2;
    font-size: $H14;
    margin-bottom: 20px;
    font-weight: 400;
  }
  &__col {
    padding-left: 24px !important;
    border-left: 1px solid $N5;
    &:first-child {
      border-left: none;
      padding-left: 0px;
    }
    &.without_border {
      border-right: unset !important;
    }
  }
  &__icon-wrapper {
    width: 40px;
    height: 38px;
    border-radius: 8px;
    background-color: $N5;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
  }
  &__value {
    color: $white;
    font-weight: 600;
    font-size: $H24;
    span {
      color: $N2;
    }
  }
  &__divider {
    width: 100%;
    background-color: $N5;
    margin: 24px 0;
  }

  @media screen and (max-width: 600px) {
    &__col {
      margin-bottom: 12px;
      padding-left: 0 !important;
      border-left: none;
    }
    &__title {
      margin-bottom: 4px;
    }
  }
}
</style>
