<template>
  <div class="narrow-container mx-2">
    <div class="mt-6 mx-4" v-if="!loading">
      <div class="PolicyList px-4 mb-2" v-for="policy in policyList">
        <div class="d-flex py-4 align-center">
          <div
            class="PolicyList__icon-button pa-2"
            :class="{ 'mr-5': !isNavDrawerMiniVariant }"
          >
            <the-icon icon="document" class="PolicyList__icon" />
          </div>
          {{ policy.name }}
        </div>

        <v-btn
          class="v-btn viewBtn"
          @click="toPolicyDetail(policy)"
          elevation="2"
          large
        >
          <v-icon dark left> mdi-eye </v-icon>
          View
        </v-btn>
      </div>
    </div>
    <div v-else class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import ColumnsFilter from '@/components/_Common/ColumnsFilter'
import { MODAL_ALERT } from '@/components/Modals'
import dayjs from 'dayjs'

const Parse = require('parse')
export default {
  name: 'PolicyList',
  components: {
    ColumnsFilter,
    IntersectingCirclesSpinner,
  },
  data() {
    return {
      loading: true,
      policyList: [],
    }
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current,
      isNavDrawerMiniVariant: ({ application }) =>
        application.isNavDrawerMiniVariant,
    }),
    isPublisherAdmin() {
      return this.currentPublisherInfo.role === 'admin'
    },
  },
  mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id)
    Parse.serverURL = this.currentPublisherInfo.parse_server_url
    this.getPolicyList()
  },
  methods: {
    async getPolicyList() {
      this.loading = true
      const res = await Parse.Cloud.run('getPoliciesList', {
        parseServerSiteId: this.currentPublisherInfo.parse_server_site_id,
      })
      this.policyList = res.policiesList
      this.loading = false
    },
    formatDate(date) {
      return dayjs(date).format('D MMM YYYY hh:mm')
    },
    toPolicyDetail(policy) {
      this.$router.push({
        name: 'publisher_policy_detail',
        params: { policyId: policy.id },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
$style: PolicyList;
.#{$style} {
  background: $N7;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &__icon-button {
    background: $N5;
    height: 40px;
  }
  &__icon {
    color: $B1;
    fill: $B1;
  }
  .v-btn {
    display: block;
    margin-left: 10px;
  }
  .viewBtn {
    color: $B1;
    background: $N5;
  }
}

.spinner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}
</style>
