import Vue from 'vue'

import ModalAlert from './ModalAlert'
Vue.component('ModalAlert', ModalAlert)

import ModalInformation from './ModalInformation'
Vue.component('ModalInformation', ModalInformation)

import ModalCreateProject from './ModalCreateProject'
Vue.component('ModalCreateProject', ModalCreateProject)

import ModalCreateSite from './ModalCreateSite'
Vue.component('ModalCreateSite', ModalCreateSite)

import ModalCreateOrganisation from './ModalCreateOrganisation'
Vue.component('ModalCreateOrganisation', ModalCreateOrganisation)

import ModalSiteHook from './ModalSiteHook'
Vue.component('ModalSiteHook', ModalSiteHook)

import ModalForgeConsole from './ModalForgeConsole'
Vue.component('ModalForgeConsole', ModalForgeConsole)

import ModalEnterBilling from './ModalEnterBilling'
Vue.component('ModalEnterBilling', ModalEnterBilling)

import ModalCardData from './ModalCardData'
Vue.component('ModalCardData', ModalCardData)

import ModalNewVersionDeployed from './ModalNewVersionDeployed'
Vue.component('ModalNewVersionDeployed', ModalNewVersionDeployed)

import ModalInviteMembers from './ModalInviteMembers'
Vue.component('ModalInviteMembers', ModalInviteMembers)

import ModalStatusChangeConfirmation from './ModalStatusChangeConfirmation'
Vue.component('ModalStatusChangeConfirmation', ModalStatusChangeConfirmation)

import ModalComments from './ModalComments'
Vue.component('ModalComments', ModalComments)

import ModalAllNotifications from './ModalAllNotifications'
Vue.component('ModalAllNotifications', ModalAllNotifications)

import ModalVersionDiff from './ModalVersionDiff'
Vue.component('ModalVersionDiff', ModalVersionDiff)

import ModalFeedback from './ModalFeedback'
Vue.component('ModalFeedback', ModalFeedback)

export const MODAL_ALERT = 'ModalAlert'
export const MODAL_INFORMATION = 'ModalInformation'
export const MODAL_CREATE_PROJECT = 'ModalCreateProject'
export const MODAL_CREATE_SITE = 'ModalCreateSite'
export const MODAL_CREATE_ORGANISATION = 'ModalCreateOrganisation'
export const MODAL_SITE_HOOK = 'ModalSiteHook'
export const MODAL_FORGE_CONSOLE = 'ModalForgeConsole'
export const MODAL_ENTER_BILLING = 'ModalEnterBilling'
export const MODAL_CARD_DATA = 'ModalCardData'
export const MODAL_NEW_VERSION_DEPLOYED = 'ModalNewVersionDeployed'
export const MODAL_INVITE_MEMBERS = 'ModalInviteMembers'
export const MODAL_STATUS_CHANGE_CONFIRMATION = 'ModalStatusChangeConfirmation'
export const MODAL_COMMENTS = 'ModalComments'
export const MODAL_FEEDBACK = 'ModalFeedback'
export const MODAL_ALL_NOTIFICATIONS = 'ModalAllNotifications'
export const MODAL_VERSION_DIFF = 'ModalVersionDiff'
