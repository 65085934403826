<template>
  <iframe
    v-if="activeApp && appURL"
    :src="appURL"
    :width="activeApp.width || '100%'"
    :height="activeApp.height || '100%'"
    class="plugin-wrapper"
    ref="iframe"
    :id="activeApp.extensionId"
    :sandbox="sandbox"
    @load="connectApp()"
  />
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'SitePlugin',
  computed: {
    ...mapState({
      activeApp: (state) => state.sites.activeApp,
      activePlugin: (state) => state.sites.activePlugin,
      user: (state) => state.user.current,
    }),
    appURL() {
      if (!this.activeApp) return ''
      let { siteSrc, component: componentPath, pickerURL } = this.activeApp
      if (pickerURL) return pickerURL // Picker
      if (this.activeApp.param && this.activeApp.param.component)
        componentPath = this.activeApp.param.component
      if (componentPath) {
        if (this.isAbsoluteURL(componentPath))
          return componentPath + window.location.search
        else {
          if (siteSrc.endsWith('/')) siteSrc = siteSrc.slice(0, -1)
          if (componentPath.startsWith('/'))
            componentPath = componentPath.slice(1)
          return `${siteSrc}/${componentPath}${window.location.search}`
        }
      }
      return ''
    },
    sandbox() {
      if (
        this.activeApp &&
        this.activeApp.sandboxPermissions &&
        this.activeApp.sandboxPermissions.length > 0
      )
        return this.activeApp.sandboxPermissions.join(' ')
      return 'allow-scripts allow-presentation allow-forms allow-same-origin'
    },
  },
  mounted() {
    if (!this.showMoreAppBar) this.setShowMoreAppBar(true)
  },
  methods: {
    ...mapMutations('sites', [
      'setActiveApp',
      'setActivePlugin',
      'setShowMoreAppBar',
    ]),
    isAbsoluteURL(urlString) {
      return (
        urlString.indexOf('http://') === 0 ||
        urlString.indexOf('https://') === 0
      )
    },
    connectApp() {
      const ref = this.$refs.iframe
      if (ref && ref.contentWindow) {
        ref.contentWindow.postMessage(
          {
            type: 'CONNECT',
            src: this.activeApp.siteSrc,
            pluginId: this.activeApp.pluginId,
            extensionId: this.activeApp.extensionId,
            initialArgs: {
              currentUser: this.user,
              activeSite: this.activeSite,
              param: this.activeApp.param,
            },
          },
          '*'
        )
      }
    },
  },
}
</script>

<style scoped>
.plugin-wrapper {
  height: calc(100vh - 150px);
  width: calc(100% - 32px);
  margin-left: 24px;
  margin-right: 8px;
  border: none;
}
</style>
