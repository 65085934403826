<template>
  <div class="PaidBilling">
    <div class="PaidBilling__plans">
      <h2 class="PaidBilling__title d-inline-block">Your Subscription</h2>
      <small class="PaidBilling__custom_plan ml-4" v-if="customPlan">
        Custom Added Plan
      </small>
      <div class="PaidBilling__row gap-3" :class="{ 'mb-4': customPlan }">
        <div
          class="PaidBilling__col"
          :class="{
            _active: selectedPlan == plan.name.toLowerCase(),
          }"
          v-for="plan in plans"
          :key="plan.name"
          @click="changeSelectedPlan(plan)"
        >
          <div class="PaidBilling__plan">
            <div class="PaidBilling__plan-head">
              <h4 class="PaidBilling__plan-title">{{ `${plan.name} Plan` }}</h4>
              <span
                class="PaidBilling__plan-label"
                v-if="currentPlan == plan.name.toLowerCase()"
                >current</span
              >
            </div>
            <div class="PaidBilling__plan-body">
              <h6 class="PaidBilling__plan-cost">
                {{ plan.cost }}
              </h6>
              <p class="PaidBilling__plan-subtext">
                (plus VAT where applicable)
              </p>
              <p class="PaidBilling__plan-text _bold">{{ plan.sitesCount }}</p>
              <p
                class="PaidBilling__plan-text"
                v-for="feature in baseFeatures"
                :key="feature"
              >
                {{ feature }}
              </p>
              <p class="PaidBilling__plan-text">
                <span> {{ plan.bandwidth }} bandwidth </span>/ month
                <br />
                ({{ plan.extra }}¢ per extra GB)
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-if="errorMain" class="error-main pa-4 mt-2">
        <div v-for="e in errorMain">{{ e }}</div>
      </div>
      <div class="PaidBilling__plans-footer" v-if="!customPlan">
        <v-btn
          large
          @click="selectedPlan = currentPlan || saving"
          color="n5"
          dark
        >
          Cancel
        </v-btn>
        <v-btn
          large
          @click="changePlan"
          color="y1"
          dark
          :disabled="selectedPlan === currentPlan || saving"
        >
          Change Plan
        </v-btn>
      </div>
    </div>
    <div class="PaidBilling__sections">
      <div class="PaidBilling__row _with-gap">
        <div class="PaidBilling__section">
          <h2 class="PaidBilling__title">Billing Details</h2>
          <p class="PaidBilling__section-text">
            {{ billingDetailsText }}
          </p>
          <v-btn large @click="upgradeDetails" color="n5" dark>
            Update Billing Details
          </v-btn>
        </div>
        <div class="PaidBilling__section">
          <h2 class="PaidBilling__title">Cancel Subscription</h2>
          <p class="PaidBilling__section-text">
            You can cancel your subscription at any point. If you'd like to
            switch to a different plan instead, please select one from the
            choices under Your Subscription.
          </p>
          <div v-if="cancelErrorsExists" class="error-main pa-4 my-2">
            <div v-for="e in cancelErrors">{{ e }}</div>
          </div>
          <v-btn
            v-if="!saving"
            large
            @click="cancelSubscription"
            color="n5"
            dark
            :disabled="saving"
          >
            Downgrade to a Tin Plan
          </v-btn>
        </div>
      </div>
      <div class="PaidBilling__section _last">
        <h2 class="PaidBilling__title">Subscription code</h2>
        <p class="PaidBilling__section-text" v-if="!couponSavePressed">
          Got a subscription code? Enter it here
        </p>
        <p
          class="PaidBilling__section-text"
          v-else-if="couponSavePressed && couponSaved"
        >
          Coupon Saved!
        </p>
        <p class="PaidBilling__section-text red--text" v-if="couponError">
          {{ couponError }}
        </p>
        <v-text-field
          class="PaidBilling__section-input"
          dark
          filled
          v-model="subscriptionCode"
          dense
        />
        <div class="PaidBilling__section-footer">
          <v-btn
            large
            @click="enterCode"
            color="y1"
            :disabled="!subscriptionCodeEntered"
            dark
          >
            Save
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MODAL_ALERT, MODAL_CARD_DATA } from '@/components/Modals'
import { STRIPE_PUBLIC_KEY } from '@/env'
export default {
  name: 'PaidBilling',
  props: {
    baseFeatures: Array,
    plans: Array,
    currentPlan: String,
    userId: Number,
    sitesCount: Number,
  },
  data() {
    return {
      selectedPlan: this.currentPlan,
      subscriptionCode: '',
      cancelErrors: '',
      couponError: '',
      couponSaved: null,
      errorMain: '',
      saving: false,
    }
  },
  methods: {
    changeSelectedPlan(plan) {
      if (this.customPlan) return
      this.selectedPlan = plan.name.toLowerCase()
    },
    async confirmStripeAction(secret) {
      const stripe = Stripe(STRIPE_PUBLIC_KEY)
      stripe.confirmCardPayment(secret).then((res) => {
        if (res.error || res.paymentIntent.status != 'succeeded') {
          this.errorMain = 'Payment not confirmed. Please check your card'
          this.saving = false
          return
        }
        const params = {
          session: {
            plan_id: this.selectedPlan,
          },
        }
        this.$store.dispatch('user/checkSubscription', params).then(() => {
          this.saving = false
        })
      })
    },
    changePlan() {
      if (this.saving) return
      this.saving = true
      this.errorMain = ''
      let params = {
        id: this.$store.state.user.current.id,
        session: {
          plan_id: this.selectedPlan,
        },
      }
      this.$store
        .dispatch('user/updateSubscription', params)
        .then((res) => {
          if (res.client_secret) {
            this.confirmStripeAction(res.client_secret)
            return
          }
          this.saving = false
        })
        .catch((e) => {
          this.errorMain = e.body.errors_array.map((e) => e + '.')
          this.saving = false
        })
    },
    upgradeDetails() {
      this.$store.commit('application/openModal', {
        component: MODAL_CARD_DATA,
        props: {
          paid: true,
          baseCountry: this.$store.state.user.current.country,
        },
      })
    },
    cancelSubscription() {
      this.cancelErrors = ''
      this.saving = true
      const user_params = {
        id: this.userId,
        session: {
          stripe_card_token: null,
          plan_id: 'free',
          subscription_active: false,
        },
      }
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Are you sure you want to cancel your subscription?',
          text: "Your payment method will no longer be billed but you'll still have access to a free Forge account. If you want to cancel an account entirely please contact our team at <a href='mailto:support@getforge.com'>support@getforge.com</a>",
          showCancelBtn: true,
          actionBtnLabel: 'Yes, cancel my subscription',
          action: () =>
            this.$store
              .dispatch('user/updateSubscription', user_params)
              .then(() => {
                this.saving = false
              })
              .catch((e) => {
                this.cancelErrors = e.body.errors.map((e) => e + '.')
                this.saving = false
              }),
        },
      })
    },
    enterCode() {
      this.couponError = ''
      const user_params = {
        id: this.userId,
        session: {
          coupon: this.subscriptionCode,
        },
      }
      this.$store
        .dispatch('user/updateSubscription', user_params)
        .then(() => {
          this.couponSaved = true
          this.subscriptionCode = ''
          const self = this
          setTimeout(() => {
            self.couponSaved = null
          }, 4000)
        })
        .catch((e) => {
          console.log(e)
          if (e.body.errors.coupon != undefined)
            this.couponError = e.body.errors.coupon.join('. ')
          this.couponSaved = null
        })
    },
  },
  computed: {
    subscriptionCodeEntered() {
      return this.subscriptionCode.length > 0
    },
    customPlan() {
      return this.$store.state.user.current.customPaidPlan
    },
    cancelErrorsExists() {
      return this.cancelErrors.length > 0
    },
    couponSavePressed() {
      return this.couponSaved != null
    },
    billingDetailsText() {
      if (this.customPlan)
        return "If you want to change your plan, click below. We will debit this payment method every month. Users with custom added plan can't select their plan."
      else
        return 'If you need to change your card details, click below. We will debit this payment method every month.'
    },
  },
}
</script>

<style lang="scss">
$style: PaidBilling;
.#{$style} {
  padding: 42px 24px 24px;

  &__title {
    color: $white;
    font-weight: 500;
    font-size: $H20;
    margin-bottom: 20px;
  }
  &__row {
    display: flex;
    flex-wrap: wrap;
    &._with-gap {
      gap: 16px;
    }
  }
  &__col {
    flex: 1 1 0;
    max-width: 25%;
    border: 1px solid $N5;
    border-right-color: transparent;
    cursor: pointer;
    &:last-child {
      border-right-color: $N5;
      border-radius: 0 8px 8px 0;
    }
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &._active {
      background-color: $N4;
      border: 1px solid $Y1;
    }
  }
  &__custom_plan {
    color: $Y1;
    border: 1px solid $Y1;
    border-radius: 8px;
    padding: 10px;
  }
  &__plan {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 12px;
  }
  &__plan-head {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &__plan-title {
    color: $white;
    font-weight: 600;
    font-size: $H18;
    padding: 8px 0;
  }
  &__plan-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__plan-cost {
    color: $white;
    font-weight: 600;
    font-size: $H16;
    margin-bottom: 16px;
  }
  &__plan-label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    background-color: $Y1;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -22px);
  }
  &__plan-subtext {
    color: $N2;
    font-size: $H11;
    margin-bottom: 16px;
  }
  &__plan-text {
    color: $white;
    font-weight: 400;
    font-size: $H14;
    margin-bottom: 10px;
    text-align: center;
    &._bold {
      font-weight: 600;
    }
    span {
      font-weight: 600;
    }
  }
  &__plans-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0;
    gap: 16px;
  }
  &__section {
    @extend %section-styles;
    flex-basis: 50%;
    &._last {
      margin-top: 16px;
    }
  }
  &__input {
    @extend %input-styles;
  }
  &__section-footer {
    display: flex;
  }
  @media screen and (max-width: 600px) {
    &__col {
      max-width: 50%;
    }
    &__section {
      flex-basis: 100%;
    }
  }
}
</style>
