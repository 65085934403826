<template>
  <div>
    <v-navigation-drawer
      class="PluginPublishFlowDrawer"
      :class="{
        settingsSidebar: true,
        isMobile: this.$vuetify.breakpoint.smAndDown,
      }"
      v-model="drawer"
      :input-value="drawer"
      @input="onDrawerChange"
      fixed
      :persistent="drawer"
      @click.stop
      height="100%"
      :bottom="isMobile"
      :right="!isMobile"
      :width="widthDimensions"
    >
      <PluginPublishFlowPanel />
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import PluginPublishFlowPanel from './Panel'

export default {
  name: 'PluginPublisherFlowDrawer',
  components: {
    PluginPublishFlowPanel,
  },
  data() {
    return {
      drawer: false,
    }
  },

  computed: {
    ...mapState({
      drawerOpened: (state) => state.plugin_publishing.drawerOpened,
    }),
    isMobile() {
      // detect the size of the screen, useful for showing navigation drawer bottom on Mobile
      return this.$vuetify.breakpoint.smAndDown
    },
    widthDimensions() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '100%'
      } else if (this.$vuetify.breakpoint.md) {
        return '40%'
      } else {
        return '30%'
      }
    },
  },
  methods: {
    ...mapMutations('plugin_publishing', ['setDrawerOpened']),
    onDrawerChange(val) {
      this.setDrawerOpened(val)
    },
  },
  watch: {
    drawerOpened: function (newVal) {
      this.drawer = !!this.drawerOpened
    },
  },
}
</script>
<style scoped lang="scss">
$style: PluginPublishFlowDrawer;
.#{$style} {
  background: $N5;
}
</style>
