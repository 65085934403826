<template>
  <MemberAccess
    :loadingProp="loading"
    :usersProp="users"
    :invitedProp="invited"
    :memberAccessTypeProp="3"
    :changeOwnerEnabledProp="false"
  />
</template>

<script>
import _ from 'lodash'
import MemberAccess from '@/components/_Common/MemberAccess'
export default {
  name: 'OrganisationAccess',
  components: { MemberAccess },
  data() {
    return {
      email: '',
      loading: true,
      users: [],
      invited: [],
    }
  },
  mounted() {
    this.$store.dispatch('invitation/load', {
      type: 'organisation',
      type_id: this.$store.state.organisations.current.id,
    })
    this.$store.dispatch('organisation_user/load')
  },
  watch: {
    '$store.state.organisation_user.list': {
      handler(newVal, oldVal) {
        this.users = _.cloneDeep(newVal)
        this.loading = false
      },
      deep: true,
    },
    '$store.state.invitation.list': {
      handler(newVal, oldVal) {
        if (newVal[0] != undefined && newVal[0].type != 'Organisation') return
        this.invited = _.cloneDeep(newVal)
        this.loading = false
      },
      deep: true,
    },
  },
}
</script>
