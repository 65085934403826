<template>
  <div class="SiteFeedbackForm__container">
    <div v-if="!myFeedback">
      <div class="SiteFeedbackForm__form">
        <v-label>Rating</v-label>
        <v-rating
          half-increments
          hover
          v-model="rating"
          background-color="red lighten-3"
          color="red"
        />
        <v-label>Review</v-label>
        <vue-editor
          v-model="review"
          :disabled="loading"
          :editor-toolbar="toolbarOptions"
          class="dark-theme"
        ></vue-editor>
      </div>
      <div class="d-flex mt-4">
        <v-btn
          class="y1 SiteFeedbackForm__submit_btn"
          @click="submitReview"
          :disabled="!isFormReady"
        >
          Submit
        </v-btn>
        <v-btn class="SiteFeedbackForm__reset_btn" text @click="resetForm">
          Reset
        </v-btn>
      </div>
    </div>
    <SiteFeedbackElement v-else :feedback="myFeedback" :mine="true" />
  </div>
</template>

<script>
import { Parse } from 'parse/lib/browser/Parse'
import { VueEditor } from 'vue2-editor'
import { mapState } from 'vuex'
import { MODAL_INFORMATION } from '@/components/Modals'
import SiteFeedbackElement from './SiteFeedbackElement'

export default {
  name: 'SiteFeedbackForm',
  props: {
    app: Object,
    myFeedback: Object,
    parseServerSiteId: String,
  },
  components: {
    VueEditor,
    SiteFeedbackElement,
  },
  data() {
    return {
      rating: null,
      review: '',
      toolbarOptions: [
        ['bold', 'italic', 'underline', 'strike'], // Basic text styles
        [{ size: ['small', false, 'large', 'huge'] }], // Font sizes
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
      loading: false,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.current,
    }),
    inputPlaceholder() {
      return `Review for #${this.app?.name} ...`
    },
    isFormReady() {
      return this.rating && this.review.length > 0
    },
  },
  methods: {
    async submitReview() {
      this.loading = true
      try {
        const res = await Parse.Cloud.run('createReview', {
          parseServerSiteId: this.parseServerSiteId,
          appSlug: this.app.slug,
          authorName: this.user.name,
          authorEmail: this.user.email,
          comment: this.review,
          rating: this.rating,
        })

        if (res.status === 'success') {
          this.$store.commit('application/openModal', {
            component: MODAL_INFORMATION,
            props: {
              title: 'Feedback Submitted and under review',
              text: 'Thank you for taking time to leave rating & review on the plugin.',
              showCancelBtn: false,
              actionBtnLabel: 'Got it',
            },
          })
        } else {
          if (res.error?.message) alert(res.error?.message)
        }
      } catch (error) {
        console.error('Error in createReview', error)
      }
      this.loading = false
    },
    resetForm() {},
  },
}
</script>

<style lang="scss">
$style: SiteFeedbackForm;
.#{$style} {
  &__container {
    .#{$style}__form {
      label {
        margin-bottom: 6px;
        color: $N2 !important;
        display: block;
      }
    }
  }
}
</style>

<style lang="scss">
.dark-theme {
  .ql-formats {
    color: white;
  }
  .ql-picker-label {
    color: white;
  }
  &.quillWrapper .ql-snow {
    .ql-stroke {
      stroke: white;
    }
    .ql-fill {
      fill: white;
    }
  }
}
</style>
