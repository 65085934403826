<template>
  <ParentAuthForm
    :subtitle="
      finished || finishedReg
        ? 'Your account is almost created'
        : 'Create an Account'
    "
    :loading="loading"
    :type="1"
  >
    <div v-if="!finished && !finishedReg">
      <CloudAuth :loading="loading" v-on:finishedReg="finished = true" />

      <v-divider class="mt-8" />

      <v-form @submit.prevent="register" class="form mt-6 mx-auto">
        <div>Username</div>
        <v-text-field
          v-model="name"
          @input="onInputUsername"
          :error-messages="errorVisibleUsername"
          :disabled="loading"
          class="mt-1"
          autofocus
          filled
          dense
        />

        <div v-if="!token">Email</div>
        <v-text-field
          v-model="email"
          @input="onInputEmail"
          :error-messages="errorVisibleEmail"
          :disabled="loading"
          v-if="!token"
          type="email"
          class="mt-1"
          filled
          dense
        />

        <div>Password</div>
        <v-text-field
          v-model="password"
          @input="onInputPassword"
          :error-messages="errorVisiblePassword"
          :type="showingPassword ? 'text' : 'password'"
          :disabled="loading"
          class="mt-1"
          filled
          dense
        >
          <template v-slot:append>
            <v-btn @click="showingPassword = !showingPassword" icon small>
              <v-icon>{{
                showingPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
              }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <div>Confirm password</div>
        <v-text-field
          v-model="passwordConfirm"
          @input="onInputPasswordConfirm"
          :error-messages="errorVisiblePasswordConfirm"
          :disabled="loading"
          :type="showingConfPassword ? 'text' : 'password'"
          class="mt-1"
          filled
          dense
        >
          <template v-slot:append>
            <v-btn
              @click="showingConfPassword = !showingConfPassword"
              icon
              small
            >
              <v-icon>{{
                showingConfPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
              }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <vue-recaptcha
          ref="recaptcha"
          :sitekey="siteKey"
          @verify="confirmCaptcha"
          @expired="onCaptchaExpired"
          @error="errorCaptcha"
        />

        <div v-if="errorMain.length > 0" class="error-main pa-4">
          <div class="err_string" v-for="err in errorMain">
            {{ err }}
          </div>
        </div>

        <v-btn
          class="mt-8 mx-auto d-block register-btn y1"
          :disabled="!dirtyForm || loading"
          type="submit"
        >
          Create Free Account
        </v-btn>

        <div class="mt-4 welcome-subtitle" align="center">
          By continuing, you're agreeing to our <u>Terms</u> <br />
          and <u>Privacy policy</u>
        </div>
      </v-form>
    </div>

    <div v-else class="mt-10">
      <p>
        To complete your account creation, please check your email for a
        verification link.
      </p>
      <p>It should arrive in your inbox shortly.</p>
      <router-link to="/login" class="d-block text-center mt-8">
        Return to Log in
      </router-link>
    </div>
  </ParentAuthForm>
</template>

<script>
import ParentAuthForm from '@/components/AuthForms/ParentAuthForm'
import CloudAuth from '@/components/AuthForms/CloudAuth'
import { VueRecaptcha } from 'vue-recaptcha'
import { RECAPTCHA_SITE_KEY } from '@/env'
export default {
  name: 'RegistrationForm',
  components: { ParentAuthForm, VueRecaptcha, CloudAuth },
  props: {
    finishedReg: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    let token = this.$route.query.token
    return {
      name: '',
      email: '',
      password: '',
      passwordConfirm: '',
      siteKey: RECAPTCHA_SITE_KEY,
      token,
      errorVisibleUsername: null,
      errorVisibleEmail: null,
      errorVisiblePassword: null,
      errorVisiblePasswordConfirm: null,
      errorMain: [],
      recaptchaToken: null,
      recaptchaFailed: false,
      finished: this.finishedReg,
      dirtyForm: false,
      showingPassword: false,
      showingConfPassword: false,
      loading: false,
    }
  },
  computed: {
    errorUsername() {
      if (this.name) return null
      return 'Username can not be empty'
    },
    errorEmail() {
      if (!this.email && !this.token) return 'Email can not be empty'
      if (!this.email.match(/.+@.+/) && !this.token)
        return 'Email must be valid'
      return null
    },
    errorPassword() {
      if (!this.password) return 'Password can not be empty'
      if (this.password.length < 8)
        return 'Password must be at least 8 characters'
      return null
    },
    errorPasswordConfirm() {
      if (this.showingPassword) return null
      if (this.password != this.passwordConfirm)
        return 'The confirmation must match the password'
      return null
    },
  },
  methods: {
    confirmCaptcha(recaptchaToken) {
      this.recaptchaToken = recaptchaToken
      this.errorMain = []
      this.recaptchaFailed = false
    },
    errorCaptcha() {
      this.errorMain = []
      this.recaptchaToken = null
      this.recaptchaFailed = true
      this.errorMain.push = 'Recaptcha Failed'
    },
    onCaptchaExpired() {
      this.errorMain = []
      this.recaptchaToken = null
      this.recaptchaFailed = true
      this.errorMain.push = 'Recaptcha Failed;'
    },
    onInputUsername() {
      this.errorVisibleUsername = null
      this.dirtyForm = true
    },
    onInputEmail() {
      this.errorVisibleEmail = null
      this.dirtyForm = true
    },
    onInputPassword() {
      this.errorVisiblePassword = null
      this.dirtyForm = true
    },
    onInputPasswordConfirm() {
      this.errorVisiblePasswordConfirm = null
      this.dirtyForm = true
    },

    register() {
      this.dirtyForm = false
      this.errorMain = []

      if (
        this.errorUsername ||
        this.errorEmail ||
        this.errorPassword ||
        this.errorPasswordConfirm ||
        this.recaptchaFailed
      ) {
        this.errorVisibleUsername = this.errorUsername
        this.errorVisibleEmail = this.errorEmail
        this.errorVisiblePassword = this.errorPassword
        this.errorVisiblePasswordConfirm = this.errorPasswordConfirm
        return
      }

      this.loading = true

      let user_params = {
        user: {
          name: this.name,
          password: this.password,
          password_confirmation: this.passwordConfirm,
        },
        token: this.$route.query.token,
        'g-recaptcha-response': this.recaptchaToken,
      }
      if (user_params.token == undefined) {
        user_params['user']['email'] = this.email
        this.api.User.register(user_params)
          .then(() => {
            this.loading = false
            this.finished = true
          })
          .catch((error) => {
            this.loading = false
            if (!error) return

            let errorMain = []
            for (let key in error.body.errors) {
              error.body.errors[key].forEach((message) => {
                errorMain.push(key + ' ' + message + '. ')
              })
            }
            this.errorMain = errorMain
          })
      } else {
        this.$store
          .dispatch('user/confirmInvitation', user_params)
          .then(() => this.$store.dispatch('application/load'))
          .then(() => {
            if (this.$route.query.redirect) {
              this.$router.push({ path: this.$route.query.redirect })
            } else {
              this.$router.push({ name: 'dashboard' })
            }
          })
          .catch((error) => {
            this.loading = false
            if (!error) return
            let errorMain = []
            error.body.error.forEach((message) => {
              errorMain.push(message + '.')
            })
            this.errorMain = errorMain
          })
      }
    },
  },
  mounted() {
    const token = this.$route.query.token
    if (token === undefined) return
    this.loading = true
    this.$store
      .dispatch('user/checkInvitation', token)
      .then((res) => {
        if (!res) {
          this.loading = false
          return
        }
        this.$store.dispatch('application/load').then(() => {
          if (this.$route.query.redirect) {
            this.$router.push({ path: this.$route.query.redirect })
          } else {
            this.$router.push({ name: 'dashboard' })
          }
        })
      })
      .catch((error) => {
        this.loading = false
        console.log(error)
      })
  },
}
</script>

<style lang="scss" scoped>
.error-main {
  background: #e94a53;
  border-radius: 2px;
}

.welcome-subtitle {
  color: $N28;
  font-family: 'Instrument Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
}

.logInLink {
  text-decoration: unset;
  color: #ff7e21;
}

.err_string:first-letter {
  text-transform: capitalize;
}

.link-disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.26);
}
.register-btn {
  text-transform: initial;
}
</style>
