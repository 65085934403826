<template>
  <div
    class="PluginInstallParamsPreview"
    :class="settingsDrawerOpened ? 'settings-drawer' : ''"
  >
    <div class="PluginInstallParamsPreview__header">
      <div class="d-flex align-center v-label cursor-pointer" @click="onBack">
        <the-icon icon="arrow-left" class="mr-2" />
        Back
      </div>
      <div class="d-flex">
        <h6>Form Preview</h6>
      </div>
      <v-btn icon text color="n2" @click="onClose" v-if="settingsDrawerOpened">
        <v-icon>close</v-icon>
      </v-btn>
      <span v-else>&nbsp;</span>
    </div>
    <div class="PluginInstallParamsPreview__body">
      <div class="d-flex my-4">
        <img
          :src="appContent.keyImage"
          v-if="appContent.keyImage"
          class="key-image"
        />
        <div v-else class="key-image placeholder"></div>
        <div class="ml-4">
          <h3 class="mb-4">{{ app.Name }}</h3>
          <div v-if="categoryNames">
            <span class="tag" v-for="categoryName in categoryNames">{{
              categoryName
            }}</span>
          </div>
        </div>
      </div>
      <vue-form-generator
        tag="div"
        class="preview-form"
        :schema="schema"
        :model="param"
      ></vue-form-generator>
    </div>
    <v-footer class="PluginInstallParamsPreview__footer" app>
      <div>
        <div class="d-flex flex-row">
          <v-btn @click="onBack" text color="white" class="mr-2">Back</v-btn>
        </div>
      </div>
    </v-footer>
  </div>
</template>
<script>
import Parse from 'parse'
import { mapState, mapMutations } from 'vuex'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import JSONPrint from '@/components/_Common/JSONPrint'

export default {
  name: 'PluginInstallParamsPreview',
  components: {
    IntersectingCirclesSpinner,
    JSONPrint,
  },
  props: {
    categories: Array,
  },
  data() {
    return {
      schema: {
        fields: this.elements || [],
      },
      param: {},
    }
  },
  mounted() {
    const elements = [...(this.elements || [])]
    this.schema = { fields: elements }
    this.param = elements.reduce(
      (acc, input) => ({ ...acc, [input.model]: input.default || '' }),
      {}
    )
  },
  computed: {
    ...mapState({
      elements: (state) => state.formBuilder.elements,
    }),
    ...mapState('plugin_publishing', {
      app: (state) => state.app,
      appData: (state) => state.appData,
      appContent: (state) => state.appContent,
      developer: (state) => state.developer,
      settingsDrawerOpened: (state) => state.settingsDrawerOpened,
    }),
    categoryNames() {
      if (this.categories && this.appContent?.Categories) {
        return this.categories
          .filter((category) =>
            this.appContent.Categories.includes(category.value)
          )
          .map((category) => category.text)
      }
      return []
    },
  },
  watch: {
    elements: {
      handler() {
        this.schema = { fields: [...(this.elements || [])] }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('plugin_publishing', ['setSettingsDrawerOpened']),
    ...mapMutations('formBuilder', ['setEditingElement', 'setIsInPreview']),
    onBack() {
      this.setIsInPreview(false)
    },
    onClose() {
      this.setEditingElement(null)
      this.setSettingsDrawerOpened(false)
      this.setIsInPreview(false)
    },
  },
}
</script>
<style scoped lang="scss">
$style: PluginInstallParamsPreview;
.#{$style} {
  height: 100%;
  background: $N5;
  position: relative;
  display: flex;
  flex-direction: column;
  &.settings-drawer {
    .#{$style}__header {
      padding: 20px;
    }
    .#{$style}__body {
      padding: 28px;
    }
  }
  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $N6;
    padding-bottom: 10px;
    h6 {
      font-size: 16px;
      font-weight: normal;
    }
  }
  &__status {
    background: $B4;
    color: $B1;
    padding: 3px 8px;
    border-radius: 6px;
    margin-left: 4px;
    font-size: 12px;
  }

  &__body {
    position: relative;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    & > div.preview-form {
      flex-grow: 1;
      overflow-y: auto;
    }
  }
  &__footer {
    padding: 0px;
    > div {
      border-top: 1px solid $N6;
      background: $N5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px;
      width: 100%;
    }
  }
}
.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease-out;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-leave,
.slide-enter-to {
  transform: translateX(100%);
  opacity: 0;
}

.spinner {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(200, 200, 200, 0.3);
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 80;
}
.key-image {
  max-height: 64px;
  width: auto;
  border-radius: 4px;
}
.key-image.placeholder {
  background: green;
  width: 64px;
  height: 64px;
}
span.tag {
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 400;
  border: 1px solid $N3;
  border-radius: 6px;
  margin-right: 8px;
}
</style>

<style lang="scss">
.PluginInstallParamsPreview .form-group label {
  display: block;
  margin-bottom: 6px;
  color: $N2;
}

.app .PluginInstallParamsPreview .vue-form-generator .form-control {
  border: 1px solid $N3;
  background: transparent;
  border-radius: 8px;
}
</style>
