<template>
  <div class="PluginPublishFlowAppContentStep">
    <h6 class="mb-4">More Information</h6>
    <vue-form-generator :schema="schema" :model="model"></vue-form-generator>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
export default {
  name: 'PluginPublishFlowAppContentStep',
  props: {
    categories: Array,
  },
  data() {
    return {
      schema: {
        fields: [
          {
            type: 'input',
            inputType: 'text',
            name: 'Short_Name',
            label: 'Short Name',
            model: 'Short_Name',
            required: true,
            validator: ['string'],
          },
          {
            type: 'editor',
            name: 'Description',
            label: 'Description',
            model: 'Description',
          },
          {
            type: 'icon-uploader',
            inputType: 'text',
            name: 'Icon',
            label: 'Icon',
            mainLabel: 'Upload Icon',
            model: 'Icon',
          },
          {
            type: 'drag-drop-uploader',
            inputType: 'text',
            name: 'Screenshots',
            label: 'Screenshots',
            mainLabel: 'Upload screenshots',
            additionalLabel: 'or drag & drop your files here',
            model: 'Screenshots',
          },
          {
            type: 'input',
            inputType: 'text',
            name: 'Terms_URL',
            label: 'Terms URL',
            model: 'Terms_URL',
            placeholder: 'Enter Terms URL',
          },
          {
            type: 'input',
            inputType: 'text',
            name: 'Privacy_URL',
            label: 'Privacy URL',
            model: 'Privacy_URL',
            placeholder: 'Enter Privacy URL',
          },
          {
            type: 'select-reset',
            label: 'Categories',
            model: 'Categories',
            options: this.categories,
            placeholder: 'Select Category',
            multiple: true,
          },
          {
            type: 'select-reset',
            label: 'Listing',
            model: 'Listing',
            options: [
              { text: 'Listed', value: 'Listed' },
              { text: 'Unlisted', value: 'Unlisted' },
              { text: 'Private', value: 'Private' },
            ],
            placeholder: 'Select Listing',
          },
        ],
        styleClasses: 'rows',
        labelClasses: 'label',
        fieldClasses: 'boxed elem mb-15',
      },
      model: {},
    }
  },
  computed: {
    ...mapState('plugin_publishing', {
      appContent: (state) => state.appContent,
    }),
  },
  methods: {
    ...mapMutations('plugin_publishing', ['setAppContent']),
  },
  mounted() {
    this.model = this.appContent
  },
  watch: {
    appContent: {
      handler() {
        if (JSON.stringify(this.appContent) != JSON.stringify(this.model)) {
          this.model = this.appContent
        }
      },
      deep: true,
    },
    model: {
      handler(newValue) {
        this.setAppContent({ ...newValue })
      },
      deep: true,
    },
  },
}
</script>
<style scoped lang="scss">
$style: PluginPublishFlowAppContentStep;
.#{$style} {
  h6 {
    font-size: 14px;
    font-weight: normal;
  }
}
</style>
