<template>
  <div id="payment-form" class="ModalEnterBilling">
    <div class="ModalEnterBilling__plans">
      <h2 class="ModalEnterBilling__title">Your Subscription</h2>
      <div class="ModalEnterBilling__row">
        <div
          class="ModalEnterBilling__col"
          :class="{
            _active: selectedPlan == plan.name.toLowerCase(),
          }"
          v-for="plan in plans"
          :key="plan.name"
          @click="selectedPlan = plan.name.toLowerCase()"
        >
          <div class="ModalEnterBilling__plan">
            <div class="ModalEnterBilling__plan-head">
              <h4 class="ModalEnterBilling__plan-title">
                {{ `${plan.name} Plan` }}
              </h4>
              <span
                class="ModalEnterBilling__plan-label"
                v-if="currentPlan == plan.name.toLowerCase()"
                >current</span
              >
            </div>
            <div class="ModalEnterBilling__plan-body">
              <h6 class="ModalEnterBilling__plan-cost">
                {{ plan.cost }}
              </h6>
              <p class="ModalEnterBilling__plan-subtext">
                (plus VAT where applicable)
              </p>
              <p class="ModalEnterBilling__plan-text _bold">
                {{ plan.sitesCount }}
              </p>
              <p
                class="ModalEnterBilling__plan-text"
                v-for="feature in baseFeatures"
                :key="feature"
              >
                {{ feature }}
              </p>
              <p class="ModalEnterBilling__plan-text">
                <span> {{ plan.bandwidth }} bandwidth </span>/ month
                <br />
                ({{ plan.extra }}¢ per extra GB)
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="ModalEnterBilling__label"> Select your country: </span>
    <v-select dark dense filled v-model="selectedCountry" :items="countries" />

    <div class="mb-4">
      <div class="errors red--text mb-2" v-if="cancelErrors">
        {{ cancelErrors }}
      </div>
      <ModalCardData :valie-fields="valueFields" @input="updateValueFields" />
    </div>

    <button
      class="ModalEnterBilling__subtle"
      @click="showCoupon = true"
      v-if="!showCoupon"
    >
      I have a coupon
    </button>
    <div v-else>
      <span class="ModalEnterBilling__label">Enter your coupon:</span>
      <v-text-field
        class="ModalEnterBilling__input"
        dark
        v-model="subscriptionCode"
        dense
      >
      </v-text-field>
      <div class="errors red--text" v-if="couponErrors">
        {{ couponErrors }}
      </div>
    </div>
    <div class="ModalEnterBilling__plans-footer">
      <v-btn @click="cancel" color="n5" dark> Cancel </v-btn>
      <v-btn
        @click="save"
        color="y1"
        dark
        :disabled="selectedPlan === currentPlan || saving"
      >
        Change Plan
      </v-btn>
    </div>
  </div>
</template>

<script>
import { COUNTRIES } from '@/countries'
import ModalCardData from '@/components/Modals/ModalCardData'
export default {
  name: 'ModalEnterBilling',
  components: { ModalCardData },
  props: {
    baseFeatures: Array,
    plans: Array,
    currentPlan: String,
    userId: Number,
  },
  data() {
    return {
      selectedPlan: this.plans[0].name.toLowerCase(),
      subscriptionCode: '',
      cancelErrors: '',
      couponErrors: '',
      couponSaved: null,
      showCoupon: false,
      countries: COUNTRIES,
      selectedCountry: COUNTRIES[0],
      saving: false,
      valueFields: {
        cardNumberElement: null,
        cardExpiryElement: null,
        cardCvcElement: null,
        stripe: null,
      },
    }
  },
  methods: {
    async confirmStripeAction(secret) {
      this.valueFields.stripe.confirmCardPayment(secret).then((res) => {
        if (res.error || res.paymentIntent.status != 'succeeded') {
          this.cancelErrors = 'Payment not confirmed. Please check your card'
          this.saving = false
          return
        }
        const params = {
          session: {
            plan_id: this.selectedPlan,
          },
        }
        this.$store.dispatch('user/checkSubscription', params).then(() => {
          location.reload()
        })
      })
    },
    save() {
      if (this.saving) return
      this.saving = true
      this.cancelErrors = ''
      this.couponErrors = ''
      this.valueFields.stripe
        .createToken(this.valueFields.cardNumberElement)
        .then((res) => {
          if (res.error != undefined) {
            this.cancelErrors = res.error.message
            this.saving = false
          } else {
            let params = {
              id: this.$store.state.user.current.id,
              session: {
                stripe_card_token: res.token.id,
                plan_id: this.selectedPlan,
                coupon: this.subscriptionCode,
                country: this.selectedCountry,
              },
            }
            this.$store
              .dispatch('user/updateSubscription', params)
              .then((res) => {
                if (res.client_secret) {
                  this.confirmStripeAction(res.client_secret)
                  return
                }
                location.reload()
              })
              .catch((e) => {
                if (e.body.errors.base != undefined)
                  this.cancelErrors = e.body.errors.base.join('. ')
                this.saving = false
              })
          }
        })
        .catch((e) => {
          this.saving = false
          console.log(e)
        })
    },
    cancel() {
      this.selectedPlan = this.currentPlan
      this.$emit('close')
    },
    updateValueFields(e) {
      this.valueFields = e
    },
    enterCode() {
      const user_params = {
        id: this.userId,
        session: {
          coupon: this.subscriptionCode,
        },
      }
      this.$store
        .dispatch('user/updateSubscription', user_params)
        .then(() => {
          this.couponSaved = true
          this.subscriptionCode = ''
          const self = this
          setTimeout(() => {
            self.couponSaved = null
          }, 4000)
        })
        .catch(() => {
          this.couponSaved = false
        })
    },
  },
  computed: {
    subscriptionCodeEntered() {
      return this.subscriptionCode.length > 0
    },
    couponSavePressed() {
      return this.couponSaved != null
    },
  },
}
</script>

<style lang="scss">
$style: ModalEnterBilling;
.#{$style} {
  padding: 42px 24px 24px;
  @extend %modal-body-styles;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &__title {
    color: $white;
    font-weight: 500;
    font-size: $H20;
    margin-bottom: 20px;
  }
  &__row {
    display: flex;
    &._with-gap {
      gap: 16px;
    }
  }
  &__col {
    flex: 1 1 33%;
    max-width: 33%;
    border: 1px solid $N5;
    border-right-color: transparent;
    cursor: pointer;
    &:last-child {
      border-right-color: $N5;
      border-radius: 0 8px 8px 0;
    }
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &._active {
      background-color: $N4;
      border: 1px solid $Y1;
    }
  }
  &__plans {
    margin-bottom: 16px;
  }
  &__plan {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 12px;
  }
  &__plan-head {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &__plan-title {
    color: $white;
    font-weight: 600;
    font-size: $H18;
    padding: 8px 0;
  }
  &__plan-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__plan-cost {
    color: $white;
    font-weight: 600;
    font-size: $H16;
    margin-bottom: 16px;
  }
  &__plan-label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    background-color: $Y1;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -22px);
  }
  &__plan-subtext {
    color: $N2;
    font-size: $H11;
    margin-bottom: 16px;
  }
  &__plan-text {
    color: $white;
    font-weight: 400;
    font-size: $H14;
    margin-bottom: 10px;
    text-align: center;
    &._bold {
      font-weight: 600;
    }
    span {
      font-weight: 600;
    }
  }
  &__plans-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0;
    gap: 16px;
  }
  &__label {
    @extend %label-styles;
  }
  &__subtle {
    color: $Y1;
    font-size: 16px;
    border: none;
    outline: none;
    margin: auto;
    padding: 16px 0;
    &:hover {
      color: lighten($color: $Y1, $amount: 10%);
    }
  }
  &__input {
    &.v-text-field.v-input--dense > .v-input__control > .v-input__slot {
      @extend %input-styles;
      height: 40px;
    }
  }
}
.narrow-container {
  margin-bottom: 100px;
}
.subscription-container {
  background: #1f1e31;
  background-color: #1f1e31 !important;
  border: 1px solid #2e2c42;
  border-color: #2e2c42 !important;
  box-sizing: border-box;
  border-radius: 8px !important;
  tr {
    background: #1f1e31 !important;
    height: 28px;
  }
  table {
    margin-bottom: 20px;
  }
}
.plan-data {
  font-size: 16px;
}
.plan-cost {
  font-size: 24px;
  padding-top: 2px;
  width: 60px;
  height: 40px;
}
span.currentPlan {
  font-size: 12px;
  border-radius: 8px;
}
.currentPlan {
  background-color: orange !important;
}
</style>
