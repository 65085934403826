import { User as UserResource } from '@/resources/forge'
import { User } from '@/models/User'

const store = {
  namespaced: true,
  state: {
    current: null,
    token: localStorage['token'],
    email: localStorage['email'],
  },
  mutations: {
    /**
     * Set the current user.
     * @param {User} user - The user object to set as current.
     */
    set(state, user) {
      state.current = user
    },

    /**
     * Update the current user.
     * @param {User} user - The updated user object.
     */
    update(state, user) {
      state.current = new User(user)
    },

    /**
     * Set the authentication token for the current user.
     * @param {string} token - The authentication token to set.
     */
    setToken(state, token) {
      state.token = token
      localStorage['token'] = token
    },

    /**
     * Set the email for the current user.
     * @param {string} email - The email to set.
     */
    setEmail(state, email) {
      state.email = email
      localStorage['email'] = email
    },

    /**
     * Delete the email for the current user.
     */
    deleteEmail(state) {
      state.email = null
      delete localStorage['email']
    },

    /**
     * Delete the authentication token for the current user.
     */
    deleteToken(state) {
      state.token = null
      delete localStorage['token']
    },

    /**
     * Set the account token for the current user.
     * @param {string} accountToken - The account token to set.
     */
    setAccountToken(state, accountToken) {
      state.current.authentication_token = accountToken
    },
    /**
     * Update the Bitbucket token for the current user.
     * @param {string} bitbucketToken - The Bitbucket token to set.
     */
    updateBitbucketToken(state, bitbucketToken) {
      state.current.bitbucketToken = bitbucketToken
    },

    /**
     * Increment the projects count for the current user.
     */
    incProjectsCount(state) {
      if (state.current == null) return
      state.current.projects_count += 1
    },

    /**
     * Decrement the sites count for the current user.
     */
    descSitesCount(state) {
      if (state.current == null) return
      state.current.sites_count -= 1
    },

    /**
     * Decrement the projects count for the current user.
     */
    descProjectsCount(state) {
      if (state.current == null) return
      state.current.projects_count -= 1
    },

    /**
     * Increment the sites count for the current user.
     */
    incSitesCount(state) {
      if (state.current == null) return
      state.current.sites_count += 1
    },
  },
  actions: {
    /**
     * Authorize a user session.
     * @param {Object} session - The user session to authorize.
     */
    async authorize({ commit }, session) {
      const res = await UserResource.authorize(session)
      commit('setToken', res.body.access_token)
      commit('setEmail', res.body.email)
    },

    /**
     * Confirm a user invitation.
     * @param {Object} session - The user session to confirm invitation.
     * @returns {Promise} A promise resolving to the response body.
     */
    async confirmInvitation({ commit }, session) {
      return UserResource.acceptInvitation(
        {},
        { user: session.user, token: session.token }
      ).then(({ body }) => {
        const user = new User(body.user)
        commit('set', user)
        if (body.token != null && body.token != undefined) {
          commit('setToken', body.token)
          commit('setEmail', user.email)
        }
      })
    },

    async checkInvitation({ commit }, token) {
      return UserResource.checkOnCreated({}, { token: token }).then(
        ({ body }) => {
          if (body.user === null) return false
          const user = new User(body.user)
          commit('set', user)
          commit('setToken', body.token)
          commit('setEmail', user.email)
          return true
        }
      )
    },

    /**
     * Authenticate a user using Google.
     * @param {string} authCode - The authentication code.
     * @returns {Promise} A promise resolving to the response.
     */
    async googleAuth({ commit }, authCode) {
      const res = await UserResource.googleAuth({ google_code: authCode })
      if (!res.body.registration) {
        commit('setToken', res.body.token)
        commit('setEmail', res.body.email)
      }
      return res
    },

    /**
     * Authenticate a user using GitHub.
     * @param {Object} params - Parameters for GitHub authentication.
     * @returns {Promise} A promise resolving to the response.
     */
    async githubAuth({ commit }, params) {
      const res = await UserResource.githubAuth({}, { code: params.code })
      if (!res.body.registration) {
        commit('setToken', res.body.token)
        commit('setEmail', res.body.email)
      }
      return res
    },

    /**
     * Authenticate a user using Bitbucket.
     * @param {Object} params - Parameters for Bitbucket authentication.
     * @returns {Promise} A promise resolving to the response.
     */
    async bitbucketAuth({ commit }, params) {
      const res = await UserResource.bitbucketAuth(
        {},
        { code: params.code, redirect_uri: params.redirect_uri }
      )
      if (!res.body.registration) {
        commit('setToken', res.body.token)
        commit('setEmail', res.body.email)
      }
      return res
    },
    /**
     * Load current user data.
     */
    async load({ commit }) {
      const response = await UserResource.current()
      const user = new User(response.body.user)
      commit('set', user)
    },

    /**
     * Update user data.
     * @param {Object} params - Parameters for user update.
     * @returns {Promise} A promise resolving to the updated user data.
     */
    update({ commit }, params) {
      return UserResource.update({ id: params.id }, { user: params.user }).then(
        ({ body }) => {
          const user = new User(body.user)
          commit('set', user)
          if (body.token != null && body.token != undefined)
            commit('setToken', body.token)
          return body
        }
      )
    },
    /**
     * Update user data.
     * @param {Object} params - Parameters for user update.
     * @returns {Promise} A promise resolving to the updated user data.
     */
    updateSource({ commit }, params) {
      return UserResource.updateSource(
        { id: params.id },
        { user: params.user }
      ).then(({ body }) => {
        const user = new User(body.user)
        commit('set', user)
        if (body.token != null && body.token != undefined)
          commit('setToken', body.token)
        return body
      })
    },

    /**
     * Update user avatar.
     * @param {Object} params - Parameters for updating user avatar.
     * @returns {Promise} A promise resolving to the response.
     */
    updateAvatar({ commit }, params) {
      return UserResource.updateAvatar({}, params).then((res) => {
        commit('set', new User(res.body.user))
        return res
      })
    },

    /**
     * Update user subscription.
     * @param {Object} params - Parameters for updating user subscription.
     * @returns {Promise} A promise resolving to the updated user subscription.
     */
    updateSubscription({ commit }, params) {
      return UserResource.updateSubscription(
        { id: params.id },
        { session: params.session }
      ).then(({ body }) => {
        if (body.session) {
          const user = new User(body.session)
          commit('set', user)
        }

        if (body.token !== null && body.token !== undefined)
          commit('setToken', body.token)

        return body
      })
    },
    async checkSubscription({ commit }, params) {
      const res = await UserResource.checkSubscription({}, params)
        .then((res) => {
          const user = res.body.session
          commit('set', user)
          return res.body
        })
        .catch((e) => {
          console.log(e)
        })
    },
    async updateCardDetails({ commit }, params) {
      const res = await UserResource.updateCardDetails({}, params).then(
        (res) => {
          if (res.body.session) {
            commit('set', new User(res.body.session))
          }
          return res.body
        }
      )
    },
    /**
     * Regenerate user token.
     * @param {Object} params - Parameters for token regeneration.
     * @returns {Promise} A promise resolving to the regenerated token.
     */
    regenerateToken({ commit }, params) {
      return UserResource.regenerateToken({ user_id: params.id }).then(
        ({ body }) => {
          commit('setAccountToken', body.deploy_token)
          return body
        }
      )
    },

    /**
     * Log out the user.
     */
    logOut({ commit }) {
      try {
        UserResource.logOut()
      } finally {
        commit('deleteToken')
        commit('deleteEmail')
        commit('set', null)
      }
    },
  },
}

export default store
