import { Version } from '@/models/Version'
import { Version as VersionResource } from '@/resources/forge'
const store = {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    /**
     * Set the list of versions.
     * @param {Array} list - List of versions.
     */
    setList(state, list) {
      state.list = list
    },

    /**
     * Delete a version from the list by ID.
     * @param {string} id - ID of the version to delete.
     */
    delete(state, id) {
      state.list = state.list.filter((v) => id != v.id)
    },

    /**
     * Create a new version and add it to the list.
     * @param {Object} version - Version to create.
     */
    create(state, version) {
      const versionInList = state.list.find((v) => v.id == version.id)
      if (versionInList) return

      state.list = [...state.list, version]
    },

    /**
     * Update a version in the list with new parameters.
     * @param {Object} params - Parameters for updating the version.
     */
    update(state, params) {
      const version = state.list.find((v) => v.id == params.id)
      if (!version) return

      Object.assign(version, params)
    },
    updateDescription(state, params) {
      const version = state.list.find((v) => v.id == params.id)
      if (!version) return

      version.description = params.version.description
    },
    /**
     * Update the unread comments count for a version.
     * @param {Object} params - Parameters for updating unread comments.
     */
    updateUnreadComments(state, params) {
      const version = state.list.find((v) => v.id == params.version_id)
      if (!version) return
      version.unreadComments = params.unread_comments
    },

    /**
     * Change temporary version data with actual version data.
     * @param {Object} actualVersion - Actual version data.
     */
    changeTmpVersion(state, actualVersion) {
      const version = state.list.find((v) => v.id == 0)
      if (!version) return
      Object.assign(version, actualVersion)
    },
  },
  actions: {
    /**
     * Load versions for a specific site.
     * @param {string} site_id - ID of the site.
     * @returns {Promise} - Promise object representing the result of the action.
     */
    async load({ commit }, site_id) {
      const res = await VersionResource.get({ site_id })
      commit(
        'setList',
        res.body.versions.map((v) => new Version(v))
      )
      return res
    },

    /**
     * Load versions for a specific parse custom code.
     * @param {string} custom_code_id - ID of the parse custom code.
     * @returns {Promise} - Promise object representing the result of the action.
     */
    async loadParse({ commit }, custom_code_id) {
      commit('setList', [])
      const res = await VersionResource.get({
        parse_custom_code_id: custom_code_id,
      })
      commit(
        'setList',
        res.body.versions.map((v) => new Version(v))
      )
      return res
    },

    /**
     * Set the current version.
     * @param {Object} payload - Payload containing data to update.
     */
    setCurrent({ dispatch }, payload) {
      dispatch('sites/updateDataInList', payload, { root: true })
    },

    /**
     * Add a parse version to the list.
     * @param {Object} params - Parameters for the version.
     */
    addParseVersion({ commit }, params) {
      commit('create', new Version(params))
    },

    /**
     * Clear version data from socket.
     * @param {string} id - ID of the version to clear from socket.
     */
    clearFromSocket({ dispatch }, id) {
      dispatch('callDeleteVersion', { id: id, clearFromSocket: true })
    },

    /**
     * Delete a version.
     * @param {string} id - ID of the version to delete.
     */
    delete({ dispatch }, id) {
      dispatch('callDeleteVersion', { id: id, clearFromSocket: false })
    },

    /**
     * Call API to delete a version.
     * @param {Object} params - Parameters for deleting the version.
     */
    callDeleteVersion({ dispatch, rootState, commit }, params) {
      const activeSite = rootState.sites.active
      if (!activeSite) return
      const versionIds = activeSite.versionIds.filter((_id) => _id != params.id)
      dispatch(
        'sites/updateDataInList',
        { id: activeSite.id, versionIds },
        { root: true }
      )
      commit('delete', params.id)
      if (!params.clearFromSocket)
        VersionResource.deleteVersion({ id: params.id }, {})
    },

    /**
     * Add a message to a version.
     * @param {Object} message - Message to add.
     */
    addMessageToVersion({ commit, getters }, message) {
      let version = getters['findById'](message.version_id)
      if (!version) return
      let messages = [...version.messages, message]
      const payload = { id: message.version_id, messages: messages }
      commit('update', payload)
    },

    /**
     * Create a temporary version.
     * @param {Object} params - Parameters for creating the temporary version.
     */
    createTmpVersion({ commit, dispatch, getters, rootState }, params) {
      const activeSite = rootState.sites.active
      const tmpVersion = new Version(params)
      tmpVersion.id = 0
      let scopedIds = activeSite.versionIds
        .map((id) => getters['findById'](id))
        .map((v) => v.scopedId)
      let maxScopedId = Math.max(...scopedIds)
      tmpVersion.scopedId = maxScopedId + 1
      let updatedVersions = activeSite.versionIds.filter((id) => id != 0)
      commit('delete', 0)
      commit('create', tmpVersion)
      updatedVersions = [...updatedVersions, 0]
      dispatch(
        'sites/updateDataInList',
        {
          id: activeSite.id,
          currentVersionId: 0,
          versionIds: updatedVersions,
        },
        { root: true }
      )
    },
    versionAdded({ commit, dispatch, getters, rootState }, version) {
      const activeSite = rootState.sites.active
      if (!activeSite || version.site_id != activeSite.id) return

      let updatedVersions = activeSite.versionIds.filter(
        (id) => id != 0 && id != version.id
      )
      updatedVersions = [...updatedVersions, version.id]
      commit('create', new Version(version))
      dispatch(
        'sites/updateDataInList',
        {
          id: activeSite.id,
          currentVersionId: version.id,
          versionIds: updatedVersions,
        },
        { root: true }
      )
      commit('delete', 0)
    },

    /**
     * Create a version.
     * @param {Object} params - Parameters for creating the version.
     */
    create({ commit, dispatch, rootState }, params) {
      const activeSite = rootState.sites.active
      VersionResource.create({ version: params })
        .then((res) => {
          commit('create', new Version(res.body.version))
          let updatedVersions = activeSite.versionIds.filter(
            (id) => id != 0 && id != res.body.version.id
          )
          updatedVersions = [...updatedVersions, res.body.version.id]
          dispatch(
            'sites/updateDataInList',
            {
              id: activeSite.id,
              currentVersionId: res.body.version.id,
              versionIds: updatedVersions,
            },
            { root: true }
          )
          commit('delete', 0)
        })
        .catch((e) => {
          console.log(e)
        })
    },

    /**
     * Update a version.
     * @param {Object} version - Updated version object.
     */
    update({ commit, getters }, version) {
      let oldVersion = getters['findById'](version.id)
      if (!oldVersion) return
      let newVersion = new Version(version)
      newVersion.messages = oldVersion.messages
      commit('update', newVersion)
    },

    /**
     * Update the description of a version.
     * @param {Object} params - Parameters for updating the version description.
     */
    updateDesc({ commit, getters }, params) {
      commit('updateDescription', params)
      VersionResource.update(
        { id: params.id },
        { version: params.version }
      ).catch((e) => {
        console.log(e)
      })
    },
  },
  getters: {
    /**
     * Find a version by its ID.
     * @param {String} id -  Target version id
     * @returns {Object|null} The found version or null if not found.
     */
    findById: (state) => (id) => {
      return state.list.find((v) => v.id == id)
    },

    /**
     * Get the active version.
     * @returns {Object|null} - The active version or null if not found.
     */
    activeVersion: (state, getters, rootState) => {
      return getters['findById'](rootState.sites.active.currentVersionId)
    },

    /**
     * Get the active parse version.
     * @returns {Object|null} - The active parse version or null if not found.
     */
    activeParseVersion: (state, getters, rootState) => {
      if (rootState.parse_cloud.current.current_version_id == null) return null
      return getters['findById'](
        rootState.parse_cloud.current.current_version_id
      )
    },
  },
}

export default store
