<template>
  <div class="mx-6 mt-12" v-if="developerDetail">
    <div class="mb-6">
      <router-link class="breadcrumb-link" to="/publisher/developers">
        Developers
      </router-link>
      <span class="breadcrumb-separator">/</span>
      <span class="breadcrumb-link" v-if="developerDetail">{{
        developerDetail.name
      }}</span>
    </div>
    <div
      class="d-flex align-start w-full section-block mb-6 justify-space-between"
    >
      <div class="d-flex align-center">
        <div class="avatar"></div>
        <div>
          <div class="d-flex align-center">
            <h3>{{ developerDetail.name }}</h3>
            <span class="badge verified" v-if="!!developerDetail.verified">
              <the-icon icon="verified" />
            </span>
            <span class="badge warn-verified" v-else>
              <the-icon icon="warn-verified" />
            </span>
          </div>
          <div class="d-flex align-center email-label">
            <the-icon icon="email" />
            <span>{{ developerDetail.email }}</span>
          </div>
        </div>
      </div>
      <v-menu left offset-x v-if="isPublisherAdmin">
        <template v-slot:activator="{ on, attrs }">
          <span class="action-button" v-bind="attrs" v-on="on">
            <the-icon icon="action-vertical" />
          </span>
        </template>

        <v-list dense>
          <v-list-item class="action-item delete" @click="onRemoveDeveloper">
            <the-icon icon="trash-filled" /> Delete
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <!-- End of Developer Head section -->

    <v-row>
      <v-col cols="12" sm="6">
        <div class="section-block">
          <h6 class="DeveloperDetail__section-heading">
            Developer Information
          </h6>
          <div class="d-flex mb-4">
            <span class="DeveloperDetail__label">Name</span>
            <span class="text-sm font-bold block tracking-wide text-gray-600">
              {{ developerDetail.company }}
            </span>
          </div>
          <div class="d-flex mb-4">
            <span class="DeveloperDetail__label">Contact</span>
            <span class="text-sm font-bold block tracking-wide text-gray-600">
              {{ developerDetail.name }}
            </span>
          </div>
          <div class="d-flex mb-4">
            <span class="DeveloperDetail__label">Contact Email</span>
            <span class="text-sm font-bold block tracking-wide text-gray-600">
              {{ developerDetail.email }}
            </span>
          </div>
          <div class="d-flex mb-4">
            <span class="DeveloperDetail__label">Country</span>
            <span class="text-sm font-bold block tracking-wide text-gray-600">
              {{ countryLabel }}
            </span>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="6">
        <div class="section-block d-none">
          <h6 class="DeveloperDetail__section-heading">
            Developer Information
          </h6>
          <div></div>
        </div>
      </v-col>
    </v-row>

    <div
      class="my-6"
      v-if="
        developerDetail &&
        developerDetail.appsList &&
        developerDetail.appsList.length > 0
      "
    >
      <div class="d-flex justify-space-between">
        <h6 class="DeveloperDetail__section-heading">
          Plugins from {{ developerDetail.name }}
        </h6>
        <span>{{ developerDetail.appsList.length }} plugins</span>
      </div>
      <v-row>
        <v-col
          cols="12"
          sm="6"
          v-for="app in developerDetail.appsList"
          :key="app.slug"
        >
          <div
            class="DeveloperDetail__app-card-image"
            :style="{ backgroundImage: getAppImage(app) }"
          ></div>
          <div class="DeveloperDetail__app-card-detail">
            <div class="d-flex justify-space-between">
              <div
                class="DeveloperDetail__app-name"
                @click="onNavigateToPlugin(app)"
              >
                {{ app.name }}
              </div>
              <v-menu left offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <span class="action-button" v-bind="attrs" v-on="on">
                    <the-icon icon="action-vertical" />
                  </span>
                </template>

                <v-list dense>
                  <v-list-item
                    class="action-item view"
                    @click="onNavigateToPlugin(app)"
                    ><the-icon icon="hide" /> View</v-list-item
                  >
                  <v-divider />
                  <v-list-item class="action-item delete"
                    ><the-icon icon="trash-filled" /> Delete</v-list-item
                  >
                </v-list>
              </v-menu>
            </div>
            <div
              class="DeveloperDetail__app-description my-2"
              v-html="app.developerContent?.description || ''"
            ></div>
            <div class="d-flex justify-space-between" v-if="app.developerData">
              <div>
                <v-rating
                  v-if="app.developerData.rating"
                  v-model="app.developerData.rating"
                  readonly
                  background-color="red lighten-3"
                  color="red"
                  small
                  dense
                ></v-rating>
              </div>
              <div
                class="DeveloperDetail__app-installs-count"
                v-if="app.developerData.installsCount > 0"
              >
                <the-icon icon="download" />
                {{ app.developerData.installsCount }} installs
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { MODAL_ALERT } from '@/components/Modals'
import { getCountryLabel } from '@/utils/common'

const Parse = require('parse')
export default {
  name: 'DeveloperDetail',
  data() {
    return {
      developerDetail: null,
    }
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current,
    }),
    isPublisherAdmin() {
      return this.currentPublisherInfo.role === 'admin'
    },
    countryLabel() {
      return getCountryLabel(this.developerDetail.country)
    },
  },
  mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id)
    Parse.serverURL = this.currentPublisherInfo.parse_server_url
    this.getDeveloperDetail()
  },
  methods: {
    // get app detail, called on page load
    // - get app detail common fields
    //  -- images
    //  -- color(Status color, eval safe color)
    //  -- Check if the current loggedin user is the owner
    async getDeveloperDetail() {
      const result = await Parse.Cloud.run('getDeveloperDetail', {
        developerId: this.$route.params.developerId,
        parseServerSiteId: this.currentPublisherInfo.parse_server_site_id,
      })

      if (result.status === 'success' && result.developer) {
        this.developerDetail = result.developer
      }
    },
    onRemoveDeveloper() {
      const developerId = this.$route.params.developerId
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Remove Developer',
          text: `You are going to remove ${this.developerDetail.name}. Are you sure?`,
          actionBtnLabel: 'Remove',
          showCancelBtn: true,
          action: async () => {
            const res = await Parse.Cloud.run('removeDeveloper', {
              developerId,
              parseServerSiteId: this.currentPublisherInfo.parse_server_site_id,
            })
            if (res.status === 'success')
              this.$router.push({ name: 'developer_list' })
          },
        },
      })
    },
    getAppImage(app) {
      let src = ''
      if (app.developerContent) {
        if (app.developerContent.keyImage) src = app.developerContent.keyImage
        else if (
          app.developerContent.screenshots &&
          app.developerContent.screenshots[0]
        )
          src = app.developerContent.screenshots[0]
      }

      return src ? `url('${src}')` : `url("/no-image.jpg")`
    },
    onNavigateToPlugin(plugin_detail) {
      this.$router.push({
        name: 'plugin_detail',
        params: { pluginId: plugin_detail.slug },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.section-block {
  border: 1px solid $N5;
  background: $N4;
  border-radius: 4px;
  padding: 24px;
}
.avatar {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background: $Y1;
  margin-right: 24px;
}
h5 {
  font-size: 20px;
  line-height: 24px;
  margin-right: 8px;
}
.badge {
  padding: 8px;
  margin-left: 12px;
  position: relative;
  &:before {
    position: absolute;
    border-radius: 28px;
    opacity: 0.1;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: block;
    content: '';
  }
  &.verified {
    &:before {
      background: $G1;
    }
    svg {
      fill: $G1;
      color: $G1;
    }
  }

  &.warn-verified {
    &:before {
      background: $Y1;
    }
    svg {
      fill: $Y1;
      color: $Y1;
    }
  }
}

.email-label {
  color: $N2;
  svg {
    fill: $N2;
    color: $N2;
  }
}

.action-button {
  svg {
    color: $N28;
    fill: $N28;
  }
}
.action-item {
  display: flex;
  align-items: center;
  width: 120px;
  cursor: pointer;
  &.view {
    svg {
      color: white;
      fill: white;
      margin-right: 5px;
    }
  }
  &.delete {
    color: $R1 !important;
    svg {
      color: $R1;
      fill: $R1;
      margin-right: 5px;
    }
  }
}

$style: DeveloperDetail;
.#{$style} {
  &__label {
    width: 150px;
    flex-shrink: 0;
    color: $N28;
    font-size: 14px;
  }
  &__section-heading {
    color: white;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
    font-weight: normal;
  }
  &__app-name {
    color: $B1;
    cursor: pointer;
  }
  &__app-card-image {
    height: 200px;
    width: 100%;
    background-size: cover;
    background-position: center center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: 1px solid #2e2c42;
  }
  &__app-card-detail {
    padding: 20px;
    border: 1px solid $N5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 0;
  }
  &__app-description {
    color: $N2;
    opacity: 0.7;
    overflow: hidden;
    height: 120px;
    text-overflow: ellipsis;
  }
  &__app-installs-count {
    display: inline-flex;
    svg {
      color: $N2;
      fill: $N2;
      margin-right: 5px;
    }
  }
}
</style>
