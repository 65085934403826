<template>
  <div class="SiteUsageBreakdownElement">
    <div class="SiteUsageBreakdownElement__card">
      <div class="SiteUsageBreakdownElement__icon-container">
        <v-icon class="SiteUsageBreakdownElement__icon" color="primary"
          >pie_chart</v-icon
        >
      </div>
      <div class="SiteUsageBreakdownElement__title">{{ value }}</div>
      <div class="SiteUsageBreakdownElement__subtitle">{{ period }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SiteUsageBreakdownElement',
  props: {
    value: String,
    period: String,
  },
}
</script>
<style lang="scss">
$style: SiteUsageBreakdownElement;
.#{$style} {
  &__icon-container {
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
  }
  &__icon {
    border: 1px solid $N5;
    border-radius: 50%;
  }
  &__card {
    @extend %modal-body-styles;
    display: block;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: relative;
    padding: 24px;
  }
  &__title {
    font-size: $H16;
    color: $white;
    font-weight: 600;
    margin-bottom: 8px;
  }
  &__subtitle {
    font-size: $H12;
    color: $white;
    font-weight: 300;
  }
}
</style>
