<template>
  <div class="FigmaIntegration">
    <div
      v-if="status === 'loading'"
      class="spinner-border animate-spin inline-block w-12 h-12 border-4 rounded-full"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
    <div v-else-if="status === 'success'">
      <v-row>
        <v-col cols="6">
          <label class="FigmaIntegration__label mt-2">Team ID</label>
          <v-text-field
            v-model="teamId"
            class="FigmaIntegration__input"
            hide-details="auto"
            filled
            dark
            dense
          />
        </v-col>
      </v-row>

      <FigmaPicker
        :teamId="teamId"
        :token="token"
        v-if="teamId && token"
        :onSelect="onFigmaSelect"
      />
    </div>
    <div v-else-if="status === 'failure'" class="mb-40">
      <a href="https://www.figma.com/" class="text-center">
        <img src="/logo.svg" alt="Figma" width="150" class="mx-auto" />
      </a>
      <div
        class="bg-white px-12 py-8 mt-6 rounded-3xl text-xl text-center w-400"
      >
        Something went wrong 😳
      </div>
    </div>
  </div>
</template>

<script>
import Parse from 'parse'
import { initParse } from '@/utils/parse'
import { safeJSONParse, getExtensionState } from '@/utils/common'
import FigmaPicker from './Figma/FigmaPicker'

export default {
  name: 'FigmaIntegration',
  components: {
    FigmaPicker,
  },
  data() {
    return {
      extensionId: '',
      token: '',
      status: '',
      pluginSlug: '',
      siteId: '',
      teamId: '', // Figma TEAM ID
    }
  },
  methods: {
    async init() {
      const query = window.location.href.split('?')[1]
      const urlParams = new URLSearchParams(query)
      const code = urlParams.get('code')
      const storageValue = localStorage.getItem('figmaToken')
      if (storageValue) {
        try {
          const tokenResponse = JSON.parse(storageValue)
          this.token = tokenResponse.accessToken
          this.status = 'success'
        } catch (error) {
          this.status = 'failure'
          console.error('Invalid figmaToken storage value', error)
        }
      } else {
        if (code) {
          try {
            const tokenResponse = await Parse.Cloud.run('token', { code })
            if (tokenResponse.success && tokenResponse.accessToken) {
              this.token = tokenResponse.accessToken
              localStorage.setItem(
                'figmaToken',
                JSON.stringify({
                  ...tokenResponse,
                  siteId: this.siteId,
                  slug: this.pluginSlug,
                })
              )
              this.status = 'success'
            } else {
              this.status = 'failure'
            }
          } catch (error) {
            this.status = 'failure'
            console.error('error in token cloud code', error)
          }
        }
      }
    },
    loadProjects() {
      if (!this.teamId || !this.token) return
    },
    onFigmaSelect(figma) {
      let extensionState = getExtensionState(this.extensionId)
      const url = `https://www.figma.com/design/${figma.key}/${figma.name}`
      extensionState = { ...extensionState, picker: { figma: { url } } }
      localStorage.setItem(this.extensionId, JSON.stringify(extensionState))

      this.$router.push({
        name: 'site_plugin',
        params: {
          id: this.siteId,
          slug: this.pluginSlug,
        },
        query: {
          url,
        },
      })
    },
  },
  async mounted() {
    const integrationState = safeJSONParse(
      localStorage.getItem('integrationState')
    )
    if (integrationState.kind !== 'figma') return
    const { parseAppId, parseServerURL, pluginSlug, siteId, extensionId } =
      integrationState
    if (parseAppId && parseServerURL && pluginSlug && siteId && extensionId) {
      this.pluginSlug = pluginSlug
      this.siteId = siteId
      this.extensionId = extensionId
      initParse(parseServerURL, parseAppId)
      this.status = 'success'
      await this.init()
    }
  },
}
</script>

<style scoped lang="scss">
$style: FigmaIntegration;
.#{$style} {
  background-color: $N4;
  border: 1px solid $N5;
  margin: 30px;
  padding: 20px;
  min-height: calc(100vh - 150px);
  &__input {
    background: transparent !important;
    @extend %input-styles;
    margin-bottom: 16px;
  }
}
</style>
