var render = function render(){var _vm=this,_c=_vm._self._c;return (!_vm.loading)?_c('div',{staticClass:"narrow-container mx-2"},[_c('h4',{staticClass:"SiteSettings__title mt-4 mx-4"},[_vm._v("Members")]),(_vm.changeOwnerEnabled)?_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.changeOwnerFailed),callback:function ($$v) {_vm.changeOwnerFailed=$$v},expression:"changeOwnerFailed"}},[_c('v-card',{staticClass:"changeOwnerFailedCard"},[_c('v-card-title',[_vm._v(" Change site owner ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.changeOwnerMessage)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"y1"},on:{"click":function($event){_vm.changeOwnerFailed = false}}},[_vm._v(" Close ")])],1)],1)],1):_vm._e(),_c('div',{staticClass:"d-flex flex-column flex-sm-row align-sm-center justify-space-between gap-3 pa-0 mt-4 mx-4"},[_c('div',{staticClass:"d-flex flex-column flex-sm-row gap-3"},[_c('v-text-field',{staticClass:"flex-grow-1 pa-0 inheritInput",attrs:{"prepend-inner-icon":"mdi-magnify","filled":"","dark":"","dense":"","hide-details":"","placeholder":"Search by email or username"},on:{"input":_vm.filterActions},model:{value:(_vm.emailFilter),callback:function ($$v) {_vm.emailFilter=$$v},expression:"emailFilter"}}),_c('v-select',{staticClass:"AccessTable__select_filter",staticStyle:{"display":"inline-block","border-color":"#1f1e31"},attrs:{"value":_vm.filterRoleInput,"items":_vm.rolesForFilter,"item-text":"name","item-value":"value","menu-props":{
          contentClass: 'AccessTable__select_filter_menu',
          bottom: true,
          offsetY: true,
        },"color":"#5e636d","return-object":"","outlined":"","hide-details":"","dense":""},on:{"change":_vm.setNewRoleInput}}),_c('v-select',{staticClass:"AccessTable__select_filter",staticStyle:{"display":"inline-block","border-color":"#1f1e31"},attrs:{"value":_vm.filterStatusInput,"items":_vm.statusFilter,"item-text":"name","item-value":"value","attach":"","auto":"","menu-props":{
          contentClass: 'AccessTable__select_filter_menu',
          bottom: true,
          offsetY: true,
        },"color":"#5e636d","return-object":"","outlined":"","hide-details":"","dense":""},on:{"change":_vm.setNewStatusInput}})],1),_c('v-btn',{staticClass:"AccessTable__new_users_btn",attrs:{"size":"large"},on:{"click":_vm.addNewUsers}},[_c('v-icon',[_vm._v(" mdi-plus ")]),_vm._v(" Invite members ")],1)],1),_c('div',{staticClass:"members_table mx-4 mt-6"},[_c('v-data-table',{staticClass:"organisations_access_members_table",attrs:{"headers":_vm.headers,"items":_vm.accessList,"item-key":"user_id","sort_by":['name']},scopedSlots:_vm._u([{key:"item.role",fn:function({ item }){return [(!_vm.isOwner() || !_vm.canBeRemoved(item.user_id))?_c('span',{style:({ 'text-transform': 'capitalize' })},[_vm._v(" "+_vm._s(_vm.userRole(item))+" ")]):(_vm.memberAccessTypeProp != 3)?_c('v-select',{ref:_vm.memberAccessType + 'owner_change_' + item.user_id,staticStyle:{"max-width":"100px","display":"inline-block"},attrs:{"value":_vm.userRole(),"menu-props":{
            contentClass: 'AccessTable__table_role_select',
            bottom: true,
            offsetY: true,
          },"items":_vm.userRoles(),"disabled":!_vm.changeOwnerEnabled || item.invited,"return-object":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.makeAnOwner(
              item,
              _vm.memberAccessType + 'owner_change_' + item.user_id
            )}}}):_c('v-select',{ref:'org_role_change_' + item.user_id,staticStyle:{"max-width":"100px","display":"inline-block"},attrs:{"items":_vm.roleOptions,"item-text":"label","item-value":"value","menu-props":{
            contentClass: 'AccessTable__table_role_select',
            bottom: true,
            offsetY: true,
          },"readonly":_vm.userIsOwner(item.user_id) || !_vm.canManage(),"return-object":"","hide-details":"","dense":""},on:{"change":function($event){return _vm.changeRole(item, 'org_role_change_' + item.user_id, item.role)}},model:{value:(item.role),callback:function ($$v) {_vm.$set(item, "role", $$v)},expression:"item.role"}})]}},{key:"item.name",fn:function({ item }){return [_c('div',[_c('div',{staticClass:"d-inline-block"},[(!_vm.avatarEmpty(item))?_c('img',{staticClass:"the-app-bar__image-wrapper",attrs:{"src":item.avatar,"alt":""}}):_c('avatar',{attrs:{"rounded":true,"initials":_vm.initials(item),"username":"name","size":26}})],1),_c('div',{staticClass:"member_table_name"},[_vm._v(" "+_vm._s(item.name)+" "+_vm._s(_vm.currentUser(item.user_id) ? ' (You)' : '')+" ")])])]}},{key:"item.invited",fn:function({ item }){return [_c('span',{staticClass:"user_status",class:item.invited ? 'invited' : 'active_user'},[_vm._v(" "+_vm._s(item.invited ? 'Pending invite' : 'Active')+" ")])]}},{key:"item.last_active",fn:function({ item }){return [(item.last_active != null)?_c('span',[_vm._v(" "+_vm._s(item.last_active)+" ")]):_c('hr',{staticStyle:{"width":"15px"}})]}},{key:"item.actions",fn:function({ item }){return [(_vm.canBeRemoved(item.user_id) && _vm.isOwner())?_c('span',{staticClass:"remove",on:{"click":function($event){return _vm.removeObject(item)}}},[_c('v-icon',{staticClass:"ml-0",attrs:{"dark":"","right":"","color":"red","medium":""}},[_vm._v(" mdi-delete-outline ")])],1):_vm._e()]}}],null,false,950333982)})],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }