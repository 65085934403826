import Parse from 'parse'

export function initParse(serverURL, appId) {
  Parse.serverURL = serverURL
  Parse.initialize(appId)
}

export const getReadyForSalePlugins = async (parseServerSiteId) => {
  try {
    const res = await Parse.Cloud.run('getPluginsList', {
      parseServerSiteId,
      filter: {
        status: 'Ready for Sale',
      },
    })
    if (res.status === 'success') {
      const apps = res.apps || []
      const siteApps = apps.filter((app) => app.kind.includes('Site'))
      const userApps = apps.filter((app) => app.kind.includes('User'))
      return { siteApps, userApps }
    }
  } catch (error) {
    console.error('getReadyForSalePlugins error', error)
  }
  return { siteApps: [], userApps: [] }
}

export const getMyPluginsInDevelopment = async (
  parseServerSiteId,
  myDeveloperId
) => {
  try {
    const res = await Parse.Cloud.run('getPluginsList', {
      filter: {
        developer: [myDeveloperId],
        status: 'Pending Developer Release',
      },
      parseServerSiteId,
    })
    if (res.status === 'success') {
      const apps = res.apps || []
      const siteApps = apps.filter((app) => app.kind.includes('Site'))
      const userApps = apps.filter((app) => app.kind.includes('User'))
      return [siteApps || [], userApps || []]
    }
  } catch (error) {
    console.error('getMyPluginsInDevelopment error', error)
  }
  return [[], []]
}

export const getUserInstalledApps = async (parseServerSiteId, userId) => {
  try {
    const res = await Parse.Cloud.run('getUserInstalledApps', {
      parseServerSiteId,
      userId,
    })
    if (res.status === 'success') {
      return res.apps || []
    }
  } catch (error) {
    console.error('getUserInstalledApps error', error)
  }
  return []
}

export const getSiteInstalledApps = async (parseServerSiteId, siteId) => {
  try {
    const res = await Parse.Cloud.run('getSiteInstalledApps', {
      parseServerSiteId,
      siteId,
    })
    if (res.status === 'success') {
      return res.apps || []
    }
  } catch (error) {
    console.error('getSiteInstalledApps error', error)
  }
  return []
}

export const installApp = async ({
  appId,
  userId,
  siteId,
  parseServerSiteId,
}) => {
  try {
    const res = await Parse.Cloud.run('installApp', {
      appId,
      userId,
      siteId,
      parseServerSiteId,
    })
    if (res.status === 'success') {
      return res.installedId
    }
  } catch (error) {
    console.error('parse / installApp error', error)
  }
  return null
}

export const uninstallApp = async ({
  instanceId,
  userId,
  siteId,
  parseServerSiteId,
}) => {
  try {
    const res = await Parse.Cloud.run('uninstallApp', {
      instanceId,
      userId,
      siteId,
      parseServerSiteId,
    })
    if (res.status === 'success') {
      return res.removedId
    }
  } catch (error) {
    console.error('parse / uninstallApp error', error)
  }
  return null
}

export const updateAppInstance = async (
  parseServerSiteId,
  instanceId,
  param
) => {
  try {
    const res = await Parse.Cloud.run('updateAppInstance', {
      parseServerSiteId,
      instanceId,
      param,
    })
    if (res.status === 'success') {
      return res.instanceDetail
    }
  } catch (error) {
    console.error('parse / updateAppInstance error', error)
  }
  return null
}

// params: { siteId, userId, appSlug }
export const getAppInstanceFromAppSlug = async (params) => {
  try {
    const res = await Parse.Cloud.run('getAppInstanceFromAppSlug', params)
    if (res.status === 'success') {
      return res.appInstance
    }
  } catch (error) {
    console.error('parse / uninstallApp error', error)
  }
}

// Update developer app params are { appDataId, installParams, status, parseServerSiteId }
export const updateDeveloperAppData = async (params) => {
  try {
    const res = await Parse.Cloud.run('updateDeveloperAppData', params)
    if (res.status === 'success') {
      return res.appDetail
    }
  } catch (error) {
    console.error('parse / updateDeveloperAppData error', error)
  }
}

export const findDeveloperByEmail = async (parseServerSiteId, email) => {
  try {
    const res = await Parse.Cloud.run('findDeveloperByEmail', {
      email,
      parseServerSiteId,
    })
    if (res.status === 'success') {
      return res.developer
    }
  } catch (error) {
    console.error('parse / findDeveloperByEmail error', error)
  }
}

// Third party Picker related, for mural for now and no `parseServerSiteId` related
export const authorize = async () => {
  try {
    const authorizeRequest = await Parse.Cloud.run('authorize')
    if (authorizeRequest.success === true && authorizeRequest.url) {
      return authorizeRequest.url
    }
  } catch (error) {
    console.error('parse / authorize error', error)
  }
  return null
}

export const getRolesList = async (parseServerSiteId) => {
  try {
    const res = await Parse.Cloud.run('getRolesList', { parseServerSiteId })
    if (res.status === 'success') {
      return res.rolesList
    }
  } catch (error) {
    console.error('parse / getRolesList error', error)
  }
}
