<template>
  <v-card min-height="90vh" class="ModalAllNotifications" max-width="600px">
    <div class="ModalAllNotifications__title">
      <div class="ModalAllNotifications__title-wrapper">
        Notifications <v-icon class="settings_icon"> mdi-cog-outline</v-icon>
      </div>
      <v-btn x-small icon dark @click="close"
        ><the-icon class="ModalAllNotifications__close-icon" icon="close"
      /></v-btn>
    </div>

    <div v-if="loading" class="spinner mt-12" align="center">
      <LottieLoader :size="100" />
    </div>

    <div
      class="d-flex ModalAllNotifications__notifications_container"
      :class="{ noNotificationContainer: notifications.length == 0 }"
    >
      <div
        class="ModalAllNotifications__notifications_block"
        id="ModalAllNotifications__notifications_block"
        v-if="notifications.length > 0 && !loading"
      >
        <div
          v-for="notification in notifications"
          class="ModalAllNotifications__notifications"
        >
          <div
            class="ModalAllNotifications__notificationContainer"
            @click="moveToObject(notification)"
          >
            <div class="ModalAllNotifications__notificationHeader">
              <div class="ModalAllNotifications__notificationProfile">
                <avatar
                  v-if="avatarEmpty(notification)"
                  :rounded="true"
                  class="ModalAllNotifications__avatar"
                  :initials="initials(notification)"
                  username="userName"
                  :size="32"
                />
                <img
                  class="ModalAllNotifications__avatar"
                  :src="notification.createdAvatarUrl"
                  v-else
                />

                <div
                  class="viewed_button"
                  @click.stop="changeViewedState(notification)"
                >
                  <v-icon v-if="notification.viewed == false"
                    >mdi-checkbox-marked-circle-outline
                  </v-icon>
                  <v-icon v-else>mdi-check-circle</v-icon>
                </div>
                <div class="notification_new" v-if="!notification.viewed"></div>

                <div
                  class="d-inline-block ModalAllNotifications__notifications_message_block"
                >
                  <div
                    :ref="'notificationRef' + notification.id"
                    class="ModalAllNotifications__notificationMessage"
                    :id="'notification_message_' + notification.id"
                    v-html="notification.header"
                  ></div>
                  <div class="notifications_timestamps">
                    {{ notification.createdAt }}
                  </div>
                  <div
                    class="ModalAllNotifications__version_number"
                    v-if="
                      ['comments', 'mentions'].includes(
                        notification.notificationType
                      )
                    "
                  >
                    <div class="version_dot"></div>
                    <span>
                      Version #{{ notification.linkInfo['scoped_id'] }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="ModalAllNotifications__notificationContents">
              <div>
                <div
                  v-if="
                    ['comments', 'mentions'].includes(
                      notification.notificationType
                    )
                  "
                  class="ModalAllNotifications__html_message"
                >
                  <div v-html="notification.htmlText"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ModalAllNotifications__noNotifications" v-else-if="!loading">
        <v-icon color="n2"> mdi-bell-outline </v-icon>
        <h3>No notifications yet</h3>
        <div class="no_notifications_small">
          Once you start receiving notifications they'll appear here...
        </div>
      </div>
    </div>
    <div
      class="ModalAllNotifications__footer"
      v-if="!loading && notifications.length > 0"
    >
      <v-btn text color="y1" @click="setAllToView">
        Mark all as read
        <v-icon class="ml-2">mdi-checkbox-marked-circle-outline </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import LottieLoader from '@/components/_Common/LottieLoader'
import Avatar from 'vue-avatar'
export default {
  name: 'ModalAllNotifications',
  components: { LottieLoader, Avatar },
  data() {
    return {
      notifications: [],
      loading: true,
    }
  },
  computed: {},
  methods: {
    close() {
      this.$emit('close')
    },
    scrollToBottom() {
      let elem = document.getElementById(
        'ModalAllNotifications__notifications_block'
      )
      if (elem) elem.scrollTop = elem.scrollHeight
    },
    initials(notification) {
      notification.createdByName[0].toUpperCase()
    },
    avatarEmpty(notification) {
      return (
        notification.createdAvatarUrl == null ||
        notification.createdAvatarUrl.length == 0
      )
    },
    moveToObject(notification) {
      if (
        [
          'new_version',
          'version_deletion',
          'version_rollback',
          'site_in_project',
        ].includes(notification.notificationType)
      ) {
        const pathToObject = `/site/${notification.linkInfo['site_id']}/versions`
        if (
          this.$route.path == pathToObject ||
          notification.notificationType == 'site_in_project'
        )
          document.location = pathToObject
        else {
          this.$router.push(pathToObject)
          this.$emit('close')
        }
      } else if (
        ['comments', 'mentions'].includes(notification.notificationType)
      ) {
        let pathToObject = `/site/${notification.linkInfo['site_id']}/versions?versionId=${notification.linkInfo['version_id']}`
        pathToObject += `&commentId=${notification.linkInfo['comment_id']}`
        document.location = pathToObject
      } else if (notification.notificationType == 'invitation')
        document.location = `/${notification.linkInfo['object_type'].toLowerCase()}/${notification.linkInfo['object_id']}/access`
    },
    changeViewedState(notification) {
      this.$store.dispatch('notifications/updateViewState', notification.id)
    },
    setAllToView() {
      this.$store.dispatch('notifications/setAllToView')
    },
  },
  async mounted() {
    await this.$store.dispatch('notifications/load', true)
    this.notifications = _.cloneDeep(this.$store.state.notifications.list)
    this.loading = false
    this.scrollToBottom()
  },
  watch: {
    '$store.state.notifications.list': {
      handler(newVal, oldVal) {
        this.notifications = _.cloneDeep(newVal).reverse()
        this.loading = false
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
$style: ModalAllNotifications;

::v-deep .mention {
  color: $Y1;
}

::v-deep .#{$style}__html_message p {
  margin-bottom: 0px;
}

.#{$style} {
  min-height: 100vh !important;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 12px 0px 0px 12px;

  &.theme--dark.v-card {
    background: $N5;
    border: 1px solid $N7;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    border-radius: 12px 0px 0px 12px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: $H16;
    color: $white;
    padding: 30px 34px !important;
    border-bottom: 1px solid $N5;
    position: sticky;
  }
  &__title-wrapper {
    display: flex;
    align-items: center;
    .settings_icon {
      vertical-align: bottom;
      margin-left: 10px;
      font-size: 20px;
      color: $N2;
    }
  }

  &__close-icon {
    fill: $N3;
    width: 18px;
    height: 18px;
  }

  &__notifications_container {
    justify-content: center;

    .viewed_button {
      display: none;
      &:hover {
        cursor: pointer;
        i {
          color: $Y1 !important;
        }
      }
    }
  }

  &__html_message {
    margin-top: 10px;
  }

  &__notifications {
    display: inherit;
  }

  &__version_number {
    display: inline-block;
    .version_dot {
      display: inline-block;
      width: 5px;
      height: 5px;
      background: #585b6a;
      vertical-align: middle;
      margin-left: 5px;
      border-radius: 50%;
      margin-right: 3px;
    }
    span {
      color: $N2;
    }
  }

  &__noNotifications {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 231px);
    h3 {
      font-size: 14px;
      font-family: 'Instrument Sans', sans-serif;
      font-weight: 500;
      line-height: 20px;
    }
    .no_notifications_small {
      margin-top: 8px;
      width: 344px;
      text-align: center;
    }

    div {
      color: $N2;
    }
  }

  &__notifications_message_block {
    max-width: 85%;
  }

  &__notifications_block {
    padding-top: 16px;
    height: calc(100vh - 144px);
    display: flex;
    flex-shrink: 0;
    flex-direction: column-reverse;
    width: 100%;
    overflow-y: scroll;
    overscroll-behavior: contain;
  }

  &__notificationContainer {
    cursor: pointer;
    padding-top: 15px;
    padding-left: 28px;
    padding-right: 28px;
    padding-bottom: 16px;
    border-top: 1px solid $N7;
    width: 100%;
    align-self: flex-end;

    .notification_new {
      float: right;
      width: 10px;
      margin-right: 8px;
      margin-top: 10px;
      height: 10px;
      border-radius: 8px;
      background: red;
    }

    &:hover {
      border-top: 1px solid $N7;
      background: $N7;

      .viewed_button {
        display: block;
        float: right;
        margin-top: 10px;
      }
      .notification_new {
        display: none;
      }
    }

    .#{$style}__html_message {
      margin-top: 10px;
      margin-left: 40px;
      border-radius: 6px;
      background: $N7;
      padding: 8px;
      max-height: 50px;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__avatar {
    display: inline-block !important;
    width: 32px;
    vertical-align: top !important;
    margin-top: 9px;
    height: 32px;
    border-radius: 8px;
    display: inline-block;
    vertical-align: baseline;
  }

  &__notificationMessage {
    margin-left: 10px;
    margin-top: 8px;
    color: $N2;
    max-height: 70px;
    overflow: hidden;
  }

  &__notificationProfile {
    .notifications_timestamps {
      color: $N2;
      display: inline-block;
      margin-left: 10px;
      font-size: 12px;
    }
  }

  &__profile_name {
    color: $N2;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
  }

  &__footer {
    padding-top: 12px;
    border-top: 1px solid $N7;
  }

  .spinner {
    height: 53vh;
  }
}
</style>
