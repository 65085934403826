import store from '@/store'

export class ProfileConfig {
  id = null
  user_id = null
  comments = true
  mentions = true
  invitation = true
  new_version = true
  version_rollback = true
  role_changed = true
  version_deletion = true
  site_in_project = true

  constructor(origin) {
    if (!origin) return

    this.id = origin.id
    this.user_id = origin.user_id
    this.comments = origin.comments
    this.mentions = origin.mentions
    this.invitation = origin.invitation
    this.new_version = origin.new_version
    this.version_rollback = origin.version_rollback
    this.role_changed = origin.role_changed
    this.version_deletion = origin.version_deletion
    this.site_in_project = origin.site_in_project
  }

  getServerObject() {
    return {
      comments: this.comments,
      mentions: this.mentions,
      invitation: this.invitation,
      new_version: this.new_version,
      version_rollback: this.version_rollback,
      role_changed: this.role_changed,
      version_deletion: this.version_deletion,
      site_in_project: this.site_in_project,
    }
  }
}
