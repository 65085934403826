<template>
  <v-card class="PublisherDashboardPluginCard" @click="onNavigateToPlugin">
    <div
      class="PublisherDashboardPluginCard__key-image"
      :style="{ 'background-image': 'url(' + keyImage + ')' }"
    ></div>
    <div class="PublisherDashboardPluginCard__body">
      <div class="PublisherDashboardPluginCard__header">
        <div class="PublisherDashboardPluginCard__title">{{ plugin.name }}</div>
      </div>
      <div
        class="PublisherDashboardPluginCard__description"
        v-html="description"
      ></div>
      <div
        class="d-flex items-center justify-space-between"
        v-if="plugin.developerData"
      >
        <div>
          <v-rating
            v-if="plugin.developerData.rating"
            v-model="plugin.developerData.rating"
            readonly
            background-color="red lighten-3"
            color="red"
            small
            dense
          ></v-rating>
        </div>
        <div
          class="PublisherDashboardPluginCard__app-installs-count"
          v-if="plugin.developerData.installsCount > 0"
        >
          <the-icon class="mr-2" icon="download" /><span
            >{{ plugin.developerData.installsCount }} installs</span
          >
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'PublisherDashboardPluginCard',
  props: {
    plugin: Object,
  },
  computed: {
    keyImage() {
      return this.plugin.developerContent
        ? this.plugin.developerContent.keyImage
        : ''
    },
    description() {
      return this.plugin.developerContent
        ? this.plugin.developerContent.description
        : ''
    },
  },
  methods: {
    onNavigateToPlugin() {
      this.$router.push({
        name: 'plugin_detail',
        params: { pluginId: this.plugin.slug },
      })
    },
  },
}
</script>

<style lang="scss">
$style: PublisherDashboardPluginCard;
.#{$style} {
  width: 100%;
  border-radius: 20px;
  border: 1px solid $N5;
  &__key-image {
    height: 200px;
    background-size: cover;
  }
  &__body {
    padding: 20px;
    background: #1f1e31;
  }
  &__header {
    margin-bottom: 10px;
    font-size: 16px;
  }
  &__description {
    color: rgba(164, 164, 174, 0.7);
    height: 100px;
    overflow: hidden;
  }
  &__app-installs-count {
    display: flex;
  }
}
.w-full {
  width: 100%;
}
</style>
