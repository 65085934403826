<template>
  <vue-editor
    v-model="modelValue"
    :disabled="schema.readonly"
    :editor-toolbar="toolbarOptions"
    class="dark-theme"
    ref="editor"
  ></vue-editor>
</template>

<script>
import { VueEditor } from 'vue2-editor'

export default {
  name: 'FieldEditor',
  components: {
    VueEditor,
  },
  props: ['model', 'schema'],
  data() {
    return {
      toolbarOptions: [
        ['bold', 'italic', 'underline', 'strike'], // Basic text styles
        [{ size: ['small', false, 'large', 'huge'] }], // Font sizes
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    }
  },
  computed: {
    modelValue: {
      get() {
        return this.model[this.schema.model]
      },
      set(value) {
        this.model[this.schema.model] = value
      },
    },
  },
  methods: {
    clearValidationErrors() {
      // Custom method to clear validation errors
      this.$refs.editor.$el.classList.remove('error')
    },
  },
}
</script>

<style lang="scss">
.dark-theme {
  .ql-formats {
    color: white;
  }
  .ql-picker-label {
    color: white;
  }
  &.quillWrapper .ql-snow {
    .ql-stroke {
      stroke: white;
    }
    .ql-fill {
      fill: white;
    }
  }
}
</style>
