<template>
  <div class="field-icon-upload d-flex align-center">
    <div class="preview-container" v-if="model && model[schema.model]">
      <div class="preview-img-wrapper">
        <img class="preview-img" :src="fileURL" />
      </div>
    </div>

    <div
      class="dropzone-container"
      @dragover="dragover"
      @dragleave="dragleave"
      @drop="drop"
    >
      <input
        type="file"
        name="file"
        id="fileInput"
        class="hidden-input"
        @change="onChange"
        ref="file"
        accept=".svg,.jpg,.jpeg,.png"
      />

      <label for="fileInput" class="file-label">
        <div>
          <div class="upload-icon mt-2">
            <the-icon icon="upload" />
          </div>
          <div class="dropzone__message-main-label" v-if="isDragging">
            Release to drop file here.
          </div>
          <div v-else>
            <div class="dropzone__message-main-label">
              {{ schema.mainLabel || 'Drag &amp; Drop files here' }}
            </div>
            <div
              v-if="schema.additionalLabel"
              class="dropzone__message-additional-label"
            >
              or drag &amp; drop your file here
            </div>
          </div>
        </div>
      </label>
    </div>
    <div v-if="loading" class="spinner">
      <LottieLoader :size="30" />
    </div>
  </div>
</template>

<script>
import Parse from 'parse'
import Vue from 'vue'
import VueFormGenerator from 'vue-form-generator'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import { fileToBase64 } from '@/utils/common'
import { mapState, mapMutations } from 'vuex'
import LottieLoader from '@/components/_Common/LottieLoader'

export default {
  name: 'FieldDragDropUploader',
  mixins: [VueFormGenerator.abstractField],
  components: { IntersectingCirclesSpinner, LottieLoader },
  props: ['schema', 'model', 'formOptions', 'required', 'disabled'],
  data() {
    return {
      isDragging: false,
      loading: false,
    }
  },
  methods: {
    async onChange(e) {
      this.loading = true
      const promises = []
      const parseFile = await this.uploadFile(this.$refs.file.files[0])
      this.model[this.schema.model] = parseFile
      Vue.set(this.model, this.schema.model, parseFile)
      this.loading = false
    },

    async uploadFile(file) {
      try {
        const base64 = await fileToBase64(file)
        const res = await Parse.Cloud.run('uploadFile', {
          fileName: file.name,
          base64,
        })
        if (res && res.parseFile) return res.parseFile
      } catch (error) {
        console.error('Error in uploadFile', error)
      }
      return null
    },

    dragover(e) {
      e.preventDefault()
      if (this.loading) return
      this.isDragging = true
    },

    dragleave() {
      this.isDragging = false
    },

    drop(e) {
      if (this.loading) return
      e.preventDefault()
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      this.isDragging = false
    },
  },
  computed: {
    fileURL() {
      const file = this.model[this.schema.model]
      return file.fileURL || file._url || file.url
    },

    fileName() {
      const file = this.model[this.schema.model]
      return file.fileName || file._name || file.name
    },
  },
}
</script>

<style lang="scss" scoped>
.field-icon-upload {
  position: relative;
}

.dropzone-container {
  flex-grow: 1;
  padding: 4rem;
  border: 1px dashed $N3;
  padding: 1rem;
  text-align: center;
  cursor: pointer;
  min-height: 80px;
  border-radius: 8px;
  position: relative;
}

.dropzone__message i {
  font-size: 3rem;
  margin-bottom: 1rem;
}

.dropzone__message-main-label {
  color: $Y1;
  font-size: 12px;
  margin-top: 8px;
  margin-bottom: 4px;
}

.dropzone__message-additional-label {
  color: $N2;
  font-size: 12px;
}

.dropzone__filename {
  font-weight: 700;
  margin-right: 0.5rem;
}

.upload-icon {
  background: $N6;
  border-radius: 8px;
  width: 40px;
  height: 40px;
  text-align: center;
  color: $Y1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
}
.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: 1px;
  height: 1px;
}
.file-label {
  font-size: 20px;
  display: block;
  cursor: pointer;
}
.preview-container {
  display: flex;
  flex-wrap: wrap;
  width: 33%;
}
.preview-img-wrapper {
  position: relative;
  text-align: center;
  width: 120px;
  height: 80px;
  margin-bottom: 10px;
}
.preview-img {
  width: auto;
  height: 80px;
  border-radius: 5px;
  border: 1px solid #a2a2a2;
  background-color: #a2a2a2;
}
.preview-action {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
  display: none;
}
.preview-img-wrapper:hover .preview-action {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}
.key-image-selector {
  font-size: 12px;
  cursor: pointer;
}
</style>

<style scoped>
.spinner {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
</style>
