var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"the-app-bar__notify"},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","internal-activator":"","left":true},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","plain":""}},'v-btn',attrs,false),on),[_c('v-icon',{class:{ Y1: _vm.menu },attrs:{"color":"n2"}},[_vm._v("mdi-bell-outline")]),(_vm.has_unread_notifications)?_c('span',{staticClass:"the-app-bar__notify-badge"}):_vm._e()],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{staticClass:"Notifications__card",attrs:{"min-width":"384"}},[_c('div',{staticClass:"Notifications__card_header"},[_c('span',{staticClass:"header_text"},[_vm._v(" Notifications ")]),_c('v-icon',{staticClass:"settings_icon"},[_vm._v(" mdi-cog-outline ")])],1),(_vm.notifications.length > 0)?_c('v-list',{staticClass:"Notifications__list",attrs:{"id":"Notifications__list"}},_vm._l((_vm.notifications),function(notification){return _c('v-list-item',{key:notification.id,on:{"click":function($event){return _vm.moveToObject(notification)}}},[_c('div',{staticClass:"d-inline-block Notifications__avatar_image"},[(!_vm.avatarEmpty(notification))?_c('img',{staticClass:"the-app-bar__image-wrapper",attrs:{"src":notification.createdAvatarUrl,"alt":""}}):_c('avatar',{attrs:{"rounded":true,"initials":_vm.initials(notification),"username":"name","size":26}})],1),_c('div',{staticClass:"d-inline-block Notifications__text"},[_c('p',{domProps:{"innerHTML":_vm._s(notification.header)}}),_c('div',{staticClass:"Notifications__timestamps"},[_vm._v(" "+_vm._s(notification.createdAt)+" "),(
                  ['comments', 'mentions'].includes(
                    notification.notificationType
                  )
                )?_c('span',{staticClass:"Notifications__version_number"},[_c('div',{staticClass:"version_dot"}),_c('span',[_vm._v(" Version #"+_vm._s(notification.linkInfo['scoped_id'])+" ")])]):_vm._e()]),(
                ['comments', 'mentions'].includes(
                  notification.notificationType
                )
              )?_c('div',{staticClass:"Notifications__html_message"},[_c('div',{domProps:{"innerHTML":_vm._s(notification.htmlText)}})]):_vm._e()]),(notification.viewed == false)?_c('div',{staticClass:"Notifications__unread_dot"}):_vm._e(),_c('div',{staticClass:"viewed_button",on:{"click":function($event){$event.stopPropagation();return _vm.changeViewedState(notification)}}},[(notification.viewed == false)?_c('v-icon',[_vm._v("mdi-checkbox-marked-circle-outline ")]):_c('v-icon',[_vm._v("mdi-check-circle")])],1)])}),1):_vm._e()],1),_c('div',{staticClass:"Notifications__footer"},[_c('v-btn',{attrs:{"text":"","color":"#ff7e21"},on:{"click":function($event){$event.stopPropagation();return _vm.setAllToView.apply(null, arguments)}}},[_vm._v(" Mark all as read ")]),_c('v-btn',{staticClass:"y1",staticStyle:{"float":"right"},on:{"click":function($event){return _vm.openAll()}}},[_vm._v(" View All Notifications ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }