<template>
  <div>
    <div class="OrganisationBilling__spinner_block" v-if="loading">
      <div class="spinner mt-12" align="center">
        <LottieLoader :size="100" />
      </div>
    </div>
    <div class="OrganisationBilling">
      <div class="OrganisationBilling__plans">
        <h2 class="OrganisationBilling__title d-inline-block">Billing</h2>

        <div class="OrganisationBilling__payment_period">
          <v-btn
            @click="paymentPeriod = 'monthly'"
            :color="paymentPeriod == 'monthly' ? 'y1' : 'n5'"
            dark
          >
            Monthly
          </v-btn>
          <v-btn
            @click="paymentPeriod = 'annually'"
            :color="paymentPeriod == 'annually' ? 'y1' : 'n5'"
            dark
          >
            Annually
          </v-btn>
        </div>

        <div class="OrganisationBilling__errors mb-2" v-if="changeErrors">
          <div class="errors-block">
            {{ changeErrors }}
          </div>
        </div>

        <div class="OrganisationBilling__row">
          <div
            class="OrganisationBilling__col"
            v-for="plan in filteredPlans"
            :class="{
              _active: selectedPlan == plan.id,
            }"
            @click="selectedPlan = plan.id"
            :key="plan.id"
          >
            <div class="OrganisationBilling__plan">
              <div class="OrganisationBilling__plan-head">
                <h4 class="OrganisationBilling__plan-title">{{ plan.name }}</h4>
                <span
                  class="OrganisationBilling__plan-label"
                  v-if="currentPlan == plan.id"
                  >current</span
                >
              </div>
              <div class="OrganisationBilling__plan-body">
                <div v-if="paymentPeriod == 'monthly'">
                  <h6
                    class="OrganisationBilling__plan-cost"
                    v-if="plan.monthly_price_per_user"
                  >
                    ${{ plan.monthly_price }}/user per month
                  </h6>
                  <h6 class="OrganisationBilling__plan-cost" v-else>
                    ${{ plan.monthly_price }} per month
                  </h6>
                </div>
                <div class="mb-4" v-else>
                  <h6 class="OrganisationBilling__plan-cost mb-1">
                    ${{ plan.annually_monthly_price * 12 }} per year
                  </h6>
                  <small class="ml-3">
                    ${{ plan.annually_monthly_price }} per month
                  </small>
                </div>
                <p
                  class="OrganisationBilling__plan-text"
                  v-for="feature in baseFeatures"
                  :key="feature"
                >
                  {{ feature }}
                </p>
                <p class="OrganisationBilling__plan-text">
                  <span> {{ planBandwidth(plan.bandwidth_limit_gb) }} </span>/
                  month
                </p>
              </div>
            </div>
          </div>

          <div
            class="OrganisationBilling__col"
            :style="{ cursor: 'auto' }"
            v-if="!enterpriseAdded"
          >
            <div class="OrganisationBilling__plan">
              <div class="OrganisationBilling__plan-head">
                <h4 class="OrganisationBilling__plan-title">Enterprise</h4>
              </div>
              <div class="OrganisationBilling__plan-body">
                <h6 class="OrganisationBilling__plan-cost">Custom</h6>
                <p
                  class="OrganisationBilling__plan-text"
                  v-for="feature in enterpriseBaseFeatures"
                  :key="feature"
                >
                  {{ feature }}
                </p>
                <p class="OrganisationBilling__plan-text">
                  <span> 1 TB bandwidth </span>/ month
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          class="OrganisationBilling__init_plan_btn"
          v-if="cardAdded || selectedInvoiceSub"
        >
          <v-btn @click="cancel" color="n5" dark> Cancel </v-btn>
          <v-btn
            @click="changePlan"
            color="y1"
            dark
            :disabled="(!cardAdded && !selectedInvoiceSub) || !planChanged"
          >
            Change Plan
          </v-btn>
        </div>
      </div>
    </div>

    <div class="mb-4 OrganisationBilling" v-if="!selectedInvoiceSub">
      <h2 class="PaidBilling__title">Subscription code</h2>
      <p class="PaidBilling__section-text">
        Got a subscription code? Enter it here
      </p>
      <p class="PaidBilling__section-text red--text" v-if="couponError">
        {{ couponError }}
      </p>
      <v-text-field
        class="PaidBilling__section-input"
        dark
        filled
        v-model="subscriptionCode"
        dense
      />
      <div class="PaidBilling__section-footer" v-if="cardAdded && !planChanged">
        <v-btn
          large
          @click="enterCode"
          color="y1"
          :disabled="!subscriptionCodeEntered"
          dark
        >
          Save
        </v-btn>
      </div>
    </div>

    <div
      class="mb-4 OrganisationBilling"
      v-if="!cardAdded && !selectedInvoiceSub"
    >
      <h2 class="OrganisationBilling__title d-inline-block">Card Details</h2>
      <div class="errors red--text mb-2" v-if="cancelErrors">
        {{ cancelErrors }}
      </div>
      <ModalCardData :valie-fields="valueFields" @input="updateValueFields" />

      <div class="OrganisationBilling__plans-footer">
        <v-btn @click="cancel" color="n5" dark> Cancel </v-btn>
        <v-btn @click="save" color="y1" dark> Save </v-btn>
      </div>
    </div>

    <div v-if="cardAdded && !selectedInvoiceSub">
      <div class="mb-4 OrganisationBilling">
        <h2 class="OrganisationBilling__title d-inline-block">Card Details</h2>
        <p>If you need to change your card details, click below.</p>
        <v-btn large @click="upgradeDetails" color="n5" dark>
          Update Billing Details
        </v-btn>
      </div>

      <div class="mb-4 OrganisationBilling">
        <OrganisationInvoices />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { COUNTRIES } from '@/countries'
import ModalCardData from '@/components/Modals/ModalCardData'
import { MODAL_CARD_DATA } from '@/components/Modals'
import LottieLoader from '@/components/_Common/LottieLoader'
import moment from 'moment'
import OrganisationInvoices from './OrganisationInvoices'
import { STRIPE_PUBLIC_KEY } from '@/env'

export default {
  name: 'OrganisationBilling',
  components: { LottieLoader, OrganisationInvoices },
  data() {
    return {
      baseFeatures: [
        'Unlimited Sites',
        'Unlimited Projects',
        'Global CDN',
        '24/7 Support',
      ],
      addedPlan: null,
      loading: true,
      enterpriseBaseFeatures: [
        'Security Features',
        'SSO and SCIM',
        'Organisation Admin',
        'SLA Based Support',
        'Custom Billing',
      ],
      valueFields: {
        cardNumberElement: null,
        cardExpiryElement: null,
        cardCvcElement: null,
        stripe: null,
      },
      subscriptionCode: '',
      cancelErrors: '',
      couponError: '',
      showCoupon: false,
      couponSaved: null,
      changeErrors: '',
      paymentPeriod: this.$store.state.organisations.current.payment_period,
      selectedPlan:
        this.$store.state.organisations.current.organisation_subscription_id,
    }
  },
  computed: {
    ...mapState({
      organisation_subscriptions: ({ organisation_subscriptions }) =>
        organisation_subscriptions.list,
      organisation: ({ organisations }) => organisations.current,
    }),
    subscriptionCodeEntered() {
      return this.subscriptionCode.length > 0
    },
    couponSavePressed() {
      return this.couponSaved != null
    },
    enterpriseAdded() {
      return this.organisation_subscriptions.length > 1
    },
    selectedInvoiceSub() {
      const orgSub = this.organisation_subscriptions.find(
        (sub) => this.selectedPlan == sub.id
      )
      return orgSub && orgSub.payment_type === 'invoice'
    },
    invoicePayment() {
      const orgSub = this.organisation_subscriptions.find(
        (sub) => this.currentPlan == sub.id
      )
      return orgSub.payment_type === 'invoice'
    },
    planChanged() {
      return (
        this.selectedPlan !== this.currentPlan ||
        this.paymentPeriod !== this.currentPeriod
      )
    },
    cardAdded() {
      return this.organisation ? this.organisation.card_added : false
    },
    currentPeriod() {
      return this.organisation ? this.organisation.payment_period : 'monthly'
    },
    trial() {
      return this.organisation.trial
    },
    currentPlan() {
      return this.organisation
        ? this.organisation.organisation_subscription_id
        : null
    },
    filteredPlans() {
      if (this.paymentPeriod == 'monthly')
        return this.organisation_subscriptions.filter(
          (sub) => sub.monthly_price && sub.monthly_price > 0
        )
      else
        return this.organisation_subscriptions.filter(
          (sub) => sub.annually_monthly_price && sub.annually_monthly_price > 0
        )
    },
  },
  methods: {
    planBandwidth(bandwidth_limit_gb) {
      if (bandwidth_limit_gb >= 1024)
        return `${Math.floor(bandwidth_limit_gb / 1024)} TB`
      else return `${bandwidth_limit_gb} GB`
    },
    existsForPaymentPeriod(plan) {
      return (
        (this.paymentPeriod == 'monthly' &&
          plan.monthly_price &&
          plan.monthly_price > 0) ||
        (this.paymentPeriod == 'annually' &&
          plan.annually_monthly_price &&
          plan.annually_monthly_price > 0)
      )
    },
    upgradeDetails() {
      this.$store.commit('application/openModal', {
        component: MODAL_CARD_DATA,
        props: {
          paid: true,
          organisation: true,
        },
      })
    },
    enterCode() {
      if (this.loading) return
      this.couponError = ''
      const params = {
        organisation_subscription: {
          coupon: this.subscriptionCode,
        },
      }
      this.$store
        .dispatch('organisation_subscriptions/saveSubscription', params)
        .then((res) => {
          if (res.errors) {
            this.couponError = res.errors.join('. ')
            this.couponSaved = null
          } else {
            this.couponSaved = true
            this.subscriptionCode = ''
          }
        })
    },
    changePlan() {
      if (this.loading) return

      this.changeErrors = ''
      this.loading = true
      let params = {
        organisation_subscription: {
          organisation_subscription_id: this.selectedPlan,
          payment_period: this.paymentPeriod,
        },
      }
      if (this.subscriptionCodeEntered)
        params['organisation_subscription']['coupon'] = this.subscriptionCode
      this.saveData(params)
    },
    async saveData(params) {
      this.$store
        .dispatch('organisation_subscriptions/saveSubscription', params)
        .then((res) => {
          if (!res.errors && !res.client_secret) {
            this.couponSaved = true
            this.subscriptionCode = ''
            this.loading = false
            this.cancel()
            return
          }
          if (res.client_secret) {
            this.confirmStripeAction(res.client_secret)
            return
          }
          this.loading = false
          this.cancel()

          if (
            res.errors &&
            res.errors.find((el) => el == 'Coupon was not found!') != undefined
          ) {
            this.couponError = res.errors.find(
              (el) => el == 'Coupon was not found!'
            )
            this.couponSaved = null
          } else if (res.errors) {
            this.changeErrors = res.errors.join('. ')
          }
        })
    },
    async confirmStripeAction(secret) {
      const stripe = Stripe(STRIPE_PUBLIC_KEY)
      stripe.confirmCardPayment(secret).then((res) => {
        if (res.error || res.paymentIntent.status != 'succeeded') {
          this.changeErrors = 'Payment not confirmed. Please check your card'
          this.cancelErrors = this.changeErrors
          this.loading = false
          return
        }
        const params = {
          organisation_subscription: {
            organisation_subscription_id: this.selectedPlan,
            payment_period: this.paymentPeriod,
          },
        }
        this.$store
          .dispatch('organisation_subscriptions/checkSubscription', params)
          .then(() => {
            this.couponSaved = true
            this.subscriptionCode = ''
            this.cancel()
            this.loading = false
            return
          })
      })
    },
    updateInvoiceSub() {
      if (!this.selectedInvoiceSub) return
      let params = {
        organisation_subscription: {
          organisation_subscription_id: this.selectedPlan,
          payment_period: this.paymentPeriod,
        },
      }
      this.saveData(params)
    },
    save() {
      if (this.loading) return
      this.loading = true
      this.cancelErrors = ''
      this.couponError = ''
      this.changeErrors = ''
      if (this.selectedInvoiceSub) {
        this.updateInvoiceSub()
        return
      }
      this.valueFields.stripe
        .createToken(this.valueFields.cardNumberElement)
        .then((res) => {
          if (res.error != undefined) {
            this.cancelErrors = res.error.message
            this.loading = false
            return
          }
          let params = {
            organisation_subscription: {
              stripe_card_token: res.token.id,
              organisation_subscription_id: this.selectedPlan,
              coupon: this.subscriptionCode,
              payment_period: this.paymentPeriod,
            },
          }
          this.saveData(params)
        })
    },
    cancel() {
      this.paymentPeriod =
        this.$store.state.organisations.current.payment_period
      this.selectedPlan =
        this.$store.state.organisations.current.organisation_subscription_id
      this.cancelErrors = ''
      this.changeErrors = ''
    },
    updateValueFields(e) {
      this.valueFields = e
    },
  },
  mounted() {
    this.$store.dispatch('organisation_subscriptions/load').then(() => {
      this.loading = false
    })
  },
  watch: {
    paymentPeriod() {
      this.selectedPlan =
        this.$store.state.organisations.current.organisation_subscription_id
    },
  },
}
</script>

<style lang="scss">
$style: OrganisationBilling;
.#{$style} {
  margin: 42px 24px 42px;
  border: 1px solid #201f32;
  background-color: #201f32;
  border-radius: 4px;
  padding: 15px;
  box-shadow:
    0 0 0 0 rgba(0, 0, 0, 0.2),
    0 0 0 0 rgba(0, 0, 0, 0.14),
    0 0 0 0 rgba(0, 0, 0, 0.12) !important;

  &__errors {
    display: flex;
    justify-content: center;
    .errors-block {
      background-color: $R1;
      color: white;
      width: 350px;
      border-radius: 4px;
      text-align: center;
      padding: 10px;
      margin-bottom: 21px;
    }
  }

  &__spinner_block {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1001;
    opacity: 0.6;
    background: #181729;

    .spinner {
      height: 100vh;
    }
  }
  &__init_plan_btn {
    display: flex;
    justify-content: right;
    margin-top: 20px;
    margin-right: 8%;
    button {
      margin-right: 10px;
    }
  }

  &__payment_period {
    display: flex;
    margin-bottom: 25px;
    justify-content: center;

    button:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    button:last-child {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__title {
    color: $white;
    font-weight: 500;
    font-size: $H20;
    margin-bottom: 20px;
  }
  &__row {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
  }
  &__col {
    flex: 1 1 0;
    max-width: 40%;
    border: 1px solid $N5;
    cursor: pointer;
    &:last-child {
      border-right-color: $N5;
      border-radius: 0 8px 8px 0;
    }
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &._active {
      background-color: $N4;
      border: 1px solid $Y1;
    }
  }
  &__custom_plan {
    color: $Y1;
    border: 1px solid $Y1;
    border-radius: 8px;
    padding: 10px;
  }
  &__plan {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    padding: 12px;
  }
  &__plan-head {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  &__plan-title {
    color: $white;
    font-weight: 600;
    font-size: $H18;
    padding: 8px 0;
  }
  &__plan-body {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &__plan-cost {
    color: $white;
    font-weight: 600;
    font-size: $H16;
    margin-bottom: 16px;
  }
  &__plan-label {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 8px;
    background-color: $Y1;
    border-radius: 20px;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -22px);

    &.period {
      margin-top: -4px;
    }
  }
  &__plan-subtext {
    color: $N2;
    font-size: $H11;
    margin-bottom: 16px;
  }
  &__plan-text {
    color: $white;
    font-weight: 400;
    font-size: $H14;
    margin-bottom: 10px;
    text-align: center;
    &._bold {
      font-weight: 600;
    }
    span {
      font-weight: 600;
    }
  }
  &__plans-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 0;
    gap: 16px;
  }
  &__section {
    @extend %section-styles;
    flex-basis: 50%;
    &._last {
      margin-top: 16px;
    }
  }
  &__input {
    @extend %input-styles;
  }
  &__section-footer {
    display: flex;
  }
  @media screen and (max-width: 600px) {
    &__col {
      max-width: 50%;
    }
    &__section {
      flex-basis: 100%;
    }
  }
}
</style>
