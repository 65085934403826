<template>
  <div class="ThePublisherDashboard">
    <v-row class="ThePublisherDashboard__row">
      <v-col cols="18">
        <publisher-dashboard-section-wrapper title="Value Metrics">
          <publisher-dashboard-metrics
            :pluginInstallsCount="valueMetrics.pluginInstallsCount"
            :developersCount="valueMetrics.developersCount"
            :sdkVersion="valueMetrics.sdkVersion"
          />
        </publisher-dashboard-section-wrapper>
      </v-col>
      <v-col cols="18">
        <publisher-dashboard-section-wrapper title="Plugin Status">
          <publisher-plugin-status
            v-if="statisticsChartData"
            :chartData="statisticsChartData"
          />
        </publisher-dashboard-section-wrapper>
      </v-col>
    </v-row>
    <v-row class="ThePublisherDashboard__row">
      <v-col>
        <publisher-dashboard-developer-map
          :statistics="developersStatistics"
          v-if="developersStatistics"
        />
      </v-col>
      <v-col>
        <publisher-overall-installation />
      </v-col>
    </v-row>
    <publisher-dashboard-top-plugins />
  </div>
</template>

<script>
import { mapState } from 'vuex'

import PublisherDashboardSectionWrapper from './ThePublisher/Dashboard/PublisherDashboardSectionWrapper.vue'
import PublisherDashboardMetrics from './ThePublisher/Dashboard/PublisherDashboardMetrics.vue'
import PublisherPluginStatus from './ThePublisher/Dashboard/PublisherPluginStatus.vue'
import PublisherOverallInstallation from './ThePublisher/Dashboard/PublisherOverallInstallation.vue'
import PublisherDashboardTopPlugins from './ThePublisher/Dashboard/PublisherDashboardTopPlugins.vue'
import PublisherDashboardDeveloperMap from './ThePublisher/Dashboard/PublisherDashboardDeveloperMap.vue'

const Parse = require('parse')

export default {
  name: 'ThePublisherDashboard',
  components: {
    PublisherDashboardSectionWrapper,
    PublisherDashboardTopPlugins,
    PublisherPluginStatus,
    PublisherOverallInstallation,
    PublisherDashboardMetrics,
    PublisherDashboardDeveloperMap,
  },
  data() {
    return {
      valueMetrics: {
        pluginInstallsCount: 0,
        developersCount: 0,
        sdkVersion: '',
      },
      developersStatistics: null,
      statisticsChartData: null,
    }
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current,
    }),
  },
  async mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id)
    Parse.serverURL = this.currentPublisherInfo.parse_server_url

    await this.getPluginStatistics()
    await this.getDevelopersStatistics()
    await this.getLatestSDKStatistics()
  },
  methods: {
    async getPluginStatistics() {
      const res = await Parse.Cloud.run('getPluginsListData', {
        filter: {},
        parseServerSiteId: this.currentPublisherInfo.parse_server_site_id,
      })
      if (!res || !res.apps) return

      const pluginsMap = res.apps.reduce(
        (acc, plugin) => {
          let key = 'draft'
          const status = (plugin.appData?.status || '').toLowerCase()
          if (status === 'ready for sale') key = 'ready-for-sale'
          if (status.indexOf('review') !== -1) key = 'in-review'
          return { ...acc, [key]: [...acc[key], plugin] }
        },
        {
          'ready-for-sale': [],
          'in-review': [],
          draft: [],
        }
      )

      this.statisticsChartData = [
        { slug: 'ready-for-sale', value: pluginsMap['ready-for-sale'].length },
        { slug: 'in-review', value: pluginsMap['in-review'].length },
        { slug: 'draft', value: pluginsMap['draft'].length },
      ]

      const installsCountSum = res.apps.reduce((acc, plugin) => {
        return acc + Number(plugin.appData?.installsCount || 0)
      }, 0)
      this.valueMetrics.pluginInstallsCount = installsCountSum
    },
    async getDevelopersStatistics() {
      const res = await Parse.Cloud.run('getDevelopersList', {
        verified: null,
        parseServerSiteId: this.currentPublisherInfo.parse_server_site_id,
      })
      if (res && res.developersList) {
        this.valueMetrics.developersCount = res.developersList.length
        const statistics = res.developersList.reduce((acc, cur) => {
          const countryCode = cur.country
          return {
            ...acc,
            [countryCode]: {
              developers: acc[countryCode]
                ? acc[countryCode].developers + 1
                : 1,
            },
          }
        }, {})
        this.developersStatistics = statistics
      }
    },
    async getLatestSDKStatistics() {
      const res = await Parse.Cloud.run('getLatestSDK', {
        parseServerSiteId: this.currentPublisherInfo.parse_server_site_id,
      })
      if (res && res.data) {
        this.valueMetrics.sdkVersion = res.data.version
      }
    },
  },
}
</script>
<style lang="scss">
$style: ThePublisherDashboard;
.#{$style} {
  padding: 42px 24px 24px;
  &__row {
    margin-bottom: 58px;
  }
  @media screen and (max-width: 600px) {
    &__row {
      margin-bottom: 0px;
    }
  }
}
.w-full {
  width: 100%;
}
</style>
