<template>
  <v-container>
    <!-- MongoDB Atlas Configuration Section -->
    <v-card class="section-card" flat>
      <v-card-title>MongoDB Atlas Configuration</v-card-title>
      <v-card-text>
        <p>
          Configure your own MongoDB Atlas account instead of using Forge's main
          Atlas account.
        </p>
        <a href="https://docs.getforge.com" target="_blank"
          >Learn more about MongoDB Atlas Configuration in the docs &rarr;</a
        >
        <div class="config-area">
          <v-card class="nested-card" flat>
            <v-card-title>Atlas Account Configuration</v-card-title>
            <v-card-text>
              <p>
                Configure your MongoDB Atlas settings to integrate with your
                infrastructure.
              </p>
            </v-card-text>
          </v-card>
          <div class="overlay">
            <div class="overlay-content">
              <p>
                Upgrade to Enterprise to configure your MongoDB Atlas account.
              </p>
              <spark-button @click="contactSales">Contact Sales</spark-button>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- Forge Ignite Private Instance Section -->
    <v-card class="section-card" flat>
      <v-card-title>Forge Ignite Private Instance</v-card-title>
      <v-card-text>
        <p>
          Configure a private instance for your Forge Ignite application
          cluster.
        </p>
        <a href="https://docs.getforge.com" target="_blank"
          >Learn more about Forge Ignite Private Instance in the docs &rarr;</a
        >
        <div class="config-area">
          <v-card class="nested-card" flat>
            <v-card-title>Private Instance Configuration</v-card-title>
            <v-card-text>
              <p>Configure your private instance settings for Forge Ignite.</p>
            </v-card-text>
          </v-card>
          <div class="overlay">
            <div class="overlay-content">
              <p>
                Upgrade to Enterprise to configure a private instance for Forge
                Ignite.
              </p>
              <spark-button @click="contactSales">Contact Sales</spark-button>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <!-- Geographic Region Configuration Section -->
    <v-card class="section-card" flat>
      <v-card-title>Geographic Region Configuration</v-card-title>
      <v-card-text>
        <p>
          Configure the geographic region for any of your sites' Forge Ignite
          apps data storage from the available regions.
        </p>
        <a href="https://docs.getforge.com" target="_blank"
          >Learn more about Geographic Region Configuration in the docs
          &rarr;</a
        >
        <div class="config-area">
          <v-card class="nested-card" flat>
            <v-card-title>Region Configuration</v-card-title>
            <v-card-text>
              <p>
                Configure your data storage region settings for Forge Ignite
                apps.
              </p>
            </v-card-text>
          </v-card>
          <div class="overlay">
            <div class="overlay-content">
              <p>
                Upgrade to Enterprise to configure geographic regions for your
                Forge Ignite apps.
              </p>
              <spark-button @click="contactSales">Contact Sales</spark-button>
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SparkButton from '../_Common/SparkButton.vue'

export default {
  name: 'OrganisationInfra',
  components: {
    SparkButton,
  },
  methods: {
    contactSales() {
      console.log('Contact Sales clicked')
    },
  },
}
</script>

<style scoped>
.section-card {
  margin-bottom: 20px;
  background: #1c1b2e;
}
.nested-card {
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.config-area {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1;
}
.overlay-content {
  text-align: center;
}
</style>
