<template>
  <div class="more-app-bar" v-if="apps && apps.length > 0 && showMoreAppBar">
    <v-tab
      class="more-app-bar__tab"
      active-class="_active"
      v-for="app in apps"
      @click.stop="onAppClick(app)"
      :key="app.slug"
      :to="{
        name: 'site_plugin',
        params: { slug: app.slug || app.extensionId },
      }"
    >
      <v-tooltip top>
        <template #activator="{ on }">
          <img
            v-on="on"
            :src="app.icon"
            v-if="app.icon"
            class="extension-icon"
          />
          <span v-on="on" v-else>{{ app.label }}</span>
        </template>
        <span> {{ app.label }}</span>
      </v-tooltip>
    </v-tab>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import router from '@/router'

export default {
  name: 'MoreAppBar',
  computed: {
    ...mapState({
      apps: (state) => state.sites.apps,
      showMoreAppBar: (state) => state.sites.showMoreAppBar,
      currentSite: (state) => state.sites.active,
    }),
  },
  methods: {
    ...mapMutations('sites', ['setActiveApp', 'setApps', 'setShowMoreAppBar']),
    onAppClick(item) {
      if (item.component) {
        const slug = item.slug || item.extensionId
        let app = this.apps.find(
          (p) => p.slug === slug || p.extensionId === slug
        )

        this.setActiveApp(app)
        router.push({
          name: 'site_plugin',
          params: { slug: item.slug || item.extensionId },
        })
      } else if (item.onClick) this.$root.$emit('sendMessage', item)
    },
  },
}
</script>

<style lang="scss">
$style: more-app-bar;
.#{$style} {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 16px;
  margin-left: 24px;
  margin-right: 8px;
  margin-bottom: 12px;
  background: $bg-color;
  border-bottom: 1px solid $N7;
  &__tab {
    &.v-tab {
      padding: 12px;
      text-transform: initial;
      font-size: $H14;
      color: $N2;
      &._active {
        color: $white;
        border-bottom: 1px solid $B2;
      }
      .extension-icon {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
