<template>
  <div class="sdk-server-iframe-container">
    <iframe
      v-for="extension in extensions"
      :src="getExtensionURL(extension)"
      style="border: none"
      :key="getExtensionIdentifier(extension)"
      :id="getExtensionIdentifier(extension)"
      :ref="getExtensionIdentifier(extension)"
      width="300"
      height="300"
      @load="connectExtension(extension)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { safeJSONParse } from '@/utils/common'
import { initParse, authorize } from '@/utils/parse'

export default {
  name: 'SdkServer',
  computed: {
    ...mapState({
      user: (state) => state.user.current,
      activeSite: (state) => state.sites.active,
      userInstalledApps: (state) => state.publisher.userInstalledApps || [],
      siteInstalledApps: (state) => state.publisher.siteInstalledApps || [],
      SDKRoles: (state) => state.publisher.SDKRoles,
    }),
    extensions() {
      const apps = this.activeSite
        ? [...this.userInstalledApps, ...this.siteInstalledApps]
        : this.userInstalledApps
      return apps.filter((app) => app.developerApp)
    },
  },
  data() {
    return {
      extensionPoints: {},
    }
  },
  mounted() {
    window.addEventListener('message', this.receiveMessage)
    this.$root.$on('sendMessage', this.sendMessage)
  },
  beforeDestroy() {
    window.removeEventListener('message', this.receiveMessage)
    this.$root.$off('sendMessage', this.sendMessage)
  },
  methods: {
    // Communication between plugin iframe
    async receiveMessage(event) {
      if (event.data) {
        const { extensionId, sdkId } = event.data
        const extension = this.findExtension(event.origin, extensionId)
        // Checked the data is from registered extension
        if (extension) {
          const permission = this.getPermissionFromSdkId(sdkId)

          if (
            !permission ||
            this.isValidRequest(
              permission,
              event.data.kind,
              event.data.action
            ) === false
          )
            return
          const location = event.data.action
          switch (event.data.kind) {
            case 'action':
              this.$store.dispatch(
                event.data.action,
                ...(event.data.args || [])
              )
              return
            case 'mutation':
              this.$store.commit(event.data.action, ...(event.data.args || []))
              return
            case 'getter':
              if (this.$store.getters[event.data.action]) {
                let data
                if (event.data.args) {
                  if (Array.isArray(event.data.args))
                    data = this.$store.getters[event.data.action](
                      ...(event.data.args || [])
                    )
                  else
                    data = this.$store.getters[event.data.action](
                      event.data.args
                    )
                } else data = this.$store.getters[event.data.action]

                event.source.postMessage(
                  {
                    type: 'CALLBACK',
                    options: data,
                    callbackId: event.data.callbackId,
                  },
                  event.origin
                )
              }
              return
            case 'register':
              if (event.data.args && event.data.args[0]) {
                let arg = event.data.args[0]
                const param = safeJSONParse(extension.param)
                arg = {
                  ...arg,
                  param,
                  slug: extension.developerApp.slug,
                  icon: extension.developerApp.icon,
                  sandboxPermissions: extension.developerApp.sandboxPermissions,
                }
                this.extensionPoints[location] = [
                  ...new Set([...(this.extensionPoints[location] || []), arg]),
                ]
                this.$root.$emit('extensionPoints', this.extensionPoints)
              }
              return
            case 'picker':
              if (event.data.args && event.data.args[0]) {
                const siteId = this.$route?.params?.id
                const routeParams = {
                  kind: event.data.action,
                  siteId,
                  pluginSlug: this.$route?.params?.slug,
                  extensionId,
                }
                const arg = event.data.args[0]

                localStorage.setItem(
                  'integrationState',
                  JSON.stringify({ ...arg, ...routeParams })
                )
                if (
                  event.data.action === 'mural' ||
                  event.data.action === 'miro' ||
                  event.data.action === 'figma'
                ) {
                  if (!arg.parseAppId || !arg.parseServerURL) return
                  const { parseAppId, parseServerURL } = arg
                  initParse(parseServerURL, parseAppId)
                  const url = await authorize()

                  if (url) window.location.href = url
                }
                if (event.data.action === 'vulcan') {
                  this.$router.push(`/integration/vulcan`)
                }
              }
              return
            case 'state.get':
              if (event.data.args) {
                const extensionDataString = localStorage.getItem(extensionId)
                let extensionData = safeJSONParse(extensionDataString)
                const key = event.data.args[0]
                event.source.postMessage(
                  {
                    type: 'state.get',
                    callbackId: event.data.callbackId,
                    data: {
                      kind: event.data.kind,
                      id: event.data.id,
                      result: extensionData[key],
                    },
                  },
                  '*'
                )
              }
              break
            case 'state.set':
              if (event.data.args) {
                const [key, value] = event.data.args
                const extensionDataString = localStorage.getItem(extensionId)
                let extensionData = safeJSONParse(extensionDataString)
                extensionData[key] = value
                localStorage.setItem(extensionId, JSON.stringify(extensionData))

                event.source.postMessage(
                  {
                    type: 'state.set',
                    callbackId: event.data.callbackId,
                    data: {
                      kind: event.data.kind,
                      id: event.data.id,
                      result: extensionData,
                    },
                  },
                  event.origin
                )
              }
              break
            default:
              console.log('Received this event from child', event.data)
          }
        }
      } /* End of event.data */
    } /* end of receiveMessage */,

    sendMessage(extension) {
      const id = this.getExtensionIdentifier(extension)
      if (
        this.$refs[id] &&
        this.$refs[id][0] &&
        this.$refs[id][0].contentWindow
      )
        this.$refs[id][0].contentWindow.postMessage(
          {
            type: 'CALLBACK',
            options: extension,
            callbackId: extension.onClick,
          },
          '*'
        )
    },

    findExtension(eventOrigin, extensionId) {
      let extension = null

      if (this.extensions && this.extensions.length > 0) {
        extension = this.extensions.find((ex) => {
          const url = this.getExtensionURL(ex)
          return (
            (this.isAbsoluteURL(url) === false || url.includes(eventOrigin)) &&
            ex.id === extensionId
          )
        })
      }
      return extension
    },

    getExtensionURL(extension) {
      if (extension && extension.developerApp) {
        return extension.developerApp.url
      }
      return ''
    },

    isAbsoluteURL(urlString) {
      return (
        urlString.indexOf('http://') === 0 ||
        urlString.indexOf('https://') === 0
      )
    },

    getExtensionIdentifier(extension) {
      return extension.id
    },

    connectExtension(extension) {
      const id = this.getExtensionIdentifier(extension)
      if (
        this.$refs[id] &&
        this.$refs[id][0] &&
        this.$refs[id][0].contentWindow
      ) {
        const param = safeJSONParse(extension.param)
        this.$refs[id][0].contentWindow.postMessage(
          {
            type: 'CONNECT',
            src: this.getExtensionURL(extension),
            extensionId: id,
            initialArgs: {
              currentUser: this.user,
              activeSite: this.activeSite,
              param,
            },
          },
          '*'
        )
      }
    },

    getPermissionFromSdkId(sdkId) {
      if (!sdkId) return 'LEGACY' // LEGACY might not have sdkId in the request yet
      const firstSecuritySymbolIndex = 14
      const secondSecuritySymbolIndex = 19

      const firstLetter = sdkId[firstSecuritySymbolIndex]
      const secondLetter = sdkId[secondSecuritySymbolIndex]

      const role = this.SDKRoles.find((r) => {
        let identifier = r.securityPrefix || r.roleName
        identifier = identifier.toLowerCase()
        return (
          identifier &&
          identifier[0] === firstLetter &&
          identifier[identifier.length - 1] === secondLetter
        )
      })
      if (role && role.content) {
        const permission = safeJSONParse(role.content)
        return permission
      }
      // Legacy prefixes or namespace name 'forge'
      if (
        (firstLetter === '4' && secondLetter.toLowerCase() === 'y') ||
        (firstLetter === 'f' && secondLetter === 'e')
      )
        return 'LEGACY'
      return null
    },

    isValidRequest(permission, kind, action) {
      if (permission === 'LEGACY') return true
      if (kind === 'state.get')
        return permission.state && permission.state.includes('get')
      if (kind === 'state.set')
        return permission.state && permission.state.includes('set')

      if (permission[kind]) return permission[kind].includes(action)

      return false
    },
  },
  watch: {
    '$store.state.sites.active.id': {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.$root.$emit('extensionPoints', { siteTab: [] })
          this.extensionPoints = { siteTab: [] }
        }
      },
      immediate: true,
    },
  },
}
</script>

<style scoped>
.sdk-server-iframe-container {
  position: fixed;
  top: 50px;
  left: 120px;
  width: 300px;
  height: 300px;
  z-index: -10;
}
</style>
