<template>
  <div>
    <v-navigation-drawer
      class="SiteAppsPluginSettingsDrawer"
      :class="{
        settingsSidebar: true,
        isMobile: this.$vuetify.breakpoint.smAndDown,
      }"
      v-model="drawer"
      :input-value="drawer"
      @input="onDrawerChange"
      fixed
      temporary
      height="100%"
      :bottom="isMobile"
      :right="!isMobile"
      :width="widthDimensions"
    >
      <div class="SiteAppsPluginSettingsDrawer__header">
        <div class="d-flex">
          <h6>Edit Settings</h6>
        </div>
        <v-btn icon text color="n2" @click="onClose">
          <v-icon>close</v-icon>
        </v-btn>
      </div>
      <div class="SiteAppsPluginSettingsDrawer__body">
        <div v-if="loading || !activePlugin" class="spinner">
          <IntersectingCirclesSpinner
            :animation-duration="1200"
            :size="100"
            :color="'#496DDB'"
          />
        </div>
        <div v-if="activePlugin">
          <div class="d-flex mb-6">
            <img
              :src="activePlugin.developerContent.keyImage"
              class="key-image"
              v-if="
                activePlugin.developerContent &&
                activePlugin.developerContent.keyImage
              "
            />
            <div v-else class="key-image placeholder"></div>
            <div class="ml-4">
              <h3 class="mb-4">{{ activePlugin.name }}</h3>
              <div v-if="categoryNames">
                <span class="tag" v-for="categoryName in categoryNames">{{
                  categoryName
                }}</span>
              </div>
            </div>
          </div>
          <vue-form-generator
            tag="div"
            :schema="schema"
            :model="param"
            :options="formOptions"
          ></vue-form-generator>
        </div>
      </div>
      <v-footer
        class="SiteAppsPluginSettingsDrawer__footer"
        app
        v-if="!loading"
      >
        <div>
          <v-btn @click="save" color="orange" dark :disabled="!appInstance">
            Save changes
          </v-btn>
        </div>
      </v-footer>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import { safeJSONParse } from '@/utils/common'
import {
  initParse,
  getAppInstanceFromAppSlug,
  updateAppInstance,
} from '@/utils/parse'
import { MODAL_ALERT, MODAL_INFORMATION } from '@/components/Modals'

export default {
  name: 'SiteAppsPluginSettingsDrawer',
  components: {
    IntersectingCirclesSpinner,
  },
  props: {
    categories: Array,
  },
  data() {
    return {
      drawer: false,
      onValidationError: false,
      loading: false,
      schema: {
        fields: [],
      },
      formOptions: {
        validateAfterLoad: true,
        validateAfterChanged: true,
      },
      param: {},
      categoryNames: [],
      appInstance: null,
    }
  },

  computed: {
    ...mapState({
      activePlugin: (state) => state.sites.activePlugin,
      modalShowing: (state) => state.application.modalShowing,
    }),
    isMobile() {
      // detect the size of the screen, useful for showing navigation drawer bottom on Mobile
      return this.$vuetify.breakpoint.smAndDown
    },
    widthDimensions() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '100%'
      } else if (this.$vuetify.breakpoint.md) {
        return '40%'
      } else {
        return '30%'
      }
    },
  },
  async mounted() {
    await this.setupSettingsForm()
  },
  methods: {
    ...mapMutations('sites', ['setActivePlugin']),
    onDrawerChange(val) {
      // watch for drawer close to update store value accordingly
      if (val === false && this.onValidationError === false)
        this.setActivePlugin(null)
    },
    async setupSettingsForm() {
      if (this.activePlugin) {
        const installParams = safeJSONParse(
          this.activePlugin.developerData.installParams
        )
        let elements = [...(installParams || [])]

        elements = elements.map((element) => {
          let pattern = element?.pattern || ''
          // Remove the leading and trailing double quotes
          pattern = pattern.slice(1, -1)
          // Unescape the backslashes
          pattern = pattern.replace(/\\\\/g, '\\')
          return { ...element, validator: ['regexp'], pattern }
        })
        this.schema = {
          fields: elements,
        }

        const defaultParam = elements.reduce(
          (acc, input) => ({ ...acc, [input.model]: input.default || '' }),
          {}
        )
        const publisher = this.$store.getters['publisher/getPublisherByAppId'](
          this.activePlugin.id
        )
        this.appInstance = await getAppInstanceFromAppSlug({
          siteId: this.activeSite.id,
          appSlug: this.activePlugin.slug,
          parseServerSiteId: publisher?.parse_server_site_id,
        })
        if (this.appInstance) {
          const param = safeJSONParse(this.appInstance.param)
          this.param = { ...defaultParam, ...param }
        }
        this.categoryNames = this.getCategoryNames()
      }
    },
    getCategoryNames() {
      if (this.categories && this.activePlugin?.developerContent?.categories) {
        const categoriesIds =
          this.activePlugin?.developerContent?.categories.map(({ id }) => id)
        return this.categories
          .filter((category) => categoriesIds.includes(category.value))
          .map((category) => category.text)
      }
      return []
    },
    async save() {
      const installParams = safeJSONParse(
        this.activePlugin.developerData.installParams
      )
      const elements = [...(installParams || [])]
      if (elements.length > 0) {
        for (const field of elements) {
          if (field.pattern) {
            const value = this.param[field.model]
            // const regex = new RegExp(field.pattern, 'i');
            const regex = new RegExp(
              '^(https?:\\/\\/)?[a-z0-9]+\\.getforge\\.com(\\/[^\s]*)?$',
              'i'
            )

            if (!regex.test(value)) {
              alert(`Invalid ${field.label}.`)
              return
            }
            return
          }
        }
      }
      // this.onValidationError = false;
      this.loading = true
      const publisher = this.$store.getters['publisher/getPublisherByAppId'](
        this.activePlugin.id
      )
      await updateAppInstance(
        publisher?.parse_server_site_id,
        this.appInstance.id,
        JSON.stringify(this.param)
      )
      this.$store.commit('application/openModal', {
        component: MODAL_INFORMATION,
        props: {
          title: 'Successfully updated the plugin settings.',
          showCancelBtn: false,
          actionBtnLabel: 'OK',
          action: () => {
            location.reload()
          },
        },
      })
      this.loading = false
    },
    onClose() {
      this.setActivePlugin(null)
    },
  },
  watch: {
    activePlugin: {
      handler(newVal, oldVal) {
        this.drawer = !!newVal
        this.setupSettingsForm()
      },
      immediate: true,
    },
  },
}
</script>
<style scoped lang="scss">
$style: SiteAppsPluginSettingsDrawer;
.#{$style} {
  background: $N5;
  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid $N6;
    h6 {
      font-size: 16px;
      font-weight: normal;
    }
  }
  &__body {
    padding: 16px 28px;
    height: calc(100vh - 160px);
    overflow-y: auto;
    .key-image {
      max-height: 64px;
      width: auto;
      border-radius: 4px;
    }
    .key-image.placeholder {
      background: green;
      width: 64px;
      height: 64px;
    }
    span.tag {
      padding: 5px 8px;
      font-size: 12px;
      font-weight: 400;
      border: 1px solid $N3;
      border-radius: 6px;
      margin-right: 8px;
    }
  }
  &__footer {
    padding: 0px;
    > div {
      border-top: 1px solid $N6;
      background: $N5;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 20px;
      width: 100%;
    }
  }
}
</style>
<style lang="scss">
.SiteAppsPluginSettingsDrawer .form-group label {
  display: block;
  margin-bottom: 6px;
  color: $N2;
}

.app .SiteAppsPluginSettingsDrawer .vue-form-generator .form-control {
  border: 1px solid $N3;
  background: transparent;
  border-radius: 8px;
}
</style>
