<template>
  <div class="narrow-container mx-2" v-if="!loading">
    <div class="members_table mx-4 mt-6">
      <v-data-table
        :headers="headers"
        :items="productionSites"
        :sort_by="sortBy"
        class="ProjectSites__members_table"
      >
        <template v-slot:top>
          <v-toolbar flat class="ProjectSites__toolbar">
            <v-toolbar-title>{{ project.name }} Sites</v-toolbar-title>
          </v-toolbar>
        </template>

        <template v-slot:item.site_name="{ item }">
          <span @click="openSite(item.id)" class="ProjectSites__open_site">
            <v-icon
              small
              class="mr-2"
              :color="item.favicon_url ? 'white' : 'grey'"
            >
              mdi-web
            </v-icon>
            {{ item.site_name }}
          </span>
        </template>

        <template v-slot:item.link="{ item }">
          <span @click="openSiteUrl(item.url)" class="ProjectSites__open_site">
            {{ item.use_ssl ? 'https://' : 'http://' }}{{ item.url }}
            <v-icon small class="ml-1">mdi-open-in-new</v-icon>
          </span>
        </template>

        <template v-slot:item.currentVersionStatus="{ item }">
          <v-chip
            :color="getStatusColor(item.currentVersionStatus)"
            small
            class="text-capitalize"
            text-color="white"
          >
            {{ item.currentVersionStatus }}
          </v-chip>
        </template>
      </v-data-table>
    </div>
    <FirstTimeWizard
      v-if="showWizard"
      @complete="onWizardComplete"
      v-model="showWizard"
    />
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import FirstTimeWizard from '@/components/Wizards/FirstTimeWizard.vue'
import _ from 'lodash'

export default {
  name: 'ProjectSites',
  components: {
    FirstTimeWizard,
  },
  data() {
    return {
      loading: false,
      showWizard: false,
      sortBy: [{ key: 'site_name', order: 'asc' }],
      headers: [
        {
          text: 'NAME',
          value: 'site_name',
          width: '20%',
          sortable: true,
        },
        {
          text: 'URL',
          value: 'link',
          width: '35%',
          sortable: false,
        },
        {
          text: 'STATUS',
          value: 'currentVersionStatus',
          width: '100px',
          sortable: true,
        },
        {
          text: 'SSL',
          value: 'use_ssl',
          width: '60px',
          align: 'center',
          sortable: true,
        },
        {
          text: 'DEPLOYS',
          value: 'deploy_count',
          width: '80px',
          align: 'center',
          sortable: true,
        },
      ],
      arrayObject: [],
    }
  },
  methods: {
    ...mapActions({
      fetchProjectSites: 'sites/loadSitesList',
    }),
    async fetchSites() {
      this.loading = true
      try {
        await this.fetchProjectSites(this.project.id)
        this.arrayObject = _.cloneDeep(
          this.$store.state.projects.active.allObjects
        ).filter((s) => s != undefined)

        // Show wizard if there are no sites at all
        const hasNoSites = this.arrayObject.length === 0

        if (hasNoSites) {
          this.showWizard = true
        }
      } catch (error) {
        console.error('Error fetching sites:', error)
      }
      this.loading = false
    },
    openSiteUrl(url) {
      window.open('http://' + url, '_blank')
    },
    openSite(id) {
      this.$router.push('/site/' + id)
    },
    onWizardComplete() {
      this.showWizard = false
      this.fetchSites()
    },
    getStatusColor(status) {
      const colors = {
        deployed: 'success',
        deploying: 'warning',
        failed: 'error',
        pending: 'grey',
      }
      return colors[status] || 'grey'
    },
  },
  computed: {
    ...mapState({
      project: ({ projects }) => projects.active,
    }),
    productionSites() {
      if (this.arrayObject.length > 0)
        return this.arrayObject.filter((s) => s.mode == 'production')
      else return []
    },
  },
  created() {
    // Show wizard in two cases:
    // 1. Coming directly from project creation
    // 2. Loading an existing project with no sites
    if (this.$route.query.newProject) {
      this.showWizard = true
    }
    this.fetchSites()
  },
  watch: {
    '$store.state.projects.active.id': {
      handler() {
        this.fetchSites()
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
$style: ProjectSites;
.#{$style} {
  &__members_table {
    background: $N4 !important;
    border: 1px solid $N5;

    .v-data-table-header {
      background-color: $N4 !important;
    }
  }

  &__toolbar {
    background-color: $N4 !important;

    .v-toolbar__title {
      color: $N2;
      font-size: 16px;
      font-weight: 500;
    }
  }

  &__open_site {
    cursor: pointer;
    color: $N2;
    display: flex;
    align-items: center;

    &:hover {
      color: $Y1;
    }
  }
}
</style>
