<template>
  <div
    class="SiteVersionComment__commentContainer"
    :id="'comment_container_' + comment.id"
    @mouseover="changeViewedState"
    @mouseleave="removeActiveClass"
    :class="{ 'ai-response': comment.is_ai_response }"
  >
    <div class="SiteVersionComment__commentHeader">
      <div class="SiteVersionComment__commentProfile">
        <avatar
          v-if="avatarEmpty()"
          :rounded="true"
          class="SiteVersionComment__avatar"
          :initials="initials()"
          username="userName"
          :size="32"
        />
        <img
          class="SiteVersionComment__avatar"
          :src="comment.userAvatar"
          v-else
        />

        <div class="SiteVersionComment__profile_name" style="margin-left: 8px">
          {{ comment.userName }}
        </div>
        <small class="comments_timestamps"> {{ comment.createdAt }} </small>
        <div class="comment_new" v-if="!comment.viewed"></div>
        <v-icon
          class="comments_remove"
          v-if="siteOrCommentOwner"
          @click="deleteComment"
        >
          mdi-delete-outline
        </v-icon>
        <div class="version_scoped">Version #{{ comment.versionScopedId }}</div>
      </div>
    </div>
    <div class="SiteVersionComment__commentContents">
      <div>
        <div
          :ref="'commentRef' + comment.id"
          :class="{ show_all_text: showMore }"
          class="SiteVersionComment__commentMessage"
          :id="'comment_message_' + comment.id"
          v-html="formattedMessage"
        ></div>
        <v-btn
          class="more_btn"
          plain
          color="y1"
          @click="showMore = !showMore"
          v-if="showMoreEnabled"
        >
          {{ showMoreText }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar'
import MarkdownIt from 'markdown-it'
import DOMPurify from 'dompurify'

const md = new MarkdownIt({
  html: true,
  linkify: true,
  typographer: true,
  breaks: true,
  highlight: function (str, lang) {
    // console.log('Highlighting code block:', { str, lang });
    return `<pre class="language-${lang}"><code>${str}</code></pre>`
  },
})

export default {
  name: 'SiteVersionComment',
  components: { Avatar },
  props: {
    comment: Object,
    version: Object,
  },
  data() {
    return {
      isMounted: false,
      showMore: false,
      showMoreEnabled: false,
    }
  },
  computed: {
    setShowMoreEnabled() {
      if (
        !this.isMounted ||
        this.$refs['commentRef' + this.comment.id] == undefined
      )
        return
      const el = this.$refs['commentRef' + this.comment.id]
      this.showMoreEnabled = el.scrollHeight > 70
    },
    showMoreText() {
      if (this.showMore) return 'Show Less'
      else return 'Show All'
    },
    siteOrCommentOwner() {
      const currentUserId = this.$store.state.user.current.id
      const objectOwner = this.comment.siteOwnerId
      const commentOwner = this.comment.userId
      return [objectOwner, commentOwner].includes(currentUserId)
    },
    formattedMessage() {
      if (this.comment.is_ai_response) {
        try {
          let message = this.comment.message

          // console.log('Original message:', message);

          if (typeof message === 'string' && message.includes('"content":')) {
            const parsed = JSON.parse(message)
            message = parsed.content
          }

          message = message
            .replace(/\\n/g, '\n')
            .replace(/\\"/g, '"')
            .replace(/\\u003c/g, '<')
            .replace(/\\u003e/g, '>')
            .replace(/\$0/g, '')
            .replace(/== \$0/g, '')

          // console.log('Processed message:', message);

          const rawHtml = md.render(message)

          // console.log('Final HTML:', rawHtml);

          return DOMPurify.sanitize(rawHtml, {
            ALLOWED_TAGS: [
              'p',
              'br',
              'strong',
              'em',
              'code',
              'pre',
              'ol',
              'ul',
              'li',
              'blockquote',
              'a',
              'h1',
              'h2',
              'h3',
              'h4',
              'h5',
              'h6',
            ],
            ALLOWED_ATTR: ['class', 'href', 'target'],
          })
        } catch (error) {
          console.error('Error formatting message:', error)
          return this.comment.message
        }
      }
      return this.comment.message
    },
  },
  methods: {
    avatarEmpty() {
      return (
        this.comment.userAvatar == null || this.comment.userAvatar.length == 0
      )
    },
    changeViewedState() {
      if (this.comment.viewed) return
      this.$store.dispatch('comments/updateViewState', this.comment.id)
    },
    initials() {
      this.comment.userName[0].toUpperCase()
    },
    deleteComment() {
      this.$store.dispatch('comments/delete', this.comment.id)
    },
    removeActiveClass() {
      const element = document.getElementById(
        'comment_container_' + this.comment.id
      )
      if (!element) return
      element.classList.remove('_active')
    },
  },
  mounted() {
    this.isMounted = true
    this.setShowMoreEnabled
  },
  updated() {
    this.isMounted = true
    this.setShowMoreEnabled
  },
}
</script>

<style lang="scss" scoped>
$style: SiteVersionComment;
::v-deep .mention {
  color: $Y1;
}

::v-deep .#{$style}__commentMessage p {
  margin-bottom: 0px;
}

.#{$style} {
  &__commentContainer {
    padding-top: 15px;
    padding-bottom: 16px;
    padding-left: 12px;
    padding-right: 12px;
    border-bottom: 1px solid $N7;
    width: 100%;
    align-self: flex-end;

    &:hover,
    &._active {
      border-radius: 8px;
      background: $N7;
    }

    .show_all_text {
      overflow: visible !important;
      max-height: unset !important;
    }

    .more_btn {
      margin-left: 24px;
      margin-top: 5px;
    }
    .comment_new {
      float: right;
      width: 10px;
      height: 10px;
      border-radius: 8px;
      background: red;
    }

    &:hover {
      .comments_remove {
        display: block;
        cursor: pointer;
        color: $N2;
      }
    }

    &.ai-response {
      background: rgba(73, 109, 219, 0.1);
      border-left: 3px solid $Y1;

      .#{$style}__profile_name {
        color: $Y1;
      }
    }
  }

  &__avatar {
    display: inline-block !important;
    width: 32px;
    height: 32px;
    border-radius: 8px;
    display: inline-block;
    vertical-align: middle;
  }

  &__commentMessage {
    margin-left: 41px;
    margin-top: 8px;
    color: $N2;
    max-height: 70px;
    overflow: hidden;

    // Markdown styles
    h1 {
      font-size: 1.5em;
      margin: 0.5em 0;
      color: $N1;
    }

    h2 {
      font-size: 1.3em;
      margin: 0.5em 0;
      color: $N1;
    }

    h3 {
      font-size: 1.1em;
      margin: 0.5em 0;
      color: $N1;
    }

    p {
      margin: 0.5em 0;
    }

    code {
      background: $N7;
      padding: 0.2em 0.4em;
      border-radius: 3px;
      font-family: monospace;
      font-size: 0.9em;
    }

    pre {
      background: $N7;
      padding: 1em;
      border-radius: 4px;
      overflow-x: auto;
      margin: 0.5em 0;

      code {
        background: none;
        padding: 0;
        color: $N2;
        font-family:
          'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro',
          monospace;
        font-size: 13px;
        line-height: 1.4;
        white-space: pre-wrap;
        word-break: break-all;
        word-wrap: break-word;
      }
    }

    blockquote {
      border-left: 3px solid $Y1;
      margin: 0.5em 0;
      padding-left: 1em;
      color: $N3;
    }

    ul,
    ol {
      margin: 0.5em 0;
      padding-left: 1.5em;
    }

    a {
      color: $Y1;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    img {
      max-width: 100%;
      height: auto;
    }

    strong {
      color: $N1;
    }

    em {
      color: $N2;
    }
  }

  &__commentProfile {
    .comments_timestamps {
      color: $N2;
      margin-left: 10px;
      font-size: 12px;
    }
    .version_scoped {
      margin-left: 41px;
      color: $N8;
      font-size: 12px;
    }
    .comments_remove {
      display: none;
      float: right;
    }
  }

  &__profile_name {
    color: $N2;
    font-weight: 500;
    font-size: 14px;
    display: inline-block;
  }
}
</style>
