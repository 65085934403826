<template>
  <v-container class="ProjectMain__container">
    <div class="narrow-container settings-form">
      <div class="ProjectMain__form_area mt-12">
        <div class="area_header ml-7">Project Details</div>

        <v-card class="pa-4 mt-4 details_card">
          <div class="d-inline-block icon_picker_block">
            <IconPicker
              :selected-icon="icon"
              :selected-color="iconColor"
              v-on:picked-icon="iconChanged($event)"
            />
          </div>

          <div class="d-inline-block project_block">
            <div class="project_header">Project Name</div>
            <v-text-field
              v-model="name"
              class="mt-1 project_name_field"
              hide-details="auto"
              autofocus
              filled
              dense
            />
            <div v-if="errorMain" class="error-main mt-4 pa-4">
              {{ errorMain }}
            </div>
          </div>

          <div class="color_block">
            <v-divider />
            <div class="color_text">
              <div class="color_label">Icon Color</div>

              <div class="right_color_block" align="right">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      v-on="on"
                      v-bind="attrs"
                      class="color_dot default_color"
                      @click="setDefaultIconColor"
                    ></div>
                  </template>
                  <span> Default Color</span>
                </v-tooltip>

                <v-menu :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <div
                      class="color_dot"
                      :style="dynamicColorStyle"
                      v-on="on"
                      v-attrs="attrs"
                    ></div>
                  </template>
                  <v-color-picker v-model="iconColor" />
                </v-menu>
              </div>
            </div>
            <v-divider />
          </div>

          <div class="mt-7" align="right" v-if="isOwner">
            <v-btn
              @click="save"
              :disabled="!dirtyForm"
              class="mr-4"
              color="orange"
              dark
            >
              Save changes
            </v-btn>
          </div>
        </v-card>
      </div>
      <div class="ProjectMain__form_area mt-4" v-if="isOwner">
        <div class="area_header ml-7">Danger Zone</div>
        <v-card class="details_card" flat>
          <v-card-title
            class="mt-6 mx-3"
            :style="{ 'font-size': '16px' }"
            align="left"
            >Delete Project</v-card-title
          >
          <v-container class="pa-4">
            <v-divider class="ml-2"></v-divider>
            <v-card-text class="px-2 card_text">
              Once you delete your Project, this cannot be undone. Please be
              careful.
            </v-card-text>
            <v-card-actions>
              <v-btn @click="removeProject" color="red" dark>
                Delete Project
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { MODAL_ALERT } from '@/components/Modals'
import IconPicker from '@/components/elements/IconPicker'
export default {
  name: 'ProjectMain',
  components: { IconPicker },
  data() {
    return {
      name: this.$store.state.projects.active.name,
      errorMain: '',
      icon: this.$store.state.projects.active.icon,
      iconColor: this.$store.state.projects.active.iconColor,
      showColorPicker: false,
    }
  },
  methods: {
    save() {
      const project_params = {
        id: this.$store.state.projects.active.id,
        project: {
          name: this.name,
          icon: this.icon,
          icon_color: this.iconColor,
        },
      }
      this.$store
        .dispatch('projects/update', project_params)
        .then(() => {
          this.errorMain = ''
        })
        .catch((e) => {
          this.errorMain = 'Project Name should be unique'
        })
    },
    setDefaultIconColor() {
      this.iconColor = '#a4a4ae'
    },
    iconChanged(icon) {
      this.icon = icon
    },
    changedColor(c) {
      console.log(c)
    },

    removeProject() {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Project deletion',
          text: 'Your project and all sites of this project will be deleted. Type project Name to delete it:',
          confirmString: this.$store.state.projects.active.name,
          actionBtnLabel: 'Delete',
          showCancelBtn: true,
          action: () => {
            this.$store.dispatch(
              'projects/delete',
              this.$store.state.projects.active.id
            )
          },
        },
      })
    },
  },
  computed: {
    dirtyForm() {
      const nameChanged = this.name != this.$store.state.projects.active.name
      const iconChanged = this.icon != this.$store.state.projects.active.icon
      const iconColorChanged =
        this.iconColor != this.$store.state.projects.active.iconColor
      return nameChanged || iconChanged || iconColorChanged
    },
    dynamicColorStyle() {
      return {
        background: this.iconColor,
      }
    },
    isOwner() {
      let access
      if (this.$store.state.organisations.current.id == 0)
        access =
          this.$store.state.projects.active.user_id ==
          this.$store.state.user.current.id
      else access = this.$store.state.organisations.current.role == 'admin'
      return access
    },
  },
  watch: {
    $route(to, from) {
      this.name = this.$store.state.projects.active.name
    },
  },
}
</script>

<style lang="scss" scoped>
.error-main {
  background: #e94a53;
  border-radius: 2px;
}
$style: ProjectMain;
.#{$style} {
  &__container {
    max-width: 820px;
  }
  &__form_area {
    padding: 32px;
    border-radius: 8px !important;
    border: 1px solid $N5;
    background: $N4;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .area_header {
      color: white;
      font-feature-settings:
        'clig' off,
        'liga' off;
      font-family: 'Instrument Sans', sans-serif;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
    }
    .details_card {
      background: inherit;
      box-shadow: none;
      .card_text {
        color: $N2;
      }
    }
    .icon_picker_block {
      vertical-align: top;
      padding-top: 12px;
    }
    .project_block {
      width: calc(100% - 84px);
      .project_header {
        color: $N2;
      }
    }
    .color_block {
      margin-left: 12px;
      .right_color_block {
        display: inline-block;
        width: calc(100% - 80px);
        vertical-align: text-top;
      }
      .color_label {
        display: inline-block;
      }
      .color_text {
        padding-top: 20px;
        padding-bottom: 20px;
        font-family: 'Instrument Sans', sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        color: $N2;
      }
      .color_dot {
        width: 20px;
        min-width: 20px;
        min-height: 20px;
        padding: 0;
        margin: 0;
        height: 20px;
        cursor: pointer;
        border-radius: 50%;
        display: inline-block;
        margin-right: 10px;
        &.default_color {
          background: $N2;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
