<template>
  <div class="DashboardFeatured">
    <div class="TypeformFeatured">
      <div
        id="wrapper"
        :data-tf-widget="typeformId"
        data-tf-inline-on-mobile
        data-tf-medium="snippet"
      ></div>
    </div>
    <v-layout
      row
      wrap
      class="DashboardFeatured__row"
      v-if="appTemplatesEnabled"
    >
      <v-flex
        xs4
        v-for="image in 5"
        :key="image"
        class="DashboardFeatured__col"
      >
        <div class="DashboardFeatured__image-wrapper">
          <img
            :src="`https://loremflickr.com/320/240?random=${image}`"
            alt=""
          />
        </div>
      </v-flex>
      <v-flex xs4 class="DashboardFeatured__col DashboardFeatured__button_area">
        <button
          type="button"
          class="DashboardFeatured__btn"
          @click="$emit('on-add-template-click')"
        >
          <v-icon color="n2">mdi-plus</v-icon>
        </button>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import { APP_TEMPLATES_ENABLED, APP_TEMPLATES_FORM_ID } from '@/env'

export default {
  name: 'DashboardFeatured',
  mounted() {
    let typeformScript = document.createElement('script')
    typeformScript.setAttribute('src', '//embed.typeform.com/next/embed.js')
    document.head.appendChild(typeformScript)
  },
  data() {
    return {
      appTemplatesEnabled: APP_TEMPLATES_ENABLED,
      typeformId: APP_TEMPLATES_FORM_ID,
    }
  },
}
</script>
<style lang="scss">
.TypeformFeatured {
  height: 400px;

  #wrapper {
    height: 100%;
  }
}

$style: DashboardFeatured;
.#{$style} {
  &__row {
    margin: -6px;
  }
  &__col {
    padding: 6px;
  }
  &__image-wrapper {
    position: relative;
    padding-top: 74.7%;
    border-radius: 8px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
    }
  }
  &__btn {
    width: 100%;
    height: 100%;
    color: $white;
    border: 1px dashed $N3;
    background-color: $N5;
    border-radius: 8px;
    transition: background-color 0.2s ease;
    &:hover {
      background-color: lighten($color: $N5, $amount: 10%);
    }
  }
}
</style>
