<template>
  <div class="MuralPicker">
    <v-row>
      <v-col cols="6">
        <span class="ModalCardData__label">Workspace</span>
        <div class="workspace-list">
          <v-autocomplete
            v-model="selectedWorkspace"
            :items="workspaces"
            placeholder="Find a workspace..."
            @input="onWorkspaceSelect"
            item-text="name"
            item-value="id"
            outlined
            hide-details
            dense
          ></v-autocomplete>
        </div>
      </v-col>
      <v-col cols="6">
        <span class="ModalCardData__label">Room</span>
        <v-autocomplete
          v-model="selectedRoom"
          :items="rooms"
          placeholder="Find a room..."
          :disabled="!selectedWorkspace"
          @input="onRoomSelect"
          item-text="name"
          item-value="id"
          outlined
          hide-details
          dense
        ></v-autocomplete>
      </v-col>
    </v-row>
    <v-alert
      v-if="error"
      :value="true"
      severity="error"
      class="mural-picker-error"
    >
      {{ error }}
    </v-alert>
    <mural-list
      v-if="selectedWorkspace && selectedRoom"
      :murals="murals"
      :onMuralSelect="onMuralSelect"
    ></mural-list>
  </div>
</template>

<script>
import Parse from 'parse'
import MuralList from './MuralList'
export default {
  props: {
    token: String,
    onMuralSelect: Function,
  },
  components: {
    MuralList,
  },
  data() {
    return {
      selectedWorkspace: null,
      selectedRoom: null,
      workspaces: [],
      rooms: [],
      murals: [],
      error: '',
    }
  },
  created() {
    this.onInitLoad()
  },
  methods: {
    async onInitLoad() {
      try {
        const res = await Parse.Cloud.run('getAllWorkspaces', {
          token: this.token,
        })
        if (res.status === 'success') {
          this.workspaces = res?.result?.value
          this.murals = []
          this.isLoading = false
        }
      } catch (e) {
        console.error('Error retrieving workspaces.', e)
      }
    },
    async onWorkspaceSelect(workspace) {
      if (!workspace) {
        // clear selections
        this.selectedWorkspace = null
        this.murals = []
        this.rooms = []
        this.roomId = ''
        return
      }

      try {
        const res = await Parse.Cloud.run('getAllRooms', {
          token: this.token,
          workspaceId: workspace,
        })
        if (res.status === 'success') {
          this.rooms = res.result.value
          this.murals = []
          this.roomId = ''
          this.room = null
        }
      } catch (e) {
        console.error('Error retrieving mural rooms.', e)
      }
    },
    async onRoomSelect(room) {
      if (!this.selectedWorkspace || !room) {
        this.room = null
        this.murals = []
        return
      }
      try {
        const res = await Parse.Cloud.run('getMuralsByRoom', {
          token: this.token,
          workspaceId: this.selectedWorkspace,
          roomId: room,
        })

        if (res && res.status === 'success' && res.result?.value) {
          const murals = res.result.value.map((item) => {
            if (item) {
              const link = item.sharingSettings?.link
              return { ...(item || {}), link }
            }
          })
          this.murals = murals
        }
      } catch (e) {
        console.error('Error retrieving room murals.', e)
      }
    },
    getRoomGroup(room) {
      if (!room) return ''
      return room.type === 'private' ? 'PRIVATE ROOMS' : 'OPEN ROOMS'
    },
  },
}
</script>
