<template>
  <div>
    <div class="inner-app pa-2">
      <router-link
        :to="{ name: 'site_apps' }"
        class="app-title-left text-white d-flex align-center justify-start"
      >
        <v-icon left class="text-white">mdi-arrow-left</v-icon>
        Back
      </router-link>
      <h2 class="text-white text-center my-0">{{ appName }}</h2>
    </div>
    <div v-if="loading" class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
    <div class="narrow-container" v-else>
      <vue-form-generator
        tag="div"
        :schema="schema"
        :model="param"
      ></vue-form-generator>
      <v-btn @click="save" class="mt-7" color="orange" dark>
        Save changes
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import { safeJSONParse } from '@/utils/common'
import { getAppInstanceFromAppSlug, updateAppInstance } from '@/utils/parse'
const defaultParam = {
  component: '',
}
export default {
  name: 'SitePluginEdit',
  components: { IntersectingCirclesSpinner },
  data() {
    return {
      appInstance: null,
      loading: true,
      appName: '',
      schema: {
        fields: [],
      },
      optionModel: {},
      param: {
        component: '',
      },
    }
  },
  async mounted() {
    this.loading = true
    const { slug: appSlug, id: siteId } = this.$route.params
    this.appInstance = await getAppInstanceFromAppSlug({
      siteId,
      appSlug,
      parseServerSiteId: this.publisher?.parse_server_site_id,
    })
    if (this.appInstance) {
      if (this.appInstance.developerApp) {
        this.appName = this.appInstance.developerApp.name
        this.schema.fields = safeJSONParse(
          this.appInstance.developerApp.installParams
        )
      }
      const param = safeJSONParse(this.appInstance.param)
      this.param = { ...defaultParam, ...param }
    }
    this.loading = false
  },
  computed: {
    ...mapState({
      allSitePluginApps: (state) => state.publisher.allSiteApps,
      siteAppInstances: (state) => state.publisher.siteInstalledApps,
      user: (state) => state.user.current,
      publisher: (state) => state.publisher.activePublisher,
    }),
  },
  methods: {
    async save() {
      this.loading = true
      const instanceDetail = await updateAppInstance(
        this.publisher?.parse_server_site_id,
        this.appInstance.id,
        JSON.stringify(this.param)
      )
      this.loading = false
      location.reload()
    },
  },
}
</script>

<style lang="scss" scoped>
h2 {
  color: #496ddb;
}

.text-white {
  color: #cacaca;
}

.dark-background {
  background: #1f2024;

  h2 {
    color: #cacaca;
  }
}

.app-title-left {
  opacity: 0.8;
  text-decoration: none;
}

.app-title-left:hover {
  opacity: 1;
}

.inner-app {
  margin-right: auto;
  margin-left: 40px;
  position: relative;
}

.narrow-container {
  // padding: 42px 24px 24px;
  max-width: 800px;
  margin: auto;
}
.note {
  background: #fcf4d4;
  color: #9b7406;
  border: 1px solid #f7e9ab;
  border-radius: 3px;
  font-weight: 500;
}
.no-edit-panel {
  width: 100%;
  height: 100%;
  text-align: center;
  padding-top: 200px;
  font-size: 20px;
}
.spinner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
}
</style>
