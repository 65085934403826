<template>
  <div class="ProfileSettings">
    <div class="ProfileSettings__row">
      <div class="ProfileSettings__section">
        <h2 class="ProfileSettings__title">My account</h2>

        <div align="center" v-if="!emptyAvatar">
          <img :src="avatar" alt="" @click="chooseImage" class="img_logo" />
        </div>
        <div class="" align="center" @click="chooseImage" v-else>
          <avatar
            class="img_logo"
            :rounded="true"
            :initials="initials"
            username="name"
            :size="160"
            :customStyle="{ display: 'inline-block' }"
          />
        </div>
        <input
          @change="handleImage"
          class=""
          id="userAvatarUpload"
          type="file"
          accept="image/*"
          style="display: none"
        />
        <label class="ProfileSettings__label mt-2">Username</label>
        <v-text-field
          v-model="name"
          @input="onInputUsername"
          :error-messages="errorVisibleUsername"
          class="ProfileSettings__input"
          hide-details="auto"
          filled
          dark
          dense
        />

        <label class="ProfileSettings__label">Email</label>
        <v-text-field
          v-model="email"
          @input="onInputEmail"
          :error-messages="errorVisibleEmail"
          class="ProfileSettings__input"
          hide-details="auto"
          filled
          readonly
          autocomplete="off"
          dark
          dense
        />

        <label class="ProfileSettings__label"> Current password</label>
        <v-text-field
          v-model="currentPassword"
          @input="onInputCurrentPassword"
          :error-messages="errorVisibleCurrentPassword"
          :type="showingCurrentPassword ? 'text' : 'password'"
          class="ProfileSettings__input"
          hide-details="auto"
          filled
          dense
          autocomplete="off"
          dark
        >
          <template v-slot:append>
            <v-btn
              @click="showingCurrentPassword = !showingCurrentPassword"
              icon
              small
            >
              <v-icon>{{
                showingCurrentPassword
                  ? 'mdi-eye-off-outline'
                  : 'mdi-eye-outline'
              }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <label class="ProfileSettings__label">New password</label>
        <v-text-field
          v-model="password"
          @input="onInputPassword"
          :error-messages="errorVisiblePassword"
          :type="showingPassword ? 'text' : 'password'"
          class="ProfileSettings__input"
          hide-details="auto"
          filled
          dense
          autocomplete="off"
          dark
        >
          <template v-slot:append>
            <v-btn @click="showingPassword = !showingPassword" icon small>
              <v-icon>{{
                showingPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
              }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <label class="ProfileSettings__label">Confirm new password</label>
        <v-text-field
          v-model="passwordConfirm"
          @input="onInputPasswordConfirm"
          :error-messages="errorVisiblePasswordConfirm"
          :type="showingPasswordConf ? 'text' : 'password'"
          class="ProfileSettings__input"
          hide-details="auto"
          filled
          dense
          dark
        >
          <template v-slot:append>
            <v-btn
              @click="showingPasswordConf = !showingPasswordConf"
              icon
              small
            >
              <v-icon>{{
                showingPasswordConf ? 'mdi-eye-off-outline' : 'mdi-eye-outline'
              }}</v-icon>
            </v-btn>
          </template>
        </v-text-field>

        <div v-if="errorMain" class="error-main pa-4 mb-4">
          {{ errorMain }}
        </div>

        <v-btn @click="save" :disabled="!dirtyForm" color="y1" dark>
          Save
        </v-btn>

        <div class="reset_password_block mt-2">
          Forgot your password?
          <span class="reset_password_btn" @click="resetPassword">
            Reset your password</span
          >
        </div>
      </div>

      <div class="ProfileSettings__section right_column">
        <div class="account_token">
          <h2 class="ProfileSettings__title">Account token</h2>

          <p class="ProfileSettings__text">
            You can use thise Personal Access Token to access the
            <a
              class="btn_text"
              target="_blank"
              href="https://docs.getforge.com/docs/rest-api-v2#account-plan-api"
              >Account API</a
            >.
          </p>

          <v-text-field
            v-model="authentication_token"
            readonly
            :type="showingAccountToken ? 'text' : 'password'"
            class="ProfileSettings__input"
            filled
            dense
            dark
            hide-details
          >
            <template v-slot:append>
              <v-btn
                class="mr-2"
                @click="showingAccountToken = !showingAccountToken"
                icon
                small
              >
                <v-icon>{{
                  showingAccountToken
                    ? 'mdi-eye-off-outline'
                    : 'mdi-eye-outline'
                }}</v-icon>
              </v-btn>

              <v-btn @click="regenToken" small color="y1"> Regen token </v-btn>
            </template>
          </v-text-field>
        </div>

        <div class="notifications_block mt-4">
          <h2 class="ProfileSettings__title">Notifications settings</h2>

          <p class="ProfileSettings__text">
            Select the kinds of notifications you get from activities here.
          </p>

          <div
            v-for="profileKey in profileConfigKeys"
            :key="profileKey.key"
            class="ProfileSettings__config_block d-flex align-center"
          >
            <div
              class="config_checkbox"
              :class="{
                active: profileConfig[profileKey.key],
                simple: !profileConfig[profileKey.key],
              }"
              @click="updateProfileConfig(profileKey)"
            >
              <v-icon
                v-if="profileConfig[profileKey.key]"
                class="config_checkbox_mark"
              >
                mdi-check
              </v-icon>
            </div>
            <div class="profile_config_labels">
              <div class="profile_config_label">{{ profileKey.label }}</div>
              <div class="ProfileSettings__text">{{ profileKey.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="ProfileSettings__section" v-if="connectedToSource">
      <h2 class="ProfileSettings__title">Account connections</h2>
      <v-btn
        depressed
        color="r1"
        class="mr-2 mb-2 ProfileSettings__delete"
        v-if="githubConnected"
        @click="disconnectSource('github')"
      >
        <v-icon color="white" class="pr-2"> mdi-close-circle-outline </v-icon>
        Disconnect from Github
      </v-btn>

      <v-btn
        depressed
        color="r1"
        class="mr-2 mb-2 ProfileSettings__delete"
        v-if="dropboxConnected"
        @click="disconnectSource('dropbox')"
      >
        <v-icon color="white" class="pr-2"> mdi-close-circle-outline </v-icon>
        Disconnect from Dropbox
      </v-btn>

      <v-btn
        depressed
        color="r1"
        class="mb-2 ProfileSettings__delete"
        v-if="bitbucketConnected"
        @click="disconnectSource('bitbucket')"
      >
        <v-icon color="white" class="pr-2"> mdi-close-circle-outline </v-icon>
        Disconnect from Bitbucket
      </v-btn>
    </div>
  </div>
</template>

<script>
import Avatar from 'vue-avatar'
import _ from 'lodash'
import { MODAL_ALERT } from '@/components/Modals'
export default {
  name: 'ProfileSettings',
  components: { Avatar },
  data() {
    const { id, name, email, authentication_token, avatar } =
      this.$store.state.user.current
    return {
      id,
      name,
      avatar,
      email,
      password: '',
      passwordConfirm: '',
      currentPassword: '',
      saving: false,
      showingPassword: false,
      showingCurrentPassword: false,
      showingPasswordConf: false,
      showingAccountToken: false,
      dirtyForm: false,
      profileConfigKeys: [
        {
          key: 'comments',
          text: 'Comments on your projects and sites.',
          label: 'Comments',
        },
        {
          key: 'mentions',
          text: 'When your username is mentioned in a comment.',
          label: 'Mentions',
        },
        {
          key: 'invitation',
          text: 'When a person you invited joins project or site.',
          label: 'Accepted invitation',
        },
        {
          key: 'new_version',
          text: 'When someone deploys a new version to the site.',
          label: 'New version deployment',
        },
        {
          key: 'version_rollback',
          text: 'When someone rolls back a previous version of the site.',
          label: 'Version roll back',
        },
        {
          key: 'version_deletion',
          text: 'When someone deletes a version of the site.',
          label: 'Version deletion',
        },
        {
          key: 'role_changed',
          text: 'When the project’s or site’s owner changes your role.',
          label: 'Role upgraded / downgraded',
        },
        {
          key: 'site_in_project',
          text: 'When someone creates a new site in the project.',
          label: 'New site creation in shared project',
        },
      ],
      profileConfig: [],

      authentication_token,

      errorVisibleUsername: null,
      errorVisibleEmail: null,
      errorVisiblePassword: null,
      errorVisibleCurrentPassword: null,
      errorVisiblePasswordConfirm: null,
      errorMain: null,
    }
  },
  async mounted() {
    await this.$store.dispatch('profile_config/load')
    this.profileConfig = _.cloneDeep(this.$store.state.profile_config.current)
  },
  watch: {
    '$store.state.profile_config.current': {
      handler(newVal, oldVal) {
        this.profileConfig = _.cloneDeep(newVal)
      },
      deep: true,
    },
  },
  computed: {
    errorUsername() {
      if (this.name) return null
      return 'Username can not be empty'
    },
    errorEmail() {
      if (!this.email) return 'Email can not be empty'
      if (this.email.indexOf('@') == -1) return 'Email must be valid'
      return null
    },
    errorPassword() {
      if (!this.password) return null

      if (this.password.length < 6 || this.password.length > 32)
        return 'Password should contain from 6 to 32 characters'
      return null
    },
    errorCurrentPassword() {
      if (!this.currentPassword) return null

      if (this.currentPassword.length < 6 || this.currentPassword.length > 32)
        return 'Password should contain from 6 to 32 characters'
      return null
    },
    errorPasswordConfirm() {
      if (this.showingPassword) return null
      if (this.password != this.passwordConfirm)
        return 'Password confirmation must coincide with the password'
      return null
    },
    emptyAvatar() {
      return this.$store.state.user.current.avatar == null
    },
    initials() {
      if (
        this.$store.state.user.current.name != null &&
        this.$store.state.user.current.name.length > 0
      )
        return this.$store.state.user.current.name[0].toUpperCase()
      else return this.$store.state.user.current.email[0].toUpperCase()
    },
    githubConnected() {
      return this.$store.state.user.current.githubToken
    },
    bitbucketConnected() {
      return this.$store.state.user.current.bitbucketToken
    },
    dropboxConnected() {
      return this.$store.state.user.current.dropboxToken
    },
    connectedToSource() {
      return (
        this.githubConnected || this.bitbucketConnected || this.dropboxConnected
      )
    },
  },
  methods: {
    onInputUsername() {
      this.errorVisibleUsername = null
      this.dirtyForm = true
    },
    onInputEmail() {
      this.errorVisibleEmail = null
      this.dirtyForm = true
    },
    onInputPassword() {
      this.errorVisiblePassword = null
      this.dirtyForm = true
    },
    onInputCurrentPassword() {
      this.errorVisibleCurrentPassword = null
    },
    onInputPasswordConfirm() {
      this.errorVisiblePasswordConfirm = null
      this.dirtyForm = true
    },
    updateProfileConfig(profileKey) {
      let newConfig = _.cloneDeep(this.profileConfig)
      newConfig[profileKey.key] = !newConfig[profileKey.key]
      const params = {
        id: this.profileConfig.id,
        profile_configs: newConfig.getServerObject(),
      }
      this.$store.dispatch('profile_config/update', params)
    },
    resetPassword() {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Reset Password',
          text: 'To reset your password, enter the email address you use to sign in',
          confirmString: this.$store.state.user.current.email,
          actionBtnLabel: 'Reset Password',
          alertMessage: false,
          showCancelBtn: true,
          action: () => {
            this.api.User.resetPassword({
              user: {
                email: this.$store.state.user.current.email,
              },
            })
          },
        },
      })
    },
    save() {
      if (
        this.errorUsername ||
        this.errorEmail ||
        this.errorPassword ||
        this.errorPasswordConfirm ||
        this.errorCurrentPassword
      ) {
        this.errorVisibleUsername = this.errorUsername
        this.errorVisibleEmail = this.errorEmail
        this.errorVisiblePassword = this.errorPassword
        this.errorVisiblePasswordConfirm = this.errorPasswordConfirm
        this.errorVisibleCurrentPassword = this.errorCurrentPassword
        return
      }
      this.dirtyForm = false
      this.errorMain = null
      const user_params = {
        id: this.id,
        user: {
          name: this.name,
          current_password: this.currentPassword,
        },
      }
      if (this.password.length > 0 && this.passwordConfirm.length > 0) {
        user_params.user.password = this.password
        user_params.user.password_confirmation = this.passwordConfirm
      }
      this.$store.dispatch('user/update', user_params).catch((error) => {
        if (!error) return

        let errorMain = ''
        error.body.errors.forEach((message) => {
          errorMain = errorMain + message + '<br>'
        })
        this.errorMain = errorMain.substr(0, errorMain.length - 4) + '.'
      })

      this.errorVisiblePasswordConfirm = null
    },
    disconnectSource(source) {
      let user_params = {
        id: this.id,
        user: {},
      }
      user_params['user'][source + '_token'] = null
      if (source == 'bitbucket')
        user_params['user'][source + '_refresh_token'] = null
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Confirm Disconnect',
          text:
            'Disconnect from ' +
            source[0].toUpperCase() +
            source.substr(1).toLowerCase(),
          showCancelBtn: true,
          action: () => this.$store.dispatch('user/updateSource', user_params),
        },
      })
    },
    chooseImage() {
      document.getElementById('userAvatarUpload').click()
    },
    handleImage(e) {
      const selectedImage = e.target.files[0]
      this.createBase64Image(selectedImage)
    },
    createBase64Image(fileObject) {
      if (this.saving) return
      this.saving = true
      this.avatar = URL.createObjectURL(fileObject)
      const formData = new FormData()
      formData.append('avatar', fileObject, fileObject.name)
      this.$store
        .dispatch('user/updateAvatar', formData)
        .then(() => {
          this.saving = false
        })
        .catch(() => (this.saving = false))
    },
    regenToken() {
      this.$store
        .dispatch('user/regenerateToken', { id: this.id })
        .then((res) => {
          this.authentication_token = res.deploy_token
        })
    },
  },
}
</script>

<style lang="scss" scoped>
$style: ProfileSettings;

.img_logo {
  border-radius: 50%;
  border: 1px solid #b3b3b3;
  width: 160px;
  height: 160px;
}

.v-text-field > .v-input__control > .v-input__slot {
  background: transparent !important;
}

.#{$style} {
  padding: 42px 24px 24px;

  &__title {
    color: $white;
    font-weight: 500;
    font-size: $H20;
    margin-bottom: 20px;
  }
  &__label {
    @extend %label-styles;
  }
  &__input {
    background: transparent !important;
    @extend %input-styles;
    margin-bottom: 16px;
  }
  &__section {
    @extend %section-styles;
    flex-basis: 50%;
    &.right_column {
      padding: 0;
      border: none;
      background: inherit;
      .account_token,
      .notifications_block {
        background: $N4;
        border: 1px solid $N5;
        border-radius: 8px;
        padding: 32px 24px 40px;
      }
    }
  }
  &__text {
    color: $N2;
    font-feature-settings:
      'clig' off,
      'liga' off;
    font-family: 'Instrument Sans', sans-serif;
    font-size: $H14;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 16.8px */
  }
  &__config_block {
    padding: 14px 15px 14px 10px;
    .profile_config_labels {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
    }

    .config_checkbox {
      display: inline-block;
      border-radius: 4px;
      &.active {
        background: $Y1;
      }
      &.simple {
        width: 20px;
        height: 20px;
        border: 1px solid gray;
        border-radius: 4px;
        vertical-align: middle;
      }
      .config_checkbox_mark {
        color: black;
        font-size: 20px;
      }
    }
  }
  &__row {
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
  }
  &__delete {
    &.v-btn {
      color: $white;
    }
  }
  .reset_password_block {
    color: $N6;
    .reset_password_btn {
      cursor: pointer;
      color: $Y1;
    }
  }
  .btn_text {
    color: $Y1;
    cursor: pointer;
    text-decoration: none;
  }
  @media screen and (max-width: 600px) {
    &__row {
      flex-direction: column;
    }
    &__section {
      flex-basis: 100%;
    }
  }
}
.error-main {
  background: #e94a53;
  border-radius: 6px;
}
.settings-form {
  max-width: 700px;
}
</style>
