import { SiteUser } from '@/models/SiteUser'
import { SiteUser as SiteUserResource } from '@/resources/forge'

const store = {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    /**
     * Set the list of site users in the store state.
     * @param {Array} list - The list of site users to set.
     */
    setList(state, list) {
      state.list = list
    },
    /**
     * Delete a site user from the store state by ID.
     * @param {string} id - The ID of the site user to delete.
     */
    delete(state, id) {
      state.list = state.list.filter((su) => id != su.id)
    },
    /**
     * Add a new site user to the store state.
     * @param {Object} site_user - The site user to add.
     */
    create(state, site_user) {
      state.list = [...state.list, site_user]
    },
    /**
     * Update an existing site user in the store state.
     * @param {Object} params - The parameters containing the updated site user information.
     */
    update(state, params) {
      const site_user = state.list.find((su) => su.id == params.id)
      if (!site_user) return
      Object.assign(site_user, params)
    },
  },
  actions: {
    /**
     * Load the list of site users from the server.
     * @param {string} site_id - The ID of the site to load users for.
     * @returns {Promise} A promise representing the result of the API request.
     */
    async load({ commit }, site_id) {
      const res = await SiteUserResource.get({ site_id: site_id })
      commit(
        'setList',
        res.body.site_users.map((su) => new SiteUser(su))
      )
      return res
    },
    /**
     * Delete a site user by ID.
     * @param {string} id - The ID of the site user to delete.
     */
    delete({ commit }, id) {
      SiteUserResource.destroy({ id: id })
        .then(() => {
          commit('delete', id)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /**
     * Add a new site user.
     * @param {Object} params - The parameters for creating a new site user.
     */
    addUser({ commit }, params) {
      SiteUserResource.create({ site_user: params })
        .then((res) => {
          if (res.body.site_user != undefined)
            commit('create', new SiteUser(res.body.site_user))
          else commit('invitation/create', res.body.invitation, { root: true })
        })
        .catch((e) => {
          console.log(e)
        })
    },
    /**
     * Add multiple new site users.
     * @param {Object} params - The parameters for creating multiple new site users.
     */
    async addUsers({ commit, dispatch }, params) {
      await SiteUserResource.addUsers({ site_user: params }).then(async () => {
        await dispatch('load', params.site_id)
        await dispatch(
          'invitation/load',
          { type: 'site', type_id: params.site_id },
          { root: true }
        )
      })
    },
  },
  getters: {
    /**
     * Get a site user by ID.
     * @param {String} id -  Target site user id
     * @returns {Object|null} The found site user or null if not found.
     */
    findById: (state) => (id) => {
      return state.list.find((v) => v.id == id)
    },
  },
}

export default store
