import { ProjectUser } from '@/models/ProjectUser'
import { ProjectUser as ProjectUserResource } from '@/resources/forge'
import router from '@/router'

const store = {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    /**
     * Sets the list of project users in the state.
     * @param {Array} list - The list of project users.
     */
    setList(state, list) {
      state.list = list
    },
    /**
     * Deletes a project user from the state.
     * @param {string} id - The ID of the project user to be deleted.
     */
    delete(state, id) {
      state.list = state.list.filter((pu) => id != pu.id)
    },
    /**
     * Creates a new project user and adds it to the state.
     * @param {Object} project_user - The new project user to be added.
     */
    create(state, project_user) {
      state.list = [...state.list, project_user]
    },
    /**
     * Updates a project user in the state.
     * @param {Object} params - The parameters for updating the project user.
     */
    update(state, params) {
      const project_user = state.list.find((pu) => pu.id == params.id)
      if (!project_user) return

      Object.assign(project_user, params)
    },
  },
  actions: {
    /**
     * Loads the project users associated with a project and sets them in the state.
     * @param {string} project_id - The ID of the project.
     */
    async load({ commit }, project_id) {
      const res = await ProjectUserResource.get({ project_id: project_id })
      commit(
        'setList',
        res.body.project_users.map((pu) => new ProjectUser(pu))
      )
      return res
    },
    /**
     * Deletes a project user.
     * @param {string} id - The ID of the project user to be deleted.
     */
    delete({ commit, rootState, getters }, id) {
      const project_user = getters['findById'](id)
      const user_id = project_user.user_id
      const project_id = project_user.project_id
      ProjectUserResource.destroy({ id: id })
        .then(() => {
          commit('delete', id)
          if (rootState.user.current.id == user_id) {
            commit('projects/delete', project_id, { root: true })
            commit('sites/deleteProjectsSite', project_id, { root: true })
            router.push('/')
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /**
     * Adds a user to a project.
     * @param {Object} params - The parameters for adding the user to the project.
     */
    addUser({ commit }, params) {
      ProjectUserResource.create({ project_user: params })
        .then((res) => {
          if (res.body.project_user != undefined)
            commit('create', new ProjectUser(res.body.project_user))
          else commit('invitation/create', res.body.invitation, { root: true })
        })
        .catch((e) => {
          console.log(e)
        })
    },
    /**
     * Adds multiple users to a project.
     * @param {Object} params - The parameters for adding multiple users to the project.
     */
    async addUsers({ commit, dispatch }, params) {
      await ProjectUserResource.addUsers({ project_user: params }).then(
        async () => {
          await dispatch('load', params.project_id)
          await dispatch(
            'invitation/load',
            { type: 'project', type_id: params.project_id },
            { root: true }
          )
        }
      )
    },
  },
  getters: {
    /**
     * Finds a project user by ID.
     * @param {String} id -  Target project user id
     * @returns {Object|null} The found project user or null if not found.
     */
    findById: (state) => (id) => {
      return state.list.find((pu) => pu.id == id)
    },
  },
}

export default store
