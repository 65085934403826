<template>
  <div class="browser-mockup">
    <div class="top-bar">
      <div class="circles">
        <span class="circle red"></span>
        <span class="circle yellow"></span>
        <span class="circle green"></span>
      </div>
    </div>
    <div class="browser-content">
      <div class="content-container" v-html="markup"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FormPreview',
  props: {
    markup: {
      type: String,
      required: true,
    },
    styles: {
      type: String,
      required: true,
    },
  },
  mounted() {
    this.injectStyles()
  },
  methods: {
    injectStyles() {
      const styleElement = document.createElement('style')
      styleElement.textContent = `
          .content-container {
            color: black;
          }
          .content-container input,
          .content-container label,
          .content-container textarea,
          .content-container button,
          .content-container select {
            display: block;
            width: 100%;
            margin-bottom: 10px;
          }
          .content-container input,
          .content-container textarea,
          .content-container select {
            padding: 10px;
            border: 1px solid #ccc;
            border-radius: 4px;
            font-size: 16px;
          }
          .content-container button {
            padding: 10px 20px;
            background-color: #007bff;
            color: white;
            border: none;
            border-radius: 4px;
            cursor: pointer;
          }
          .content-container button:hover {
            background-color: #0056b3;
          }
          ${this.styles}
        `
      document.head.appendChild(styleElement)
    },
  },
}
</script>

<style scoped>
.browser-mockup {
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  background-color: #f9f9f9;
  margin-top: 20px;
}

.top-bar {
  background: silver;
  padding: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.circles {
  display: flex;
  gap: 5px;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.red {
  background-color: #ff605c;
}

.yellow {
  background-color: #ffbd44;
}

.green {
  background-color: #00ca4e;
}

.browser-content {
  background-color: lightgray;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content-container {
  background-color: white; /* Default background color */
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
  color: black; /* Default text color for readability */
}
</style>
