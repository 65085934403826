<template>
  <div
    v-if="$route.name !== 'dashboard' && isMobile && !publisherRoute"
    class="MobileSiteMenu"
  >
    <v-tabs
      class="MobileSiteMenu__tabs"
      background-color="transparent"
      v-model="activeTab"
    >
      <v-tab
        class="MobileSiteMenu__tab"
        active-class="_active"
        v-for="tab in tabs"
        :key="tab.name"
        :to="tab.to"
        @click="onTabClick(tab)"
        >{{ tab.name }}</v-tab
      >
      <v-tab
        class="MobileSiteMenu__more-icon-tab"
        active-class="_active"
        @click="toggleMoreAppsBar"
        v-if="type == TYPE_SITE && apps && apps.length > 0 && !publisherRoute"
      >
        <the-icon class="mr-2" icon="more-vert" />
      </v-tab>
    </v-tabs>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import Avatar from 'vue-avatar'
import router from '@/router'
import SiteModeSelect from './TheAppBar/SiteModeSelect.vue'
import Notifications from '@/components/elements/Notifications'
import { MODAL_ALL_NOTIFICATIONS } from '@/components/Modals'

const TYPE_PROFILE = 'TYPE_PROFILE'
const TYPE_PROJECT = 'TYPE_PROJECT'
const TYPE_SITE = 'TYPE_SITE'
const TYPE_ORGANISATION = 'TYPE_ORGANISATION'

const TABS_PROFILE = [
  {
    name: 'Settings',
    to: { name: 'profile_settings' },
  },
  {
    name: 'Billing',
    to: { name: 'profile_billing' },
  },
  {
    name: 'Invoices',
    to: { name: 'profile_invoices' },
  },
]

const TABS_PROJECT = [
  {
    name: 'Sites',
    to: { name: 'project_sites' },
  },
  {
    name: 'Main',
    to: { name: 'project_main' },
  },
  {
    name: 'Access',
    to: { name: 'project_access' },
  },
]

const TABS_ORGANISATION = [
  {
    name: 'General',
    to: { name: 'organisation_main' },
  },
  {
    name: 'Members',
    to: { name: 'organisation_access' },
  },
]

const TABS_SITE = [
  {
    name: 'Version',
    to: { name: 'site_versions' },
  },
  {
    name: 'Forms',
    to: { name: 'site_forms_list' },
  },
  {
    name: 'Usage',
    to: { name: 'site_usage' },
  },
  {
    name: 'Settings',
    to: { name: 'site_settings' },
  },
]

const TAB_ACCESS = {
  name: 'Sharing',
  to: { name: 'site_access' },
}

export default {
  components: { SiteModeSelect, Avatar, Notifications },
  name: 'TheAppBar',
  data() {
    return {
      TYPE_PROFILE,
      TYPE_PROJECT,
      TYPE_SITE,
      TABS_PROFILE,
      activeTab: null,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.current,
      showMoreAppBar: (state) => state.sites.showMoreAppBar,
      organisation: (state) => state.organisations.current,
      apps: (state) => state.sites.apps,
      notifications: (state) => state.notifications.list,
    }),
    navDrawer() {
      return this.$store.state.application.navDrawer
    },
    type() {
      if (!this.$route.matched[0]) return null

      switch (this.$route.matched[0].name) {
        case 'profile':
          return TYPE_PROFILE
        case 'project':
          return TYPE_PROJECT
        case 'site':
          return TYPE_SITE
        case 'organisation':
          return TYPE_ORGANISATION
      }
      return null
    },
    notPaid() {
      return (
        !['iron', 'bronze', 'copper', 'lead'].includes(this.user.plan_id) &&
        this.$route.name != 'profile_billing'
      )
    },
    siteMode() {
      return this.activeSite.mode
    },
    siteTabs() {
      let app_access
      if (this.organisation.id == 0) app_access = this.user.appAccess
      else app_access = this.organisation.app_access
      if (app_access) {
        return TABS_SITE.concat({
          name: 'Apps',
          to: { name: 'site_apps' },
        })
      } else return TABS_SITE
    },
    tabs() {
      switch (this.type) {
        case TYPE_PROFILE:
          return TABS_PROFILE
        case TYPE_PROJECT: {
          let tabs
          if (this.$store.state.organisations.current.id == 0)
            tabs = TABS_PROJECT
          else tabs = TABS_PROJECT.filter((tab) => tab.name != 'Access')
          return tabs
        }
        case TYPE_ORGANISATION:
          return TABS_ORGANISATION
        case TYPE_SITE: {
          if (
            !this.activeSite.projectId &&
            this.$store.state.organisations.current.id == 0 &&
            this.activeSite.mode == 'production'
          )
            return this.siteTabs.concat(TAB_ACCESS)
          else return this.siteTabs
        }
      }
      return null
    },
    avatarEmpty() {
      return this.user.avatar == null || this.user.avatar.length == 0
    },
    initials() {
      if (
        this.$store.state.user.current.name != null &&
        this.$store.state.user.current.name.length > 0
      )
        return this.$store.state.user.current.name[0].toUpperCase()
      else return this.$store.state.user.current.email[0].toUpperCase()
    },
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly
    },
    publisherRoute() {
      return this.$route.meta.publisherRoute === true
    },
  },
  mounted() {
    this.$root.$on('extensionPoints', this.handleExtensionPoints)
  },
  beforeDestroy() {
    this.$root.$off('extensionPoints', this.handleExtensionPoints)
  },
  methods: {
    ...mapMutations('sites', ['setActiveApp', 'setApps']),
    ...mapMutations('application', ['toggleMobileNav']),
    changeDrawer() {
      this.$store.commit('application/setNavDrawer', !this.navDrawer)
    },
    onLogOut() {
      this.$store.dispatch('application/logOut', null)
    },
    openSite() {
      let site_url = ''
      if (this.activeSite.use_ssl) site_url = 'https://' + this.activeSite.url
      else site_url = 'http://' + this.activeSite.url
      window.open(site_url, '_blank')
    },
    modeChanged(value) {
      if (this.siteMode != value)
        this.$store.dispatch('sites/getSiteMode', {
          id: this.activeSite.id,
          mode: value,
        })
    },
    onTabClick() {
      this.setShowMoreAppBar(false)
    },
    onAppClick(item) {
      if (item.component) {
        router.push({
          name: 'site_plugin',
          params: { slug: item.slug || item.extensionId },
        })
      } else if (item.onClick) this.$root.$emit('sendMessage', item)
    },
    handleExtensionPoints(data) {
      if (data && data.siteTab) {
        // Remove duplicates from registered extension points
        const unique = [
          ...new Map(
            data.siteTab.map((m) => [m.id || m.slug || m.extensionId, m])
          ).values(),
        ]
        this.setApps(unique)
        this.findAndSetActiveApp()
      }
    },
    findAndSetActiveApp() {
      if (
        this.$route.name.indexOf('site_plugin') !== -1 &&
        this.$route?.params?.slug
      ) {
        const slug = this.$route.params.slug
        let app = this.apps.find(
          (p) => p.slug === slug || p.extensionId === slug
        )

        this.setActiveApp(app)
        this.activeTab = this.tabs.length
      }
    },
    toggleMoreAppsBar() {
      this.setShowMoreAppBar(!this.showMoreAppBar)
    },
    openAllNotificationsModal() {
      this.$store.commit('application/openModal', {
        component: MODAL_ALL_NOTIFICATIONS,
      })
    },
  },
  watch: {
    showMoreAppBar: {
      handler(newValue) {
        if (newValue) this.activeTab = this.tabs.length
      },
      immediate: true,
    },
    '$route.name': {
      handler() {
        this.findAndSetActiveApp()
        if (this.type !== TYPE_SITE && this.showMoreAppBar)
          this.setShowMoreAppBar(false)
      },
    },
  },
}
</script>

<style lang="scss">
$style: MobileSiteMenu;
.#{$style} {
  display: unset;
  &__tabs {
    position: sticky;
    top: 0;
    & .v-tabs-bar {
      height: 42px;
    }
  }
  &__tab {
    &.v-tab {
      text-transform: initial;
      font-size: $H14;
      color: $N2;
      &._active {
        color: $white;
      }
    }
  }
  &__more-icon-tab {
    padding: 0;
    &.v-tab {
      width: 36px;
      min-width: 36px;
    }
  }
}
</style>
