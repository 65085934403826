<template>
  <v-card class="ModalInviteMembers" max-width="600px">
    <h4 class="ModalInviteMembers__title">Invite Members</h4>
    <div v-if="!saving">
      <span class="ModalInviteMembers__label"
        >Enter You coworkers' emails and select their roles
      </span>
      <div class="ModalInviteMembers__members_block">
        <div class="members_list">
          <div class="chip" v-for="(member, i) of members" :key="member.label">
            {{ member }}
            <v-icon class="chip_close_icon" @click="removeMember(i)">
              mdi-window-close
            </v-icon>
          </div>
        </div>
        <input
          class="members_input"
          :class="wrongInput ? 'error_input' : ''"
          @keypress.space="addMember"
          v-model="currentInput"
          @keypress.enter.prevent="addMember"
        />
      </div>
      <div class="ModalInviteMembers__roles_block">
        <v-select
          :value="membersRoleInput"
          @change="changeRoleInput"
          :items="roles"
          :menu-props="{
            contentClass: 'ModalInviteMembers__roles_menu',
            bottom: true,
            offsetY: true,
          }"
          class="ml-2 ModalInviteMembers__select_roles"
          style="
            max-width: 116px;
            border-radius: 8px;
            display: inline-block;
            border-color: #1f1e31;
          "
          color="#5e636d"
          return-object
          :disabled="rolesDisabled"
          outlined
          hide-details
          dense
        >
        </v-select>
      </div>
      <div v-if="errorMain" class="error-main mt-4 pa-4">
        {{ errorMain }}
      </div>
      <div class="ModalInviteMembers__footer">
        <v-btn @click="close" color="n5" class="">Cancel</v-btn>
        <v-btn @click="sendInvitation" color="y1">Send Invitation</v-btn>
      </div>
    </div>
    <div v-else class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
  </v-card>
</template>

<script>
import { debounce } from 'lodash'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import { generateDomain } from '@/utils/common'

export default {
  name: 'ModalInviteMembers',
  components: { IntersectingCirclesSpinner },
  props: {
    inviteAction: {
      type: Number,
      default: 1,
    },
    roles: {
      type: Array,
      default: () => {
        return ['Editor']
      },
    },
    rolesDisabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      errorMain: '',
      saving: false,
      members: [],
      currentInput: '',
      selectedRole: 'Editor',
      wrongInput: false,
      membersRoleInput: this.roles[0],
    }
  },
  created() {},
  computed: {
    membersAdded() {
      return this.members.length > 0
    },
  },
  methods: {
    sendInvitation() {
      if (this.currentInput.length > 0 && this.members.length == 0)
        this.addMember()
      if (this.members.length == 0) {
        this.errorMain = 'You should add email to continue'
        this.wrongInput = true
        return
      }
      if (this.inviteAction == 1) this.sendInvitationForSite()
      else if (this.inviteAction == 2) this.sendInvitationForProject()
      else if (this.inviteAction == 3) this.sendInvitationForOrgs()
    },
    sendInvitationForSite() {
      this.saving = true
      this.$store
        .dispatch('site_user/addUsers', {
          site_id: this.activeSite.id,
          emails: this.members,
        })
        .then(() => {
          this.saving = false
          this.$emit('close')
        })
        .catch((e) => {
          this.saving = false
          console.log(e)
        })
    },
    sendInvitationForProject() {
      this.saving = true
      this.$store
        .dispatch('project_user/addUsers', {
          project_id: this.$store.state.projects.active.id,
          emails: this.members,
        })
        .then(() => {
          this.saving = false
          this.$emit('close')
        })
        .catch((e) => {
          this.saving = false
          console.log(e)
        })
    },
    sendInvitationForOrgs() {
      this.saving = true
      this.$store
        .dispatch('organisation_user/addUsers', {
          organisation_id: this.$store.state.organisations.current.id,
          emails: this.members,
          role: this.selectedRole.toLowerCase(),
        })
        .then(() => {
          this.saving = false
          this.$emit('close')
        })
        .catch((e) => {
          this.saving = false
          console.log(e)
        })
    },
    close() {
      this.$emit('close')
    },
    addMember() {
      if (this.currentInput.length == 0) return
      this.validateEmail(this.currentInput.trim())
      const memberAlreadyAdded = this.members.includes(this.currentInput.trim())
      if (!this.wrongInput) {
        if (!memberAlreadyAdded) this.members.push(this.currentInput.trim())
        this.currentInput = ''
        this.wrongInput = false
        this.errorMain = ''
      }
    },
    removeMember(index) {
      this.members.splice(index, 1)
    },
    changeRoleInput(a) {
      this.membersRoleInput = a
      this.selectedRole = a
    },
    validateEmail(email) {
      const reg =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      this.wrongInput = !email.toLowerCase().match(reg)
    },
  },
}
</script>

<style lang="scss">
$style: ModalInviteMembers;
.#{$style} {
  &.theme--dark.v-card {
    @extend %modal-body-styles;
    min-width: 560px;
    max-width: 560px !important;
    padding: 24px 24px 0;
  }
  &__members_block {
    width: 368px;
    min-height: 40px;
    border: 1px solid $N6;
    border-radius: 8px;
    color: $N1;
    display: inline-block;

    .members_list {
      margin-left: 8px;
      .chip {
        display: inline-block;
        padding: 3px 8px;
        border-radius: 8px;
        border: 1px solid $N7;
        margin-left: 10px;
        margin-top: 10px;

        .chip_close_icon {
          font-size: 16px;
          vertical-align: middle;
          &:focus {
            outline-width: 0;
          }
        }
      }
    }
    .members_input {
      height: 40px;
      width: inherit;
      outline-width: 0;
      padding: 10px;
      color: inherit;

      &.error_input {
        color: red;
      }
    }
  }
  &__roles_block {
    display: inline-block;
    vertical-align: top;
    &:focus {
      outline-width: 0;
    }
  }
  &__title {
    font-weight: 500;
    font-size: $H16;
    color: $white;
    margin-bottom: 32px;
  }
  &__label {
    @extend %label-styles;
  }
  &__input {
    &.v-text-field--filled.v-input--dense > .v-input__control > .v-input__slot {
      @extend %input-styles;
    }
    &.v-input input {
      color: $white;
    }
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 15px;
    padding: 16px 0;
    gap: 16px;
    & > .theme--dark.v-btn {
      color: $white;
    }
  }
  .error-main {
    background: #e94a53;
    border-radius: 2px;
  }

  .spinner {
    display: flex;
    width: 100%;
    min-width: 150px;
    height: 100%;
    align-items: center;
    margin-bottom: 15px;
    justify-content: center;
  }
  &__roles_menu {
    .v-list {
      background: $N4 !important;
      min-width: 118px !important;
      border-radius: 8px;
    }
  }
}
</style>
