<template>
  <div class="TheDashboard">
    <first-time-wizard v-model="showWizard" @completed="onWizardComplete" />

    <v-row class="TheDashboard__row">
      <v-col sm="6" cols="12">
        <dashboard-section-wrapper title="Metrics">
          <dashboard-metrics />
        </dashboard-section-wrapper>
      </v-col>
      <v-col sm="6" cols="12">
        <dashboard-section-wrapper title="Featured Templates" isButtonShown>
          <dashboard-featured />
        </dashboard-section-wrapper>
      </v-col>
    </v-row>

    <dashboard-section-wrapper title="Recently visited sites">
      <dashboard-recent-sites :sites="getRecentSites" />
    </dashboard-section-wrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import { guid } from '@/utils/common'

// import DashboardSection from "./TheDashboard/DashboardSection";
import DashboardSectionWrapper from './_Common/DashboardSectionWrapper.vue'
import DashboardMetrics from './TheDashboard/DashboardMetrics.vue'
import DashboardFeatured from './TheDashboard/DashboardFeatured.vue'
import DashboardRecentSites from './TheDashboard/DashboardRecentSites.vue'
import { size, orderBy, slice } from 'lodash'
import moment from 'moment'
import FirstTimeWizard from './Wizards/FirstTimeWizard.vue'

export const MODE_GALLERY = 'MODE_GALLERY'
export const MODE_LIST = 'MODE_LIST'

export default {
  name: 'TheDashboard',
  components: {
    DashboardSectionWrapper,
    // DashboardSection,
    DashboardMetrics,
    DashboardFeatured,
    DashboardRecentSites,
    FirstTimeWizard,
  },
  data() {
    return {
      MODE_GALLERY,
      MODE_LIST,
      mode: MODE_GALLERY,
      name: '',
      errorMain: '',
      showWizard: false,
    }
  },
  computed: {
    ...mapState({
      projects: (state) => state.projects.list,
      sites: ({ sites }) => sites.list,
      user: (state) => state.user.current,
    }),
    productionSites() {
      return this.sites.filter((s) => s.mode == 'production')
    },
    getRecentSites() {
      const sevenDaysBefore = moment().subtract(7, 'days')
      const sortedSites = orderBy(this.productionSites, ['updatedAt'], 'desc')
      const filteredSites = sortedSites.filter(
        (site) => moment(site.updatedAt) > sevenDaysBefore
      )
      if (size(filteredSites) > 4) {
        return slice(filteredSites, 0, 4)
      }
      return filteredSites
    },
    isFirstTimeUser() {
      return this.projects.length === 0 && this.productionSites.length === 0
    },
  },
  mounted() {
    this.showWizard = this.isFirstTimeUser
  },
  methods: {
    createSite() {
      if (!this.name) return

      const siteData = {
        url: this.name,
      }
      this.$store
        .dispatch('sites/create', siteData)
        .then((res) => {
          this.errorMain = ''
          this.$router.push('/site/' + res.id)
        })
        .catch((error) => {
          if (!error) return

          let errorMain = ''
          error.body.errors.url.forEach((message) => {
            errorMain = errorMain + message + '. '
          })
          this.errorMain = errorMain.substr(0, errorMain.length - 4) + '.'
        })
    },
    createProject() {
      if (!this.name) return

      const projectData = {
        id: guid(),
        name: this.name,
      }
      this.$store.dispatch('projects/create', projectData)
    },
    onWizardComplete() {
      this.showWizard = false
    },
  },
}
</script>
<style lang="scss">
$style: TheDashboard;
.#{$style} {
  padding: 34px 24px 24px;
  &__row {
    margin-bottom: 25px;
  }
}
.w-full {
  width: 100%;
}
</style>
