import Vue from 'vue'
import VueResource from 'vue-resource'

import { FORGE_ROOT_URL } from '@/env'
import store from '@/store'
import router from '@/router'

Vue.use(VueResource)

Vue.http.options.root = FORGE_ROOT_URL

Vue.http.interceptors.push((request, next) => {
  if (
    (!request.headers.get('X-USER-EMAIL') ||
      !request.headers.get('X-USER-TOKEN')) &&
    request.root == FORGE_ROOT_URL &&
    !request.headers.get('Authorization')
  ) {
    const { token } = store.state.user
    const { email } = store.state.user
    request.headers.set('X-USER-EMAIL', email)
    request.headers.set('X-USER-TOKEN', token)
  }

  request.headers.set('Accept', 'application/json')

  next((response) => {
    if (
      response.status !== 200 &&
      response.body.error &&
      response.body.error.message
    ) {
      return response.body.error.message
    }
    if (
      response.status === 401 &&
      router.currentRoute.matched.some((record) => record.meta.requiresAuth)
    ) {
      store.dispatch('application/logOut')
    }
  })
})

export const User = Vue.resource(
  'v1/users{/id}',
  {},
  {
    current: { method: 'GET', url: 'api/v2/users/current_user' },
    authorize: {
      method: 'POST',
      url: 'api/v2/cli/login',
    },
    checkSubscription: {
      method: 'PUT',
      url: 'internal_api/sessions/check_subscription',
    },
    updateCardDetails: {
      method: 'PUT',
      url: 'internal_api/sessions/update_card_details',
    },
    register: {
      method: 'POST',
      url: 'users',
    },
    logOut: {
      method: 'DELETE',
      url: 'api/v2/users/logout',
    },
    resetPassword: {
      method: 'POST',
      url: 'users/password',
    },
    update: {
      method: 'PUT',
      url: 'api/v2/users{/id}',
    },
    updateSource: {
      method: 'PUT',
      url: 'api/v2/users/update_source',
    },
    regenerateToken: {
      method: 'POST',
      url: 'internal_api/users/regenerate_token',
    },
    updateSubscription: {
      method: 'PUT',
      url: 'internal_api/sessions{/id}',
    },
    googleAuth: {
      method: 'POST',
      url: 'api/v2/users/google_auth',
    },
    githubAuth: {
      method: 'POST',
      url: 'api/v2/users/github_auth',
    },
    bitbucketAuth: {
      method: 'POST',
      url: 'api/v2/users/bitbucket_auth',
    },
    acceptInvitation: {
      method: 'POST',
      url: 'api/v2/users/accept_invitation',
    },
    checkOnCreated: {
      method: 'POST',
      url: 'api/v2/users/accept_invitation_for_created',
    },
    updateAvatar: {
      method: 'PUT',
      url: 'api/v2/users/update_avatar',
    },
  }
)

export const Invoice = Vue.resource(
  'internal_api/invoices',
  {},
  {
    downloadCsv: {
      method: 'GET',
      url: 'internal_api/invoices/invoices_csv_data',
    },
  }
)

export const Openai = Vue.resource(
  '',
  {},
  {
    completions: { method: 'POST', url: 'api/v2/openai/completions' },
  }
)

export const Site = Vue.resource(
  'internal_api/sites{/id}',
  {},
  {
    create: { method: 'POST', url: 'internal_api/sites' },
    update: { method: 'PUT', url: 'internal_api/sites{/id}' },
    regenerateToken: {
      method: 'POST',
      url: 'internal_api/site_users/regenerate_token',
    },
    checkUrl: { method: 'POST', url: 'internal_api/sites{/id}/check_url' },
    delete: { method: 'DELETE' },
    url: 'internal_api/sites{/id}',
    addWebhook: { method: 'POST', url: 'internal_api/webhook_triggers' },
    removeWebhook: {
      method: 'DELETE',
      url: 'internal_api/webhook_triggers{/id}',
    },
    updateWebhook: { method: 'PUT', url: 'internal_api/webhook_triggers{/id}' },
    moveSite: { method: 'PUT', url: 'internal_api/sites{/id}/move_site' },
    policy: { method: 'GET', url: 'upload/policy' },
    siteMode: { method: 'GET', url: 'internal_api/sites{/id}/site_mode' },
    siteModes: { method: 'GET', url: 'internal_api/sites/site_modes' },
    publicSiteUrl: { method: 'GET', url: 'cdn{/id}.json' },
    changeSiteOwner: {
      method: 'PUT',
      url: 'internal_api/sites{/id}/change_site_owner',
    },
    confirmChangeOwner: {
      method: 'PUT',
      url: 'internal_api/sites{/id}/confirm_change_owner',
    },
    updateEnvironmens: {
      method: 'PUT',
      url: 'internal_api/sites{/id}/update_environments',
    },
  }
)

export const Version = Vue.resource(
  'internal_api/versions{/id}',
  {},
  {
    download: {
      method: 'GET',
      url: 'internal_api/versions{/id}/download_url',
    },
    deleteVersion: {
      method: 'DELETE',
      url: 'internal_api/versions{/id}',
    },
    create: {
      method: 'POST',
      url: 'internal_api/versions',
    },
    update: {
      method: 'PUT',
      url: 'internal_api/versions{/id}',
    },
  }
)

export const siteApp = Vue.resource(
  'internal_api/site_apps',
  {},
  {
    download: {
      method: 'GET',
      url: 'internal_api/versions{/id}/download',
    },
    delete: {
      method: 'DELETE',
      url: 'internal_api/site_apps{/id}',
    },
    create: {
      method: 'POST',
      url: 'internal_api/site_apps',
    },
    update: {
      method: 'PUT',
      url: 'internal_api/site_apps{/id}',
    },
    checkServiceLog: {
      method: 'GET',
      url: 'internal_api/site_apps{/id}/get_parse_service_logs',
    },
    checkServiceHealh: {
      method: 'GET',
      url: 'internal_api/site_apps{/id}/check_parse_service_health',
    },
    updateParseEnvironments: {
      method: 'PUT',
      url: 'internal_api/parse_settings{/id}/update_environments',
    },
  }
)

export const SiteUser = Vue.resource(
  'internal_api/site_users',
  {},
  {
    create: {
      method: 'POST',
      url: 'internal_api/site_users',
    },
    addUsers: {
      method: 'POST',
      url: 'internal_api/site_users/add_users',
    },
    regenerateToken: {
      method: 'POST',
      url: 'internal_api/site_users/regenerate_token',
    },
    destroy: {
      method: 'DELETE',
      url: 'internal_api/site_users{/id}',
    },
  }
)

export const ParseCloud = Vue.resource(
  'internal_api/parse_custom_codes',
  {},
  {
    update: {
      method: 'PUT',
      url: 'internal_api/parse_custom_codes{/id}',
    },
  }
)

export const Project = Vue.resource(
  'internal_api/projects{/id}',
  {},
  {
    create: {
      method: 'POST',
      url: 'internal_api/projects',
    },
    update: {
      method: 'PUT',
      url: 'internal_api/projects{/id}',
    },
    destroy: {
      method: 'DELETE',
      url: 'internal_api/projects{/id}',
    },
    loadSitesList: {
      method: 'GET',
      url: 'internal_api/projects{/id}/load_sites_list',
    },
  }
)

export const ProjectUser = Vue.resource(
  'internal_api/project_users',
  {},
  {
    create: {
      method: 'POST',
      url: 'internal_api/project_users',
    },
    destroy: {
      method: 'DELETE',
      url: 'internal_api/project_users{/id}',
    },
    addUsers: {
      method: 'POST',
      url: 'internal_api/project_users/add_users',
    },
  }
)

export const Organisation = Vue.resource(
  'internal_api/organisations',
  {},
  {
    create: {
      method: 'POST',
      url: 'internal_api/organisations',
    },
    destroy: {
      method: 'DELETE',
      url: 'internal_api/organisations{/id}',
    },
    update: {
      method: 'PUT',
      url: 'internal_api/organisations{/id}',
    },
    setCurrent: {
      method: 'POST',
      url: 'internal_api/organisations/set_current',
    },
    getCurrent: {
      method: 'GET',
      url: 'internal_api/organisations/get_current',
    },
    updateLogo: {
      method: 'PUT',
      url: 'internal_api/organisations{/id}/update_logo',
    },
    getPublisherInfo: {
      method: 'GET',
      url: 'internal_api/organisations{/id}/publisher_info',
    },
    updateDomains: {
      method: 'PUT',
      url: 'internal_api/organisations{/id}/update_domains',
    },
  }
)

export const ProfileConfig = Vue.resource(
  'internal_api/profile_configs',
  {},
  {
    update: {
      method: 'PUT',
      url: 'internal_api/profile_configs{/id}',
    },
  }
)

export const OrganisationUser = Vue.resource(
  'internal_api/organisation_users',
  {},
  {
    create: {
      method: 'POST',
      url: 'internal_api/organisation_users',
    },
    destroy: {
      method: 'DELETE',
      url: 'internal_api/organisation_users{/id}',
    },
    update: {
      method: 'PUT',
      url: 'internal_api/organisation_users{/id}',
    },
    addUsers: {
      method: 'POST',
      url: 'internal_api/organisation_users/add_users',
    },
  }
)

export const OrganisationSubscription = Vue.resource(
  'internal_api/organisation_subscriptions',
  {},
  {
    updateSubscription: {
      method: 'PUT',
      url: 'internal_api/organisation_subscriptions/update_subscription',
    },
    checkSubscription: {
      method: 'PUT',
      url: 'internal_api/organisation_subscriptions/check_subscription',
    },
    updateCardDetails: {
      method: 'PUT',
      url: 'internal_api/organisation_subscriptions/update_card_details',
    },
  }
)

export const Invitation = Vue.resource(
  'internal_api/invitations',
  {},
  {
    destroy: {
      method: 'DELETE',
      url: 'internal_api/invitations{/id}',
    },
    update: {
      method: 'PUT',
      url: 'internal_api/invitations{/id}',
    },
  }
)

export const Form = Vue.resource(
  'internal_api/forms',
  {},
  {
    update: {
      method: 'PUT',
      url: 'internal_api/forms{/id}',
    },
    destroy: {
      method: 'DELETE',
      url: 'internal_api/forms{/id}',
    },
    show: {
      method: 'GET',
      url: 'internal_api/forms{/id}',
    },
    deleteFormData: {
      method: 'DELETE',
      url: 'internal_api/forms{/form_id}/form_data{/id}',
    },
    bulkDeleteFormData: {
      method: 'DELETE',
      url: 'internal_api/forms{/form_id}/form_data/bulk_destroy',
    },
    policy: {
      method: 'GET',
      url: 'upload/image_policy',
    },
    downloadCsv: { method: 'GET', url: 'internal_api/forms{/id}/csv_data' },
  }
)

export const Comment = Vue.resource(
  'internal_api/comments',
  {},
  {
    update: {
      method: 'PUT',
      url: 'internal_api/comments{/id}',
    },
    destroy: {
      method: 'DELETE',
      url: 'internal_api/comments{/id}',
    },
    create: {
      method: 'POST',
      url: 'internal_api/comments',
    },
    updateViewState: {
      method: 'PUT',
      url: 'internal_api/comments{/id}/update_viewed_state',
    },
  }
)

export const Notification = Vue.resource(
  'internal_api/notifications',
  {},
  {
    update: {
      method: 'PUT',
      url: 'internal_api/notifications{/id}',
    },
    destroy: {
      method: 'PUT',
      url: 'internal_api/notifications{/id}',
    },
    setAllToView: {
      method: 'PUT',
      url: 'internal_api/notifications/view_all_notifications',
    },
  }
)

export const AppInterest = Vue.resource(
  'internal_api/app_interests',
  {},
  {
    register: {
      method: 'POST',
      url: 'internal_api/app_interests',
    },
    get: {
      method: 'GET',
      url: 'internal_api/app_interests',
    },
  }
)

Vue.prototype.api = {
  User,
  Site,
  Openai,
  Version,
  Invoice,
  siteApp,
  SiteUser,
  ParseCloud,
  Project,
  ProjectUser,
  OrganisationUser,
  Organisation,
  Invitation,
  Form,
  Comment,
  ProfileConfig,
  Notification,
  OrganisationSubscription,
  AppInterest,
}
