<template>
  <div>
    <div v-if="showBrowser">
      <div class="header-browser" align="left">
        <v-icon dark left>mdi-bitbucket</v-icon>
        <span> CHOOSE A REPO </span>
        <v-btn color="error" class="one_line" text @click="showBrowser = false">
          CANCEL
        </v-btn>
        <span v-show="!reposSelected">
          <v-select
            v-model="selectedOrganisation"
            :items="filteredOrgs"
            @change="fetchRepos"
            placeholder="Select Organisation"
            item-text="slug"
            item-value="slug"
            class="d-inline-block"
            dark
          ></v-select>
        </span>
        <span v-if="!reposSelected">
          <v-text-field
            dark
            class="d-inline-block"
            placeholder="Filter"
            v-model="filterText"
            filled
          ></v-text-field>
        </span>
        <br />
        <div class="mt-3 mb-3">
          <span @click="clearRepoPath">
            <v-icon dark left>mdi-folder</v-icon> Repositories
          </span>
          <span v-if="currentGitRepoPath">
            > {{ currentGitPath }}
            <span align="right">
              <v-btn
                small
                @click="selectFolder(reposCursor)"
                class="white--text choose-btn"
                color="y1"
                >Choose This Folder</v-btn
              >
            </span>
          </span>
        </div>
      </div>

      <div v-if="!currentGitRepoPath" class="mt-4 repos-block" align="left">
        <div
          class="mt-1 git-row"
          v-for="repo in filteredRepos"
          @click="selectRepo(repo.full_name)"
        >
          <v-icon dark v-if="repo.private" left>mdi-folder-key</v-icon>
          <v-icon dark v-else left>mdi-folder</v-icon>
          <span> {{ repo.full_name }} </span>
        </div>
      </div>

      <div class="mt-2 repos-block" align="left" v-if="currentGitRepoPath">
        <div>
          <v-icon dark left>mdi-source-branch</v-icon>
          <v-select
            v-model="selectedBranch"
            :items="gitBranches"
            item-text="name"
            item-value="name"
            class="d-inline-block"
            dark
          ></v-select>
        </div>
      </div>
    </div>
    <div class="syncing-text" v-else>
      <p>
        <b>{{ activeSite.url }} </b> is syncing with <b>{{ savedData.path }}</b>
        <span v-if="savedData.branch != null"> {{ savedData.branch }}</span>
      </p>
      <v-btn
        @click="showBrowser = true"
        class="white--text mt-3"
        color="orange darken-4"
      >
        <span v-if="selectedRepo == null" class="pr-1">Select </span>
        <span v-else class="pr-1"> Change </span> Bitbucket Folder
      </v-btn>
      <div v-if="selectedRepo != null" align="center">
        <v-checkbox
          dark
          class="align-center justify-center d-block checkbox-auto"
          @change="changeAutoDeploy"
          v-model="deployHandler"
          :label="`${deployHandler.toString() == 'true' ? 'Disable' : 'Enable'} Auto-Deployment`"
        ></v-checkbox>
        Push to your Bitbucket repository and automatically trigger a
        re-deployment
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BitbucketBrowser',
  props: {
    savedData: Object,
    url: String,
  },

  data() {
    return {
      repos: [],
      selectedRepo: this.savedData.path,
      selectedBranch: this.savedData.branch,
      branches: [],
      files: [],
      organisations: [],
      reposCursor: this.savedData.path,
      showBrowser: this.savedData.path == null,
      deployHandler: this.savedData.autoDeploy,
      filePath: '',
      filterText: '',
      selectedOrganisation: null,
    }
  },
  methods: {
    async getDataFromApi(path, type, refreshed = false, nextURL = null) {
      let reqParams = {
        params: {},
        headers: { Authorization: 'Bearer ' + this.savedData.token },
      }
      let url = ''
      if (nextURL) url = nextURL
      else url = this.url + path
      try {
        let res = await this.$http.get(url, reqParams)
        this[type] = this[type].concat(res.body.values)
        if (res.body.next)
          await this.getDataFromApi(path, type, false, res.body.next)
        else if (type == 'organisations') {
          if (this.selectedOrganisation == null && type == 'organisations')
            this.selectedOrganisation = res.body.values[0].slug
          this.fetchRepos()
        }
        if (type == 'branches' && this.selectedBranch == null)
          this.selectedBranch = res.body.values[0].name
      } catch (err) {
        console.log(err)
        if (err.includes('Access token expired.') && !refreshed) {
          await this.refreshToken()
          await this.getDataFromApi(path, type, true)
        }
      }
    },
    async refreshToken() {
      let res = await this.$http.post('oauth/refresh_bitbucket_token')
      this.$store.commit('user/updateBitbucketToken', res.body.access_token)
    },
    fetchRepos() {
      this.repos = []
      if (
        this.selectedOrganisation == null ||
        this.selectedOrganisation.length == 0
      )
        return

      this.getDataFromApi(`/repositories/${this.selectedOrganisation}`, 'repos')
    },
    fetchOrganisations() {
      this.organisations = []
      this.getDataFromApi('/workspaces', 'organisations')
    },
    selectRepo(full_name) {
      this.reposCursor = full_name
      this.branches = []
      this.getDataFromApi(
        '/repositories/' + this.reposCursor + '/refs/branches',
        'branches'
      )
    },
    clearRepoPath() {
      this.reposCursor = null
      this.selectedRepo = null
      this.selectedBranch = null
    },
    selectFolder(folderPath) {
      let params = {
        github_path: null,
        github_branch: null,
        bitbucket_path: folderPath,
        bitbucket_branch: this.selectedBranch,
        dropbox_path: null,
        oauth_user_id: this.$store.state.user.current.id,
      }
      this.$store.dispatch('sites/update', { site: params })
      this.selectedRepo = folderPath
      this.showBrowser = false
    },
    changeAutoDeploy() {
      this.$store.dispatch('sites/update', {
        site: { bitbucket_autodeploy: this.deployHandler },
      })
    },
  },
  mounted() {
    if (this.reposCursor != null)
      this.selectedOrganisation = this.reposCursor.split('/')[0]
    this.fetchOrganisations()
  },
  computed: {
    gitRepos() {
      let sorted = this.repos.sort((a, b) => {
        a.name.localeCompare(b.name)
      })
      return sorted
    },
    currentGitRepoPath() {
      return this.reposCursor != null || this.selectedRepo != null
    },
    currentGitPath() {
      return this.reposCursor == null ? this.selectedRepo : this.reposCursor
    },
    gitBranches() {
      let sorted = this.branches.sort((a, b) => a.name.localeCompare(b.name))
      return sorted
    },
    filteredOrgs() {
      let sorted = this.organisations.sort((a, b) =>
        a.slug.localeCompare(b.slug)
      )
      return sorted
    },
    reposSelected() {
      return this.reposCursor != null && this.reposCursor.length > 0
    },
    filteredRepos() {
      if (this.filterText.length > 0) {
        let filtered = this.gitRepos.filter(
          (f) =>
            f.full_name.toLowerCase().indexOf(this.filterText.toLowerCase()) >
            -1
        )
        return filtered
      } else return this.gitRepos
    },
  },
}
</script>

<style>
.one_line {
  margin-top: -5px;
}
.syncing-text {
  align-items: center;
  text-align: center;
}
.header-browser {
  border-bottom: 1px solid lightgray;
  border-bottom-color: rgba(255, 255, 255, 0.1);
  padding-bottom: 4px;
}
.git-row {
  height: 36px;
  border-bottom: 1px solid lightgray;
  border-bottom-color: rgba(255, 255, 255, 0.1);
  padding-top: 6px;
}
.repos-block {
  max-height: 700px;
  overflow-y: auto;
}
.choose-btn {
  margin-top: 8px;
  float: right;
  bottom: 10px;
}
.checkbox-auto .v-input--selection-controls__input {
  vertical-align: top;
}
.checkbox-auto .v-input__slot {
  display: block;
  text-align: center;
}
</style>
