<template>
  <div class="SiteFeedbackElement mb-4">
    <div class="d-flex items-center justify-space-between">
      <v-rating
        half-increments
        readonly
        density="compact"
        v-model="feedback.rating"
        background-color="red lighten-3"
        color="red"
      />
      <div>
        <span v-if="!mine"
          >By <b>{{ feedback.author }}</b
          >,
        </span>
        <div class="draft-status" v-if="feedback.status == 'Draft'">Draft</div>
        <span>{{ modified }}</span>
      </div>
    </div>
    <div class="pa-2" v-html="feedback.comment"></div>
  </div>
</template>

<script>
const dayjs = require('dayjs')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default {
  name: 'SiteFeedbackElement',
  props: {
    feedback: Object,
    mine: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      allComments: false,
      comments: [],
      usersList: [],
      loading: true,
    }
  },
  computed: {
    modified() {
      return dayjs(this.feedback.updatedAt).fromNow()
    },
  },
  methods: {},
}
</script>

<style lang="scss">
$style: SiteFeedbackElement;
.#{$style} {
  .v-rating .v-icon {
    padding: 0;
  }
  .draft-status {
    color: gray;
    font-style: italic;
    text-align: right;
  }
}
</style>
