<template>
  <v-card class="mural-card" @click="onClick">
    <v-img :src="thumbnailUrl" class="mural-thumbnail"></v-img>
    <div class="mural-info">
      <div class="mural-title">{{ mural.title }}</div>
      <div class="mural-details">Modified {{ modified }}</div>
    </div>
  </v-card>
</template>

<script>
const dayjs = require('dayjs')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default {
  name: 'MuralCard',
  props: {
    mural: Object,
    onMuralSelect: Function,
  },
  computed: {
    thumbnailUrl() {
      return this.mural.thumbnailUrl ===
        'https://app.mural.co/static/images/mural-thumb.svg'
        ? ''
        : this.mural.thumbnailUrl
    },
    modified() {
      return dayjs(this.mural.updatedOn).fromNow()
    },
  },
  methods: {
    onClick() {
      this.onMuralSelect(this.mural)
    },
  },
}
</script>

<style scoped lang="scss">
.mural-card {
  cursor: pointer;
  background-color: $N5;
}
.mural-thumbnail {
  height: 200px;
  background-size: cover;
  background-position: center;
}
.mural-info {
  padding: 8px;
  text-align: center;
}
.mural-title {
  font-size: 16px;
  font-weight: bold;
}
.mural-details {
  margin-top: 4px;
  font-size: 12px;
  color: gray;
}
</style>
