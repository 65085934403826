<template>
  <div class="VulcanAuthForm">
    <h2 class="mb-4">Vulcan Auth</h2>
    <v-card class="VulcanAuthForm__card">
      <v-form @submit.prevent="signIn">
        <span class="VulcanAuthForm__label">Email:</span>
        <v-text-field
          v-model="email"
          @input="onInputEmail"
          :error-messages="errorVisibleEmail"
          :disabled="loading"
          type="email"
          autofocus
          hide-details
          dense
          class="mb-4"
        />
        <span class="VulcanAuthForm__label">Password:</span>
        <v-text-field
          v-model="password"
          @input="onInputPassword"
          :error-messages="errorVisiblePassword"
          :disabled="loading"
          type="password"
          counter="32"
          class="mb-4"
          hide-details
          dense
        />
        <v-btn
          type="submit"
          :disabled="loading"
          class="mt-6"
          color="primary"
          large
          rounded
          >Sign In</v-btn
        >
      </v-form>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'VulcanAuthForm',
  props: {
    authorize: Function,
  },
  data() {
    return {
      email: '',
      password: '',
      errorVisibleEmail: null,
      errorVisiblePassword: null,
      loading: false,
    }
  },
  computed: {
    errorEmail() {
      if (!this.email) return 'Email can not be empty'
      if (!this.email.match(/.+@.+/)) return 'Email must be valid'
      return null
    },
    errorPassword() {
      if (!this.password) return 'Password can not be empty'
      if (this.password.length < 8 || this.password.length > 32)
        return 'Password shoud contain 8-32 characters'
      return null
    },
  },
  methods: {
    onInputEmail() {
      this.errorVisibleEmail = null
      this.dirtyForm = true
    },
    onInputPassword() {
      this.errorVisiblePassword = null
      this.dirtyForm = true
    },
    async signIn() {
      this.dirtyForm = false
      if (this.errorEmail || this.errorPassword) {
        this.errorVisibleEmail = this.errorEmail
        this.errorVisiblePassword = this.errorPassword
        return
      }
      this.loading = true
      await this.authorize(this.email, this.password)
      this.loading = false
    },
  },
}
</script>

<style scoped lang="scss">
$style: VulcanAuthForm;
.#{$style} {
  margin: 150px auto;
  max-width: 450px;
  &__card {
    background-color: $N4;
    border: 1px solid $N5;
    padding: 20px;
  }
  &__label {
    @extend %label-styles;
  }
}
</style>
