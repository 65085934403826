import { Invoice } from '@/models/Invoice'
import { Invoice as InvoiceResource } from '@/resources/forge'

const store = {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    /**
     * Sets the list of invoices in the store state.
     * @param {Array} list - The list of invoices.
     */
    setList(state, list) {
      state.list = list
    },
  },
  actions: {
    /**
     * Loads invoices from the API and sets them in the store state.
     * @returns {Promise} A promise resolving to the API response.
     */
    async load({ commit }) {
      const res = await InvoiceResource.get()
      commit(
        'setList',
        (res.body.invoices || []).map((v) => new Invoice(v))
      )
      return res
    },
    /**
     * Downloads a CSV file of invoices.
     * @returns {Promise} A promise resolving to the API response.
     */
    async downloadCsv() {
      const res = await InvoiceResource.downloadCsv()
      return res
    },
  },
}

export default store
