<template>
  <div class="PublisherDashboardTopPlugins">
    <div class="PublisherDashboardTopPlugins__header">
      <h4 class="PublisherDashboardTopPlugins__title">Top Plugins</h4>
      <publisher-dashboard-top-plugin-filter
        :filter="sortBy"
        :disabled="loading"
        @updateSortBy="updateSortBy"
      />
    </div>
    <v-row class="PublisherDashboardTopPlugins__body" v-if="!loading">
      <v-col
        v-for="plugin in topPlugins"
        :key="plugin.id"
        cols="12"
        sm="6"
        md="4"
      >
        <publisher-dashboard-plugin-card :plugin="plugin" />
      </v-col>
    </v-row>
    <div v-else class="spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import PublisherDashboardTopPluginFilter from './PublisherDashboardTopPluginFilter.vue'
import PublisherDashboardPluginCard from './PublisherDashboardPluginCard.vue'
const Parse = require('parse')

const legends = [
  {
    label: 'Ready for Sale',
    color: 'rgb(87, 76, 250)',
    slug: 'ready-for-sale',
  },
  { label: 'In Review', color: 'rgba(87, 76, 250, 0.5)', slug: 'in-review' },
  { label: 'Draft', color: 'rgba(87, 76, 250, 0.2)', slug: 'draft' },
]

export default {
  name: 'PublisherDashboardTopPlugins',
  components: {
    PublisherDashboardTopPluginFilter,
    PublisherDashboardPluginCard,
    IntersectingCirclesSpinner,
  },
  data() {
    return {
      sortBy: 'installsCount',
      data: {
        labels: ['Ready for Sale', 'In Preview', 'Draft'],
        datasets: [
          {
            data: [300, 50, 100],
            backgroundColor: [
              'rgb(87, 76, 250)',
              'rgba(87, 76, 250, 0.5)',
              'rgba(87, 76, 250, 0.2)',
            ],
            borderColor: '#1C1B2E',
            borderWidth: 4,
            borderAlign: 'inner',
            spacing: 10,
            hoverOffset: 4,
          },
        ],
      },
      options: {
        cutoutPercentage: 80,
        legend: false,
      },
      legendsData: legends,
      total: 0,
      pluginList: [],
      topPlugins: [],
      loading: false,
    }
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current,
    }),
  },
  async mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id)
    Parse.serverURL = this.currentPublisherInfo.parse_server_url
    await this.fetchTopPluginsList()
  },
  methods: {
    async fetchTopPluginsList() {
      this.loading = true
      const res = await Parse.Cloud.run('getTopPluginsList', {
        sortBy: this.sortBy,
        parseServerSiteId: this.currentPublisherInfo.parse_server_site_id,
      })
      this.topPlugins = res.apps
      this.loading = false
    },
    async updateSortBy(params) {
      this.sortBy = params.sortBy
      await this.fetchTopPluginsList()
    },
  },
}
</script>

<style lang="scss">
$style: PublisherDashboardTopPlugins;
.#{$style} {
  margin-bottom: 50px;
  &__header {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }
  &__title {
    color: $white;
    font-weight: 500;
    font-size: $H20;
  }
  &__body {
    margin-top: 10px;
  }
  @media screen and (max-width: 600px) {
    &__header {
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }
  }
}
.w-full {
  width: 100%;
}
</style>
