<template>
  <ParentAuthForm subtitle="Reset password" :loading="loading" :type="2">
    <div v-if="!finished">
      <v-form @submit.prevent="reset" class="form mt-14 mx-auto">
        <div>
          Enter your email you registered with and we'll send you instructions
          to reset your password.
        </div>
        <v-text-field
          v-model="email"
          @input="onInputEmail"
          placeholder="your@email.com"
          :error-messages="errorVisibleEmail"
          :disabled="loading"
          type="email"
          class="mt-1"
          autofocus
          filled
          dense
        />

        <div v-if="errorMain" class="error-main pa-4">
          {{ errorMain }}
        </div>

        <v-btn
          class="mt-8 mx-auto d-block login-btn y1"
          :disabled="!dirtyForm || loading"
          type="submit"
        >
          Send reset link
        </v-btn>
      </v-form>
    </div>

    <div v-else class="mt-10">
      You will receive an email with instructions about how to reset your
      password in a few minutes.
      <router-link to="/login" class="d-block text-center mt-8">
        Return to Login
      </router-link>
    </div>
  </ParentAuthForm>
</template>

<script>
import ParentAuthForm from '@/components/AuthForms/ParentAuthForm'
export default {
  name: 'ForgotPasswordForm',
  components: { ParentAuthForm },
  data() {
    return {
      email: '',

      errorVisibleEmail: null,
      errorMain: null,

      finished: false,
      dirtyForm: false,
      loading: false,
    }
  },
  computed: {
    errorEmail() {
      if (!this.email) return 'Email can not be empty'
      if (!this.email.match(/.+@.+/)) return 'Email must be valid'
      return null
    },
  },
  methods: {
    onInputEmail() {
      this.errorVisibleEmail = null
      this.dirtyForm = true
    },

    reset() {
      this.dirtyForm = false
      this.errorMain = null

      if (this.errorEmail) {
        this.errorVisibleEmail = this.errorEmail
        return
      }

      this.loading = true

      this.api.User.resetPassword({
        user: {
          email: this.email,
        },
      })
        .then(() => {
          this.loading = false
          this.finished = true
        })
        .catch((error) => {
          this.loading = false
          if (!error) return

          let errorMain = ''
          error.forEach((message) => {
            errorMain = errorMain + message + '<br>'
          })
          this.errorMain = errorMain.substr(0, errorMain.length - 4) + '.'
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.error-main {
  background: #e94a53;
  border-radius: 2px;
}

.link-disabled {
  pointer-events: none;
  color: rgba(0, 0, 0, 0.26);
}
</style>
