<template>
  <div>
    <span v-if="schema.collapsable">
      <v-expansion-panels accordion>
        <v-expansion-panel>
          <v-expansion-panel-header>
            <b>{{ schema.label || schema.title }}</b>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <vue-form-generator
              :schema="schema.schema"
              :model="value"
              :options="formOptions"
            ></vue-form-generator>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </span>
    <span v-if="!schema.collapsable">
      <vue-form-generator
        :schema="schema.schema"
        :model="value"
        :options="formOptions"
      ></vue-form-generator>
    </span>
  </div>
</template>

<script>
import VueFormGenerator from 'vue-form-generator'
import Vue from 'vue'

export default {
  name: 'FieldCustomObject',
  mixins: [VueFormGenerator.abstractField],
  created() {
    if (!this.value) this.value = {}
  },
  formOptions: {
    validateAfterLoad: true,
    validateAfterChanged: true,
  },
  mounted() {
    if (!this.value) return

    let valueKeys = Object.keys(this.value)
    let keyTypes = {}

    for (let i = 0; i < valueKeys.length; i++) {
      let key = valueKeys[i]
      keyTypes[key] = typeof this.value[key]
    }
    this.keyTypes = keyTypes
  },

  data() {
    return {
      newKeyType: 'string',
      newKeyName: '',
      keyTypes: {},
    }
  },

  methods: {
    removeElement(index) {
      let value = this.value
      delete value[index]

      this.value = { ...value }

      let keyTypes = this.keyTypes
      delete keyTypes[index]

      this.keyTypes = { ...keyTypes }
    },

    addKey() {
      //TODO change to vm.$set
      Vue.set(this.value, this.newKeyName, undefined)
      Vue.set(this.keyTypes, this.newKeyName, this.newKeyType)
      this.newKeyName = ''
    },
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
