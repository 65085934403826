<template>
  <v-app dark id="app" class="app">
    <div v-if="loading" class="spinner">
      <LottieLoader :fullscreen="true" />
    </div>

    <Page404 v-if="!loading && showing404" />

    <div v-if="!loading && !showing404">
      <IntercomScript />
      <TheNavigation v-if="showNavbar" />
      <TheAppBar v-if="showNavbar" />
      <v-main class="main-app">
        <v-container class="pa-0" fluid>
          <MobileSiteMenu />
          <MoreAppBar />
          <router-view />
        </v-container>
      </v-main>
      <SdkServer />
    </div>

    <Snackbar />
    <Modal />
  </v-app>
</template>

<script>
import { mapState } from 'vuex'

import './components/Modals'

import Snackbar from '@/components/_Common/Snackbar'
import Modal from '@/components/_Common/Modal'
import LottieLoader from '@/components/_Common/LottieLoader'
import TheNavigation from '@/components/TheNavigation'
import IntercomScript from '@/components/IntercomScript'
import TheAppBar from '@/components/TheAppBar'
import MobileSiteMenu from '@/components/MobileSiteMenu'
import MoreAppBar from '@/components/MoreAppBar'
import Page404 from '@/components/Page404'
import SdkServer from '@/components/SdkServer'

export default {
  name: 'App',
  components: {
    Snackbar,
    Modal,
    LottieLoader,
    TheAppBar,
    TheNavigation,
    MobileSiteMenu,
    MoreAppBar,
    Page404,
    SdkServer,
    IntercomScript,
  },
  computed: {
    ...mapState({
      loading: (state) => state.application.loading,
      showing404: (state) => state.application.showing404,
      user: (state) => state.user.current,
      showNavbar(state) {
        return (
          !!state.user.current &&
          !(
            this.$route &&
            ['githubAuth', 'bitbucketAuth', 'dropboxAuth'].includes(
              this.$route.name
            )
          )
        )
      },
    }),
  },
  mounted() {
    let stripeScript = document.createElement('script')
    stripeScript.setAttribute('src', 'https://js.stripe.com/v3/')
    document.head.appendChild(stripeScript)
    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute(
      'src',
      'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
    )
    recaptchaScript.async = true
    recaptchaScript.defer = true
    document.head.appendChild(recaptchaScript)
  },
}
</script>

<style scoped>
#app {
  background-color: #1c1b2e;
  color: white;
}
.spinner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.more-app {
  position: relative;
}
</style>
