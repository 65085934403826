<template>
  <div class="SiteApp">
    <div class="image-wrapper">
      <img :src="app.developerContent.keyImage" class="image" />
      <div class="forge-native-tag" v-if="app.status === 'in-development'">
        DEV MODE
      </div>
      <div class="plugin-type-icon-wrapper">
        <v-tooltip
          slot="append"
          bottom
          v-if="icons && icons.length > 0"
          v-for="icon in icons"
        >
          <template #activator="{ on }">
            <v-icon class="plugin-type-icon" v-on="on" :color="icon.color">{{
              icon.icon
            }}</v-icon>
          </template>
          <span>{{ icon.label }}</span>
        </v-tooltip>
      </div>
    </div>
    <div class="pa-5">
      <div class="d-flex justify-space-between items-center">
        <div class="app-name">
          {{ app.name }}
        </div>
        <v-menu offset-x>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text icon compact v-on="on">
              <the-icon icon="more-vert" />
            </v-btn>
          </template>
          <v-list dark class="the-app-bar__dropdown">
            <v-list-item>
              <v-list-item-title class="SiteApp__action">
                <span class="SiteApp__icon">
                  <v-icon>exit_to_app</v-icon>
                </span>
                Documentation
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="SiteApp__action">
                <span class="SiteApp__icon">
                  <v-icon>exit_to_app</v-icon>
                </span>
                Visit Product Site
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title class="SiteApp__action">
                <span class="SiteApp__icon">
                  <v-icon>help</v-icon>
                </span>
                Get Support
              </v-list-item-title>
            </v-list-item>
            <v-list-item v-if="app.installed">
              <div @click="shareFeedback" class="SiteApp__action">
                <span class="SiteApp__icon">
                  <the-icon icon="eye-on" />
                </span>
                <span>Share Feedback</span>
              </div>
            </v-list-item>
            <v-list-item v-if="app.installed">
              <div @click="resetData" class="SiteApp__action dangerous">
                <span class="SiteApp__icon">
                  <the-icon icon="reset" />
                </span>
                <span>Reset Data</span>
              </div>
            </v-list-item>
            <v-list-item v-if="app.installed">
              <div @click="remove" class="SiteApp__action dangerous">
                <span class="SiteApp__icon">
                  <the-icon icon="trash" />
                </span>
                <span>Uninstall App</span>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <div v-if="categoryNames" class="mt-2 apps-plugin-tag-wrapper">
        <span class="tag" v-for="categoryName in categoryNames">{{
          categoryName
        }}</span>
      </div>
      <div
        v-html="app.developerContent.description"
        class="app-description my-5"
      ></div>

      <div
        class="d-flex justify-space-between align-center"
        v-if="app.installed"
      >
        <v-btn color="n5" large dark @click="launch"> Open App </v-btn>
        <v-btn color="y1" text @click="edit">
          <the-icon class="mr-2" color="y1" icon="square-edit" />
          Edit Settings
        </v-btn>
      </div>
      <div class="d-flex justify-space-between align-center" v-else>
        <v-btn large dark color="y1" @click="install">
          <the-icon class="mr-2" color="y1" icon="download" />
          Install
        </v-btn>
        <div v-if="app.developerData">
          <v-rating
            v-if="app.developerData.rating"
            v-model="app.developerData.rating"
            readonly
            background-color="red lighten-3"
            color="red"
            small
            dense
          />
          <div
            class="d-flex align-center justify-end mt-2"
            v-if="app.developerData.installsCount > 0"
          >
            <the-icon
              class="mr-2"
              color="y1"
              icon="download"
              width="16"
              height="16"
            />
            {{ app.developerData.installsCount }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex'

import router from '@/router'

import { MODAL_ALERT, MODAL_FEEDBACK } from '@/components/Modals'
import { initParse, installApp, uninstallApp } from '@/utils/parse'

export default {
  name: 'SiteAppsPlugin',
  props: {
    app: Object,
    categories: Array,
  },
  computed: {
    ...mapState({
      currentSite: (state) => state.sites.active,
      showMoreAppBar: (state) => state.sites.showMoreAppBar,
      allSitePluginApps: (state) => state.publisher.allSiteApps,
      siteAppInstances: (state) => state.publisher.siteInstalledApps,
    }),
    launched() {
      return this.app.url
    },
    icons() {
      const icons = []
      if (this.app.pluginKind) {
        if (this.app.pluginKind.includes('Site'))
          icons.push({
            label: 'Site-wide Plugin',
            icon: 'mdi-web',
            color: '#4CAF50',
          })
        if (this.app.pluginKind.includes('User'))
          icons.push({
            label: 'Personal Plugin',
            icon: 'mdi-account-lock',
            color: '#7E57C2',
          })
      }
      return icons
    },
    categoryNames() {
      if (this.categories && this.app.developerContent?.categories) {
        const categories = this.app.developerContent?.categories.map(
          ({ id }) => id
        )
        const texts = this.categories
          .filter((category) => categories.includes(category.value))
          .map((category) => category.text)
        return texts
      }
      return []
    },
  },
  methods: {
    ...mapMutations('sites', [
      'setActiveApp',
      'setActivePlugin',
      'setShowMoreAppBar',
    ]),
    launch() {
      this.setActiveApp(this.app)
      this.setShowMoreAppBar(true)
      router.push({ name: 'site_plugin', params: { slug: this.app.slug } })
    },
    remove() {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Uninstall App',
          text: `You are going to remove ${this.app.name}. Are you sure?`,
          actionBtnLabel: 'Remove',
          showCancelBtn: true,
          action: async () => {
            const publisher = this.$store.getters[
              'publisher/getPublisherByAppId'
            ](this.app.id)
            if (publisher && publisher.parse_server_app_id) {
              initParse(
                publisher.parse_server_url,
                publisher.parse_server_app_id
              )
              const instance = this.siteAppInstances.find(
                (i) => i.developerApp.id === this.app.id
              )
              const removedId = await uninstallApp({
                instanceId: instance.id,
                siteId: this.currentSite.id,
                parseServerSiteId: publisher.parse_server_site_id,
              })
              if (removedId) location.reload()
            }
          },
        },
      })
    },
    resetData() {
      const instance = this.siteAppInstances.find(
        (i) => i.developerApp.id === this.app.id
      )
      const instanceId = instance?.id || ''
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i)
        if (
          key.indexOf(this.app.slug) !== -1 &&
          key.indexOf(this.currentSite.id) !== -1
        ) {
          localStorage.removeItem(key)
        }
        if (instanceId && key.indexOf(instanceId) !== -1) {
          localStorage.removeItem(key)
        }
      }
      alert('Successfully reset the data.')
    },
    edit() {
      // this.setActiveApp(this.app);
      // router.push({ name: 'site_plugin_edit', params: { slug: this.app.slug } });
      this.setActivePlugin(this.app)
    },
    install() {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'App installation',
          text: `You are going to install ${this.app.name}. Are you sure?`,
          actionBtnLabel: 'Install',
          showCancelBtn: true,
          action: async () => {
            const publisher = this.$store.getters[
              'publisher/getPublisherByAppId'
            ](this.app.id)
            if (publisher && publisher.parse_server_app_id) {
              initParse(
                publisher.parse_server_url,
                publisher.parse_server_app_id
              )
              const installedId = await installApp({
                appId: this.app.id,
                siteId: this.currentSite.id,
                parseServerSiteId: publisher.parse_server_site_id,
              })
              if (installedId) location.reload()
            }
          },
        },
      })
    },
    shareFeedback() {
      const publisher = this.$store.getters['publisher/getPublisherByAppId'](
        this.app.id
      )
      if (publisher && publisher.parse_server_app_id) {
        initParse(publisher.parse_server_url, publisher.parse_server_app_id)
        this.$store.commit('application/openModal', {
          component: MODAL_FEEDBACK,
          props: {
            app: this.app,
            parseServerSiteId: publisher.parse_server_site_id,
          },
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
$style: SiteApp;
.#{$style} {
  border: 1px solid $N6;
  border-radius: 8px;
  background: $N7;
  .app-name {
    font-size: 16px;
  }
}
.#{$style}__icon {
  width: 20px;
  height: 20px;
  text-align: center;
  margin-right: 10px;
  .v-icon.v-icon {
    font-size: 20px;
  }
}
.#{$style}__action {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  &.dangerous {
    color: $Y1;
  }
}
.image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  aspect-ratio: 16/7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.image-wrapper {
  position: relative;
  margin-bottom: 5px;
}
.forge-native-tag {
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 6px;
  padding: 4px 6px;
  background: $B5;
  display: flex;
  align-items: center;
}
.plugin-type-icon-wrapper {
  position: absolute;
  bottom: 10px;
  right: 10px;
  .plugin-type-icon {
    border-radius: 6px;
    margin-left: 8px;
    background: $B5;
    padding: 2px 4px;
  }
}
.apps-plugin-tag-wrapper {
  height: 3rem;
}
span.tag {
  padding: 4px 8px;
  font-size: 11px;
  font-weight: 400;
  border-radius: 6px;
  margin-right: 8px;
  background: $N5;
  margin-bottom: 5px;
  display: inline-block;
}
.image-wrapper {
  position: relative;
  margin-bottom: 5px;
}
</style>
