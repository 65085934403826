<template>
  <div class="my-4 mx-6">
    <div class="mb-6">
      <router-link class="breadcrumb-link" to="/publisher/reports">
        Reports
      </router-link>
      <span class="breadcrumb-separator">/</span>
      <span class="breadcrumb-link">Top Developers</span>
    </div>
    <div
      class="TopDevelopersReport__header d-flex flex-column flex-sm-row justify-space-between align-sm-center gap-3 pa-0 mt-4"
    >
      <div class="d-flex flex-column flex-sm-row align-sm-center gap-3">
        <v-select
          v-model="filter.sortBy"
          :items="sortOptions"
          item-text="label"
          item-value="value"
          class="TopDevelopersReport__select-filter flex-grow-0"
          height="40"
          dense
          dark
          hide-details
          :readonly="reportReady"
          @click="displayReadonlyAlert"
        />
        <v-select
          v-model="filter.limit"
          :items="limits"
          item-text="label"
          item-value="value"
          class="TopDevelopersReport__select-filter flex-grow-0"
          height="40"
          dense
          dark
          hide-details
          :readonly="reportReady"
          @click="displayReadonlyAlert"
        />
        <v-select
          v-model="filter.period"
          :items="periods"
          item-text="label"
          item-value="value"
          class="TopDevelopersReport__select-filter flex-grow-0"
          height="40"
          dense
          dark
          hide-details
          :readonly="reportReady"
          @click="displayReadonlyAlert"
        />
        <RangePicker
          v-if="filter.period == 'custom'"
          :start-date.sync="filter.startDate"
          :end-date.sync="filter.endDate"
          :read-only="reportReady"
          :click="displayReadonlyAlert"
        />
      </div>
      <div class="d-flex" v-if="reportReady">
        <v-btn
          class="v-btn primary-btn mr-4"
          @click="onDowloadReport"
          elevation="2"
          large
        >
          <v-icon dark left> mdi-download </v-icon>
          Download
        </v-btn>
        <v-btn
          class="v-btn reset-btn"
          @click="onResetFilter"
          elevation="2"
          large
        >
          <v-icon dark left> mdi-refresh </v-icon>
          Reset
        </v-btn>
      </div>
      <v-btn
        class="v-btn primary-btn"
        @click="onRunReport"
        elevation="2"
        large
        v-else
      >
        <v-icon dark left> mdi-play </v-icon>
        Run
      </v-btn>
    </div>
    <div class="TopDevelopersReport__body">
      <v-row v-if="reportReady" class="mt-4">
        <v-col>
          <v-data-table
            :headers="headers"
            :items="tableData"
            ref="dataTable"
            class="data-table"
            @click:row="handleClickDeveloperRow"
          />
        </v-col>
      </v-row>
      <div v-if="loading" class="spinner">
        <IntersectingCirclesSpinner
          :animation-duration="1200"
          :size="100"
          :color="'#496DDB'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import RangePicker from './RangePicker.vue'
import jsPDF from 'jspdf'
import { getFirstMondayAndLastSunday } from '@/utils/common'
import 'jspdf-autotable'
const Parse = require('parse')

export default {
  name: 'TopDevelopersReport',
  components: {
    RangePicker,
    IntersectingCirclesSpinner,
  },
  data() {
    const periods = [
      { value: 'all', label: 'All Time' },
      { value: 'custom', label: 'Custom Range' },
    ]

    const limits = [10, 20, 50, 100].map((value) => ({
      value,
      label: 'Top ' + value,
    }))

    const [firstMonday, lastSunday] = getFirstMondayAndLastSunday()

    return {
      periods,
      limits,
      sortOptions: [
        { value: 'installsCount', label: 'Sort By Installs Count' },
        { value: 'rating', label: 'Sort By Rating' },
        { value: 'revenue', label: 'Sort By Revenue', disabled: true },
      ],
      filter: {
        period: 'all',
        sortBy: 'installsCount',
        limit: 10,
        startDate: firstMonday,
        endDate: lastSunday,
      },
      data: [],
      loading: false,
      reportReady: false,
      tableData: [],
    }
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current,
    }),
    headers() {
      const columns = [
        {
          text: 'Name',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'Email', value: 'email' },
      ]
      if (this.filter.sortBy === 'installsCount')
        return [...columns, { text: 'Installs Count', value: 'installsCount' }]
      if (this.filter.sortBy === 'rating')
        return [...columns, { text: 'Rating', value: 'rating' }]
      return columns
    },
  },
  mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id)
    Parse.serverURL = this.currentPublisherInfo.parse_server_url
  },
  methods: {
    // Main Action part of the component.
    // Run button click event handler
    async onRunReport() {
      const params = {
        sortBy: this.filter.sortBy,
        limit: this.filter.limit,
      }

      let result

      if (this.filter.period === 'custom') {
        if (!this.filter.startDate && !this.filter.endDate) {
          alert('Please select either start or end date.')
          return
        }
        params['startDate'] = this.filter.startDate
        params['endDate'] = this.filter.endDate
        result = await Parse.Cloud.run('getTopDevelopersWithinPeriod', {
          parseServerSiteId: this.currentPublisherInfo?.parse_server_site_id,
          filter: params,
        })
      } else {
        result = await Parse.Cloud.run('getTopDevelopers', {
          parseServerSiteId: this.currentPublisherInfo?.parse_server_site_id,
          filter: params,
        })
      }
      this.loading = true
      if (result.status === 'success') {
        this.data = result.developers
        this.tableData = result.developers
        this.reportReady = true
      }
      this.loading = false
    },

    // Download button click event handler
    async onDowloadReport() {
      var source = this.$refs['dataTable']

      let columnHeader = ['Name', 'URL', 'Status', 'InstallsCount']
      let pdfName = 'Plugin Installs'
      const rows = source.items.map((element) => [
        element.name,
        element.url,
        element.status,
        element.installsCount,
      ])

      var doc = new jsPDF()
      doc.autoTable(columnHeader, rows, { startY: 10 })
      doc.save(pdfName + '.pdf')
    },

    // Reset Button Click event handler
    onResetFilter() {
      this.reportReady = false
      this.loading = false
    },

    displayReadonlyAlert() {
      if (this.reportReady)
        alert(
          'Filters are readonly and please click on Reset button to restart filter.'
        )
    },

    handleClickDeveloperRow(developer) {
      this.$router.push({
        name: 'developer_detail',
        params: { developerId: developer.id },
      })
    },
  },
}
</script>
<style lang="scss">
$style: TopDevelopersReport;
.#{$style} {
  padding: 42px 24px 24px;
  &__row {
    margin-bottom: 58px;
  }

  &__header {
    .v-btn.primary-btn {
      background-color: $B1;
      color: white;
    }
    .v-btn.reset-btn {
      background-color: $R1;
      color: white;
    }

    .date-picker {
      border-radius: 8px;
      padding: 8px 10px;
      background-color: hsla(0, 0%, 100%, 0.08);
      border: 1px solid #5e636d;
      margin-right: 10px;
    }
  }

  &__select-filter {
    border: 1px solid #5e636d;
    border-radius: 8px;
    padding: 0 10px;
    background-color: hsla(0, 0%, 100%, 0.08);
    @media screen and (min-width: 600px) {
      width: 200px;
    }
  }

  &__body {
    min-height: 500px;
    position: relative;
    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -50px;
      margin-left: -50px;
      width: 100px;
      height: 100px;
    }
  }
}
</style>
