import router from '@/router'

const store = {
  namespaced: true,
  state: {
    tryLoad: false,
    loading: false,

    isNavDrawerMiniVariant: false,
    showingMobileNav: false,

    showing404: false,

    modalShowing: false,
    modalParams: {},
  },
  mutations: {
    /**
     * Sets the tryLoad state.
     * @param {boolean} tryLoad - The value to set for tryLoad.
     */
    setTryLoad(state, tryLoad) {
      state.tryLoad = tryLoad
    },
    /**
     * Sets the loading state.
     * @param {boolean} loading - The value to set for loading.
     */
    setLoading(state, loading) {
      state.loading = loading
    },
    /**
     * Sets the isNavDrawerMiniVariant state.
     * @param {boolean} isNavDrawerMiniVariant - The value to set for isNavDrawerMiniVariant.
     */
    setisNavDrawerMiniVariant(state, isNavDrawerMiniVariant) {
      state.isNavDrawerMiniVariant = isNavDrawerMiniVariant
    },
    /**
     * Toggles the isNavDrawerMiniVariant state.
     */
    toggleisNavDrawerMiniVariant(state) {
      state.isNavDrawerMiniVariant = !state.isNavDrawerMiniVariant
    },
    /**
     * Sets the showingMobileNav state.
     * @param {boolean} showingMobileNav - The value to set for showingMobileNav.
     */
    setShowingMobileNav(state, showingMobileNav) {
      state.showingMobileNav = showingMobileNav
    },
    /**
     * Toggles the Mobile Nav.
     */
    toggleMobileNav(state) {
      state.showingMobileNav = !state.showingMobileNav
    },
    /**
     * Sets the showing404 state.
     * @param {boolean} showing404 - The value to set for showing404.
     */
    setShowing404(state, showing404) {
      state.showing404 = showing404
    },
    /**
     * Opens the modal with the specified parameters.
     * @param {Object} params - The parameters for the modal.
     */
    openModal(state, params) {
      state.modalParams = params ? params : {}
      state.modalShowing = true
    },
    /**
     * Closes the modal.
     */
    closeModal(state) {
      state.modalParams = {}
      router.push({ query: {} }).catch(() => {})
      state.modalShowing = false
    },
  },
  actions: {
    /**
     * Loads various data from the server.
     */
    async load({ commit, dispatch }) {
      commit('setLoading', true)
      commit('setTryLoad', true)
      try {
        await dispatch('user/load', null, { root: true })
        await dispatch('organisations/load', null, { root: true })
        await dispatch('notifications/load', false, { root: true })
        await dispatch('sites/load', null, { root: true })
        await dispatch('sites/modeLoad', null, { root: true })
        await dispatch('projects/load', null, { root: true })
      } finally {
        commit('setLoading', false)
      }
    },
    /**
     * Logs out the user.
     */
    logOut({ dispatch }) {
      dispatch('user/logOut', null, { root: true })
      router.push('/login')
    },
  },
}

export default store
