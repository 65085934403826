<template>
  <div class="SiteApps">
    <div
      class="d-flex flex-column flex-sm-row align-sm-center mb-4 gap-3"
      v-if="isRootRoute"
    >
      <h4 class="SiteApps__title">Apps &amp; Plugins ({{ allAppsCount }})</h4>
      <span
        >You have
        {{ installedCoreApps.length + installedPluginApps.length }} apps
        installed</span
      >
    </div>
    <div
      class="d-flex flex-column flex-sm-row gap-3 align-sm-center pa-0 px-4 mt-4 mb-6 w-full"
      v-if="isRootRoute"
    >
      <v-text-field
        v-model="search"
        @change="applyFilter"
        filled
        dark
        dense
        hide-details
        prepend-inner-icon="search"
        placeholder="Search apps, plugins"
        class="pa-0 flex-grow-0 search-input"
        :disabled="loading"
      />

      <div class="d-flex flex-column flex-sm-row gap-3 align-sm-center">
        <v-select
          v-model="filter.kind"
          @change="applyFilter"
          height="40"
          :items="kinds"
          class="filter-select"
          placeholder="All apps"
          hide-details
          filled
          dark
          dense
          :disabled="loading"
        >
          <template v-slot:item="{ item }">
            <div class="styled-option" :class="item.value">
              <span>{{ item.text }}</span>
              <span>
                <v-icon
                  v-if="item.value === filter.kind && item.value !== 'all'"
                  >mdi mdi-check</v-icon
                >
              </span>
            </div>
          </template>
        </v-select>
        <v-select
          v-model="filter.category"
          @change="init"
          height="40"
          :items="categories"
          class="filter-select"
          placeholder="All Categories"
          hide-details
          filled
          dark
          dense
          :disabled="loading"
        >
          <template v-slot:item="{ item }">
            <div class="styled-option" :class="item.value">
              <span>{{ item.text }}</span>
              <span>
                <v-icon
                  v-if="item.value === filter.category && item.value !== 'all'"
                  >mdi mdi-check</v-icon
                >
              </span>
            </div>
          </template>
        </v-select>
        <v-checkbox
          dark
          class="sm-align-center justify-center d-block checkbox-auto"
          v-model="filter.hideInstalled"
          @click="applyFilter"
          label="Hide Installed"
        ></v-checkbox>
        <v-checkbox
          dark
          class="sm-align-center justify-center d-block checkbox-auto"
          v-model="filter.showAvailable"
          @click="applyFilter"
          label="Show Available"
        ></v-checkbox>
      </div>
    </div>
    <div v-if="loading" class="spinner mt-12" align="center">
      <LottieLoader :size="100" />
    </div>
    <div v-else-if="isRootRoute && !loading">
      <v-row class="mx-n6 mt-n6">
        <v-col
          v-for="app in apps"
          :key="app.id"
          cols="12"
          xs="12"
          sm="6"
          md="4"
          lg="4"
          xl="3"
          class="pa-6"
        >
          <SiteAppsAppInstalled
            v-if="app.installed && app.kind === 'apps'"
            :app="app.app"
            @app-changed="getAppsData"
          />
          <SiteAppsAppAvailable
            v-if="!app.installed && app.kind === 'apps'"
            :app="app"
            @app-changed="getAppsData"
          />
          <SiteAppsPlugin
            v-if="app.kind === 'plugin'"
            :app="app"
            :categories="categories"
          />
        </v-col>
      </v-row>
      <SiteAppsPluginSettingsDrawer :categories="categories" />
    </div>

    <div v-else-if="!isRootRoute && !loading" class="">
      <div class="inner-app pa-2">
        <router-link
          :to="{ name: 'site_apps' }"
          class="app-title-left text-white d-flex align-center justify-start text-decoration-none"
        >
          <v-icon left class="text-white">mdi-arrow-left</v-icon>
          Back
        </router-link>
        <h2 class="text-white text-center my-0">{{ currentAppName }}</h2>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import Parse from 'parse'
import { mapState } from 'vuex'
import LottieLoader from '@/components/_Common/LottieLoader'

import SiteAppsAppInstalled from '@/components/TheSite/SiteApps/SiteAppsAppInstalled'
import SiteAppsAppAvailable from '@/components/TheSite/SiteApps/SiteAppsAppAvailable'
import SiteAppsPlugin from '@/components/TheSite/SiteApps/SiteAppsPlugin'
import SiteAppsPluginSettingsDrawer from '@/components/TheSite/SiteApps/SiteAppsPluginSettingsDrawer'

import { initParse, findDeveloperByEmail } from '@/utils/parse'
import { APPS_DATA, APP_STATUS } from '@/models/siteApp'

export default {
  name: 'SiteApps',
  components: {
    SiteAppsAppAvailable,
    SiteAppsAppInstalled,
    SiteAppsPlugin,
    SiteAppsPluginSettingsDrawer,
    LottieLoader,
  },
  data() {
    return {
      loading: true,
      installedCoreApps: [],
      availableCoreApps: APPS_DATA,
      search: '',
      siteNameId: '', // Forge Ignite SiteNameId, used for DB manipulation
      apps: [],
      appsInCategory: [],
      filter: {
        kind: '',
        status: '',
        hideInstalled: false,
        showAvailable: false,
      },
      kinds: [
        { text: 'Apps', value: 'apps' },
        { text: 'Plugins', value: 'plugin' },
        { text: 'Select All', value: 'all' },
      ],
      categories: [],
      pluginList: [],
      wholePluginList: [],
      developersList: [],
      previousDeveloperFilters: '', // Used to prevent unnecessary api request from developer select unchanged
      headerRowspan: 1,
      parseServerSiteId: '',
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.current,
      organisation: (state) => state.organisations.current,
      currentSite: (state) => state.sites.active,
      allSitePluginApps: (state) => state.publisher.allSiteApps,
      publishers: (state) => state.publisher.publishers,
      installedPluginAppsSlugs: (state) =>
        (state.publisher.siteInstalledApps || []).map(
          (plugin) => plugin.developerApp?.slug
        ),
    }),
    allAppsCount() {
      return APPS_DATA.length + this.allSitePluginApps.length
    },
    canManagePlugins() {
      if (this.organisation.id == 0) return true
      const pluginsAvailable =
        this.organisation.subscription_features.find(
          (feat) => feat.key == 'plugins' && feat.value == true
        ) || false
      return pluginsAvailable
    },
    filteredApps() {
      const coreApps = APPS_DATA.map((app) => ({ ...app, kind: 'apps' }))
      const plugins = this.allSitePluginApps.map((app) => ({
        ...app,
        pluginKind: app.kind,
        kind: 'plugin',
      }))
      let apps = [...coreApps, ...plugins]
      // Kind Filter first
      if (this.filter.kind && this.filter.kind !== 'all') {
        apps = apps.filter((app) => app.kind === this.filter.kind)
      }
      return apps
    },
    installedAppsCount() {
      return this.installedCoreApps.length + this.installedPluginApps.length
    },
    isRootRoute() {
      return this.$route.name == 'site_apps'
    },
    currentAppName() {
      if (this.isRootRoute) return null
      let arr = this.$route.name.split('_')
      const id = arr[arr.length - 1]
      if (id == 'cloud') return 'Forge Ignite'
      return APPS_DATA.find((a) => a.id == id).name
    },
    installedPluginApps() {
      return this.allSitePluginApps.filter(
        (plugin) => this.installedPluginAppsSlugs.indexOf(plugin.slug) !== -1
      )
    },
    availablePluginApps() {
      return this.allSitePluginApps.filter(
        (plugin) => this.installedPluginAppsSlugs.indexOf(plugin.slug) === -1
      )
    },
  },
  methods: {
    // The main start point of filter.
    // Get the plugins list from parse server based on category selection
    // Blend Core Apps list with plugins list and specifying kind: 'apps' or 'plugin'
    // Add 'installed' property
    async load() {
      const availableCoreApps = APPS_DATA.filter(
        (app_data) =>
          !this.$store.state.site_app.list.find(
            (app) => app.all_app_id == app_data.id
          )
      )

      this.availableCoreApps = [...availableCoreApps]
      this.installedCoreApps = [...this.$store.state.site_app.list]
    },
    async init() {
      this.loading = true
      try {
        const plugins = await this.getPluginsList()
        const myPluginsInDevelopment = await this.getMyPluginsInDevelopment()

        const coreApps = [
          ...this.availableCoreApps,
          ...this.installedCoreApps,
        ].map((app) => ({ ...app, kind: 'apps' }))
        let apps = [...coreApps, ...plugins, ...myPluginsInDevelopment]

        this.appsInCategory = this.addInstalledProperty(apps)
        this.applyFilter()
      } catch (error) {
        console.error('Error inside SiteApps / init', error)
      }
      this.loading = false
    },
    async getPluginsList() {
      if (!this.canManagePlugins) return []
      let filter = {
        status: 'Ready for Sale',
      }
      if (this.filter.category && this.filter.category !== 'all')
        filter['category'] = this.filter.category
      try {
        const res = await Parse.Cloud.run('getPluginsList', {
          filter,
          parseServerSiteId: this.parseServerSiteId,
        })

        let plugins = []
        if (res.status === 'success') plugins = res.apps || []
        return plugins.map((app) => ({
          ...app,
          pluginKind: app.kind,
          kind: 'plugin',
        }))
      } catch (error) {
        console.error('getPluginsList error', error)
        return []
      }
    },
    async getMyPluginsInDevelopment() {
      const developer = await findDeveloperByEmail(
        this.parseServerSiteId,
        this.user.email
      )
      if (!developer || !developer.id || !this.canManagePlugins) return []
      const myDeveloperId = developer.id

      let filter = {
        developer: [myDeveloperId],
        status: 'Pending Developer Release',
      }
      if (this.filter.category && this.filter.category !== 'all')
        filter['category'] = this.filter.category

      const res = await Parse.Cloud.run('getPluginsList', {
        filter,
        parseServerSiteId: this.parseServerSiteId,
      })

      let plugins = []
      if (res.status === 'success') plugins = res.apps || []
      return plugins.map((app) => ({
        ...app,
        pluginKind: app.kind,
        kind: 'plugin',
        status: 'in-development',
      }))
    },
    applyKindFilter(apps) {
      if (this.filter.kind && this.filter.kind !== 'all') {
        const result = apps.filter((app) => app.kind === this.filter.kind)
        return result
      }
      return apps
    },
    applyHideInstalledFilter(apps) {
      if (this.filter.hideInstalled) {
        const result = apps.filter((app) => !app.installed)
        return result
      }
      return apps
    },
    applyShowAvailableFilter(apps) {
      if (this.filter.showAvailable) {
        return apps.filter((app) => {
          // For core apps, check the status directly
          if (app.kind === 'apps') {
            return app.status === APP_STATUS.AVAILABLE
          }
          // For plugins, they are always considered available unless specified otherwise
          return true
        })
      }
      return apps
    },
    applySearchFilter(apps) {
      if (!!this.search) {
        const search = this.search.toLowerCase()
        const result = apps.filter((plugin) => {
          const pluginName = (
            plugin.app?.appData?.name ||
            plugin.name ||
            ''
          ).toLowerCase()
          const url = (plugin.url || '').toLowerCase()
          const developerName = (plugin.developer?.name || '').toLowerCase()
          const status = (plugin.developerData?.status || '').toLowerCase()
          return (
            pluginName.indexOf(search) !== -1 ||
            url.indexOf(search) !== -1 ||
            developerName.indexOf(search) !== -1 ||
            status.indexOf(search) !== -1
          )
        })
        return result
      }
      return apps
    },

    // Add installed property to apps as part of tailoring the result we get from server
    // Called from onChangeCategory
    addInstalledProperty(apps) {
      const result = apps.map((app) => {
        let index
        if (app.kind === 'apps')
          index = this.installedCoreApps.findIndex((ap) => app.id === ap.id)
        else
          index = this.installedPluginApps.findIndex((ap) => app.id === ap.id)
        return { ...app, installed: index !== -1 }
      })
      return result
    },

    sort(apps) {
      return apps.sort((a, b) => {
        if (a.installed && !b.installed) return -1
        if (!a.installed && b.installed) return 1
        if (a.kind < b.kind) return -1
        if (a.kind > b.kind) return 1
        return a.name - b.name
      })
    },

    applyFilter() {
      let apps = this.applySearchFilter(this.appsInCategory)
      apps = this.applyKindFilter(apps)
      apps = this.applyHideInstalledFilter(apps)
      apps = this.applyShowAvailableFilter(apps)
      apps = this.sort(apps)
      this.apps = apps
    },
    getAvailablePlugins(pluginsList) {
      let availablePlugins = [],
        installedPlugins = []
      pluginsList.forEach((plugin) => {
        if (this.siteInstalledAppsSlugs.indexOf(plugin.slug) !== -1)
          installedPlugins.push(plugin)
        else availablePlugins.push(plugin)
      })
      return [availablePlugins, installedPlugins]
    },

    // - Get Categories on initialization
    async getCategories() {
      try {
        const res = await Parse.Cloud.run('getCategories', {
          parseServerSiteId: this.parseServerSiteId,
        })
        if (res.categories) {
          this.categories = res.categories.map((category) => ({
            text: category.name,
            value: category.id,
          }))
          this.categories.push({ text: 'Select All', value: 'all' })
        }
      } catch (error) {
        console.error('Error inside SiteApps / getCategories', error)
      }
    },
    selectedOptionIcon() {
      return 'mdi mdi-check' // Replace with your checkmark icon CSS class
    },
    optionTemplate() {
      return (option) => {
        if (option.value === 'all') {
          return `<span style="color: yellow;">${option.label}</span>`
        } else {
          return option.label
        }
      }
    },
    getItemStyle(item) {
      return item.value === 'all' ? 'color: #ff7e21' : ''
    },
    async getAppsData() {
      this.loading = true
      this.$store
        .dispatch('site_app/load', this.activeSite.id)
        .then(async () => {
          const availableCoreApps = APPS_DATA.filter(
            (app_data) =>
              !this.$store.state.site_app.list.find(
                (app) => app.all_app_id == app_data.id
              )
          )

          this.availableCoreApps = [...availableCoreApps]
          this.installedCoreApps = [...this.$store.state.site_app.list]
          const validPublishers = this.publishers.filter(
            (publisher) =>
              publisher.parse_server_url && publisher.parse_server_app_id
          )
          if (validPublishers && validPublishers.length > 0) {
            const publisher = validPublishers[0]
            const {
              parse_server_url,
              parse_server_app_id,
              parse_server_site_id,
            } = publisher

            initParse(parse_server_url, parse_server_app_id)
            this.parseServerSiteId = parse_server_site_id
            await this.getCategories()
            await this.init()
          }
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
  },
  async mounted() {
    await this.getAppsData()
  },
}
</script>

<style lang="scss" scoped>
$style: SiteApps;
.#{$style} {
  padding: 42px 24px 24px;
  & .v-btn {
    border-radius: 8px;
  }
  &__title {
    font-size: $H20;
    font-weight: 500;
    color: $white;
  }
  .search-input {
    width: 352px;
    border: 1px solid $N3;
  }
  .filter-select {
    width: 168px;
    border: 1px solid $N3;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}
.styled-option {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &.all {
    color: $Y1;
  }
}
.spinner {
  display: flex;
  width: 100%;
  height: 85vh;
  background: transparent;
  align-items: center;
  justify-content: center;
}
.text-white {
  color: #cacaca;
}
.inner-app {
  margin-right: auto;
  margin-left: 40px;
  position: relative;
}
.app-title-left {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  cursor: pointer;
  opacity: 0.8;
  text-decoration: none;
}

.app-title-left:hover {
  opacity: 1;
}
</style>

<style lang="scss">
.app-tag-wrapper {
  height: 3rem;
}
.app-description {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  height: 4em;
  line-height: 1.2rem;
  color: $N1;
}
.gap-3 {
  gap: 12px;
}
</style>
