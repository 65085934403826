<template>
  <div class="narrow-container mx-2 mt-4">
    <router-link
      class="app-title-left text-white mx-4 mt-4 mb-8"
      :to="{ name: 'site_forms_list' }"
    >
      <v-icon left class="text-white">mdi-arrow-left</v-icon>
      Back
    </router-link>
    <div v-if="!loading">
      <div class="SiteFormSettings__list mx-3 mt-8 pa-4" v-if="dataPresent">
        <h2 class="mb-2" style="display: inline-block">Data</h2>
        <span @click="downloadCsv" class="SiteFormSettings__csv">
          <img src="@/assets/csv.png" />
        </span>

        <span class="SiteFormSettings__header_btn">
          <v-btn @click="removeMode = !removeMode" color="y1" dark>
            {{ removeMode ? 'Disable Remove Mode' : 'Remove Mode' }}
          </v-btn>
        </span>

        <v-divider />

        <v-data-table
          :headers="headers"
          v-model="selectedData"
          :show-select="removeMode"
          :items="form.data"
          item-key="form_data_id"
          class="organisations_access_members_table form_data_table"
          :sort_by="['timestamp']"
        >
          <template v-slot:top>
            <v-toolbar flat color="#232139">
              <v-toolbar-title>Recent Reponses</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <span class="remove" @click="removeObject(item)" :if="isOwner">
              <v-icon dark right color="red" class="ml-0" medium>
                mdi-delete-outline
              </v-icon>
            </span>
          </template>
          <template v-slot:footer>
            <v-btn
              @click="removeSelected"
              class="footer_remove_btn"
              color="y1"
              :disabled="selectedData.length == 0"
              v-if="removeMode"
              dark
            >
              Remove Selected Data
            </v-btn>
          </template>
        </v-data-table>
      </div>
      <div class="SiteFormSettings__list mx-3 mt-6">
        <h2 class="pa-4">Form Options</h2>
        <v-divider />
        <div class="SiteFormSettings__config pa-4">
          <h3>Submit</h3>
          <p>How to handle form responses in your site</p>
          <div class="my-4">
            <v-checkbox
              v-model="form.ajax_form"
              @change="ajaxSubmitChanged($event)"
              label="AJAX Submit"
              class="mr-3"
              style="display: inline-block"
            ></v-checkbox>

            <v-checkbox
              v-model="form.ajax_without_message"
              @change="ajaxWithoutMessageSubmitChanged($event)"
              label="AJAX Submit without message"
              class="mr-3"
              style="display: inline-block"
            ></v-checkbox>

            <v-checkbox
              v-model="form.redirect_to_url"
              @change="redirectChanged($event)"
              label="Redirect on submit"
              style="display: inline-block"
            ></v-checkbox>

            <div v-if="form.ajax_form">
              <label class="SiteFormSettings__label"
                >Success message for user</label
              >
              <v-text-field
                v-model="form.ajax_message"
                class="SiteFormSettings__input"
                placeholder="Enter your custom message here"
                hide-details="auto"
                filled
                dark
                dense
              />
            </div>
            <div v-else-if="form.redirect_to_url">
              <label class="SiteFormSettings__label">URL for redirection</label>
              <v-text-field
                v-model="form.redirect_url"
                class="SiteFormSettings__input"
                placeholder="e.g https://somewebsite.com/thankyou.html or thankyou.html"
                hide-details="auto"
                filled
                dark
                dense
              />
            </div>
          </div>

          <h3 class="mt-8">Notifications</h3>
          <p>Choose who to notify when you receive responses</p>
          <v-checkbox
            v-model="form.notifications"
            label="Send me notifications for new responses"
          ></v-checkbox>

          <v-checkbox
            v-model="form.auto_response"
            label="Send auto-response to people who submitted the form with an email address"
          ></v-checkbox>

          <div v-if="form.auto_response" class="mt-2">
            <h4>Create your email</h4>
            <v-text-field
              v-model="form.email_address"
              class="SiteFormSettings__input mt-1"
              placeholder="from address"
              hide-details="auto"
              filled
              dark
              dense
            />

            <v-text-field
              v-model="form.email_subject"
              class="SiteFormSettings__input mt-1"
              placeholder="subject"
              hide-details="auto"
              filled
              dark
              dense
            />

            <v-textarea
              v-model="form.email_body"
              class="SiteFormSettings__input mt-1"
              placeholder="email body"
              hide-details="auto"
              filled
              dark
              dense
            />
          </div>

          <v-checkbox
            v-model="form.custom_email_enabled"
            label="Send notification to specified email address"
          ></v-checkbox>

          <div v-if="form.custom_email_enabled" class="mt-2">
            <h4>Type your custom email</h4>
            <v-text-field
              v-model="form.custom_email"
              class="SiteFormSettings__input mt-1"
              placeholder="custom email address"
              hide-details="auto"
              filled
              dark
              dense
            />
          </div>

          <v-checkbox
            v-model="form.collaborator_enabled"
            label="Send notification to collaborator"
          ></v-checkbox>

          <div v-if="form.collaborator_enabled" class="mt-2">
            <h4>Choose a collaborator or collaborators</h4>
            <v-select
              v-model="form.collaborator_emails"
              :items="form.collaborators"
              filled
              dense
              multiple
            />
          </div>
        </div>
        <div class="mx-4 mb-6">
          <v-btn @click="save" :disabled="saving" color="y1" class="mr-2" dark>
            Save
          </v-btn>

          <v-btn @click="cancel" :disabled="saving" color="n5" dark>
            Cancel
          </v-btn>
        </div>
      </div>

      <div class="SiteFormSettings__list mx-3 pa-4">
        <h2 class="py-4">Email Template</h2>
        <v-divider />
        <v-checkbox
          v-model="form.custom_template_enabled"
          :label="`Custom Email Template ${
            form.custom_template_enabled ? 'enabled' : 'disabled'
          }`"
        ></v-checkbox>
        <div v-if="form.custom_template_enabled">
          <label>You can customise your email template for this form. </label>
          <h3 class="mt-4 mb-2">From</h3>
          <label class="SiteFormSettings__label"
            >Set a custom 'from' email address
          </label>
          <v-text-field
            v-model="form.custom_from_text"
            class="SiteFormSettings__input"
            placeholder="custom email address"
            hide-details="auto"
            filled
            dark
            dense
          />

          <h3 class="mt-4 mb-2">Subject</h3>
          <label class="SiteFormSettings__label"
            >Set a custom 'subject' for the email
          </label>
          <v-text-field
            v-model="form.custom_subject_text"
            class="SiteFormSettings__input"
            placeholder="custom subject"
            hide-details="auto"
            filled
            dark
            dense
          />

          <h3 class="mt-8 mb-2">Add a header image</h3>
          <input
            type="file"
            style="background: #2f2e3f; color: black"
            @change="onFileChange"
          />

          <div>
            <img
              v-if="form.header_image_url"
              class="SiteFormSettings__preview"
              :src="form.header_image_url"
            />
          </div>
          <div class="SiteFormSettings__note_label pa-3 mt-2">
            <v-icon class="SiteFormSettings__note_icon">mdi-lightbulb</v-icon>
            Recommended image ratio is 5:1 for better display.
          </div>

          <h3 class="mt-8 mb-2">Personalise the email</h3>

          <v-textarea
            v-model="form.message_body"
            class="SiteFormSettings__input textarea mt-3"
            :placeholder="defaultBody"
            :auto-grow="true"
            rows="11"
            hide-details="auto"
            filled
            dark
            dense
          />

          <div class="SiteFormSettings__note_label pa-3 mt-2">
            <v-icon class="SiteFormSettings__note_icon">mdi-lightbulb</v-icon>

            Use these variables to personalise your emails.

            <div class="mt-4 pa-2">
              <div class="my-2">
                <span class="SiteFormSettings__note_pre">[user_name]</span> -
                This is the name of the user who receives a message.
              </div>
              <div class="my-2">
                <span class="SiteFormSettings__note_pre">[form_name]</span> -
                This is the name of the form that was sent.
              </div>
              <div class="my-2">
                <span class="SiteFormSettings__note_pre">[form_data]</span> -
                This is the data from completed forms.
              </div>
              <div class="my-2">
                <span class="SiteFormSettings__note_pre">[link_to_form]</span> -
                This is a link to your forms.
              </div>
            </div>
          </div>

          <div class="mb-4 mt-4">
            <v-btn
              @click="saveCustomConfig"
              :disabled="saving"
              color="y1"
              class="mr-2"
              dark
            >
              Save
            </v-btn>

            <v-btn @click="cancel" :disabled="saving" color="n5" dark>
              Cancel
            </v-btn>
          </div>
        </div>
      </div>

      <div class="SiteFormSettings__list mx-3 pa-4">
        <h2 class="py-4">Recaptcha Config</h2>
        <v-divider />
        <v-checkbox
          v-model="form.recaptcha_enabled"
          :label="`Recaptcha ${
            form.recaptcha_enabled ? 'enabled' : 'disabled'
          }`"
        ></v-checkbox>
        <div v-if="form.recaptcha_enabled">
          <label>You can customise your recapthca config for this form. </label>

          <div
            v-if="capthcaError.length > 0"
            class="SiteFormSettings__error-message mt-4"
          >
            {{ capthcaError }}
          </div>

          <h3 class="mt-4 mb-2">Site Key</h3>
          <label class="SiteFormSettings__label">
            Set your recaptcha site key
          </label>
          <v-text-field
            v-model="form.recaptcha_site_key"
            class="SiteFormSettings__input"
            placeholder="recaptcha site key"
            hide-details="auto"
            filled
            dark
            dense
          />
          <h3 class="mt-4 mb-2">Secret Key</h3>
          <label class="SiteFormSettings__label">
            Set your recaptcha secret key
          </label>
          <v-text-field
            v-model="form.recaptcha_server_key"
            class="SiteFormSettings__input"
            placeholder="recaptcha secret key"
            hide-details="auto"
            filled
            dark
            dense
          />
        </div>
        <div class="mb-4 mt-4">
          <v-btn
            @click="saveCaptchaConfig"
            :disabled="saving"
            color="y1"
            class="mr-2"
            dark
          >
            Save
          </v-btn>

          <v-btn @click="cancel" :disabled="saving" color="n5" dark>
            Cancel
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import { HEADER_IMAGE_BUCKET } from '@/env'
import { MODAL_ALERT } from '@/components/Modals'

export default {
  name: 'SiteFormSettings',
  components: { IntersectingCirclesSpinner },
  data() {
    return {
      form: null,
      defaultForm: null,
      loading: true,
      saving: false,
      newImage: null,
      removeMode: false,
      selectedData: [],
      selectedCollaborators: [],
      headers: [],
      capthcaError: '',
    }
  },
  mounted() {
    this.$store.dispatch('form/setCurrent', this.$route.params.formId)
  },
  computed: {
    defaultBody() {
      let msg =
        'Hi, [user_name]\n' +
        'Your Forge Form [form_name]  has a new response:\n' +
        '\t[form_data]\n\n' +
        '\tLogin to view your responses [link_to_form].\n' +
        '\tYou can turn off or configure notifications for this form [link_to_form]\n\n' +
        'Happy Coding!\n' +
        'The Forge Robot'
      return msg
    },
    templateImage() {
      return this.form.header_image_url
    },
    dataPresent() {
      return this.form.form_datum_ids.length > 0
    },
  },
  methods: {
    ajaxSubmitChanged(e) {
      if (e) {
        this.form.ajax_without_message = false
        this.form.redirect_to_url = false
      }
    },
    removeObject(item) {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Remove Form Data',
          text: 'Do you really want to remove this data?',
          actionBtnLabel: 'Remove',
          showCancelBtn: true,
          withSpiner: false,
          action: () => {
            this.$store.dispatch('form/deleteFormData', {
              form_id: this.form.id,
              id: item.form_data_id,
            })
          },
        },
      })
    },
    removeSelected() {
      let dataIds = this.selectedData.map((sd) => sd.form_data_id)
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Remove Form Data',
          text: 'Do you really want to remove selected data?',
          actionBtnLabel: 'Remove',
          showCancelBtn: true,
          withSpiner: false,
          action: () => {
            this.$store
              .dispatch('form/bulkDeleteFormData', {
                form_id: this.form.id,
                data_ids: dataIds,
              })
              .then(() => {
                this.selectedData = []
              })
          },
        },
      })
    },
    isOwner() {
      return this.$store.state.users.current.id == this.ownerId()
    },
    ownerId() {
      if (this.$store.state.organisations.current.id != 0)
        return this.$store.state.organisations.current.user_id
      else if (this.$store.state.projects.active != null)
        return this.$store.state.projects.active.user_id
      else this.form.user_id
    },
    onFileChange(e) {
      const file = e.target.files[0]
      this.newImage = e.target.files[0]
      this.form.header_image_url = URL.createObjectURL(file)
    },
    ajaxWithoutMessageSubmitChanged(e) {
      if (e) {
        this.form.ajax_form = false
        this.form.redirect_to_url = false
      }
    },
    redirectChanged(e) {
      if (e) {
        this.form.ajax_without_message = false
        this.form.ajax_form = false
      }
    },
    save() {
      if (this.saving) return
      this.saving = true
      this.updateForm()
    },
    updateForm(customEmail = false) {
      let params = customEmail
        ? this.form.getCustomEmailObject()
        : this.form.getServerObject()
      params = {
        form_id: this.form.id,
        form: params,
      }
      this.$store
        .dispatch('form/update', params)
        .then((res) => {
          this.saving = false
        })
        .catch((e) => {
          console.log(e)
          this.saving = false
        })
    },
    cancel() {
      this.form = _.cloneDeep(this.defaultForm)
    },
    downloadCsv() {
      this.$store.dispatch('form/downloadCsv', this.form.id).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Form_Data.csv')
        document.body.appendChild(link)
        link.click()
      })
    },
    saveCaptchaConfig() {
      if (this.saving) return
      this.saving = true
      this.capthcaError = ''
      if (
        this.form.recaptcha_enabled &&
        (this.form.recaptcha_site_key == null ||
          this.form.recaptcha_site_key.length < 4 ||
          this.form.recaptcha_server_key == null ||
          this.form.recaptcha_server_key.length < 4)
      ) {
        this.capthcaError = 'Site Key and Server Key should be added'
        this.saving = false
        return
      }
      let params = this.form.getCaptchaObject()
      params = {
        form_id: this.form.id,
        form: params,
      }
      this.$store
        .dispatch('form/update', params)
        .then(() => {
          this.saving = false
        })
        .catch((e) => {
          console.log(e)
          this.saving = false
        })
    },
    saveCustomConfig() {
      if (this.saving) return
      this.saving = true
      if (this.newImage != null) {
        let policyParams = {
          form_id: this.form.id,
          file_name: this.newImage.name,
        }
        this.$store
          .dispatch('form/getPolicy', policyParams)
          .then((res) => {
            const formData = new FormData()
            for (let param in res.body) formData.append(param, res.body[param])
            formData.append('file', this.newImage)
            this.uploadPromise = this.$http
              .post(HEADER_IMAGE_BUCKET, formData, {
                before(request) {
                  this.previousRequest = request
                },
              })
              .then(() => {
                this.form.header_image_url =
                  HEADER_IMAGE_BUCKET + '/' + res.body.key
                this.newImage = null
                this.updateForm(true)
              })
              .catch((e) => {
                console.log(e)
                this.saving = false
              })
          })
          .catch((e) => {
            console.log(e)
          })
      } else {
        this.updateForm(true)
      }
    },
  },
  watch: {
    '$store.state.form.currentForm': {
      handler(newVal, oldVal) {
        this.form = _.cloneDeep(newVal)
        this.defaultForm = _.cloneDeep(newVal)
        this.loading = false
        this.headers = []
        this.form.fields.forEach((element) => {
          let header = {
            text: element,
            value: element.replace(/ /g, '_').toLowerCase(),
          }
          this.headers.push(header)
        })
        this.headers.push({ text: '', value: 'actions', sortable: false })
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss">
$style: SiteFormSettings;

.form_data_table {
  background-color: #232139 !important;
  tbody tr:hover {
    background: #3c3a55 !important;
  }
  tbody tr.v-data-table__selected {
    background-color: #3c3a55 !important;
  }
  .footer_remove_btn {
    position: absolute;
    margin-top: 15px;
    margin-left: 15px;
  }
}

.#{$style} {
  &__backBtn {
    color: #cacaca !important;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: none;
      color: white;
    }
  }
  &__header_btn {
    float: right;
    margin-right: 21px;
    margin-top: -5px;
  }
  &__list {
    background: #1f1e31;
    border: 1px solid #2e2c42;
    border-radius: 8p;
  }
  &__label {
    @extend %label-styles;
  }
  &__error-message {
    background: rgba($R1, 0.3);
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 26px;
    &._last {
      color: $N2;
    }
  }
  &__note_label {
    background: #fcf4d4;
    border: 1px solid #f7e9ab;
    color: #9b7406;
    border-radius: 3px;
  }
  &__note_icon {
    color: #9b7406 !important;
    font-size: 16px;
    margin-right: 4px;
  }
  &__note_pre {
    padding: 2px 4px;
    font-weight: bold;
    background: #c89509;
    color: #6a4f04;
    border-radius: 3px;
    border: 1px solid #6a4f04;
  }
  &__input {
    @extend %input-styles;
    margin-bottom: 16px;
    &.textarea {
      width: 502px;
      overflow-y: auto;
    }
  }
  &__csv {
    float: right;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: filter 0.2s ease;
    img {
      width: 30px;
      height: 30px;
    }
    &:hover {
      filter: brightness(60%);
    }
  }
  &__preview {
    width: 502px;
    height: 100px;
  }
}

.app-title-left {
  position: relative;
  left: 0;
  top: 0;
  height: 100%;
  cursor: pointer;
  opacity: 0.8;
  text-decoration: none;
  &:hover {
    opacity: 1;
  }
}

.text-white {
  color: #cacaca !important;
}
</style>
