<template>
  <v-card min-height="90vh" class="ModalFeedback" :width="widthDimensions">
    <div class="ModalFeedback__title">
      <div class="ModalFeedback__title-wrapper">
        Feedback for {{ app.name }}
      </div>
      <v-btn x-small icon dark @click="close"
        ><the-icon class="ModalFeedback__close-icon" icon="close"
      /></v-btn>
    </div>

    <div class="ModalFeedback__feedback-block">
      <div class="ModalFeedback__feedback_select">
        <v-btn
          :ripple="false"
          class="ModalFeedback__feedback_select_btn"
          @click="selectMyFeedback"
          :class="{ selected: myFeedbackView }"
          plain
        >
          Your Feedback
        </v-btn>
        <v-btn
          :ripple="false"
          class="ModalFeedback__feedback_select_btn"
          @click="selectAllFeedbacks"
          :class="{ selected: allFeedbackView }"
          plain
        >
          All Feedbacks
        </v-btn>
      </div>
    </div>

    <div class="ModalFeedback__body">
      <SiteFeedbackForm
        :app="app"
        :parseServerSiteId="parseServerSiteId"
        :myFeedback="myFeedback"
        v-if="myFeedbackView"
      />
      <SiteFeedbackElement
        v-else
        v-for="feedback in feedbacks"
        :feedback="feedback"
        :key="feedback.id"
      />
    </div>
  </v-card>
</template>

<script>
import { Parse } from 'parse/lib/browser/Parse'
import { mapState } from 'vuex'
import SiteFeedbackForm from '@/components/TheSite/SiteFeedback/SiteFeedbackForm'
import SiteFeedbackElement from '@/components/TheSite/SiteFeedback/SiteFeedbackElement'
const MY_FEEDBACK_VIEW = 'mine'
const ALL_FEEDBACKS_VIEW = 'all'
export default {
  name: 'ModalFeedback',
  props: {
    app: Object,
    parseServerSiteId: String,
  },
  components: {
    SiteFeedbackForm,
    SiteFeedbackElement,
  },
  data() {
    return {
      currentView: MY_FEEDBACK_VIEW,
      feedbacks: [],
      myFeedback: null,
      loading: true,
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.user.current,
    }),
    myFeedbackView() {
      return this.currentView === 'mine'
    },
    allFeedbackView() {
      return this.currentView === 'all'
    },
    widthDimensions() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '100%'
      } else if (this.$vuetify.breakpoint.md) {
        return '40%'
      } else {
        return '30%'
      }
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    async loadAllFeedbacks() {
      this.currentView = ALL_FEEDBACKS_VIEW
    },
    selectMyFeedback() {
      if (this.currentView === MY_FEEDBACK_VIEW) {
        return
      }
      this.loading = true
      this.currentView = MY_FEEDBACK_VIEW

      this.loading = false
    },
    selectAllFeedbacks() {
      if (this.currentView === ALL_FEEDBACKS_VIEW) {
        return
      }
      this.loading = true
      this.currentView = ALL_FEEDBACKS_VIEW

      this.loading = false
    },
  },
  async mounted() {
    this.feedbacks = []
    this.loading = true
    const res = await Parse.Cloud.run('fetchFeedbacksForPlugin', {
      parseServerSiteId: this.parseServerSiteId,
      filter: {
        appId: this.app.id,
      },
    })

    if (res.status === 'success' && res.result) {
      this.feedbacks = res.result.filter(
        (feedback) => feedback.status === 'Published'
      )
      this.myFeedback = res.result.find(
        (feedback) => feedback.authorEmail === this.user.email
      )
    }
    this.loading = false
  },
}
</script>

<style lang="scss">
$style: ModalFeedback;
.#{$style} {
  min-height: 100vh !important;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 12px 0px 0px 12px;

  &.theme--dark.v-card {
    background: $N5;
    border: 1px solid $N7;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    border-radius: 12px 0px 0px 12px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: $H16;
    color: $white;
    padding: 30px 34px !important;
    border-bottom: 1px solid $N5;
    position: sticky;
  }
  &__title-wrapper {
    display: flex;
    align-items: center;
  }

  &__close-icon {
    fill: $N3;
    width: 18px;
    height: 18px;
  }

  &__feedback-block {
    border-bottom: 1px solid $N7;
    border-radius: 0px !important;

    .#{$style}__feedback_select {
      padding-left: 36px;
      .#{$style}__feedback_select_btn {
        span {
          color: $N8;
        }
        &.selected {
          border-radius: 0;
          border-bottom: 1px solid $B1;
          span {
            color: #fff;
            opacity: 1 !important;
          }
        }
      }
    }
  }

  &__body {
    padding: 28px;
    width: 100%;
  }
}
</style>
