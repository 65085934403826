<template>
  <div class="SiteVersionDiffTree__tree">
    <div v-for="object in tree" :key="objectKey()">
      <v-list-group
        v-if="isFolder(object)"
        :value="true"
        class="group_tree"
        prepend-icon="mdi-menu-right"
        sub-group
        eager
      >
        <template v-slot:activator>
          <v-list-item-icon>
            <v-icon class="file_icon"> mdi-folder-outline </v-icon>
          </v-list-item-icon>
          <v-list-item-title class="files_title">
            {{ object.name }}
          </v-list-item-title>
        </template>
        <SiteVersionDiffTree
          :tree="object[0]"
          :uniqIndex="uniqIndex + 1"
          :deleted="deleted"
        />
      </v-list-group>
      <div v-else class="ml-2 simple_file mt-2" :class="{ deleted: deleted }">
        <v-icon class="simple_file_ico"> mdi-file-outline </v-icon>
        <div class="file_name ml-2">{{ object }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import SiteVersionDiffTree from '@/components/TheSite/SiteVersion/SiteVersionDiffTree'

export default {
  name: 'SiteVersionDiffTree',
  components: { SiteVersionDiffTree },
  props: {
    tree: Array,
    uniqIndex: {
      type: Number,
      default: 0,
    },
    deleted: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    isFolder(object) {
      return typeof object == 'object'
    },
    objectKey() {
      return new Date().getTime() + Math.floor(Math.random() * 10000).toString()
    },
  },
}
</script>

<style lang="scss">
$style: SiteVersionDiffTree;
.#{$style} {
  &__tree {
    margin-left: 24px;
    .group_tree .v-list-group__header {
      max-height: 29px;
      min-height: 29px;
      .v-list-item__icon {
        max-height: 20px;
        margin-top: 7px;
      }
    }
    .file_icon {
      color: $N8;
    }

    .files_title {
      font-size: 14px !important;
      color: $N8 !important;
      padding-top: 6px;
    }
    .v-list-group--sub-group.v-list-group--active
      .v-list-item__icon.v-list-group__header__prepend-icon {
      max-height: 20px;
      margin-bottom: 0px;
      vertical-align: top;
      margin-top: 7px;
      .v-icon {
        color: $N8;
      }
    }

    .simple_file {
      color: $N8;
      &.deleted {
        color: $R1;
        .simple_file_ico {
          color: $R1;
        }
        .file_name {
          color: $R1;
        }
      }
      .simple_file_ico {
        color: $N8;
      }
      .file_name {
        display: inline-block;
      }
    }
  }
}
</style>
