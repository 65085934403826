<template>
  <div>
    <div class="SiteApp">
      <div class="image-wrapper">
        <img :src="app.appData.image" class="image" />
        <div class="forge-native-tag">
          <the-icon icon="logo-small" width="16" height="16" class="mr-1" />
          Forge app
        </div>
      </div>
      <div class="pa-5">
        <div class="d-flex justify-space-between items-center">
          <div class="app-name">
            {{ app.appData.name }}
          </div>
          <v-menu offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn text icon compact v-on="on">
                <the-icon icon="more-vert" />
              </v-btn>
            </template>
            <v-list dark class="the-app-bar__dropdown">
              <v-list-item>
                <v-list-item-title class="SiteApp__action" @click="openDocs">
                  <span class="SiteApp__icon">
                    <v-icon>exit_to_app</v-icon>
                  </span>
                  Documentation
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="SiteApp__action" @click="productSite">
                  <span class="SiteApp__icon">
                    <v-icon>exit_to_app</v-icon>
                  </span>
                  Visit Product Site
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="SiteApp__action" @click="getSupport">
                  <span class="SiteApp__icon">
                    <v-icon>help</v-icon>
                  </span>
                  Get Support
                </v-list-item-title>
              </v-list-item>
              <v-list-item>
                <div @click="remove" class="SiteApp__action dangerous">
                  <span class="SiteApp__icon">
                    <the-icon icon="trash" />
                  </span>
                  <span>Uninstall App</span>
                </div>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-if="categoryNames" class="app-tag-wrapper mt-3">
          <span class="tag" v-for="categoryName in categoryNames">{{
            categoryName
          }}</span>
        </div>
        <div class="app-description my-5">
          {{ app.appData.description }}
        </div>

        <div class="d-flex justify-space-between align-center">
          <v-btn
            color="n5"
            :disabled="!openAppEnabled"
            large
            dark
            @click="openApp"
          >
            Open App
          </v-btn>
          <v-btn color="y1" text @click="edit">
            <the-icon class="mr-2" color="y1" icon="square-edit" />
            Edit Settings
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MODAL_ALERT } from '@/components/Modals'

export default {
  name: 'SiteAppsAppInstalled',
  props: {
    app: Object,
  },
  computed: {
    launched() {
      return this.app.activated && this.app.deployed
    },
    categoryNames() {
      return []
    },
    openAppEnabled() {
      if (
        this.app.appData.id == '1' &&
        this.$store.state.site_app.parseSetting != null
      )
        return this.$store.state.site_app.parseSetting.cloud_code_enabled
      else if (
        this.app.appData.id == '2' &&
        this.$store.state.site_app.chiselApp != null
      )
        return (
          this.$store.state.site_app.chiselApp.activated &&
          this.$store.state.site_app.chiselApp.deployed
        )
    },
  },
  methods: {
    toRoute(path) {
      this.$router.push(path)
    },
    launch() {
      if (this.app.appData.id == '1')
        window.open(this.app.parse_dashboard_url, '_blank')
      else if (this.app.appData.id == '2')
        window.open(`https://${this.app.url}`, '_blank')
    },
    openDocs() {
      if (this.app.appData.docs) {
        window.open(this.app.appData.docs, '_blank')
      }
    },
    productSite() {
      if (this.app.appData.product_page) {
        window.open(this.app.appData.product_page, '_blank')
      }
    },
    openApp() {
      if (!this.openAppEnabled) return
      if (this.app.appData.id == '1')
        this.$router.push(
          `apps/parse_server/${this.$store.state.site_app.parseSetting.id}/parse_cloud`
        )
      else if (this.app.appData.id == '2')
        window.open(
          'https://' + this.$store.state.site_app.chiselApp.url,
          '_blank'
        )
    },
    edit() {
      const routeName = `site_apps_${this.app.appData.id}`
      const params = { siteAppId: this.app.id }
      this.$router.push({ name: routeName, params })
    },
    remove() {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Uninstall App',
          text: `You are going to remove ${this.app.appData.name}. Are you sure?`,
          actionBtnLabel: 'Remove',
          showCancelBtn: true,
          action: () => {
            this.$store
              .dispatch('site_app/delete', this.app.site_app_id)
              .then(() => {
                this.$emit('app-changed')
              })
          },
        },
      })
    },
    getSupport() {
      window.open(
        'https://community.beach.io/t/forge-apps-wishlist-feedback/36',
        '_blank'
      )
    },
  },
}
</script>

<style scoped lang="scss">
$style: SiteApp;
.#{$style} {
  border: 1px solid $N6;
  border-radius: 8px;
  background: $N7;
  .app-name {
    font-size: 16px;
  }
}

.#{$style}__icon {
  width: 30px;
  text-align: center;
  .v-icon.v-icon {
    font-size: 20px;
  }
}
.#{$style}__action {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 14px;
  &.dangerous {
    color: $Y1;
  }
}
.image {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  aspect-ratio: 16/7;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
span.tag {
  padding: 5px 8px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 6px;
  margin-right: 8px;
  background: $N5;
}
.image-wrapper {
  position: relative;
  margin-bottom: 5px;
}
.forge-native-tag {
  position: absolute;
  top: 16px;
  right: 16px;
  border-radius: 6px;
  padding: 4px 6px;
  background: $B5;
  display: flex;
  align-items: center;
}
</style>
