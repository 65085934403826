import dayjs from 'dayjs'
import countries from '../_countries'
const locale = navigator.language || navigator.userLanguage
export function guid() {
  const s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }

  return (
    s4() +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    '-' +
    s4() +
    s4() +
    s4()
  )
}

export function parseBandwidth(bandwidth) {
  const bytesArray = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (!bandwidth) return '0 MB'
  let index = parseInt(Math.floor(Math.log(bandwidth) / Math.log(1024)))
  const num = Math.round(bandwidth / Math.pow(1024, index, 2))
  return `${num} ${bytesArray[index]}`
}

export function generateDomain(plan_id = null) {
  const words = ['robot', 'hamster', 'shark', 'monkey']
  let domain = 'getforge.io'
  if (['free', null, undefined].includes(plan_id)) domain = 'frge.io'
  const number = Math.floor(Math.random() * 1000)
  const word = words[Math.floor(Math.random() * words.length)]
  return `${word}-${number}.${domain}`
}

export function generateDomainName() {
  const words = ['robot', 'hamster', 'shark', 'monkey']
  const number = Math.floor(Math.random() * 1000)
  const word = words[Math.floor(Math.random() * words.length)]
  return `${word}-${number}`
}

function fallbackCopyTextToClipboard(str) {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }
}

export async function copyToClipboard(text) {
  try {
    return await navigator.clipboard.writeText(text)
  } catch (e) {
    fallbackCopyTextToClipboard(text)
  }
}

export function getTextDate(date) {
  return date.toLocaleString(locale, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
}

export function getRelativeTime(date) {
  let now = new Date()

  const SECOND = 1000
  const MINUTE = 60 * SECOND
  const HOUR = 60 * MINUTE

  let diff = now - date

  let time = date.toLocaleString(locale, { hour: 'numeric', minute: 'numeric' })

  if (diff < MINUTE) {
    if (diff < 10 * SECOND) return 'A few seconds ago'
    else return 'Less a minute ago'
  } else if (diff < HOUR) {
    let minutes = Math.floor(diff / MINUTE)
    if (minutes == 1) return `${minutes} minute ago`
    else return `${minutes} minutes ago`

    // today
  } else if (
    now.getDate() == date.getDate() &&
    now.getMonth() == date.getMonth() &&
    now.getFullYear() == date.getFullYear()
  ) {
    return `Today, at ${time}`
  } else {
    let tomorrow = new Date(date)
    tomorrow.setDate(tomorrow.getDate() + 1)

    // yesterday
    if (
      now.getDate() == tomorrow.getDate() &&
      now.getMonth() == tomorrow.getMonth() &&
      now.getFullYear() == tomorrow.getFullYear()
    )
      return `Yesterday, at ${time}`
  }

  return getTextDate(date)
}

export function isAbsoluteURL(urlString) {
  return (
    urlString.indexOf('http://') === 0 || urlString.indexOf('https://') === 0
  )
}

export function safeJSONParse(paramString) {
  let param = {}
  try {
    if (paramString && paramString.length > 0) param = JSON.parse(paramString)
  } catch (error) {
    console.error('incompatible JSON string', error)
  }
  return param
}

export function prettyJSON(json) {
  if (json) {
    json = JSON.stringify(json, undefined, 4)
    json = json.replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>')
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match) {
        var cls = 'number'
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key'
          } else {
            cls = 'string'
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean'
        } else if (/null/.test(match)) {
          cls = 'null'
        }
        return '<span class="' + cls + '">' + match + '</span>'
      }
    )
  }
}

export function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      const base64String = reader.result.split(',')[1]
      resolve(base64String)
    }
    reader.onerror = (error) => reject(error)
  })
}

export function getCountryLabel(countryCode) {
  const country = countries.find(({ value }) => value === countryCode)
  return country?.text || ''
}

export function getExtensionState(extensionId) {
  const extensionStateString = localStorage.getItem(extensionId)
  let extensionState = {}
  try {
    if (extensionStateString && extensionStateString.length > 0)
      extensionState = safeJSONParse(extensionStateString)
  } catch (error) {
    extensionState = {}
  }
  return extensionState
}

export function getFirstMondayAndLastSunday() {
  const today = new Date()
  const currentMonth = today.getMonth()
  const firstDayOfMonth = new Date(today.getFullYear(), currentMonth, 1)
  const lastDayOfMonth = new Date(today.getFullYear(), currentMonth + 1, 0)

  // Find the first Monday of the month
  let firstMonday = firstDayOfMonth
  while (firstMonday.getDay() !== 1) {
    firstMonday.setDate(firstMonday.getDate() + 1)
  }

  // Find the last Sunday of the month
  let lastSunday = lastDayOfMonth
  while (lastSunday.getDay() !== 0) {
    lastSunday.setDate(lastSunday.getDate() - 1)
  }

  return [
    dayjs(firstMonday).format('YYYY-MM-DD'),
    dayjs(lastSunday).format('YYYY-MM-DD'),
  ]
}
