<template>
  <publisher-dashboard-section-wrapper title="Overall Installation">
    <canvas id="overall-installation" height="350"></canvas>
  </publisher-dashboard-section-wrapper>
</template>

<script>
import Chart from 'chart.js'
import PublisherDashboardSectionWrapper from './PublisherDashboardSectionWrapper.vue'

export default {
  name: 'PublisherOverallInstallation',
  components: {
    PublisherDashboardSectionWrapper,
  },
  data() {
    return {
      chartData: {
        type: 'line',
        data: {
          labels: ['May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
          datasets: [
            {
              label: 'Overall Installation',
              data: [0, 0, 1, 2, 7, 8, 7, 4],
              borderColor: '#36495d',
              borderWidth: 3,
            },
            {
              label: 'Revenue',
              data: [0.166, 2.081, 3.003, 0.323, 5.792, 2.886, 4.662, 5.514],
              borderColor: '#47b784',
              borderWidth: 3,
              spanGaps: true,
              borderDash: [10, 5],
            },
          ],
        },
        options: {
          responsive: true,
          lineTension: 1,
          pointHoverRadius: 10,
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                  padding: 25,
                },
              },
            ],
          },
        },
      },
    }
  },
  mounted() {
    const ctx = document.getElementById('overall-installation')
    new Chart(ctx, this.chartData)
  },
}
</script>
