<template>
  <div class="SiteSettingsOutgoingHooks">
    <v-data-table
      dark
      :headers="headers"
      :items="outgoingHooks"
      @click:row="rowClicked"
      class="SiteSettingsOutgoingHooks__table"
    >
      <template v-slot:item.event="{ item }">
        <p class="capitalize">{{ item.event }}</p>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn icon @click.stop="deleteHook(item)" dark>
          <v-icon>delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <br />
  </div>
</template>

<script>
import { MODAL_SITE_HOOK } from '@/components/Modals'
export default {
  name: 'SiteSettingsOutgoindHooks',
  props: {
    outgoingHooks: Array,
  },
  computed: {
    headers() {
      return [
        {
          text: 'Method',
          align: 'start',
          sortable: false,
          value: 'http_method',
        },
        {
          text: 'URL',
          value: 'url',
        },
        { text: 'Event', value: 'event' },
        {
          text: 'Updated At',
          value: 'updated_at',
        },
        {
          text: '',
          value: 'action',
        },
      ]
    },
  },
  methods: {
    deleteHook(item) {
      this.$store.dispatch('sites/removeHook', item.id)
    },
    rowClicked(row) {
      this.$store.commit('application/openModal', {
        component: MODAL_SITE_HOOK,
        props: {
          webhook: row,
        },
      })
    },
  },
}
</script>

<style lang="scss">
$style: SiteSettingsOutgoingHooks;
.#{$style} {
  margin-top: 16px;
  &__table {
    &.theme--dark.v-data-table {
      background-color: $NNotice;
      border-radius: 8px;
    }
  }
}
.capitalize {
  white-space: nowrap;
  text-transform: capitalize;
}
</style>
