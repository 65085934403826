<template>
  <div>
    <VulcanAuthForm :authorize="authorize" v-if="!authToken" />
    <VulcanPickerForm :authToken="authToken" :openChart="openChart" v-else />
  </div>
</template>

<script>
import VulcanAuthForm from './Vulcan/AuthForm'
import VulcanPickerForm from './Vulcan/PickerForm'
import {
  VULCAN_API_URL,
  VULCAN_APPLICATION_ID,
  VULCAN_APPLICATION_SECRET,
} from '@/env'
import { safeJSONParse, getExtensionState } from '@/utils/common'

export default {
  name: 'VulcanIntegration',
  components: {
    VulcanAuthForm,
    VulcanPickerForm,
  },
  data() {
    return {
      extensionId: '',
      pluginSlug: '',
      siteId: '',
      authToken: '',
    }
  },
  computed: {},
  async mounted() {
    const integrationState = safeJSONParse(
      localStorage.getItem('integrationState')
    )
    if (integrationState.kind !== 'vulcan') {
      location.href = '/'
      return
    }
    const { pluginSlug, siteId, extensionId } = integrationState
    if (pluginSlug && siteId && extensionId) {
      this.pluginSlug = pluginSlug
      this.siteId = siteId
      this.extensionId = extensionId
    }
  },
  methods: {
    // authorize from auth form.
    async authorize(email, password) {
      const res = await this.$http.post(
        `${VULCAN_API_URL}/v1/auth`,
        {
          session: {
            email,
            password,
          },
        },
        {
          headers: {
            AUTHORIZATION: `application_id ${VULCAN_APPLICATION_ID}, client_secret ${VULCAN_APPLICATION_SECRET}`,
          },
        }
      )
      this.authToken = res.body.access_token
    },
    // Vulcan Chart select event handler
    // - Update extension state via localStorage so that PowerPlay would get the selected chart status via forgeSDK
    //
    openChart({ chartId, projectId }) {
      let extensionState = getExtensionState(this.extensionId)
      extensionState = {
        ...extensionState,
        picker: { vulcan: { chartId, projectId } },
      }
      localStorage.setItem(this.extensionId, JSON.stringify(extensionState))
      this.$router.push({
        name: 'site_plugin',
        params: { id: this.siteId, slug: this.pluginSlug },
      })
    },
  },
}
</script>
