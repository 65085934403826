<template>
  <v-list class="the-navigation__list" dark dense>
    <v-list-item class="py-2" dark>
      <v-list-item-content
        dark
        class="the-navigation__org_select"
        :class="{ _mini: isNavDrawerMiniVariant }"
      >
        <v-select
          v-model="activeOrganisation"
          @input="setActiveOrganisation"
          :items="organisations"
          item-text="name"
          :menu-props="{ bottom: true, offsetY: true }"
          item-value="id"
          :disabled="isNavDrawerMiniVariant"
          color="darken-3"
          class="myOrgSelect"
          hide-details
          filled
          dark
          dense
        >
          <template v-slot:item="{ item }">
            <span class="org_select" dark>
              <img
                :src="item.logo"
                alt=""
                class="the-navigation__img_logo"
                v-if="item.logo != null"
              />
              <v-icon
                v-else-if="item.id == 0"
                class="the-navigation__img_logo"
                color="orange"
                medium
              >
                mdi-account-box
              </v-icon>
              <avatar
                v-else
                class="the-navigation__img_logo"
                :rounded="false"
                :initials="initials(item.name)"
                :username="item.name"
                :size="24"
                :customStyle="{
                  display: 'inline-block',
                  borderRadius: '4px',
                  fontSize: '11px',
                }"
              />
              <span class="the-navigation__item_name"> {{ item.name }} </span>
              <v-icon
                v-if="item.id == organisationId"
                color="orange"
                style="float: right; margin-top: 4px"
                dark
                small
              >
                mdi-checkbox-marked-circle
              </v-icon>
            </span>
          </template>
          <template v-slot:selection="{ item, index }">
            <span class="org_select" dark>
              <img
                :src="item.logo"
                alt=""
                class="the-navigation__img_logo"
                v-if="item.logo != null"
              />
              <v-icon
                v-else-if="item.id == 0"
                class="the-navigation__img_logo"
                color="orange"
                medium
              >
                mdi-account-box
              </v-icon>
              <avatar
                v-else
                class="the-navigation__img_logo"
                :rounded="false"
                :initials="initials(item.name)"
                :username="item.name"
                :size="24"
                :customStyle="{
                  display: 'inline-block',
                  borderRadius: '4px',
                  fontSize: '11px',
                }"
              />

              <span class="the-navigation__item_name"> {{ item.name }} </span>
            </span>
          </template>

          <template v-slot:append-item v-if="canCreateOrg">
            <v-divider class="mb-2"></v-divider>
            <v-list-item dark @click="createOrganisation">
              <v-list-item-icon>
                <v-icon dark> mdi-plus </v-icon>
              </v-list-item-icon>
              <v-list-item-content dark> Add Organisation </v-list-item-content>
            </v-list-item>
          </template>
        </v-select>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="orgSettingsAvailable"
      link
      :to="{ name: 'organisation_main', params: { id: this.organisationId } }"
      class="the-navigation__list-item"
      active-class="_active"
    >
      <v-list-item-icon :class="{ 'mr-3': !isNavDrawerMiniVariant }">
        <v-icon class="the-navigation__nav-icon">settings </v-icon>
      </v-list-item-icon>

      <v-list-item-content class="the-navigation__nav-content">
        Company Settings
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <div class="the-navigation__projects-header">
      <div
        class="projects-title"
        @mouseenter="showSearchIcon = true"
        @mouseleave="showSearchIcon = false"
      >
        <v-icon
          class="projects-icon"
          :class="{ 'mr-2': !isNavDrawerMiniVariant }"
          >mdi-layers</v-icon
        >
        <span v-if="!isSearching">Your Projects</span>
        <span v-if="!isSearching && showSearchIcon" class="search-trigger">
          <v-icon small @click.stop="startSearch"> mdi-magnify </v-icon>
        </span>
      </div>
      <v-slide-x-transition>
        <div v-if="isSearching" class="search-container">
          <v-text-field
            v-model="searchQuery"
            dense
            dark
            hide-details
            placeholder="Search sites..."
            class="search-input"
            @blur="onSearchBlur"
            ref="searchInput"
          >
            <template v-slot:prepend>
              <v-icon small color="#A4A4AE">mdi-magnify</v-icon>
            </template>
            <template v-slot:append>
              <v-icon
                small
                color="#A4A4AE"
                @click="clearSearch"
                style="cursor: pointer"
              >
                mdi-close
              </v-icon>
            </template>
          </v-text-field>
        </div>
      </v-slide-x-transition>
    </div>

    <!-- Show this when searching -->
    <template v-if="isSearching && searchQuery">
      <div class="search-results">
        <div
          v-for="site in filteredSites"
          :key="site.id"
          class="search-result-group"
        >
          <div v-if="site.projectName" class="search-result-project">
            {{ site.projectName }}
          </div>
          <v-list-item
            :to="'/site/' + site.id"
            class="navigation-sites__list-item"
            :class="{ _active: isActive(site) }"
          >
            <v-tooltip right :disabled="!isNavDrawerMiniVariant">
              <template #activator="{ on, attrs }">
                <div class="site-icon-wrapper mr-3" v-bind="attrs" v-on="on">
                  <template v-if="getSiteIcon(site).type === 'icon'">
                    <v-icon class="site-default-icon">
                      {{ getSiteIcon(site).name }}
                    </v-icon>
                  </template>
                  <template v-else>
                    <img
                      :src="getSiteIcon(site).src"
                      :alt="site.url"
                      class="site-favicon"
                      @error="onImageError($event, site)"
                    />
                  </template>
                  <div
                    class="status-dot"
                    :class="{
                      'status-live': site.kind === 'site',
                      'status-plugin': site.kind === 'plugin',
                    }"
                  ></div>
                </div>
              </template>
              <span>{{ formatSiteUrl(site.url) }}</span>
            </v-tooltip>

            <v-tooltip top :disabled="isNavDrawerMiniVariant">
              <template #activator="{ on, attrs }">
                <span
                  class="site-name"
                  :class="{ _mini: isNavDrawerMiniVariant }"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ formatSiteUrl(site.url) }}
                </span>
              </template>
              <span>{{ formatSiteUrl(site.url) }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  @click.stop="openSiteUrl(site)"
                  text
                  v-bind="attrs"
                  v-on="on"
                  icon
                  x-small
                  class="navigation-sites__action-btn"
                  :class="{ _mini: isNavDrawerMiniVariant }"
                >
                  <the-icon icon="arrow-up-and-right" />
                </v-btn>
              </template>
              <span>Open Site</span>
            </v-tooltip>
          </v-list-item>
        </div>
      </div>
    </template>

    <!-- Show regular list when not searching -->
    <template v-else>
      <v-list-group
        v-for="project in projectsArray"
        :key="project.id"
        v-model="listGroups[project.id]"
        class="the-navigation__projects"
        :class="{
          _active: isProjectActive(project),
          _small: isNavDrawerMiniVariant,
          '': !isNavDrawerMiniVariant,
        }"
        :prepend-icon="!isNavDrawerMiniVariant ? 'mdi-menu-right' : ''"
        sub-group
        eager
      >
        <template v-slot:activator>
          <v-list-item
            @click.stop="openProject(project)"
            inactive
            class="the-navigation__head"
          >
            <v-list-item-icon class="the-navigation__project-icon">
              <v-tooltip top>
                <template #activator="{ on, attrs }">
                  <img v-on="on" v-if="project.image" :src="project.image" />
                  <v-icon v-on="on" v-else :color="project.iconColor"
                    >mdi-{{ project.icon }}</v-icon
                  >
                </template>
                <span> {{ project.name }}</span>
              </v-tooltip>
            </v-list-item-icon>
            <v-list-item-content class="the-navigation__project_title_content">
              <v-list-item-title>
                <span class="the-navigation__project_title">
                  {{ project.name }}
                </span>
              </v-list-item-title>
            </v-list-item-content>
            <v-tooltip top v-if="canManage">
              <template #activator="{ on, attrs }">
                <v-icon
                  v-if="!siteLimitReached"
                  v-on="on"
                  right
                  class="the-navigation__add_site_to_project_icon"
                  medium
                  @click.stop="createSite(project.id)"
                >
                  mdi-plus
                </v-icon>
              </template>
              <span> Create Site in Project </span>
            </v-tooltip>
          </v-list-item>
        </template>

        <draggable
          v-model="project['allObjects']"
          group="sites"
          @change="handleChangePosition($event, project['allObjects'], project)"
        >
          <div v-if="project['allObjects'].length == 0">
            <v-list-item class="the-navigation__project">
              <v-list-item-content>
                <v-list-item-title
                  class="the-navigation__sub-list-item-name ml-1"
                >
                  I'm empty. Create a site.
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>

          <div
            v-for="site in filteredObjects(project['allObjects'])"
            :key="project.id + 'obj' + site.id"
            @click.stop="moveToSite(site)"
            class="the-navigation__project"
            :class="{
              _active: site.id == selectedSite,
            }"
          >
            <v-tooltip right :disabled="!isNavDrawerMiniVariant">
              <template #activator="{ on, attrs }">
                <div class="site-icon-wrapper mr-3" v-bind="attrs" v-on="on">
                  <template v-if="getSiteIcon(site).type === 'icon'">
                    <v-icon class="site-default-icon">
                      {{ getSiteIcon(site).name }}
                    </v-icon>
                  </template>
                  <template v-else>
                    <img
                      :src="getSiteIcon(site).src"
                      :alt="site.url"
                      class="site-favicon"
                      @error="onImageError($event, site)"
                    />
                  </template>
                  <div
                    class="status-dot"
                    :class="{
                      'status-live': site.kind === 'site',
                      'status-plugin': site.kind === 'plugin',
                    }"
                  ></div>
                </div>
              </template>
              <span>{{ formatSiteUrl(site.url) }}</span>
            </v-tooltip>

            <v-tooltip top :disabled="isNavDrawerMiniVariant">
              <template #activator="{ on, attrs }">
                <span
                  class="site-name"
                  :class="{ _mini: isNavDrawerMiniVariant }"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ formatSiteUrl(site.url) }}
                </span>
              </template>
              <span>{{ formatSiteUrl(site.url) }}</span>
            </v-tooltip>

            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  @click.stop="openSiteUrl(site)"
                  text
                  v-bind="attrs"
                  v-on="on"
                  icon
                  x-small
                  class="the-navigation__sub-arrow"
                >
                  <the-icon icon="arrow-up-and-right" />
                </v-btn>
              </template>
              <span>Open Site</span>
            </v-tooltip>
          </div>
        </draggable>
      </v-list-group>

      <!-- Add divider if we have both projects and unassigned sites -->
      <v-divider
        v-if="projectsArray.length && sitesUnassigned.length"
        class="navigation-sites__divider my-2"
      />

      <div class="navigation-sites" :class="{ _small: isNavDrawerMiniVariant }">
        <v-list-item
          v-for="site in sitesUnassigned"
          :key="site.id"
          :to="'/site/' + site.id"
          class="navigation-sites__list-item"
          :class="{ _active: isActive(site) }"
        >
          <v-tooltip right :disabled="!isNavDrawerMiniVariant">
            <template #activator="{ on, attrs }">
              <div class="site-icon-wrapper mr-3" v-bind="attrs" v-on="on">
                <template v-if="getSiteIcon(site).type === 'icon'">
                  <v-icon class="site-default-icon">
                    {{ getSiteIcon(site).name }}
                  </v-icon>
                </template>
                <template v-else>
                  <img
                    :src="getSiteIcon(site).src"
                    :alt="site.url"
                    class="site-favicon"
                    @error="onImageError($event, site)"
                  />
                </template>
                <div
                  class="status-dot"
                  :class="{
                    'status-live': site.kind === 'site',
                    'status-plugin': site.kind === 'plugin',
                  }"
                ></div>
              </div>
            </template>
            <span>{{ formatSiteUrl(site.url) }}</span>
          </v-tooltip>

          <v-tooltip top :disabled="isNavDrawerMiniVariant">
            <template #activator="{ on, attrs }">
              <span
                class="site-name"
                :class="{ _mini: isNavDrawerMiniVariant }"
                v-bind="attrs"
                v-on="on"
              >
                {{ formatSiteUrl(site.url) }}
              </span>
            </template>
            <span>{{ formatSiteUrl(site.url) }}</span>
          </v-tooltip>

          <v-tooltip top>
            <template #activator="{ on, attrs }">
              <v-btn
                @click.stop="openSiteUrl(site)"
                text
                v-bind="attrs"
                v-on="on"
                icon
                x-small
                class="navigation-sites__action-btn"
                :class="{ _mini: isNavDrawerMiniVariant }"
              >
                <the-icon icon="arrow-up-and-right" />
              </v-btn>
            </template>
            <span>Open Site</span>
          </v-tooltip>
        </v-list-item>
      </div>
    </template>
  </v-list>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import Avatar from 'vue-avatar'
import moment from 'moment'
import { Site as SiteResource } from '@/resources/forge'
import draggable from 'vuedraggable'
import {
  MODAL_CREATE_PROJECT,
  MODAL_CREATE_SITE,
  MODAL_CREATE_ORGANISATION,
  MODAL_ALERT,
} from '@/components/Modals'
import NavigationSites from '@/components/TheNavigation/NavigationSites'

export default {
  name: 'NavigationClientList',
  components: { Avatar, draggable, NavigationSites },
  data() {
    let activeOrg = this.$store.state.organisations.current
      ? this.$store.state.organisations.current.id
      : 0
    return {
      activeOrganisation: activeOrg,
      listGroups: {},
      projectsArray: [],
      sitesArray: [],
      searchQuery: '',
      isSearching: false,
      showSearchIcon: false,
    }
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.current,
      projects: ({ projects }) => projects.list,
      sites: ({ sites }) => sites.list,
      organisations: ({ organisations }) => organisations.list,
      isNavDrawerMiniVariant: ({ application }) =>
        application.isNavDrawerMiniVariant,
    }),
    getSiteIcon() {
      return (site) => {
        if (site.kind === 'plugin' && site.publisherInfo?.logo_url) {
          return {
            type: 'image',
            src: site.publisherInfo.logo_url,
          }
        }
        if (site.image && site.image !== '') {
          return {
            type: 'image',
            src: site.image,
          }
        }
        // Fallback to icon
        return {
          type: 'icon',
          name: site.kind === 'plugin' ? 'mdi-puzzle' : 'mdi-web',
        }
      }
    },
    selectedSite() {
      if (this.$route.path.includes('/site/')) return this.$route.params.id
    },
    sitesUnassigned() {
      return this.productionSites.filter((s) => !s.projectId)
    },
    forgeSites() {
      return this.sitesUnassigned.filter((s) => s.kind == 'site')
    },
    canManage() {
      if (this.$store.state.organisations.current.id == 0) return true
      return this.$store.state.organisations.current.subscription_active
    },
    forgePlugins() {
      return this.sitesUnassigned.filter((s) => s.kind == 'plugin')
    },
    canCreateOrg() {
      return this.user.isPaid
    },
    objMaxSites() {
      if (this.$store.state.organisations.current.id == 0)
        return this.user.maximum_number_of_sites
      else return this.$store.state.organisations.current.maximum_sites
    },
    objSites() {
      if (this.$store.state.organisations.current.id == 0)
        return this.user.sites_count
      else return this.$store.state.organisations.current.sites_count
    },
    productionSites() {
      return this.sites.filter((s) => s.mode == 'production')
    },
    resourceTypes() {
      return [
        { type: 'sites', text: 'Sites' },
        { type: 'plugins', text: 'Plugins' },
      ]
    },
    orgSettingsAvailable() {
      return this.$store.state.organisations.current.id != 0
    },
    organisationId: {
      get() {
        return this.$store.state.organisations.current
          ? this.$store.state.organisations.current.id
          : null
      },
    },
    avatar() {
      if (this.user.avatar) return this.user.avatar
      return require('@/assets/avatar-placeholder.svg')
    },
    siteLimitReached() {
      if (this.$store.state.organisations.current.id == 0)
        return this.user.maximum_number_of_sites <= this.productionSites.length
      else
        return (
          this.$store.state.organisations.current.maximum_sites <=
          this.$store.state.organisations.current.sites_count
        )
    },
    filteredSites() {
      const query = this.searchQuery.toLowerCase()
      const results = []

      // Search in project sites
      this.projectsArray.forEach((project) => {
        project.allObjects.forEach((site) => {
          if (this.formatSiteUrl(site.url).toLowerCase().includes(query)) {
            results.push({
              ...site,
              projectName: project.name,
            })
          }
        })
      })

      // Search in unassigned sites
      this.sitesUnassigned.forEach((site) => {
        if (this.formatSiteUrl(site.url).toLowerCase().includes(query)) {
          results.push({
            ...site,
            projectName: null,
          })
        }
      })

      return results
    },
  },
  mounted() {
    this.projectsArray = _.cloneDeep(this.$store.state.projects.list)
    this.sitesArray = this.filteredSitesArray(
      _.cloneDeep(this.$store.state.sites.list)
    )
    if (this.$store.state.organisations.current.id == 0) return

    if (!this.$store.state.organisations.current.subscription_active) {
      this.showOrganisationSubscriptionExpired()
    }
  },
  watch: {
    '$route.params.id'() {
      if (this.$route.matched[0].name == 'site' && this.activeSite)
        this.$set(this.listGroups, this.activeSite.projectId, true)
      if (this.$route.matched[0].name == 'project' && this.activeProject)
        this.$set(this.listGroups, this.activeProject.id, true)
    },
    '$store.state.sites.list': {
      handler(newVal, oldVal) {
        this.sitesArray = this.filteredSitesArray(_.cloneDeep(newVal))
        this.loading = false
      },
      deep: true,
    },
    '$store.state.projects.list': {
      handler(newVal, oldVal) {
        this.projectsArray = _.cloneDeep(newVal)
        this.sitesArray = this.filteredSitesArray(
          _.cloneDeep(this.$store.state.sites.list)
        )
        this.loading = false
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations('application', ['toggleisNavDrawerMiniVariant']),
    ...mapActions('application', ['logOut']),
    formatSiteUrl(url) {
      return url ? url.replace('.getforge.io', '') : ''
    },
    isProjectActive(project) {
      if (!this.$route.matched[0]) return false
      if (this.$route.matched[0].name == 'site')
        return this.activeSite.projectId == project.id
      if (this.$route.matched[0].name == 'project')
        return this.activeProject.id == project.id
    },
    openProject(project) {
      this.$router.push('/project/' + project.id)
    },
    moveToSite(site) {
      this.$router.push('/site/' + site.id)
    },
    showOrganisationSubscriptionExpired() {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Subscription Expired',
          text: 'To continue use Forge features please select a subscription.',
          actionBtnLabel: 'OK',
        },
      })
    },
    handleChangePosition(event, list, project) {
      if (event.added && this.canManage) {
        let { newIndex, element } = event.added
        let validated = true
        let projectOwner = 0
        if (this.$store.state.organisations.current.id == 0 && project)
          projectOwner = project.user_id
        else if (this.$store.state.organisations.current.id != 0)
          projectOwner = this.$store.state.organisations.current.user_id
        let validatedMessage = 'You should be the owner of a site and a project'
        let validatedChangeOwner = false
        if (!project && !element) validated = false
        else if (
          project &&
          (projectOwner != this.user.id || element.userId != this.user.id)
        )
          validated = false
        else if (!project && this.user.id != element.userId) validated = false
        if (
          project &&
          element &&
          projectOwner != this.user.id &&
          element.userId == this.user.id
        ) {
          validatedChangeOwner = true
          validated = true
        }
        if (validated && !validatedChangeOwner) {
          const moveToId = project ? project.id : null
          let moveToName = project ? ' to ' + project.name : ' out from project'
          this.$store.commit('application/openModal', {
            component: MODAL_ALERT,
            props: {
              title: 'Site Move',
              text: 'Are you want to move ' + element.url + moveToName + ' ?',
              actionBtnLabel: 'Move',
              showCancelBtn: true,
              action: () => {
                this.$store
                  .dispatch('sites/moveSite', {
                    id: element.id,
                    project_id: moveToId,
                  })
                  .then(() => {
                    return true
                  })
              },
            },
          })
        } else if (validated && validatedChangeOwner) {
          let app_url = location.protocol + '//' + location.host
          let success = null
          let params = {
            new_owner_id: project.user_id,
            app_url: app_url,
            project_id: project.id,
          }
          this.$store.commit('application/openModal', {
            component: MODAL_ALERT,
            props: {
              title: 'Change site owner to project owner',
              text: 'Are you sure? To project owner will be send an email to confirm change.',
              actionBtnLabel: 'Change',
              showCancelBtn: true,
              action: () => {
                SiteResource.changeSiteOwner({ id: element.id }, params).catch(
                  (e) => {
                    setTimeout(() => {
                      this.changeOwnerMessage = e.body.message
                      this.changeOwnerFailed = true
                    }, 1800)
                  }
                )
              },
            },
          })
        } else {
          this.$store.commit('application/openModal', {
            component: MODAL_ALERT,
            props: {
              title: 'Site Move',
              text: validatedMessage,
              actionBtnLabel: 'Close',
            },
          })
        }
      }

      this.projectsArray = _.cloneDeep(this.$store.state.projects.list)
      this.sitesArray = this.filteredSitesArray(
        _.cloneDeep(this.$store.state.sites.list)
      )
    },
    filteredObjects(sites) {
      sites = sites.filter((site) => site !== undefined)
      let prodSites = sites.filter((s) => s.mode == 'production')
      prodSites = _.uniqBy(prodSites, 'id')

      prodSites = prodSites.map((site) => {
        const fullSiteData = this.sites.find((s) => s.id === site.id)
        return { ...fullSiteData, ...site }
      })

      const itemsOnTop = prodSites.filter((site) => site.kind == 'site')
      const itemsOnBottom = prodSites.filter((site) => site.kind == 'plugin')
      return [...itemsOnTop, ...itemsOnBottom]
    },
    filteredSitesArray(sites) {
      sites = sites.filter((s) => s.mode == 'production' && !s.projectId)

      sites = sites.map((site) => {
        const fullSiteData = this.sites.find((s) => s.id === site.id)
        return { ...fullSiteData, ...site }
      })

      const itemsOnTop = sites.filter((site) => site.kind == 'site')
      const itemsOnBottom = sites.filter((site) => site.kind == 'plugin')
      return [...itemsOnTop, ...itemsOnBottom]
    },
    initials(username) {
      username[0].toUpperCase()
    },
    openSiteUrl(site) {
      window.open('http://' + site.url, '_blank')
    },

    createOrganisation() {
      this.$store.commit('application/openModal', {
        component: MODAL_CREATE_ORGANISATION,
      })
    },

    async setActiveOrganisation(id) {
      if (id == -1) {
        this.createOrganisation()
        this.activeOrganisation = this.organisations.find(
          (org) => org.id == this.$store.state.organisations.current.id
        )
      } else {
        await this.$store.dispatch('organisations/setCurrent', id, {
          root: true,
        })
        location = '/'
      }
    },
    createSite(project_id = null) {
      this.$store.commit('application/openModal', {
        component: MODAL_CREATE_SITE,
        props: {
          projectId: project_id,
        },
      })
    },
    openOrganisationSettings() {
      this.$router.push(`/organisation/${this.organisationId}`)
    },
    createProject() {
      this.$store.commit('application/openModal', {
        component: MODAL_CREATE_PROJECT,
      })
    },
    isActive(site) {
      return this.selectedSite === site.id
    },
    startSearch() {
      this.isSearching = true
      this.$nextTick(() => {
        this.$refs.searchInput.focus()
      })
    },
    clearSearch() {
      this.searchQuery = ''
      this.isSearching = false
    },
    onSearchBlur() {
      if (!this.searchQuery) {
        this.isSearching = false
      }
    },
    onImageError(event, site) {
      console.error(`Error loading image for site: ${site.url}`, event)
    },
  },
}
</script>
<style lang="scss">
$style: the-navigation;

.v-text-field > .v-input__control > .v-input__slot:after {
  border-style: unset !important;
}

.v-list-item--active .org_select {
  width: 100%;
}

.v-list-item__icon {
  margin-right: 5px !important;
}
.v-list-group--sub-group .v-list-group__header {
  padding-left: 0px !important;
}

.#{$style} {
  padding: 82px 24px 191px;
  &.v-navigation-drawer--mini-variant {
    overflow: initial;
  }
  &__img_logo {
    width: 24px;
    height: 24px;
    display: inline-block;
    vertical-align: middle;
  }
  &__item_name {
    margin-left: 10px;
    display: inline-block;
  }
  &__project-icon.v-list-item__icon {
    margin-right: 8px !important;
  }
  &__project_title {
    overflow: hidden;
    min-width: 50%;
    display: inline-block;
    vertical-align: middle;
  }

  &.v-navigation-drawer {
    background-color: $dark-bg-color;
  }
  &__header {
    position: absolute;
    top: 16px;
    left: 6px;
    right: 0;
  }
  &__icon {
    cursor: pointer;
    transition: filter 0.2s ease;
    &:hover {
      filter: brightness(1.2);
    }
  }
  &__toggle-btn.v-icon {
    position: absolute;
    right: 22px;
    top: 13px;
    border: 1px solid $N5;
    border-radius: 4px;
    z-index: 1;
    background-color: $dark-bg-color;
    &._mini {
      right: -10px;
    }
    &:focus::after {
      content: none;
    }
    &:hover {
      border-color: $N3;
    }
  }
  &__list {
    padding: 0;
  }
  &__divider {
    margin: 0 0 24px;
    &.v-divider {
      background-color: $N5;
    }
  }
  &__list-item {
    margin-bottom: 20px;
  }
  &__footer {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 24px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 100%;
    &._small {
      padding: 0 28px 40px;
    }
  }
  &__footer-btn {
    &.v-btn {
      margin-bottom: 20px;
      padding: 0 !important;
    }
  }
  &__footer-link {
    &.v-size--default {
      font-size: 16px;
      padding: 0 !important;
    }
    font-weight: 600;
  }
  &__footer-icon {
    margin-right: 14px;
    &._margin-less {
      margin-right: 0;
    }
  }
  &__nav-icon {
    fill: $N2;
  }
  &__nav-content {
    color: $N2;
    font-weight: 500;
    font-size: $H14;
    white-space: nowrap;
  }
  &__list-item {
    &._active {
      color: $white;
      .#{$style}__nav-icon {
        fill: $B1;
      }
      .#{$style}__nav-content {
        color: $B1;
      }
      &:before {
        content: none;
      }
    }
  }
  &__projects {
    &._small {
      padding: 0;
      margin-left: 8px;
    }
    &
      .v-list-group__header
      .v-list-item__icon.v-list-group__header__append-icon {
      display: none;
    }
    & .#{$style}__head {
      color: $N2 !important;
      .v-icon {
        color: inherit;
      }
    }
    &._active {
      & .#{$style}__head {
        color: $white !important;
      }
    }
    & .v-list-item {
      padding: 0;
    }
    .v-list-group__items {
      padding-left: 0;
    }
  }
  &__project {
    cursor: pointer;
    padding: 0 8px;
    min-height: 40px;
    display: flex;
    align-items: center;
    width: 100%;

    &:hover {
      background: #2a293a;

      .#{$style}__sub-arrow {
        opacity: 1;
      }

      .site-name {
        color: white;
      }

      .site-default-icon {
        color: white;
      }
    }

    &._active {
      .site-name {
        color: white;
      }

      .site-default-icon {
        color: white;
      }
    }

    .site-icon-wrapper {
      width: 28px;
      height: 28px;
      margin-left: 4px;
      margin-right: 8px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .site-default-icon {
        font-size: 20px;
        color: #a4a4ae;
        transition: color 0.2s ease;
      }

      .site-favicon {
        width: 20px;
        height: 20px;
        object-fit: contain;
      }

      .status-dot {
        position: absolute;
        bottom: -2px;
        right: -2px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        border: 1px solid #1f1e31;

        &.status-live {
          background-color: #4caf50;
        }

        &.status-plugin {
          background-color: #ff9800;
        }
      }
    }

    .site-name {
      font-size: 12px;
      font-weight: 500;
      color: #a4a4ae;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
      min-width: 0;
      transition: color 0.2s ease;
      margin-right: 4px;
    }

    .#{$style}__sub-arrow {
      opacity: 0;
      margin-left: auto;
      transition: opacity 0.2s ease;
      flex-shrink: 0;
      width: 24px;

      &.theme--dark.v-btn.v-btn--icon {
        color: #a4a4ae;

        &:hover {
          color: white;
        }
      }
    }
  }
  &__sub-list-name {
    &.v-list-item {
      padding-left: 0 8px;
    }
  }
  &__sub-list-item-name {
    font-size: 12px;
    font-weight: 500;
    color: $N2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 8px;
  }
  &__sub-arrow {
    &.theme--dark.v-btn.v-btn--icon {
      color: #a4a4ae;

      &:hover {
        color: white;
      }
    }
  }
  &__dot {
    display: inline-block !important;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $G1;
    margin-right: 8px;
  }

  &__head {
    .the-navigation__add_site_to_project_icon {
      display: none;
      margin-left: auto;
    }
    &:hover {
      .the-navigation__add_site_to_project_icon {
        display: inline-flex;
      }
    }
  }
}
.navigation-sites__list-item {
  padding: 0 8px;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 8px;
  min-width: 0;

  &._active {
    .site-name {
      color: white;
    }

    .site-default-icon {
      color: white;
    }
  }

  &:hover {
    background: #2a293a;

    .site-name {
      color: white;
    }

    .site-default-icon {
      color: white;
    }

    .navigation-sites__action-btn {
      opacity: 1;
    }
  }
}

.navigation-sites__action-btn {
  opacity: 0;
  margin-left: auto;
  color: #a4a4ae;
  transition: opacity 0.2s ease;
  width: 24px;
  flex-shrink: 0;
}

.site-icon-wrapper {
  width: 28px;
  height: 28px;
  margin-left: 4px;
  margin-right: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .site-default-icon {
    font-size: 20px;
    color: #a4a4ae;
    transition: color 0.2s ease;
  }

  .site-favicon {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }

  .status-dot {
    position: absolute;
    bottom: -2px;
    right: -2px;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid #1f1e31;

    &.status-live {
      background-color: #4caf50;
    }

    &.status-plugin {
      background-color: #ff9800;
    }
  }
}

.status-dot {
  position: absolute;
  bottom: -2px;
  right: -2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  border: 1.5px solid #1f1e31;

  &.status-live {
    background-color: #4caf50;
  }

  &.status-plugin {
    background-color: #ff9800;
  }

  &.status-error {
    background-color: #ff5252;
  }
}

.site-name {
  font-size: 12px;
  font-weight: 500;
  color: #a4a4ae;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  min-width: 0;
  transition: all 0.2s ease;
  margin-right: 4px;

  .v-navigation-drawer--mini-variant & {
    opacity: 0;
    width: 0;
  }
}

.the-navigation__projects-header {
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 8px;
  position: relative;

  .projects-title {
    display: flex;
    align-items: center;
    color: #a4a4ae;
    font-size: 12px;
    font-weight: 500;
    padding: 0 8px;
    width: 100%;

    .projects-icon {
      color: #a4a4ae;
      font-size: 20px;
    }

    span {
      transition: opacity 0.2s ease;
    }

    .search-trigger {
      margin-left: auto;
      opacity: 0;
      transition: opacity 0.2s ease;
      cursor: pointer;

      &:hover {
        color: white;
      }
    }

    &:hover .search-trigger {
      opacity: 1;
    }

    .v-navigation-drawer--mini-variant & {
      justify-content: center;
      padding: 0;
      width: 100%;

      span,
      .search-trigger {
        display: none;
      }

      .projects-icon {
        margin: 0;
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }
  }

  .search-container {
    position: absolute;
    left: 0;
    right: 0;
    background: $dark-bg-color;
    z-index: 1;

    .search-input {
      padding: 0 8px;

      ::v-deep .v-input__slot {
        background: transparent !important;
        min-height: 32px !important;
        border: 1px solid #2e2c42;

        &:hover,
        &:focus-within {
          border-color: #a4a4ae;
        }
      }
    }
  }
}

.search-results {
  .search-result-group {
    margin-bottom: 8px;
  }

  .search-result-project {
    font-size: 11px;
    color: #a4a4ae;
    padding: 4px 16px;
    opacity: 0.7;
  }
}

.navigation-sites__divider {
  &.v-divider {
    border-color: #2e2c42 !important;
    margin-left: 8px;
    margin-right: 8px;
  }
}
</style>
