import { siteApp } from '@/models/siteApp'
import { siteApp as SiteAppResource } from '@/resources/forge'

const store = {
  namespaced: true,
  state: {
    chiselApp: null,
    list: [],
    parseSetting: null,
  },
  /**
   * Mutations to modify the state related to site apps.
   */
  mutations: {
    /**
     * Set the list of site apps.
     * @param {Array} list - The list of site apps.
     */
    setList(state, list) {
      state.list = list
    },
    /**
     * Delete a site app from the list.
     * @param {string} id - The ID of the site app to delete.
     */
    delete(state, id) {
      state.list = state.list.filter((v) => id != v.id)
    },
    /**
     * Create a new site app.
     * @param {Object} site_app - The new site app to create.
     */
    create(state, site_app) {
      state.list = [...state.list, site_app]
    },
    /**
     * Update parse environment settings.
     * @param {Object} data - The data containing updated parse environment settings.
     */
    updateParseEnv(state, data) {
      if (state.parseSetting == null) return
      state.parseSetting.environments = data
    },
    /**
     * Update a site app.
     * @param {Object} params - The parameters containing the updated site app data.
     */
    update(state, params) {
      const site_app = state.list.find((v) => v.id == params.id)
      if (!site_app) return

      Object.assign(site_app, params)
    },
    /**
     * Update the percentage deployed for a chisel app.
     * @param {Object} data - The data containing the updated percentage deployed for a chisel app.
     */
    updateChiselPercent(state, data) {
      if (
        state.chiselApp == null ||
        state.chiselApp.current_version_id != data.id
      )
        return

      state.chiselApp.percent_deployed = data.percent_deployed
      if (data.percent_deployed >= 100) state.chiselApp.deployed = true
    },
    /**
     * Set a site app as deployed.
     * @param {Object} options - The options containing information about the site app deployment.
     */
    setDeployedParseSetting(state, options) {
      const site_app = state.list.find((app) => app.id == options.site_app_id)
      if (!site_app) return

      site_app.deployed = true
      site_app.deploying = false
      site_app.redeploy = false
      state.parseSetting.cloud_code_enabled = options.cloud_code_enabled
      state.parseSetting.igniteFolderUsed = options.igniteFolderUsed
      state.parseSetting.deployed = true
      state.parseSetting.deploying = false
      state.parseSetting.redeploy = false
    },
    /**
     * Set service messages for a parse setting.
     * @param {Object} params - The parameters containing information about the service messages.
     */
    setServiceMessages(state, params) {
      if (state.parseSetting == null || state.parseSetting.appId != params.id)
        return
      if (params.clear) state.parseSetting.messages = params.message
      else
        state.parseSetting.messages = state.parseSetting.messages.concat(
          params.message
        )
    },
    /**
     * Set messages for a chisel app.
     * @param {string} version_id - The version ID of the chisel app.
     */
    setChiselMessages(state, version_id) {
      if (
        state.chiselApp == null ||
        state.chiselApp.current_version_id != version_id
      )
        return
      state.chiselApp.messages = JSON.parse(
        localStorage.getItem(`version_${version_id}`)
      )
    },
    /**
     * Set the active site and chisel apps.
     */
    setApps(state) {
      for (let list of state.list) {
        if (list.all_app_id == 1) state.parseSetting = list.app
        else state.chiselApp = list.app
      }
    },
    /**
     * Change temporary version data.
     * @param {Object} actualVersion - The actual version data.
     */
    changeTmpVersion(state, actualVersion) {
      const version = state.list.find((v) => v.id == 0)
      if (!version) return
      Object.assign(version, actualVersion)
    },
  },
  /**
   * Actions to perform asynchronous operations related to site apps.
   */
  actions: {
    /**
     * Load site apps.
     * @param {string} site_id - The ID of the site.
     * @returns {Promise} A promise representing the result of loading site apps.
     */
    async load({ commit }, site_id) {
      const list = []
      const res = await SiteAppResource.get({ site_id })
      commit(
        'setList',
        res.body.site_apps.map((v) => new siteApp(v))
      )
      commit('setApps')
      return res
    },
    /**
     * Install a new app.
     * @param {Object} params - The parameters containing information about the app to install.
     */
    async installApp({ commit, dispatch }, params) {
      await SiteAppResource.create({ site_app: params })
        .then((res) => {
          commit('create', new siteApp(res.body.site_app))
          commit('setApps')
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /**
     * Check parse service logs.
     * @param {string} id - The ID of the app.
     */
    checkParseLogs({ commit, dispatch }, id) {
      SiteAppResource.checkServiceLog({ id }, {})
    },
    /**
     * Check parse service health.
     * @param {string} id - The ID of the app.
     */
    checkParseHealth({ commit, dispatch }, id) {},
    /**
     * Delete a site app.
     * @param {string} id - The ID of the site app to delete.
     */
    async delete({ commit, dispatch }, id) {
      commit('delete', id)
      await SiteAppResource.delete({ id: id }, {})
    },
    /**
     * Update a site app.
     * @param {Object} params - The parameters containing the updated site app data.
     * @returns {Promise} A promise representing the result of updating the site app.
     */
    update({ commit, getters }, params) {
      return SiteAppResource.update(
        { id: params['id'] },
        { site_app: params['site_app'] }
      ).then((res) => {
        commit('update', new siteApp(res.body.site_app))
        commit('setApps')
        return res.body
      })
    },
    /**
     * Update parse environments.
     * @param {Object} params - The parameters containing the updated parse environments.
     * @returns {Promise} A promise representing the result of updating parse environments.
     */
    updateParseEnvironments({ commit, getters }, params) {
      return SiteAppResource.updateParseEnvironments(
        { id: params['id'] },
        { parse_setting: { new_environments: params['environment'] } }
      ).then((res) => {
        commit('updateParseEnv', res.body.parse_setting.environments)
      })
    },
    /**
     * Update data in the list of site apps.
     * @param {Object} params - The parameters containing the updated data.
     */
    updateDataInList({ commit }, params) {
      commit('update', new siteApp(params))
      commit('setApps')
    },
  },
  /**
   * Getters to retrieve computed state from the store related to site apps.
   */
  getters: {
    /**
     * Find a site app by its ID.
     * @param {String} id -  Target site app id
     * @returns {Object|null} The found site app or null if not found.
     */
    findById: (state) => (id) => {
      return state.list.find((ap) => ap.id == id)
    },
  },
}

export default store
