import { guid } from '@/utils/common'
import { Organisation } from '@/models/Organisation'
import { Organisation as OrganisationResource } from '@/resources/forge'
import { Invitation as InvitationResource } from '@/resources/forge'

export const ADMIN_ROLE_ID = 1

const store = {
  namespaced: true,
  state: {
    list: [],
    current: null,
  },
  mutations: {
    /**
     * Sets the list of organisations in the store state.
     * @param {Array} list - The list of organisations.
     */
    setList(state, list) {
      state.list = list
    },
    /**
     * Sets the current organisation in the store state.
     * @param {Object} organisation - The organisation to set as current.
     */
    setCurrent(state, organisation) {
      state.current = organisation
    },
    /**
     * Adds a new organisation to the list.
     * @param {Object} organisation - The organisation to add.
     */
    create(state, organisation) {
      state.list = [...state.list, organisation]
    },
    /**
     * Updates an existing organisation in the list.
     * @param {Object} params - The parameters to update the organisation.
     */
    update(state, params) {
      const organisation = state.list.find((o) => o.id == params.id)
      if (!organisation) return

      Object.assign(organisation, params)
    },
    updateCurrentOrg(state, params) {
      const organisation = state.list.find((o) => o.id == params.id)
      if (!organisation) return

      Object.assign(organisation, new Organisation(params))
      state.current = organisation
    },
    /**
     * Deletes an organisation from the list based on its ID.
     * @param {string} id - The ID of the organisation to delete.
     */
    delete(state, id) {
      if (state.current != null && state.current.id == id) state.current = null
      state.list = state.list.filter((o) => id != o.id)
    },
    /**
     * Increments the count of sites for the current organisation.
     */
    incSitesCount(state) {
      if (state.current == null) return
      state.current.sites_count += 1
    },
    /**
     * Increments the count of projects for the current organisation.
     */
    incProjectsCount(state) {
      if (state.current == null) return
      state.current.projects_count += 1
    },
    /**
     * Decrements the count of sites for the current organisation.
     */
    descSitesCount(state) {
      if (state.current == null) return
      state.current.sites_count -= 1
    },
    /**
     * Decrements the count of projects for the current organisation.
     */
    descProjectsCount(state) {
      if (state.current == null) return
      state.current.projects_count -= 1
    },
  },
  actions: {
    /**
     * Loads organisations from the API and sets them in the store state.
     * @returns {Promise} A promise resolving to the API response.
     */
    async load({ commit, getters, dispatch }) {
      const list = []
      list.push(
        new Organisation({
          id: 0,
          name: 'Individual account',
          role: 'admin',
        })
      )
      let orgLoaded = false
      const res = await OrganisationResource.get()
      let orgs = res.body.organisations.map((o) => new Organisation(o))
      commit('setList', list.concat(orgs))
      const result = await OrganisationResource.getCurrent()
      let organisation
      if (result && result.body) {
        organisation = getters['findById'](result.body.organisation.id)
        orgLoaded = true
      }

      if (!organisation) organisation = getters['individual']
      commit('setCurrent', organisation)
      if (orgLoaded)
        await dispatch(
          'publisher_infos/load',
          {
            id: organisation.id,
          },
          { root: true }
        )
      else {
        commit('publisher_infos/setList', [], { root: true })
        commit('publisher_infos/setCurrent', {}, { root: true })
      }
    },
    /**
     * Sets the current organisation.
     * @param {string} id - The ID of the organisation to set as current.
     * @returns {Promise} A promise resolving to the API response.
     */
    async setCurrent({ commit, getters }, id) {
      const res = await OrganisationResource.setCurrent({}, { set_org_id: id })
      commit('setCurrent', getters['findById'](id))
      return res
    },
    /**
     * Creates a new organisation.
     * @param {Object} params - The parameters for creating the organisation.
     * @returns {Promise} A promise resolving to the API response.
     */
    async create({ commit, dispatch, rootState }, params) {
      if (!params.name) return

      return OrganisationResource.create({ organisation: params }).then(
        ({ body }) => {
          commit('create', new Organisation(body.organisation))
          dispatch('setCurrent', body.organisation.id)
          return body.organisation
        }
      )
    },
    /**
     * Updates an organisation.
     * @param {Object} params - The parameters for updating the organisation.
     * @returns {Promise} A promise resolving to the API response.
     */
    update({ commit, getters }, params) {
      const organisation = getters['findById'](params.id)
      if (!organisation) return

      return OrganisationResource.update(
        { id: params.id },
        params.organisation
      ).then((res) => {
        commit('update', new Organisation(res.body.organisation))
        return res
      })
    },
    /**
     * Updates the email domains for an organisation.
     * @param {Object} params - The parameters for updating the email domains.
     * @returns {Promise} A promise resolving to the API response.
     */
    updateDomains({ commit, getters }, params) {
      const organisation = getters['findById'](params.id)
      if (!organisation) return

      return OrganisationResource.updateDomains(
        { id: params.id },
        { email_templates: params.domains }
      ).then((res) => {
        commit('update', new Organisation(res.body.organisation))
        return res
      })
    },
    /**
     * Updates the logo for an organisation.
     * @param {Object} params - The parameters for updating the logo.
     * @returns {Promise} A promise resolving to the API response.
     */
    updateLogo({ commit, getters, rootState }, params) {
      const organisation = getters['findById'](
        rootState.organisations.current.id
      )
      if (!organisation) return

      return OrganisationResource.updateLogo(
        { id: rootState.organisations.current.id },
        params
      ).then((res) => {
        commit('update', new Organisation(res.body.organisation))
        return res
      })
    },
    /**
     * Deletes an organisation.
     * @param {string} id - The ID of the organisation to delete.
     */
    async delete({ state, commit }, id) {
      return await OrganisationResource.destroy({ id: id })
        .then(() => {
          commit('delete', id)
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
  getters: {
    /**
     * Finds an organisation by its ID.
     * @param {string} id - The ID of the organisation to find.
     * @returns {Object|null} The organisation if found, otherwise null.
     */
    findById: (state) => (id) => {
      return state.list.find((p) => p.id == id)
    },
    /**
     * Finds the individual account.
     * @returns {Object|null} The individual account if found, otherwise null.
     */
    individual: (state) => state.list.find((o) => o.id == 0),
  },
}

export default store
