<template>
  <div class="SiteUsage">
    <div class="SiteUsage__row">
      <div class="SiteUsage__section">
        <h2 class="SiteUsage__title">Site Bandwidth</h2>

        <div class="SiteUsage__texts">
          <p class="SiteUsage__text">
            Below is the bandwidth usage for <span>{{ activeSite.url }}</span
            >.
          </p>
          <p class="SiteUsage__text">
            Please, be aware that your Forge account is billed according to the
            total bandwidth across <i>all of your sites</i>.
          </p>
          <p class="SiteUsage__text">
            The fewer assets your sites use, the less bandwidth you'll use.
          </p>
        </div>
      </div>

      <div class="SiteUsage__section">
        <h2 class="SiteUsage__title">Bandwidth Usage</h2>
        <div class="SiteUsage__row">
          <h4 class="SiteUsage__site">{{ activeSite.url }}</h4>
          <div class="SiteUsage__bandwidth">
            <span>{{ parseBandwidth(activeSite.bandwidth.thisMonth) }}</span>
            this month
          </div>
        </div>
      </div>
    </div>

    <div class="SiteUsage__section _mb">
      <h2 class="SiteUsage__title">Breakdown</h2>
      <v-container class="pa-0">
        <v-row>
          <v-col>
            <SiteUsageBreakdownElement
              :value="parseBandwidth(activeSite.bandwidth.today)"
              period="Today"
            />
          </v-col>
          <v-col>
            <SiteUsageBreakdownElement
              :value="parseBandwidth(activeSite.bandwidth.week)"
              period="This Week"
            />
          </v-col>
          <v-col>
            <SiteUsageBreakdownElement
              :value="parseBandwidth(activeSite.bandwidth.month)"
              period="Last 30 Days"
            />
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div class="SiteUsage__section">
      <h2 class="SiteUsage__title">Historical Usage</h2>

      <p class="SiteUsage__text">
        Below is the historical usage for {{ activeSite.url }}.
      </p>

      <v-simple-table class="SiteUsage__table">
        <template v-slot:default>
          <tbody>
            <tr
              v-for="item in activeSite.bandwidth.previousMonths"
              :key="item.month"
            >
              <td class="SiteUsage__table-head">{{ item.month }}</td>
              <td class="SiteUsage__table-value">
                {{ parseBandwidth(item.bandwidth) }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </div>
</template>

<script>
import SiteUsageBreakdownElement from './SiteUsage/SiteUsageBreakdownElement'
import { parseBandwidth } from '@/utils/common'

export default {
  name: 'SiteUsage',
  components: { SiteUsageBreakdownElement },
  data() {
    return {}
  },
  methods: {
    parseBandwidth(bandwidth) {
      return parseBandwidth(bandwidth)
    },
  },
}
</script>
<style lang="scss">
$style: SiteUsage;
.#{$style} {
  padding: 42px 24px 24px;

  &__title {
    color: $white;
    font-weight: 500;
    font-size: $H20;
    margin-bottom: 20px;
  }
  &__site {
    color: $white;
    font-weight: 400;
    font-size: $H14;
    margin-bottom: 12px;
  }
  &__bandwidth {
    color: $Y1;
    font-size: $H16;
    span {
      font-weight: 500;
    }
  }
  &__section {
    @extend %section-styles;
    flex-basis: 50%;
    &._mb {
      margin-bottom: 32px;
    }
  }
  &__row {
    display: flex;
    gap: 32px;
    margin-bottom: 32px;
  }
  &__table {
    &.theme--dark.v-data-table {
      @extend %modal-body-styles;
    }

    &.theme--dark.v-data-table
      > .v-data-table__wrapper
      > table
      > tbody
      > tr:hover:not(.v-data-table__expanded__content):not(
        .v-data-table__empty-wrapper
      ) {
      background-color: $N4;
    }
  }
  &__table-head {
    color: $white;
    font-size: $H12 !important;
  }
  &__table-value {
    color: $white;
    font-size: $H12 !important;
    text-align: right;
    font-weight: 500;
  }
  @media screen and (max-width: 600px) {
    &__row {
      flex-direction: column;
    }
  }
}
</style>
