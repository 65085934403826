<template>
  <div class="d-flex SiteVersionComments__comments_container">
    <div
      class="SiteVersionComments__comments_block"
      id="SiteVersionComments__comments_block"
      v-if="comments.length > 0 && !loading"
    >
      <div
        v-for="comment in comments"
        class="SiteVersionComments__comment"
        :id="'comment_' + comment.id"
      >
        <SiteVersionComment :comment="comment" :version="version" />
      </div>
    </div>
    <div class="SiteVersionComments__noComment" v-else-if="!loading">
      <the-icon class="SiteVersionComments__comment_icon" icon="comment" />
      <h3>No comments yet</h3>
      <div class="no_comments_small">
        Once you start writing and receiving comments they’ll appear here...
      </div>
    </div>
    <div v-else-if="loading" class="spinner mt-12" align="center">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>

    <v-footer class="SiteVersionComments__footer" fixed>
      <SiteVersionMentionInput
        @input="send"
        :placeholder="placeholder"
        ref="childComponent"
      />
      <v-btn class="y1 SiteVersionComments__footer_btn" @click="addComment">
        <v-icon> mdi-send </v-icon>
      </v-btn>
    </v-footer>
  </div>
</template>

<script>
import { IntersectingCirclesSpinner } from 'epic-spinners'
import SiteVersionMentionInput from '@/components/TheSite/SiteVersion/SiteVersionComments/SiteVersionMentionInput'
import SiteVersionComment from '@/components/TheSite/SiteVersion/SiteVersionComments/SiteVersionComment'
import _ from 'lodash'
export default {
  name: 'SiteVersionComments',
  components: {
    SiteVersionMentionInput,
    IntersectingCirclesSpinner,
    SiteVersionComment,
  },
  props: {
    comments: Array,
    placeholder: String,
    version: Object,
    loading: Boolean,
    cloudCode: Boolean,
  },
  data() {
    return {
      msg: '',
      mentions: [],
    }
  },
  methods: {
    send(v) {
      this.msg = v.value
      this.mentions = v.mentions
    },
    scrollToBottom() {
      let elem = document.getElementById('SiteVersionComments__comments_block')
      if (elem) elem.scrollTop = elem.scrollHeight
    },
    async addComment() {
      const currentUserId = this.$store.state.user.current.id
      let siteUsers = _.cloneDeep(this.$store.state.site_user.list)
      let usersIds = siteUsers
        .filter((su) => this.mentions.includes(su.id))
        .map((su) => su.user_id)

      // Create the initial comment
      await this.$store.dispatch('comments/create', {
        message: this.msg,
        user_id: currentUserId,
        mentioned_users: usersIds,
        version_id: this.version.id,
      })

      // Check if the message contains a forge mention by looking for the data-id attribute
      const hasForgeAIMention = this.msg.includes('data-id="forge-ai"')

      if (hasForgeAIMention) {
        console.log('Forge AI mentioned - triggering response')
        // Extract the actual text without HTML tags for the AI prompt
        const tempDiv = document.createElement('div')
        tempDiv.innerHTML = this.msg
        const cleanMessage = tempDiv.textContent || tempDiv.innerText

        this.msg = cleanMessage // Set clean text for AI processing
        this.handleForgeAIResponse()
      }

      this.$refs.childComponent.clearInput()
    },

    getBasePrompt(userMessage) {
      // Safely get site information with fallbacks
      const site = this.$store.state.sites?.active || {}

      // Extract only relevant site information
      const siteInfo = {
        name: site.site_name,
        url: site.url,
        type: site.kind,
        mode: site.mode,
        deployment: {
          status: site.currentVersionStatus,
          version: site.currentVersionScopedId,
          lastUpdated: new Date(site.updatedAt).toLocaleString(),
          compiler: site.compiler,
        },
        features: {
          ssl: site.sslEnabled,
          autoDeployEnabled: !!(
            site.githubAutodeploy ||
            site.dropboxAutodeploy ||
            site.bitbucketAutodeploy
          ),
          buildCommand: site.buildCommand || 'None',
          buildFolder: site.buildFolder || 'None',
        },
      }

      // Get the last 5 comments (or fewer if there aren't 5)
      const recentComments = this.comments
        .slice(0, 5)
        .reverse()
        .map((comment) => {
          const author = comment.is_ai_response ? 'Forge AI' : comment.userName
          return `${author}: ${comment.message}`
        })
        .join('\n')

      return `You are Forge AI, an AI assistant integrated into the Forge platform. You help users with their questions and tasks related to web development, forms, and site building.

Key Guidelines:
- Be concise and direct in your responses
- Use markdown for code examples or technical explanations
- When discussing code, focus on best practices and modern web standards
- If you're unsure about something, acknowledge it and suggest alternatives
- Keep responses friendly but professional
- If a request is unclear, ask for clarification
- For questions outside your scope (web development, forms, site building), politely redirect to relevant topics

Site Context:
${JSON.stringify(siteInfo, null, 2)}

Recent Conversation:
${recentComments}

Current User Message: ${userMessage}`
    },

    async handleForgeAIResponse() {
      try {
        const userMessage = this.msg.replace(/@forge/g, '').trim()
        const prompt = this.getBasePrompt(userMessage)

        const response = await this.api.Openai.completions({
          chat: {
            model: 'gpt-3.5-turbo-0125',
            response_json: true,
            prompt: prompt,
          },
        })

        const messageContent = response.body.choices[0].message.content

        const aiResponse = {
          message: messageContent,
          user_id: 'forge-ai',
          userName: 'Forge AI',
          userAvatar: require('@/assets/svg/LogoSmall.svg'),
          mentioned_users: [],
          version_id: this.version.id,
          is_ai_response: true,
          createdAt: new Date().toISOString(),
          id: `forge-ai-${Date.now()}`,
        }

        this.comments.unshift(aiResponse)
      } catch (error) {
        console.error('Error getting AI response:', error)

        const errorResponse = {
          message: "Sorry, I couldn't process that request. Please try again.",
          user_id: 'forge-ai',
          userName: 'Forge AI',
          userAvatar: require('@/assets/svg/LogoSmall.svg'),
          mentioned_users: [],
          version_id: this.version.id,
          is_ai_response: true,
          createdAt: new Date().toISOString(),
          id: `forge-ai-${Date.now()}`,
        }

        this.comments.unshift(errorResponse)
      }
    },
  },
  mounted() {
    this.scrollToBottom()
  },
}
</script>

<style lang="scss" scoped>
$style: SiteVersionComments;
.spinner {
  height: 53vh;
}
.#{$style} {
  &__comments_container {
    justify-content: center;
  }

  &__comment {
    display: inherit;
  }

  &__noComment {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: calc(100vh - 231px);
    h3 {
      font-size: 14px;
      font-family: 'Instrument Sans', sans-serif;
      font-weight: 500;
      line-height: 20px;
    }
    .no_comments_small {
      margin-top: 8px;
      width: 344px;
      text-align: center;
    }
    .#{$style}__comment_icon {
      margin-bottom: 15px;
    }
    div {
      color: $N2;
    }
  }

  &__comments_block {
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
    height: calc(100vh - 244px);
    display: flex;
    flex-shrink: 0;
    flex-direction: column-reverse;
    width: 100%;
    overflow-y: scroll;
    overscroll-behavior: contain;
  }

  &__footer {
    min-height: 80px;
    padding: 16px 28px 24px 28px;
    align-items: flex-start;
    border-radius: 0px 0px 0px 12px;
    border-top: 1px solid $N7;
    background: $N5;
    .#{$style}__footer_btn {
      position: absolute;
      right: 16px;
    }
  }
}
</style>
