import { render, staticRenderFns } from "./SiteAppsAppInstalled.vue?vue&type=template&id=cbe76bb0&scoped=true&"
import script from "./SiteAppsAppInstalled.vue?vue&type=script&lang=js&"
export * from "./SiteAppsAppInstalled.vue?vue&type=script&lang=js&"
import style0 from "./SiteAppsAppInstalled.vue?vue&type=style&index=0&id=cbe76bb0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbe76bb0",
  null
  
)

export default component.exports