<template>
  <v-container>
    <div class="narrow-container settings-form">
      <div class="company-details">
        <h3 class="mt-6 mx-4" align="left">Company Details</h3>
        <v-card color="rgba(0,0,0,0.1)" flat class="pa-4">
          <div class="mx-4 mt-6 d-flex flex-column flex-sm-row gap-3">
            <div class="company-avatar-input">
              <img
                :src="logo"
                alt=""
                @click="chooseImage"
                class="img_logo"
                v-if="!emptyAvatar"
              />
              <span class="img_logo" @click="chooseImage" v-else>
                <avatar
                  class="img_logo"
                  :rounded="false"
                  :initials="initials"
                  username="name"
                  :size="160"
                  :customStyle="{ display: 'inline-block' }"
                />
              </span>
              <input
                @change="handleImage"
                class=""
                id="avatarUpload"
                type="file"
                accept="image/*"
                style="display: none"
              />
            </div>
            <div class="team_input">
              <span> Company Name </span>
              <v-text-field
                v-model="name"
                hide-details="auto"
                :disabled="!isAdmin"
                autofocus
                class="mt-2 OrganisationMain__company_name"
                filled
                dark
                dense
              />

              <div v-if="errorMain" class="error-main mt-4 pa-4">
                <b> {{ errorMain }} </b>
              </div>
            </div>
          </div>
          <v-card-actions>
            <div class="mt-7" align="center" v-if="isAdmin">
              <v-btn
                @click="save"
                :disabled="!dirtyForm"
                class="mr-4"
                color="orange"
                dark
              >
                Save
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </div>

      <div v-if="isAdmin">
        <v-card color="rgba(0,0,0,0.1)" flat>
          <v-card-title class="mt-6 mx-4" align="left"
            >Company Domains</v-card-title
          >
          <v-container class="pa-4">
            <v-divider></v-divider>
            <v-card-text>
              You can add email domains to add users from your company
              automatically
            </v-card-text>
            <div class="d-flex gap-3 flex-column flex-sm-row">
              <div class="OrganisationMain__members_block">
                <div class="members_list">
                  <div
                    class="chip"
                    v-for="(domain, i) of domains"
                    :key="domain.label"
                  >
                    {{ domain }}
                    <v-icon
                      class="chip_close_icon"
                      @click="removeMember(i, domain)"
                    >
                      mdi-window-close
                    </v-icon>
                  </div>
                </div>
                <input
                  class="members_input"
                  :class="wrongInput ? 'error_input' : ''"
                  @keypress.space="addMember"
                  v-model="currentInput"
                  @keypress.enter.prevent="addMember"
                />
              </div>
              <v-btn
                @click="addDomains"
                class="OrganisationMain__domain_button"
                color="y1"
              >
                Update Domains
              </v-btn>
            </div>
          </v-container>
        </v-card>

        <h3 class="mt-6 mx-4" align="left">Danger Zone</h3>
        <v-card color="rgba(0,0,0,0.1)" flat>
          <v-card-title class="mt-6 mx-4" align="left"
            >Delete Company</v-card-title
          >
          <v-container class="pa-4">
            <v-divider></v-divider>
            <v-card-text>
              Once you delete your Company, this cannot be undone. Please be
              careful.
            </v-card-text>
            <v-card-actions>
              <v-btn @click="deleteOrganisation" color="red" dark>
                Delete Company
              </v-btn>
            </v-card-actions>
          </v-container>
        </v-card>
      </div>
    </div>
  </v-container>
</template>

<script>
import { MODAL_ALERT } from '@/components/Modals'
import Avatar from 'vue-avatar'
import _ from 'lodash'

export default {
  name: 'OrganisationMain',
  components: { Avatar },
  data() {
    return {
      name: this.$store.state.organisations.current.name,
      logo: this.$store.state.organisations.current.logo,
      logoBase64: null,
      errorMain: '',
      saving: false,
      currentInput: '',
      domains: _.cloneDeep(
        this.$store.state.organisations.current.companyEmails
      ),
      addedDomains: [],
      wrongInput: false,
    }
  },
  methods: {
    save() {
      this.saving = true
      const organisation_params = {
        id: this.$store.state.organisations.current.id,
        organisation: {
          name: this.name,
        },
      }
      this.$store
        .dispatch('organisations/update', organisation_params)
        .then(() => {
          this.errorMain = ''
          this.saving = false
        })
        .catch((error) => {
          this.saving = false
          if (!error) return
          let errorMain = ''
          error.body.errors.forEach((message) => {
            errorMain = errorMain + message + '. '
          })
          this.errorMain = errorMain
        })
    },

    addMember() {
      if (this.currentInput.length == 0) return
      this.validateDomain(this.currentInput.trim())
      const memberAlreadyAdded = this.domains.includes(this.currentInput.trim())
      if (!this.wrongInput) {
        if (!memberAlreadyAdded) {
          this.domains.push(this.currentInput.trim())
          this.addedDomains.push(this.currentInput.trim())
        }
        this.currentInput = ''
        this.wrongInput = false
        this.errorMain = ''
      }
    },
    validateDomain(domain) {
      const reg = /^@\w*\.\w*$/
      this.wrongInput = !domain.toLowerCase().match(reg)
    },
    removeMember(index, domain) {
      this.domains.splice(index, 1)
      this.addedDomains.filter((d) => d == domain)
    },
    addDomains() {
      if (this.currentInput.length > 0 && this.domains.length == 0)
        this.addMember()
      if (this.domains.length == 0) {
        this.errorMain = 'You should add email domain to continue'
        this.wrongInput = true
        return
      }
      this.saveDomainsUpdate()
    },
    saveDomainsUpdate() {
      this.saving = true
      this.$store
        .dispatch('organisations/updateDomains', {
          id: this.$store.state.organisations.current.id,
          domains: this.domains,
        })
        .then(() => {
          this.saving = false
          this.currentInput = ''
          this.addedDomains = []
        })
        .catch((e) => {
          this.saving = false
          console.log(e)
        })
    },
    chooseImage() {
      if (this.isAdmin) document.getElementById('avatarUpload').click()
    },
    handleImage(e) {
      const selectedImage = e.target.files[0]
      this.createBase64Image(selectedImage)
    },
    createBase64Image(fileObject) {
      this.saving = true
      const reader = new FileReader()
      this.logo = URL.createObjectURL(fileObject)
      const formData = new FormData()
      formData.append('logo', fileObject, fileObject.name)
      this.$store
        .dispatch('organisations/updateLogo', formData)
        .then(() => {
          this.saving = false
        })
        .catch(() => (this.saving = false))
    },
    deleteOrganisation() {
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Organisation deletion',
          text: 'Your organisation and all sites of this organisation will be deleted. Type organisation Name to delete it:',
          confirmString: this.$store.state.organisations.current.name,
          actionBtnLabel: 'Delete',
          showCancelBtn: true,
          action: () => {
            this.$store
              .dispatch(
                'organisations/delete',
                this.$store.state.organisations.current.id
              )
              .then(() => (location = '/'))
          },
        },
      })
    },
  },
  computed: {
    dirtyForm() {
      return (
        this.name != this.$store.state.organisations.current.name &&
        !this.saving
      )
    },
    isAdmin() {
      return this.$store.state.organisations.current.role == 'admin'
    },
    emptyAvatar() {
      return this.$store.state.organisations.current.logo == null
    },
    initials() {
      return this.$store.state.organisations.current.name[0].toUpperCase()
    },
  },
  watch: {
    $route(to, from) {
      this.name = this.$store.state.organisations.current.name
    },
    '$store.state.organisations.current.companyEmails': {
      handler(newVal, oldVal) {
        this.domains = _.cloneDeep(newVal)
        this.domains = [...new Set(this.domains.concat(this.addedDomains))]
      },
      deep: true,
    },
  },
}
</script>

<style lang="scss" scoped>
$style: OrganisationMain;
.#{$style} {
  &__domain_button {
    display: inline-block;
    vertical-align: top;
  }
  &__members_block {
    min-width: 368px;
    width: 75%;
    min-height: 40px;
    border: 1px solid $N6;
    border-radius: 8px;
    color: $N1;
    display: inline-block;
    @media screen and (max-width: 600px) {
      width: 100%;
      min-width: initial;
    }
    .members_list {
      margin-left: 8px;
      .chip {
        display: inline-block;
        padding: 3px 8px;
        border-radius: 8px;
        border: 1px solid $N7;
        margin-left: 10px;
        margin-top: 10px;

        .chip_close_icon {
          font-size: 16px;
          vertical-align: middle;
          &:focus {
            outline-width: 0;
          }
        }
      }
    }
    .members_input {
      height: 40px;
      width: inherit;
      outline-width: 0;
      padding: 10px;
      color: inherit;

      &.error_input {
        color: #e94a53;
      }
    }
  }
}
.error-main {
  background: #e94a53;
  border-radius: 2px;
}
.img_logo {
  width: 160px;
  height: 160px;
}
.team_input {
  display: inline-block;
  vertical-align: top;
  flex-grow: 1;
}
</style>
