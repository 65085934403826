<template>
  <v-card min-height="90vh" class="ModalForgeConsole" max-width="600px">
    <div class="ModalForgeConsole__title">
      <div class="ModalForgeConsole__title-wrapper">
        <the-icon class="ModalForgeConsole__console-icon" icon="console" />
        Forge Console
      </div>
      <v-btn x-small icon dark @click="close"
        ><the-icon class="ModalForgeConsole__close-icon" icon="close"
      /></v-btn>
    </div>

    <div class="ModalForgeConsole__content">
      <div class="ModalForgeConsole__top-text" v-if="!app">
        <span>Version #{{ version.scopedId }}</span>
        <span v-if="!app && !deployed && !deployFailed"
          >Deploying {{ activeSite.url }}</span
        >
        <span v-else-if="!app && deploySuccess">
          <the-icon
            class="ModalForgeConsole__check-icon"
            icon="check-in-circle"
          />
          <span class="ModalForgeConsole__label">
            Your site was deployed successfully
          </span>
        </span>
        <span v-else-if="!app && deployFailed">
          <the-icon
            class="ModalForgeConsole__error-icon"
            icon="close-in-circle"
          />
          <span class="ModalForgeConsole__label _error">Deploying Failed</span>
        </span>
      </div>

      <div class="ModalForgeConsole__progress" v-if="progressAvailable">
        <v-progress-linear
          height="4"
          :color="deployFailed ? 'e1' : 'y1'"
          background-color="n5"
          rounded
          class="ModalForgeConsole__progress"
          :value="percentDeployed"
        ></v-progress-linear>
      </div>

      <div class="ModalForgeConsole__console" id="ModalConsole">
        <div
          v-for="message in messages"
          :key="message.id"
          class="mt-4 ModalForgeConsole__console-item"
        >
          <div
            class="mt-1 ModalForgeConsole__console-text"
            v-if="!app || appType == 2"
          >
            <span class="ModalForgeConsole__console-text _grey">
              {{ message.time }}
            </span>
            <span v-html="message.message"> </span>
            <span v-if="message.status == 'ok' || message.status == 'fail'">
              ...
              <span :class="status(message.status)">
                {{ message.status }}
              </span>
            </span>
          </div>
          <div class="mt-1" v-else-if="appType == 1">
            <span class="green--text"> {{ message.time }}:</span>
            <span class="ml-2 white--text">{{ message.message }}</span>
          </div>
        </div>
      </div>

      <div class="ModalForgeConsole__footer" id="autoScrollBlock">
        <v-switch
          color="y1"
          v-model="autoScroll"
          @change="changeAutoScroll"
          dense
          hide-details
          inset
          dark
          id="autoScrollSwitch"
          ref="autoScrollSwitch"
        >
          <template v-slot:label>
            <span>{{
              autoScroll ? 'AutoScroll is enabled' : 'AutoScroll is disabled'
            }}</span>
          </template>
        </v-switch>
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'ModalForgeConsole',
  props: {
    version: Object,
    appType: {
      type: Number,
      default: 0,
    },
    app: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      autoScroll: true,
    }
  },
  computed: {
    messages() {
      if (this.app && this.appType == 1) {
        return this.$store.state.site_app.parseSetting.messages
      } else if (this.app && this.appType == 2) {
        return this.$store.state.site_app.chiselApp.messages
      } else return this.version.messages
    },
    deployed() {
      return this.percentDeployed >= 100
    },
    percentDeployed() {
      if (this.appType == 2)
        return this.$store.state.site_app.chiselApp.percent_deployed
      else if (this.appType == 1) return 100
      else return this.version.percentDeployed
    },
    deployFailed() {
      return this.messages.filter((msg) => msg.status == 'fail').length > 0
    },
    deploySuccess() {
      return this.deployed && !this.deployFailed
    },
    progressAvailable() {
      return (
        (this.app && this.appType == 2) || !this.deployed || this.deployFailed
      )
    },
  },
  methods: {
    status(message_status) {
      return message_status == 'warning' ? 'notice' : message_status
    },
    close() {
      this.$emit('close')
    },
    scrollToBottom() {
      if (this.autoScroll) {
        let elem = document.getElementById('ModalConsole')
        if (elem) elem.scrollTop = elem.scrollHeight
      }
    },
    changeAutoScroll() {
      this.scrollToBottom()
    },
    focusScroll() {
      this.$refs.autoScrollSwitch.$el.focus()
      const elem = document.getElementById('autoScrollSwitch')
      if (elem) elem.focus()
    },
  },
  updated() {
    this.scrollToBottom()
  },
  mounted() {
    setTimeout(this.focusScroll(), 1000)
  },
}
</script>

<style lang="scss">
$style: ModalForgeConsole;
#autoScrollBlock {
  justify-content: right;
}
.#{$style} {
  min-height: 100vh !important;
  position: absolute;
  right: 0;
  top: 0;
  border-radius: 12px 0px 0px 12px;

  &.theme--dark.v-card {
    background: $N5;
    border: 1px solid $N7;
    box-shadow: 0px 20px 60px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(5px);
    border-radius: 12px 0px 0px 12px;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: $H16;
    color: $white;
    padding: 30px 34px !important;
    border-bottom: 1px solid $N5;
    position: sticky;
  }
  &__title-wrapper {
    display: flex;
    align-items: center;
  }
  &__console-icon {
    fill: $white;
    margin-right: 10px;
  }
  &__close-icon {
    fill: $N3;
    width: 18px;
    height: 18px;
  }
  &__content {
    padding-top: 0px !important;
    padding: 24px 36px;
  }
  &__top-text {
    margin-bottom: 12px;
    color: $N2;
    font-weight: 500;
    font-size: $H14;
    span:last-child {
      display: inline-block;
      margin-left: 20px;
      font-weight: 400;
    }
  }

  &__progress {
    margin-bottom: 16px;
  }
  &__footer {
    display: flex;
    align-items: center;
  }
  &__check-icon {
    color: $N2;
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
  &__error-icon {
    color: $N2;
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }
  &__label {
    color: $G1;
    margin-left: 8px !important;
    font-weight: 400;
    font-size: $H14;
    &._error {
      color: $E1;
    }
  }
  @media (min-height: 1000px) {
    &__console {
      height: 80vh !important;
    }
  }
  &__console {
    height: 68vh;
    overflow-y: scroll;
    background: #181729;
    border: 1px solid $N5;
    border-radius: 8px;
    padding: 32px;
  }
  &__console-text {
    font-size: $H14;
    color: $white;
    .ok {
      color: $G1;
    }
    .fail {
      color: $R1;
    }

    .notice {
      color: $Y1;
    }
    &._grey {
      color: $N2;
    }
  }
  &__console-item {
    width: 100%;
  }
}
</style>
