<template>
  <v-container>
    <v-card class="section-card" flat>
      <v-card-title>Web Application Firewall (WAF)</v-card-title>
      <v-card-text>
        <p>
          Block malicious requests with rules that protect your site from known
          attack patterns. WAF rules run after Firewall Traffic Rules and before
          Rate limiting.
        </p>
        <a href="https://docs.getforge.com" target="_blank"
          >Learn more about Web Application Firewall (WAF) in the docs &rarr;</a
        >
        <v-card class="nested-card" flat>
          <v-card-title>Baseline Ruleset</v-card-title>
          <v-card-text>
            <p>
              The baseline ruleset can be activated and configured for a site in
              Site configuration. This ruleset is a set of rules maintained and
              updated by Forge. It detects the most common attacks and can
              typically be applied with little to no exceptions.
            </p>
          </v-card-text>
        </v-card>
        <v-card class="nested-card" flat>
          <v-card-title>WAF Rules</v-card-title>
          <v-data-table
            :headers="wafHeaders"
            :items="wafRules"
            class="elevation-1"
          >
            <template v-slot:item.icon="{ item }">
              <v-icon>{{ item.icon }}</v-icon>
            </template>
            <template v-slot:item.description="{ item }">
              <div>
                <div>{{ item.description }}</div>
                <div class="subtext">{{ item.subtext }}</div>
              </div>
            </template>
          </v-data-table>
          <div class="overlay">
            <div class="overlay-content">
              <p>
                Upgrade to Enterprise to enable Web Application Firewall (WAF).
              </p>
              <spark-button @click="upgradeToEnterprise"
                >Contact Sales</spark-button
              >
            </div>
          </div>
        </v-card>
      </v-card-text>
    </v-card>

    <v-card class="section-card" flat>
      <v-card-title>Firewall Traffic Rules</v-card-title>
      <v-card-text>
        <p>
          Control who can access all the sites in your team by default based on
          IP address or country. Custom rules for a site can be set in Site
          configuration.
        </p>
        <a href="https://docs.getforge.com" target="_blank"
          >Learn more about Firewall Traffic Rules in the docs &rarr;</a
        >
        <v-card class="nested-card">
          <v-card-title>Published deploys</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>Default traffic setting:</v-col>
              <v-col class="text-right">Allow all traffic &#x2713;</v-col>
            </v-row>
            <v-divider></v-divider>
            <v-row>
              <v-col>Geographic restrictions:</v-col>
              <v-col class="text-right">Not set</v-col>
            </v-row>
            <v-row>
              <v-col>IP restrictions:</v-col>
              <v-col class="text-right">Not set</v-col>
            </v-row>
            <v-row>
              <v-col>Geographic exceptions:</v-col>
              <v-col class="text-right">Not set</v-col>
            </v-row>
            <v-row>
              <v-col>IP exceptions:</v-col>
              <v-col class="text-right">Not set</v-col>
            </v-row>
            <div class="overlay">
              <div class="overlay-content">
                <p>Upgrade to Enterprise to access Firewall Traffic Rules.</p>
                <spark-button @click="upgradeToEnterprise"
                  >Contact Sales</spark-button
                >
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import SparkButton from '../_Common/SparkButton.vue'

export default {
  name: 'OrganisationSecurity',
  components: {
    SparkButton,
  },
  data() {
    return {
      wafHeaders: [
        { text: '', value: 'icon', width: '50px' },
        { text: 'Description', value: 'description' },
      ],
      wafRules: [
        {
          icon: 'mdi-shield-alert',
          description: 'SQL Injection Attack',
          subtext: 'Enabled',
        },
        {
          icon: 'mdi-shield-lock',
          description: 'Cross-Site Scripting (XSS)',
          subtext: 'Enabled',
        },
        {
          icon: 'mdi-shield-key',
          description: 'Remote File Inclusion',
          subtext: 'Disabled',
        },
        {
          icon: 'mdi-shield-home',
          description: 'Local File Inclusion',
          subtext: 'Enabled',
        },
        {
          icon: 'mdi-shield-check',
          description: 'Command Injection',
          subtext: 'Enabled',
        },
        {
          icon: 'mdi-shield-account',
          description: 'Directory Traversal',
          subtext: 'Enabled',
        },
      ],
    }
  },
  methods: {
    upgradeToEnterprise() {
      // Handle the upgrade action here
      console.log('Upgrade to Enterprise clicked')
    },
    contactSales() {
      // Handle the contact sales action here
      console.log('Contact Sales clicked')
    },
  },
}
</script>

<style scoped>
.section-card {
  margin-bottom: 20px;
  background: #1c1b2e;
}
.nested-card {
  margin-top: 10px;
  background: rgba(0, 0, 0, 0.2);
}
.subtext {
  color: gray;
  font-size: 0.9em;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  z-index: 1;
}
.overlay-content {
  text-align: center;
}
</style>
