<template>
  <v-menu :close-on-content-click="true" class="mx-4" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <span
        :class="`status-chip ${chipClassName}`"
        v-bind="attrs"
        v-if="status"
        v-on="on"
      >
        {{ status }}
      </span>
    </template>

    <v-card max-height="250">
      <v-list>
        <v-list-item
          v-for="statusOption in statusOptions"
          :key="statusOption.class"
          @click="onSelectStatusOption(statusOption)"
          :class="`status-filter-option ${statusOption.class} ${status === statusOption.value ? 'active' : ''}`"
        >
          {{ statusOption.value }}
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'ReviewStatusChip',
  props: {
    status: String,
    dataId: String,
  },
  data() {
    return {
      statusOptions: [
        { class: 'approved', value: 'Approved' },
        { class: 'waiting-review', value: 'Waiting Review' },
        { class: 'declined', value: 'Declined' },
      ],
    }
  },
  computed: {
    chipClassName() {
      const status = this.status ? this.status.toLowerCase() : ''
      if (status == 'waiting review') return 'waiting-review'
      return status
    },
  },
  methods: {
    onSelectStatusOption(statusOption) {
      this.$emit('updateStatus', {
        status: statusOption.value,
        dataId: this.dataId,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.status-chip {
  position: relative;
  padding: 6px 15px;
  width: 160px;
  display: block;
  text-align: center;
  border-radius: 16px;
  background: $N2;
  color: $N4;
  font-size: 13px;
  &:before {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0.1;
    display: block;
    border-radius: 999px;
    content: '';
  }
}
.approved {
  color: $G1 !important;
  background: $G1_5;
}
.waiting-review {
  color: $Y1 !important;
  background: $Y1_5;
}
.declined {
  color: $R2 !important;
  background: $R1_5;
}
</style>
