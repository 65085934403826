<template>
  <v-card class="figma-card" @click="onSelect(file)">
    <v-img :src="file.thumbnail_url" class="figma-thumbnail"></v-img>
    <div class="figma-info">
      <div class="figma-title">{{ file.name }}</div>
      <div class="figma-details">Modified {{ modified }}</div>
    </div>
  </v-card>
</template>

<script>
const dayjs = require('dayjs')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default {
  name: 'FigmaCard',
  props: {
    file: Object,
    onSelect: Function,
  },
  computed: {
    modified() {
      return dayjs(this.file.last_modified).fromNow()
    },
  },
  methods: {
    onClick() {
      this.onSelect(this.file)
    },
  },
}
</script>

<style scoped lang="scss">
.figma-card {
  cursor: pointer;
  background-color: $N5;
}
.figma-thumbnail {
  height: 200px;
  background-size: cover;
  background-position: center;
}
.figma-info {
  padding: 8px;
  text-align: center;
}
.figma-title {
  font-size: 16px;
  font-weight: bold;
}
.figma-details {
  margin-top: 4px;
  font-size: 12px;
  color: gray;
}
</style>
