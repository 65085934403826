var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-navigation-drawer',{staticClass:"SiteAppsPluginSettingsDrawer",class:{
      settingsSidebar: true,
      isMobile: this.$vuetify.breakpoint.smAndDown,
    },attrs:{"input-value":_vm.drawer,"fixed":"","temporary":"","height":"100%","bottom":_vm.isMobile,"right":!_vm.isMobile,"width":_vm.widthDimensions},on:{"input":_vm.onDrawerChange},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"SiteAppsPluginSettingsDrawer__header"},[_c('div',{staticClass:"d-flex"},[_c('h6',[_vm._v("Edit Settings")])]),_c('v-btn',{attrs:{"icon":"","text":"","color":"n2"},on:{"click":_vm.onClose}},[_c('v-icon',[_vm._v("close")])],1)],1),_c('div',{staticClass:"SiteAppsPluginSettingsDrawer__body"},[(_vm.loading || !_vm.activePlugin)?_c('div',{staticClass:"spinner"},[_c('IntersectingCirclesSpinner',{attrs:{"animation-duration":1200,"size":100,"color":'#496DDB'}})],1):_vm._e(),(_vm.activePlugin)?_c('div',[_c('div',{staticClass:"d-flex mb-6"},[(
              _vm.activePlugin.developerContent &&
              _vm.activePlugin.developerContent.keyImage
            )?_c('img',{staticClass:"key-image",attrs:{"src":_vm.activePlugin.developerContent.keyImage}}):_c('div',{staticClass:"key-image placeholder"}),_c('div',{staticClass:"ml-4"},[_c('h3',{staticClass:"mb-4"},[_vm._v(_vm._s(_vm.activePlugin.name))]),(_vm.categoryNames)?_c('div',_vm._l((_vm.categoryNames),function(categoryName){return _c('span',{staticClass:"tag"},[_vm._v(_vm._s(categoryName))])}),0):_vm._e()])]),_c('vue-form-generator',{attrs:{"tag":"div","schema":_vm.schema,"model":_vm.param,"options":_vm.formOptions}})],1):_vm._e()]),(!_vm.loading)?_c('v-footer',{staticClass:"SiteAppsPluginSettingsDrawer__footer",attrs:{"app":""}},[_c('div',[_c('v-btn',{attrs:{"color":"orange","dark":"","disabled":!_vm.appInstance},on:{"click":_vm.save}},[_vm._v(" Save changes ")])],1)]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }