export class User {
  id = null
  name = ''
  email = ''
  avatar = null
  plan_id = ''
  bandwidth = 0
  buildMinutes = 0
  pusherChannel = ''
  authentication_token = ''
  githubToken = null
  bitbucketToken = null
  appAccess = false
  dropboxToken = null
  maximum_number_of_sites = 1
  maximum_number_of_projects = 1
  sites_count = 0
  projects_count = 0
  country = ''
  isPaid = false
  customPaidPlan = false
  roles = {
    editor: false,
    developer: false,
    admin: false,
    visitor: false,
  }

  constructor(origin) {
    if (!origin) return
    this.id = origin.id
    this.maximum_number_of_projects = origin.maximum_number_of_projects
    this.country = origin.country
    this.bandwidth = origin.bandwidth
    this.buildMinutes = origin.build_minutes
    this.isPaid = origin.subscription_active
    this.customPaidPlan = origin.custom_paid_plan
    this.name = origin.name
    this.pusherChannel = origin.pusher_channel
    this.authentication_token = origin.authentication_token
    this.githubToken = origin.github_token
    this.avatar = origin.avatar_url
    this.bitbucketToken = origin.bitbucket_token
    this.dropboxToken = origin.dropbox_token
    this.appAccess = origin.app_access
    this.sites_count = origin.sites_count
    this.projects_count = origin.projects_count
    this.email = origin.email
    if (origin.plan_id) this.plan_id = origin.plan_id
    else this.plan_id = 'free'
    this.maximum_number_of_sites = origin.maximum_number_of_sites
    this.created_at = origin.created_at

    // Object.assign(this.roles, origin.roles);
  }

  getServerObject() {
    return {
      name: this.name,
      email: this.email,
      country: this.country,
      avatar: this.avatar,
      roles: this.roles,
      app_access: this.appAccess,
      plan_id: this.plan_id,
      maximum_number_of_sites: this.maximum_number_of_sites,
      authentication_token: this.authentication_token,
      subscription_active: this.isPaid,
    }
  }
}
