import { Invitation } from '@/models/Invitation'
import { Invitation as InvitationResource } from '@/resources/forge'
import router from '@/router'

const store = {
  namespaced: true,
  state: {
    list: [],
  },
  mutations: {
    /**
     * Sets the list of invitations in the store state.
     * @param {Array} list - The list of invitations.
     */
    setList(state, list) {
      state.list = list
    },
    /**
     * Deletes an invitation from the list in the store state.
     * @param {number} id - The ID of the invitation to delete.
     */
    delete(state, id) {
      state.list = state.list.filter((ou) => id != ou.id)
    },
    /**
     * Adds a new invitation to the list in the store state.
     * @param {Object} invitation - The new invitation to add.
     */
    create(state, invitation) {
      state.list = [...state.list, new Invitation(invitation)]
    },
    /**
     * Updates an invitation in the list in the store state.
     * @param {Object} params - The payload containing the updated invitation.
     */
    update(state, params) {
      const invitation = state.list.find((inv) => inv.id == params.id)
      if (!invitation) return

      Object.assign(invitation, params)
    },
  },
  actions: {
    /**
     * Loads invitations from the API and sets them in the store state.
     * @param {Object} params - The parameters to pass to the API request.
     * @returns {Promise} A promise resolving to the API response.
     */
    async load({ commit }, params) {
      commit('setList', [])
      const res = await InvitationResource.get({
        type: params.type,
        type_id: params.type_id,
      })
      commit(
        'setList',
        res.body.invitations.map((inv) => new Invitation(inv))
      )
      return res
    },
    /**
     * Deletes an invitation.
     * @param {number} id - The ID of the invitation to delete.
     */
    delete({ commit, rootState, getters }, id) {
      InvitationResource.destroy({ id: id })
        .then(() => {
          commit('delete', id)
        })
        .catch((err) => {
          console.log(err)
        })
    },
    /**
     * Changes the role of an invitation.
     * @param {Object} params - The parameters to pass to the API request.
     * @returns {Promise} A promise resolving to the API response.
     */
    changeRole({ commit }, params) {
      return InvitationResource.update(
        { id: params.id },
        { invitation: params.invitation }
      ).then((res) => {
        commit('update', new Invitation(res.body.invitation))
        return res
      })
    },
  },
  getters: {
    /**
     * Finds an invitation by its ID.
     * @param {number} id - The ID of the invitation to find.
     * @returns {Object|null} The found invitation or null if not found.
     */
    findById: (state) => (id) => {
      return state.list.find((ou) => ou.id == id)
    },
  },
}

export default store
