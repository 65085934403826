<template>
  <MemberAccess
    :loadingProp="loading"
    :usersProp="users"
    :invitedProp="invited"
    :memberAccessTypeProp="1"
    :changeOwnerEnabledProp="true"
  />
</template>

<script>
import MemberAccess from '@/components/_Common/MemberAccess'
export default {
  name: 'SiteAccess',
  components: { MemberAccess },
  data() {
    return {
      email: '',
      loading: true,
      emailFilter: '',
      users: [],
      invited: [],
    }
  },
  mounted() {
    this.$store.dispatch('invitation/load', {
      type: 'site',
      type_id: this.activeSite.id,
    })
    this.$store.dispatch('site_user/load', this.activeSite.id)
  },
  watch: {
    '$store.state.site_user.list': {
      handler(newVal, oldVal) {
        this.users = _.cloneDeep(newVal)
        this.loading = false
      },
      deep: true,
    },
    '$store.state.invitation.list': {
      handler(newVal, oldVal) {
        if (newVal[0] != undefined && newVal[0].type != 'Site') return
        this.invited = _.cloneDeep(newVal)
        this.loading = false
      },
      deep: true,
    },
  },
}
</script>
