<template>
  <div class="container">
    <v-select
      v-model="icon"
      @input="saveIcon"
      :searchable="false"
      :items="filteredIcons"
      :menu-props="{
        bottom: true,
        offsetY: true,
        contentClass: 'IconPicker__menu',
      }"
      color="darken-3"
      class="IconPicker"
      hide-details
      filled
      dark
      dense
      content-class="IconPickerMenu"
    >
      <template v-slot:selection="{ item, index, selectedColor }">
        <v-icon :color="addColor()"> mdi-{{ item }} </v-icon>
      </template>

      <template v-slot:item="{ item }">
        <span>
          <v-icon> mdi-{{ item }} </v-icon></span
        >
      </template>

      <template v-slot:prepend-item>
        <v-text-field
          class="ml-5"
          v-model="searchIcon"
          placeholder="Search Icon"
        ></v-text-field>
      </template>
    </v-select>
  </div>
</template>

<script>
const ALL_ICONS = [
  'account',
  'account-alert',
  'account-box',
  'account-box-outline',
  'account-check',
  'account-circle',
  'account-key',
  'tooltip-account',
  'account-minus',
  'account-multiple',
  'account-multiple-outline',
  'account-multiple-plus',
  'account-network',
  'account-outline',
  'account-plus',
  'account-remove',
  'account-search',
  'account-star',
  'account-switch',
  'airballoon',
  'airplane',
  'airplane-off',
  'alarm',
  'alarm-check',
  'alarm-multiple',
  'alarm-off',
  'alarm-plus',
  'album',
  'alert',
  'alert-box',
  'alert-circle',
  'alert-octagon',
  'alpha',
  'alphabetical',
  'amazon',
  'google-cloud',
  'ambulance',
  'android',
  'android-debug-bridge',
  'android-studio',
  'apple',
  'apple-finder',
  'apple-ios',
  'apple-safari',
  'apps',
  'archive',
  'arrange-bring-forward',
  'arrange-bring-to-front',
  'arrange-send-backward',
  'arrange-send-to-back',
  'arrow-all',
  'arrow-bottom-left',
  'arrow-bottom-right',
  'arrow-collapse',
  'arrow-down',
  'arrow-down-bold',
  'arrow-down-bold-circle',
  'arrow-down-bold-circle-outline',
  'arrow-down-bold-hexagon-outline',
  'arrow-expand',
  'arrow-left',
  'arrow-left-bold',
  'arrow-left-bold-circle',
  'arrow-left-bold-circle-outline',
  'arrow-left-bold-hexagon-outline',
  'arrow-right',
  'arrow-right-bold',
  'arrow-right-bold-circle',
  'arrow-right-bold-circle-outline',
  'arrow-right-bold-hexagon-outline',
  'arrow-top-left',
  'arrow-top-right',
  'arrow-up',
  'arrow-up-bold',
  'arrow-up-bold-circle',
  'arrow-up-bold-circle-outline',
  'arrow-up-bold-hexagon-outline',
  'at',
  'attachment',
  'auto-fix',
  'auto-upload',
  'baby',
  'backburger',
  'backup-restore',
  'bank',
  'barcode',
  'barley',
  'barrel',
  'basket',
  'basket-fill',
  'basket-unfill',
  'battery',
  'battery-10',
  'battery-20',
  'battery-30',
  'battery-40',
  'battery-50',
  'battery-60',
  'battery-70',
  'battery-80',
  'battery-90',
  'battery-alert',
  'battery-charging-100',
  'battery-charging-20',
  'battery-charging-30',
  'battery-charging-40',
  'battery-charging-60',
  'battery-charging-80',
  'battery-charging-90',
  'battery-minus',
  'battery-negative',
  'battery-outline',
  'battery-plus',
  'battery-positive',
  'battery-unknown',
  'beach',
  'beaker',
  'beaker-outline',
  'beer',
  'bell',
  'bell-off',
  'bell-outline',
  'bell-ring',
  'bell-ring-outline',
  'bell-sleep',
  'beta',
  'bike',
  'binoculars',
  'bio',
  'biohazard',
  'bitbucket',
  'black-mesa',
  'blinds',
  'block-helper',
  'blogger',
  'bluetooth',
  'bluetooth-audio',
  'bluetooth-connect',
  'bluetooth-settings',
  'bluetooth-transfer',
  'blur',
  'blur-linear',
  'blur-off',
  'blur-radial',
  'bone',
  'book',
  'book-multiple',
  'book-open',
  'book-variant',
  'bookmark',
  'bookmark-check',
  'bookmark-music',
  'bookmark-outline',
  'bookmark-plus',
  'bookmark-remove',
  'border-all',
  'border-bottom',
  'border-color',
  'border-horizontal',
  'border-inside',
  'border-left',
  'border-none',
  'border-outside',
  'border-right',
  'border-top',
  'border-vertical',
  'bowling',
  'box',
  'briefcase',
  'briefcase-check',
  'briefcase-download',
  'briefcase-upload',
  'brightness-1',
  'brightness-2',
  'brightness-3',
  'brightness-4',
  'brightness-5',
  'brightness-6',
  'brightness-7',
  'brightness-auto',
  'broom',
  'brush',
  'bug',
  'bulletin-board',
  'bullhorn',
  'bus',
  'cake',
  'cake-variant',
  'calculator',
  'calendar',
  'calendar-blank',
  'calendar-check',
  'calendar-clock',
  'calendar-multiple',
  'calendar-multiple-check',
  'calendar-plus',
  'calendar-remove',
  'calendar-text',
  'calendar-today',
  'camcorder',
  'camcorder-off',
  'camera',
  'camera-front',
  'camera-front-variant',
  'camera-iris',
  'camera-party-mode',
  'camera-rear',
  'camera-rear-variant',
  'camera-switch',
  'camera-timer',
  'candycane',
  'car',
  'car-wash',
  'carrot',
  'cart',
  'cart-outline',
  'cash',
  'cash-100',
  'cash-multiple',
  'cash-usd',
  'cast',
  'cast-connected',
  'castle',
  'cat',
  'cellphone',
  'cellphone-android',
  'cellphone-dock',
  'cellphone-iphone',
  'cellphone-link',
  'cellphone-link-off',
  'cellphone-settings',
  'chair-school',
  'chart-arc',
  'chart-areaspline',
  'chart-bar',
  'chart-histogram',
  'chart-line',
  'chart-pie',
  'check',
  'check-all',
  'checkbox-blank',
  'checkbox-blank-circle',
  'checkbox-blank-circle-outline',
  'checkbox-blank-outline',
  'checkbox-marked',
  'checkbox-marked-circle',
  'checkbox-marked-circle-outline',
  'checkbox-marked-outline',
  'checkbox-multiple-blank',
  'checkbox-multiple-blank-outline',
  'checkbox-multiple-marked',
  'checkbox-multiple-marked-outline',
  'checkerboard',
  'chevron-double-down',
  'chevron-double-left',
  'chevron-double-right',
  'chevron-double-up',
  'chevron-down',
  'chevron-left',
  'chevron-right',
  'chevron-up',
  'church',
  'city',
  'clipboard',
  'clipboard-account',
  'clipboard-alert',
  'clipboard-arrow-down',
  'clipboard-arrow-left',
  'clipboard-check',
  'clipboard-outline',
  'clipboard-text',
  'clippy',
  'clock',
  'clock-fast',
  'close',
  'close-box',
  'close-box-outline',
  'close-circle',
  'close-circle-outline',
  'close-network',
  'closed-caption',
  'cloud',
  'apple-icloud',
  'cloud-check',
  'cloud-circle',
  'cloud-download',
  'cloud-outline',
  'cloud-off-outline',
  'cloud-upload',
  'cloud-refresh',
  'cloud-lock',
  'cloud-lock-outline',
  'cloud-question',
  'cloud-tags',
  'cloud-print',
  'cloud-print-outline',
  'cloud-search',
  'cloud-search-outline',
  'code-array',
  'code-braces',
  'code-equal',
  'code-greater-than',
  'code-less-than',
  'code-less-than-or-equal',
  'code-not-equal',
  'code-not-equal-variant',
  'code-string',
  'code-tags',
  'codepen',
  'coffee',
  'coffee-to-go',
  'color-helper',
  'comment',
  'comment-account',
  'comment-account-outline',
  'comment-alert',
  'comment-alert-outline',
  'comment-check',
  'comment-check-outline',
  'comment-multiple-outline',
  'comment-outline',
  'comment-plus-outline',
  'comment-processing',
  'comment-processing-outline',
  'comment-remove-outline',
  'comment-text',
  'comment-text-outline',
  'compare',
  'compass',
  'compass-outline',
  'console',
  'content-copy',
  'content-cut',
  'content-duplicate',
  'content-paste',
  'content-save',
  'content-save-all',
  'contrast',
  'contrast-box',
  'contrast-circle',
  'cow',
  'credit-card',
  'credit-card-multiple',
  'crop',
  'crop-free',
  'crop-landscape',
  'crop-portrait',
  'crop-square',
  'crosshairs',
  'crosshairs-gps',
  'crown',
  'cube',
  'cube-outline',
  'cube-unfolded',
  'cup',
  'cup-water',
  'currency-btc',
  'currency-eur',
  'currency-gbp',
  'currency-inr',
  'currency-rub',
  'currency-try',
  'currency-usd',
  'cursor-default',
  'cursor-default-outline',
  'cursor-move',
  'cursor-pointer',
  'database',
  'database-minus',
  'database-outline',
  'database-plus',
  'debug-step-into',
  'debug-step-out',
  'debug-step-over',
  'decimal-decrease',
  'decimal-increase',
  'delete',
  'delete-variant',
  'deskphone',
  'desktop-mac',
  'desktop-tower',
  'details',
  'deviantart',
  'diamond',
  'dice-1',
  'dice-2',
  'dice-3',
  'dice-4',
  'dice-5',
  'dice-6',
  'directions',
  'disqus',
  'division',
  'division-box',
  'dns',
  'domain',
  'dots-horizontal',
  'dots-vertical',
  'download',
  'drag',
  'drag-horizontal',
  'drag-vertical',
  'drawing',
  'drawing-box',
  'drone',
  'dropbox',
  'drupal',
  'duck',
  'dumbbell',
  'earth',
  'earth-off',
  'eject',
  'elevation-decline',
  'elevation-rise',
  'elevator',
  'email',
  'email-open',
  'email-outline',
  'emoticon',
  'emoticon-cool',
  'emoticon-devil',
  'emoticon-happy',
  'emoticon-neutral',
  'emoticon-poop',
  'emoticon-sad',
  'emoticon-tongue',
  'engine',
  'engine-outline',
  'equal',
  'equal-box',
  'eraser',
  'escalator',
  'evernote',
  'exclamation',
  'exit-to-app',
  'export',
  'eye',
  'eye-off',
  'eyedropper',
  'eyedropper-variant',
  'facebook',
  'facebook-messenger',
  'factory',
  'fan',
  'fast-forward',
  'ferry',
  'file',
  'file-cloud',
  'file-delimited',
  'file-document',
  'file-excel-box',
  'file-find',
  'file-image',
  'file-multiple',
  'file-music',
  'file-outline',
  'file-pdf',
  'file-pdf-box',
  'file-powerpoint',
  'file-powerpoint-box',
  'file-presentation-box',
  'file-video',
  'file-word',
  'file-word-box',
  'film',
  'filmstrip',
  'filmstrip-off',
  'filter',
  'filter-outline',
  'filter-remove-outline',
  'filter-variant',
  'fire',
  'firefox',
  'fish',
  'flag',
  'flag-checkered',
  'flag-outline',
  'flag-triangle',
  'flag-variant',
  'flash',
  'flash-auto',
  'flash-off',
  'flashlight',
  'flashlight-off',
  'flip-to-back',
  'flip-to-front',
  'floppy',
  'flower',
  'folder',
  'folder-account',
  'folder-download',
  'folder-google-drive',
  'folder-image',
  'folder-lock',
  'folder-lock-open',
  'folder-move',
  'folder-multiple',
  'folder-multiple-image',
  'folder-multiple-outline',
  'folder-outline',
  'folder-plus',
  'folder-remove',
  'folder-upload',
  'food',
  'food-apple',
  'food-variant',
  'football',
  'football-helmet',
  'format-align-center',
  'format-align-justify',
  'format-align-left',
  'format-align-right',
  'format-bold',
  'format-clear',
  'format-color-fill',
  'format-float-center',
  'format-float-left',
  'format-float-none',
  'format-float-right',
  'format-header-1',
  'format-header-2',
  'format-header-3',
  'format-header-4',
  'format-header-5',
  'format-header-6',
  'format-header-decrease',
  'format-header-equal',
  'format-header-increase',
  'format-header-pound',
  'format-indent-decrease',
  'format-indent-increase',
  'format-italic',
  'format-line-spacing',
  'format-list-bulleted',

  'format-paint',
  'format-paragraph',

  'format-size',
  'format-strikethrough',
  'format-subscript',
  'format-superscript',
  'format-text',
  'format-textdirection-l-to-r',
  'format-textdirection-r-to-l',
  'format-underline',
  'format-wrap-inline',
  'format-wrap-square',
  'format-wrap-tight',
  'format-wrap-top-bottom',
  'forum',
  'forward',
  'fridge',
  'fullscreen',
  'fullscreen-exit',
  'function',
  'gamepad',
  'gamepad-variant',
  'gas-station',
  'gavel',
  'gender-female',
  'gender-male',
  'gender-male-female',
  'gender-transgender',
  'gift',
  'git',
  'github',
  'glass-flute',
  'glass-mug',
  'glass-stange',
  'glass-tulip',
  'glasses',
  'gmail',
  'google',
  'google-chrome',
  'google-circles',
  'google-circles-communities',
  'google-circles-extended',
  'google-circles-group',
  'google-controller',
  'google-controller-off',
  'google-drive',
  'google-earth',
  'google-glass',
  'google-maps',

  'google-play',
  'google-plus',
  'google-hangouts',
  'grid',
  'grid-off',
  'group',

  'guitar-pick',
  'guitar-pick-outline',
  'hand-pointing-right',
  'hanger',

  'harddisk',
  'headphones',
  'headphones-box',
  'headphones-settings',
]
export default {
  name: 'IconPicker',
  props: {
    selectedIcon: {
      type: String,
      default: 'folder',
    },
    selectedColor: {
      type: String,
      default: '#a4a4ae',
    },
  },
  data() {
    return {
      allIcons: ALL_ICONS,
      filteredIcons: ALL_ICONS,
      showDialog: false,
      searchIcon: '',
      icon: this.selectedIcon,
    }
  },
  methods: {
    saveIcon(icon) {
      this.icon = icon
      this.showDialog = false
      this.$emit('picked-icon', this.icon)
    },
    addColor() {
      return this.selectedColor
    },
  },
  watch: {
    searchIcon: {
      handler(newVal, oldVal) {
        let filteredIcons = []
        this.filteredIcons = this.allIcons
        if (this.searchIcon.length === 0) {
          filteredIcons = this.allIcons
        } else {
          filteredIcons = this.filteredIcons.filter((i) =>
            i.includes(this.searchIcon)
          )
        }
        this.filteredIcons = filteredIcons
      },
    },
  },
}
</script>

<style lang="scss">
$style: IconPicker;
.IconPicker > .v-input__control > .v-input__slot {
  width: 60px;
  padding-right: 0px !important;
}
.#{$style} {
  .v_input__slot {
    width: 60px;
    padding-right: 0px;
  }
  &__menu {
    max-width: 365px;
    max-height: 195px !important;
    margin-top: 10px;
    overflow-y: scroll;
    overscroll-behavior: contain;
    .v-list-item {
      display: inline-block;
      padding-top: 6px;
    }
  }
}
</style>
