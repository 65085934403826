<template>
  <div class="text-center">
    <span v-if="data.isPaid">{{ label }}</span>
    <span class="FeeChip_free" v-else>Free</span>
  </div>
</template>

<script>
export default {
  name: 'FeeChip',
  props: {
    data: Object,
  },
  computed: {
    label() {
      const { feeAmount = '', feeType = '' } = this.data
      const amount = feeAmount ? '$' + feeAmount : ''
      const type = feeType ? ' / ' + feeType : ' '
      return amount + type
    },
  },
}
</script>

<style lang="scss" scoped>
$style: FeeChip;
.#{$style} {
  &_free {
    color: $G1 !important;
  }
}
</style>
