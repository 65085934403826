export const APPLICATION_ID = process.env.VUE_APP_APPLICATION_ID
export const APPLICATION_SECRET = process.env.VUE_APP_APPLICATION_SECRET
export const AWS_BUCKET = process.env.VUE_APP_AWS_BUCKET
export const PUSHER_KEY = process.env.VUE_APP_PUSHER_KEY
export const GITHUB_CLIENT_ID = process.env.VUE_APP_GITHUB_CLIENT_KEY
export const GITHUB_SECRET_KEY = process.env.VUE_APP_GITHUB_CLIENT_SECRET
export const DROPBOX_KEY = process.env.VUE_APP_DROPBOX_CLIENT_ID
export const BITBUCKET_KEY = process.env.VUE_APP_BITBUCKET_CLIENT_ID
export const STRIPE_API_KEY = process.env.VUE_APP_STRIPE_API_KEY
export const STRIPE_PUBLIC_KEY = process.env.VUE_APP_STRIPE_PUBLIC_KEY
export const GOOGLE_CLIENT_ID = process.env.VUE_APP_GOOGLE_CLIENT_ID
export const GOOGLE_SECRET = process.env.VUE_APP_GOOGLE_SECRET
export const HEADER_IMAGE_BUCKET = process.env.VUE_APP_HEADER_IMAGE_BUCKET
export const RECAPTCHA_SITE_KEY = process.env.VUE_APP_RECAPTCHA_SITE_KEY
export const APP_TEMPLATES_ENABLED = process.env.VUE_APP_APP_TEMPLATES_ENABLED
export const APP_TEMPLATES_FORM_ID = process.env.VUE_APP_TEMPLATES_FORM_ID
export const VULCAN_API_URL = process.env.VUE_APP_VULCAN_API_URL
export const VULCAN_APPLICATION_ID = process.env.VUE_APP_VULCAN_APPLICATION_ID
export const VULCAN_APPLICATION_SECRET =
  process.env.VUE_APP_VULCAN_APPLICATION_SECRET
export const INTERCOM_API_KEY = process.env.VUE_APP_INTERCOM_API_KEY
export const INTERCOM_APP_ID = process.env.VUE_APP_INTERCOM_APP_ID

export const EXTENSIONS_MARKETPLACE_PARSE_SERVER_URL =
  process.env.VUE_APP_EXTENSIONS_MARKETPLACE_PARSE_SERVER_URL
export const EXTENSIONS_MARKETPLACE_PARSE_SERVER_APP_ID =
  process.env.VUE_APP_EXTENSIONS_MARKETPLACE_PARSE_SERVER_APP_ID
export const EXTENSIONS_MARKETPLACE_PARSE_SERVER_SITE_ID =
  process.env.VUE_APP_EXTENSIONS_MARKETPLACE_PARSE_SERVER_SITE_ID

export const ROOT_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://developers.beach.io'
    : process.env.VUE_APP_ROOT_URL

export const FORGE_ROOT_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.getforge.com/'
    : process.env.VUE_APP_FORGE_ROOT_URL
const WEBSOCKETS_URL =
  process.env.NODE_ENV === 'production'
    ? 'wss://developers.beach.io/cable'
    : process.env.VUE_APP_WEBSOCKETS_URL
