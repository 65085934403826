var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',{staticClass:"the-navigation",attrs:{"app":"","mini-variant":_vm.isNavDrawerMiniVariant,"mini-variant-width":96,"mobile-breakpoint":600,"dark":"","width":288},model:{value:(_vm.isNavigationShow),callback:function ($$v) {_vm.isNavigationShow=$$v},expression:"isNavigationShow"}},[_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.changeOwnerFailed),callback:function ($$v) {_vm.changeOwnerFailed=$$v},expression:"changeOwnerFailed"}},[_c('v-card',{staticClass:"changeOwnerFailedCard"},[_c('v-card-title',[_vm._v(" Change site owner ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.changeOwnerMessage)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"y1"},on:{"click":function($event){_vm.changeOwnerFailed = false}}},[_vm._v(" Close ")])],1)],1)],1),_c('div',{staticClass:"the-navigation__header"},[_c('v-icon',{staticClass:"the-navigation__toggle-btn d-none d-sm-block",class:{ _mini: _vm.isNavDrawerMiniVariant },attrs:{"color":"#A4A4AE"},on:{"click":_vm.changeDrawerState}},[_vm._v(" "+_vm._s(_vm.isNavDrawerMiniVariant ? 'mdi-chevron-right' : 'mdi-chevron-left')+" ")]),_c('v-list-item',[_c('router-link',{attrs:{"to":"/"}},[_c('the-icon',{staticClass:"the-navigation__logo",attrs:{"icon":_vm.isNavDrawerMiniVariant
              ? _vm.publisherRoute
                ? 'blue-logo-small'
                : 'logo-small'
              : _vm.publisherRoute
                ? 'blue-logo'
                : 'logo'}})],1)],1)],1),_c('v-divider',{staticClass:"the-navigation__divider"}),(_vm.publisherRoute)?_c('navigation-publisher-list'):_c('navigation-client-list'),_c('div',{staticClass:"the-navigation__footer",class:{ _small: _vm.isNavDrawerMiniVariant }},[(!_vm.publisherRoute)?_c('v-menu',{attrs:{"location":"top","content-class":"the-navigation__footer_menu"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [(_vm.canManage)?_c('v-btn',_vm._g(_vm._b({staticClass:"the-navigation__footer-btn",class:{ _mini: _vm.isNavDrawerMiniVariant },attrs:{"block":"","dark":""}},'v-btn',attrs,false),on),[(!_vm.isNavDrawerMiniVariant)?_c('span',[_c('v-icon',{staticClass:"the-navigation__nav-icon mr-2"},[_vm._v(" mdi-plus ")]),_vm._v(" Create new ")],1):_c('span',[_c('v-icon',{staticClass:"the-navigation__nav-icon"},[_vm._v(" mdi-plus ")])],1)]):_c('v-btn',{staticClass:"the-navigation__footer-btn",class:{ _mini: _vm.isNavDrawerMiniVariant },attrs:{"block":"","color":"primary"},on:{"click":_vm.goToUpgrade}},[(!_vm.isNavDrawerMiniVariant)?_c('span',[_c('v-icon',{staticClass:"the-navigation__nav-icon mr-2",attrs:{"color":"y1"}},[_vm._v(" mdi-arrow-up-bold-circle-outline ")]),_vm._v(" Upgrade Plan ")],1):_c('span',[_c('v-icon',{staticClass:"the-navigation__nav-icon footer_icon _small",attrs:{"color":"y1"}},[_vm._v(" mdi-arrow-up-bold-circle-outline ")])],1)])]}}],null,false,2970271489)},[_c('v-list',{staticClass:"footer_menu_list"},[_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"the-navigation__footer-menu-btn",attrs:{"block":"","dark":""},on:{"click":function($event){_vm.projectsAvailable
                  ? _vm.createProject()
                  : _vm.showUpgradeDialog('projects')}}},[(!_vm.projectsAvailable)?_c('v-icon',{staticClass:"the-navigation__nav-icon footer_menu_icon mr-2",attrs:{"color":"y1"}},[_vm._v(" mdi-arrow-up-bold-circle-outline ")]):_c('the-icon',{staticClass:"the-navigation__nav-icon footer_menu_icon mr-2",attrs:{"icon":"fill"}}),_c('span',{staticClass:"the-navigation__footer-menu-btn-text",class:{ _upgrade: !_vm.projectsAvailable }},[_vm._v(" "+_vm._s(_vm.projectsAvailable ? 'Project' : 'Upgrade for More Projects')+" ")])],1)],1)],1),_c('v-list-item',[_c('v-list-item-title',[_c('v-btn',{staticClass:"the-navigation__footer-menu-btn",attrs:{"block":""},on:{"click":function($event){_vm.siteLimitReached ? _vm.showUpgradeDialog('sites') : _vm.createSite()}}},[(_vm.siteLimitReached)?_c('v-icon',{staticClass:"the-navigation__nav-icon footer_menu_icon mr-2",attrs:{"color":"y1"}},[_vm._v(" mdi-arrow-up-bold-circle-outline ")]):_c('v-icon',{staticClass:"the-navigation__nav-icon footer_menu_icon mr-2"},[_vm._v(" mdi-web ")]),_c('span',{staticClass:"the-navigation__footer-menu-btn-text",class:{ _upgrade: _vm.siteLimitReached }},[_vm._v(" "+_vm._s(_vm.siteLimitReached ? 'Upgrade for More Sites' : 'Website')+" ")])],1)],1)],1)],1)],1):_vm._e(),(_vm.currentPublisherInfo && _vm.currentPublisherInfo.id)?_c('v-btn',{staticClass:"the-navigation__footer-btn",class:{ _mini: _vm.isNavDrawerMiniVariant },attrs:{"block":"","color":"n5"},on:{"click":function($event){return _vm.togglePublisherSite()}}},[(!_vm.isNavDrawerMiniVariant)?_c('span',[(_vm.publisherRoute)?_c('span',[_vm._v("To Developer Dashboard")]):_c('span',[_vm._v("To Publisher Dashboard")])]):_c('span',[(_vm.publisherRoute)?_c('span',[_vm._v("C")]):_c('span',[_vm._v("P")])])]):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }