import { getRelativeTime } from '@/utils/common'

export class Comment {
  id = null
  version_id = null
  message = null
  createdAt = null
  versionScopedId = null
  userId = null
  userName = null
  userAvatar = null
  readMore = false
  fullMessage = ''
  mentionedUsers = []
  siteOwnerId = null
  siteId = null
  viewed = false

  constructor(origin) {
    if (!origin) return

    this.id = origin.id
    this.userId = origin.user_id
    this.userName = origin.user_name
    this.mentionedUsers = origin.mentioned_users
    this.userAvatar = origin.user_avatar
    this.version_id = origin.version_id
    this.createdAt = getRelativeTime(new Date(origin.created_at)).toLowerCase()
    this.message = origin.message
    this.siteOwnerId = origin.site_owner_id
    this.versionScopedId = origin.version_scoped_id
    this.siteId = origin.site_id
    this.viewed = origin.viewed
  }

  getServerObject() {
    return {
      version_id: this.version_id,
      message: this.message,
    }
  }
}
