import router from '@/router'

// import {guid} from '@/utils/common';
import { Site } from '@/models/Site'
import { Site as SiteResource } from '@/resources/forge'
import { Project as ProjectResource } from '@/resources/forge'

const store = {
  namespaced: true,
  state: {
    list: [],
    active: null,
    publicFileUrl: '',
    slug: new Date(),
    apps: [], // 3rd party apps, not core apps. Registered by SDK call from clients
    activeApp: null, // indicating the active plugin app of the site, selected from router params, router-bound, mainly used to indicate which tab should be highlighted
    currentApp: null,
    showMoreAppBar: false,
    activePlugin: null,
    previousKind: null,
    currentVersionStatus: '',
  },
  mutations: {
    /**
     * Set the list of sites.
     * @param {Array} list - The list of sites to set.
     */
    setList(state, list) {
      state.list = list
    },
    /**
     * Add sites to the list.
     * @param {Array} new_list - The list of sites to add.
     */
    addToList(state, new_list) {
      state.list = state.list.concat(new_list)
    },
    /**
     * Set the active site.
     * @param {Object} activeSite - The active site object.
     */
    setActive(state, activeSite) {
      state.active = activeSite
    },
    /**
     * Create a new site.
     * @param {Object} site - The new site object to create.
     */
    create(state, site) {
      state.list = [...state.list, site]
    },
    /**
     * Update a site.
     * @param {Object} params - The parameters containing the updated site information.
     */
    update(state, params) {
      const site = state.list.find((s) => s.id == params.id)
      if (!site) state.list = [...state.list, params]
      else Object.assign(site, params)
    },
    /**
     * Update the active site.
     * @param {Object} params - The parameters containing the updated active site information.
     */
    updateActive(state, params) {
      if (!state.active) Object.assign(state.active, params)
    },
    /**
     * Set the public file URL.
     * @param {string} params - The public file URL to set.
     */
    setPublicFileUrl(state, params) {
      state.publicFileUrl = params
    },
    /**
     * Update the site's slug.
     */
    updateSlug(state) {
      state.slug = new Date()
    },
    /**
     * Add a webhook trigger to the site.
     * @param {Object} webhook_params - The parameters for adding the webhook trigger.
     */
    addWebhook(state, webhook_params) {
      const site = state.list.find((s) => s.id == webhook_params.site_id)
      if (!site) return
      let webhook_trigger = webhook_params.webhook

      webhook_trigger.updated_at = new Date(
        webhook_trigger.updated_at
      ).toTimeString()
      webhook_trigger.event = webhook_trigger.event.replaceAll('_', ' ')
      webhook_trigger.http_method = webhook_trigger.http_method.toUpperCase()

      site.webhookTriggers = [...site.webhookTriggers, webhook_trigger]
    },
    /**
     * Remove a webhook trigger from the site.
     * @param {Object} hook_params - The parameters for removing the webhook trigger.
     */
    removeWebhook(state, hook_params) {
      const site = state.list.find((s) => s.id == hook_params.site_id)
      if (!site) return

      site.webhookTriggers = site.webhookTriggers.filter(
        (w) => hook_params.webhook_id != w.id
      )
    },
    /**
     * Delete a site.
     * @param {string} id - The ID of the site to delete.
     */
    delete(state, id) {
      state.list = state.list.filter((s) => id != s.id)
      state.list = state.list.filter((s) => id != s.parent_site_id)
    },
    /**
     * Delete projects related to a site.
     * @param {string} project_id - The ID of the project to delete.
     */
    deleteProjectsSite(state, project_id) {
      state.list = state.list.filter((s) => project_id != s.projectId)
    },

    /**
     * Set the list of third-party apps for the site.
     * @param {Array} apps - The list of third-party apps.
     */
    setApps(state, apps) {
      state.apps = apps
    },
    /**
     * Set the active plugin app for the site.
     * @param {Object} app - The active plugin app.
     */
    setActiveApp(state, app) {
      state.activeApp = app
    },
    /**
     * Set the current app for the site.
     * @param {Object} app - The current app.
     */
    setCurrentApp(state, app) {
      state.currentApp = app
    },
    /**
     * Set the active plugin for the site.
     * @param {Object} plugin - The active plugin.
     */
    setActivePlugin(state, plugin) {
      state.activePlugin = plugin
    },
    /**
     * Set whether to show more options in the app bar.
     * @param {boolean} show - Whether to show more options.
     */
    setShowMoreAppBar(state, show) {
      state.showMoreAppBar = show
    },
    /**
     * Set the previous kind of the site.
     * @param {string} kind - The previous kind of the site.
     */
    setPreviousKind(state, kind) {
      state.previousKind = kind
    },
    /**
     * Set the status of the current version of the site.
     * @param {string} status - The status of the current version.
     */
    setCurrentVersionStatus(state, status) {
      state.currentVersionStatus = status
    },
  },
  actions: {
    /**
     * Load all sites.
     */
    async load({ commit }) {
      const res = await SiteResource.get()
      commit(
        'setList',
        res.body.sites.map((s) => new Site(s))
      )
    },
    /**
     * Load sites based on mode.
     */
    async modeLoad({ commit }) {
      const res = await SiteResource.siteModes()
      commit(
        'addToList',
        res.body.sites.map((s) => new Site(s))
      )
    },
    async getSiteMode({ commit, getters }, params) {
      const site = getters['findById'](params.id)
      if (!site) return
      let parent_id = null
      if (site.mode == 'production') parent_id = site.id
      else parent_id = site.parent_site_id
      const res = await SiteResource.siteMode({
        id: parent_id,
        mode: params.mode,
      })
      if (getters['findById'](res.body.site.id)) {
        commit('update', new Site(res.body.site))
      } else {
        commit('create', new Site(res.body.site))
      }
      router.push('/site/' + res.body.site.id)
    },
    async getPublicFileUrl({ commit }, id) {
      await SiteResource.publicSiteUrl({ id: id }).then(({ body }) => {
        commit('setPublicFileUrl', body.public_file_url)
        commit('updateSlug')
      })
    },
    /**
     * Change the owner of a site.
     * @param {Object} params - Parameters including the site to update.
     */
    async changeSiteOwner({ commit }, params) {
      await SiteResource.changeSiteOwner({ site: params }).then(({ body }) => {
        return body.message
      })
    },
    /**
     * Set a site as active.
     * @param {string} id - The ID of the site to set as active.
     */
    setActive({ commit, getters, dispatch }, id) {
      const site = getters['findById'](id)
      commit('setActive', site)
      dispatch('publisher/load', id, { root: true })
      dispatch('plugin_publishing/reset', null, { root: true })
    },
    /**
     * Create a new site.
     * @param {Object} params - Parameters including the site to create.
     * @returns {Promise} A promise resolving to the created site.
     */
    create({ commit, rootGetters, rootState }, params) {
      if (!params.url) return
      return SiteResource.create({ site: params }).then(({ body }) => {
        const site = new Site(body.site)
        commit('create', site)
        if (site.projectId) {
          let sites
          let plugins
          let allObjects
          const project = rootGetters['projects/findById'](site.projectId)
          if (site.kind == 'site') {
            sites = project.sites.concat(site)
            plugins = project.plugins
          } else {
            sites = project.sites
            plugins = project.plugins.concat(site)
          }
          allObjects = project.allObjects.concat(site)

          commit(
            'projects/update',
            {
              id: project.id,
              sites,
            },
            { root: true }
          )
        }
        if (rootState.organisations.current.id != 0)
          commit('organisations/incSitesCount', {}, { root: true })
        else commit('user/incSitesCount', {}, { root: true })
        return body.site
      })
    },
    /**
     * Move a site to another project.
     * @param {Object} params - Parameters including the site ID and new project ID.
     * @returns {Promise} A promise resolving to the moved site.
     */
    moveSite({ commit, getters, rootGetters }, params) {
      return SiteResource.moveSite(
        { id: params.id },
        { sites: { project_id: params.project_id } }
      ).then(({ body }) => {
        const site = new Site(body.site)
        commit('update', site)
        let sites
        let project
        let plugins
        let allObjects
        let kind
        if (body.old_project_id) {
          project = rootGetters['projects/findById'](body.old_project_id)
          if (project) {
            if (site.kind == 'site') {
              sites = project[site.kind + 's'].filter((s) => s.id != site.id)
              sites = sites.filter((s) => s.parent_site_id != site.id)
              plugins = project.plugins
            } else {
              plugins = project[site.kind + 's'].filter((s) => s.id != site.id)
              sites = project.sites
              plugins = plugins.filter((s) => s.parent_site_id != site.id)
            }

            allObjects = project.allObjects.filter((s) => s.id != site.id)

            commit(
              'projects/update',
              {
                id: body.old_project_id,
                sites,
                plugins,
                allObjects,
              },
              { root: true }
            )
          }
        }
        if (params.project_id) {
          project = rootGetters['projects/findById'](params.project_id)
          if (project) {
            if (site.kind == 'site') {
              sites = project[site.kind + 's'].concat(site)
              plugins = project.plugins
            } else {
              sites = project.sites
              plugins = project[site.kind + 's'].concat(site)
            }
            allObjects = project.allObjects.concat(site)
            commit(
              'projects/update',
              {
                id: project.id,
                sites,
                plugins,
                allObjects,
              },
              { root: true }
            )
          }
        }
      })
    },
    /**
     * Add a webhook to the active site.
     * @param {Object} params - Parameters including the webhook to add.
     */
    addHook({ commit, rootState }, params) {
      SiteResource.addWebhook({ webhook_trigger: params }).then(({ body }) => {
        const payload = {
          site_id: rootState.sites.active.id,
          webhook: body.webhook_trigger,
        }
        commit('addWebhook', payload)
      })
    },
    /**
     * Update a webhook for the active site.
     * @param {Object} params - Parameters including the webhook ID and updated webhook.
     */
    updateHook({ commit, rootState }, params) {
      SiteResource.updateWebhook(
        { id: params.id },
        { webhook_trigger: params.webhook_trigger }
      ).then(({ body }) => {
        const payload = {
          site_id: rootState.sites.active.id,
          webhook: body.webhook_trigger,
          webhook_id: params.id,
        }
        commit('removeWebhook', payload)
        commit('addWebhook', payload)
      })
    },
    /**
     * Remove a webhook from the active site.
     * @param {string} id - The ID of the webhook to remove.
     */
    removeHook({ commit, rootState }, id) {
      SiteResource.removeWebhook({ id: id }, {}).then(() => {
        const payload = {
          site_id: rootState.sites.active.id,
          webhook_id: id,
        }
        commit('removeWebhook', payload)
      })
    },
    /**
     * Check the URL of the active site.
     * @param {string} new_url - The new URL to check.
     * @returns {Promise} A promise resolving to the result of the URL check.
     */
    checkURL({ rootState }, new_url) {
      return SiteResource.checkUrl(
        { id: rootState.sites.active.id },
        { site: { new_url: new_url } }
      )
    },
    /**
     * Retrieve the policy for the current site.
     */
    getPolicy() {
      return SiteResource.policy()
    },
    /**
     * Update a site.
     * @param {Object} params - Parameters including the site to update.
     * @returns {Promise} A promise resolving to the updated site.
     */
    update({ commit, rootState }, params) {
      return SiteResource.update({ id: rootState.sites.active.id }, params)
        .then((res) => {
          commit('update', new Site(res.body.site))
          return res
        })
        .catch((e) => {
          return e
        })
    },
    /**
     * Update environments for a site.
     * @param {Object} params - Parameters including the environments to update.
     * @returns {Promise} A promise resolving to the updated site.
     */
    updateEnvironmens({ commit, rootState }, params) {
      return SiteResource.updateEnvironmens(
        { id: rootState.sites.active.id },
        params
      ).then((res) => {
        const updatedSite = new Site(res.body.site)
        commit('update', updatedSite)
        commit('updateActive', updatedSite)
        return res
      })
    },
    /**
     * Update data for a site in the list.
     * @param {Object} params - Parameters including the updated site data.
     */
    updateDataInList({ commit, getters, state, rootGetters }, params) {
      if (!params) return
      let site = getters['findById'](params.id)
      if (!site) return

      commit('update', params)
      if (state.active && state.active.id == params.id)
        commit('updateActive', params)
      site = getters['findById'](params.id)
      if (params.projectId) {
        const project = rootGetters['projects/findById'](params.projectId)
        let sites = project.sites.filter((site) => site.id != params.id)
        let plugins = project.plugins.filter((site) => site.id != params.id)
        let allObjects = project.allObjects.filter(
          (site) => site.id != params.id
        )
        if (site.kind == 'site') {
          sites.push(site)
        } else plugins.push(site)
        allObjects.push(site)
        commit(
          'projects/update',
          {
            id: project.id,
            sites,
            plugins,
            allObjects,
          },
          { root: true }
        )
      }
    },
    /**
     * Set the current version of a site.
     * @param {string} version_id - The ID of the current version.
     */
    setCurrentVersion({ commit, rootState }, version_id) {
      SiteResource.update(
        { id: rootState.sites.active.id },
        { site: { current_version_id: version_id } }
      ).then(() => {
        const payload = {
          id: rootState.sites.active.id,
          currentVersionId: version_id,
        }
        commit('update', payload)
      })
    },
    /**
     * Regenerate the token for a site.
     * @param {string} user_id - The ID of the user associated with the token.
     * @returns {Promise} A promise resolving to the updated site token.
     */
    async regenerateToken({ commit, rootState }, user_id) {
      let params = {
        site_id: rootState.sites.active.id,
        user_id: user_id,
      }
      return await SiteResource.regenerateToken(params)
        .then((res) => {
          const payload = {
            id: rootState.sites.active.id,
            token: res.body.site_token,
          }
          commit('update', payload)
          return res
        })
        .catch((e) => {
          console.log(e)
        })
    },

    /**
     * Load the list of sites associated with a project.
     * @param {string} project_id - The ID of the project.
     */
    async loadSitesList({ commit, getters, rootGetters }, project_id) {
      ProjectResource.loadSitesList({ id: project_id }, {}).then(({ body }) => {
        let plugins = []
        let sites = []
        let allObjects = []
        let itemsCount = 0
        const sitesArray = body.map((s) => new Site(s))
        for (let site of sitesArray) {
          commit('update', site)
          if (site.kind == 'site') sites.push(site)
          else plugins.push(site)
          allObjects.push(site)
          itemsCount += 1
        }

        commit(
          'projects/update',
          {
            id: project_id,
            sites,
            plugins,
            allObjects,
            itemsCount,
          },
          { root: true }
        )
      })
    },
    /**
     * Delete a site.
     * @param {string} id - The ID of the site to delete.
     */
    delete({ state, commit, getters, rootGetters, rootState }, id) {
      const { projectId } = getters['findById'](id)
      SiteResource.delete({ id: id })
      commit('delete', id)
      const project = rootGetters['projects/findById'](projectId)
      if (project) {
        let sites = project.sites.filter((site) => site.id != id)
        sites = sites.filter((site) => site.parent_site_id != id)
        let plugins = project.plugins.filter((site) => site.id != id)
        plugins = plugins.filter((site) => site.parent_site_id != id)
        const allObjects = project.allObjects.filter((site) => site.id != id)
        commit(
          'projects/update',
          {
            id: project.id,
            sites,
            plugins,
            allObjects,
          },
          { root: true }
        )
      }
      if (rootState.organisations.current.id != 0)
        commit('organisations/descSitesCount', {}, { root: true })
      else commit('user/descSitesCount', {}, { root: true })
      if (id == state.active.id) {
        if (state.list.length) router.push('/site/' + state.list[0].id)
        else router.push('/')
      }
    },
  },
  getters: {
    /**
     * Find a site by its ID.
     * @param {String} id -  Target site id
     * @returns {Object|null} The found site or null if not found.
     */
    findById: (state) => (id) => {
      return state.list.find((s) => s.id == id)
    },
  },
}

export default store
