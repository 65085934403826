<template>
  <v-card class="miro-card" @click="onSelect(board)">
    <v-img :src="thumbnailUrl" class="miro-thumbnail"></v-img>
    <div class="miro-info">
      <div class="miro-title">{{ board.name }}</div>
      <div class="miro-details">Modified {{ modified }}</div>
    </div>
  </v-card>
</template>

<script>
const dayjs = require('dayjs')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default {
  name: 'MiroCard',
  props: {
    board: Object,
    onSelect: Function,
  },
  computed: {
    thumbnailUrl() {
      return this.board.picture?.imageURL || ''
    },
    modified() {
      return dayjs(this.board.modifiedAt).fromNow()
    },
  },
  methods: {
    onClick() {
      this.onSelect(this.board)
    },
  },
}
</script>

<style scoped lang="scss">
.miro-card {
  cursor: pointer;
  background-color: $N5;
}
.miro-thumbnail {
  height: 200px;
  background-size: cover;
  background-position: center;
}
.miro-info {
  padding: 8px;
  text-align: center;
}
.miro-title {
  font-size: 16px;
  font-weight: bold;
}
.miro-details {
  margin-top: 4px;
  font-size: 12px;
  color: gray;
}
</style>
