<template>
  <div class="lottie-loader" :style="containerStyle">
    <dotlottie-player
      :key="type"
      :src="animationSrc"
      background="transparent"
      :speed="speed"
      :style="playerStyle"
      loop
      autoplay
    ></dotlottie-player>
  </div>
</template>

<script>
export default {
  name: 'LottieLoader',
  props: {
    size: {
      type: Number,
      default: 300,
    },
    speed: {
      type: Number,
      default: 1,
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'loading',
      validator: function (value) {
        return ['loading', 'error'].indexOf(value) !== -1
      },
    },
  },
  computed: {
    containerStyle() {
      if (this.fullscreen) {
        return {
          display: 'flex',
          width: '100%',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center',
        }
      }
      return {}
    },
    playerStyle() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
      }
    },
    animationSrc() {
      const animations = {
        loading:
          'https://lottie.host/a4bbffae-63db-48dd-815e-b91fd5747e57/TVHW42HIz1.lottie',
        error:
          'https://lottie.host/ce430dea-99be-4064-8a84-3445e762e189/uHsPtDHX5V.lottie',
      }
      return animations[this.type]
    },
  },
}
</script>

<style scoped>
.lottie-loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
