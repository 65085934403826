<template>
  <v-dialog :width="width" v-model="opened">
    <component :is="component" v-bind="props" @close="close" />
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'Modal',
  computed: {
    ...mapState({
      component: (state) => state.application.modalParams.component,
      props: (state) => state.application.modalParams.props,
      width: (state) => {
        const { width } = state.application.modalParams
        return width ? width : 'initial'
      },
    }),
    opened: {
      get() {
        return this.$store.state.application.modalShowing
      },
      set(opened) {
        if (!opened) this.$store.commit('application/closeModal')
      },
    },
  },
  methods: {
    close() {
      this.opened = false
    },
  },
}
</script>

<style scoped></style>
