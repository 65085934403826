<template>
  <div class="px-6 pt-8 relative">
    <div class="mb-6">
      <router-link class="breadcrumb-link" to="/publisher/plugins">
        Plugins
      </router-link>
      <span class="breadcrumb-separator">/</span>
      <span class="breadcrumb-link" v-if="appDetail">{{ appDetail.name }}</span>
    </div>
    <div
      class="d-flex gap-3 align-center w-full flex-column flex-sm-row section-block mb-6"
    >
      <div class="d-flex flex-grow-1 max-w-full gap-3">
        <div class="PluginDetail__logo" :style="mainImageStyle"></div>
        <div class="flex-grow-1" v-if="appDetail">
          <h3 class="PluginDetail__app-name">
            {{ appDetail.name }}
          </h3>
          <a
            class="d-inline-flex align-center PluginDetail__link"
            :href="appDetail.url"
            target="_blank"
          >
            <the-icon icon="link" class="" />
            <span>
              {{ appDetail.url }}
            </span>
          </a>
        </div>
      </div>
      <div
        class="PluginDetail__status-audit"
        v-if="appDetail && appDetail.developerData"
      >
        <StatusChip
          :status="appDetail.developerData.status"
          :dataId="appDetail.developerData.id"
          @updateStatus="onUpdateStatus"
        />
        <div class="PluginDetail__audit-wrapper">
          <div
            v-for="index in 4"
            :key="index"
            class="audit-chip"
            :class="getAuditClass()"
          />
        </div>
      </div>
    </div>

    <div
      class="w-full section-block mb-6"
      v-if="appDetail && appDetail.developerContent"
    >
      <v-row>
        <v-col cols="12" md="7">
          <h6 class="PluginDetail__section-heading">Content</h6>
          <div class="d-flex mb-4">
            <span class="PluginDetail__label">Short name</span>
            <span class="text-sm font-bold block tracking-wide text-gray-600">
              {{ appDetail.developerContent.shortName }}
            </span>
          </div>
          <div class="d-flex mb-4">
            <span class="PluginDetail__label">Description</span>
            <div
              class="text-sm block tracking-wide text-gray-600"
              v-html="appDetail.developerContent.description"
            ></div>
          </div>
          <div class="d-flex mb-4">
            <span class="PluginDetail__label">Privacy URL</span>
            <span class="text-sm block tracking-wide text-gray-600">
              {{ appDetail.developerContent.privacyURL }}
            </span>
          </div>
          <div class="d-flex mb-4">
            <span class="PluginDetail__label">Terms URL</span>
            <span class="text-sm block tracking-wide text-gray-600">
              {{ appDetail.developerContent.termsURL }}
            </span>
          </div>
          <div class="d-flex mb-4">
            <span class="PluginDetail__label">Dev Mode</span>
            <span class="text-sm block tracking-wide text-gray-600">
              {{ appDetail.devMode ? 'Enabled' : 'Disabled' }}
            </span>
          </div>
          <div class="d-flex mb-4" v-if="appDetail.devURL">
            <span class="PluginDetail__label">Dev URL</span>
            <span class="text-sm block tracking-wide text-gray-600">
              {{ appDetail.devURL }}
            </span>
          </div>
        </v-col>
        <v-col cols="12" md="5">
          <h6 class="PluginDetail__section-heading">Screenshots</h6>
          <div
            v-if="
              appDetail.developerContent.screenshots &&
              appDetail.developerContent.screenshots.length > 0
            "
          >
            <viewer
              :images="appDetail.developerContent.screenshots"
              :options="options"
              class="viewer"
            >
              <template #default="scope">
                <v-row>
                  <v-col
                    cols="4"
                    v-for="image in scope.images"
                    :key="image"
                    class="image-wrapper"
                  >
                    <img class="PluginDetail__screenshot" :src="image" />
                  </v-col>
                </v-row>
              </template>
            </viewer>
          </div>
        </v-col>
      </v-row>
    </div>
    <!-- /.developerContent -->

    <v-row>
      <v-col>
        <div class="section-block mb-6" v-if="appDetail && appDetail.developer">
          <h6 class="PluginDetail__section-heading">Developer Information</h6>
          <div>
            <div class="d-flex mb-4">
              <span class="PluginDetail__label">Name</span>
              <span class="text-sm block tracking-wide text-gray-600">
                <router-link
                  :to="{
                    name: 'developer_detail',
                    params: { developerId: appDetail.developer.id },
                  }"
                >
                  {{ appDetail.developer.name }}
                </router-link>
              </span>
            </div>
            <div class="d-flex mb-4">
              <span class="PluginDetail__label">Company</span>
              <span class="text-sm block tracking-wide text-gray-600">
                {{ appDetail.developer.company }}
              </span>
            </div>
            <div class="d-flex mb-4">
              <span class="PluginDetail__label">Website</span>
              <a
                :href="appDetail.developer.website"
                target="_blank"
                class="text-sm block tracking-wide text-blue-500 cursor-poionter"
              >
                {{ appDetail.developer.website }}
              </a>
            </div>
            <div class="d-flex mb-4">
              <span class="PluginDetail__label">Contact Email</span>
              <a
                :href="`mailto:${appDetail.developer.email}`"
                class="text-sm block tracking-wide text-blue-500 cursor-poionter"
              >
                {{ appDetail.developer.email }}
              </a>
            </div>
          </div>
        </div>

        <div
          class="section-block mb-6"
          v-if="
            appDetail && appDetail.siteInfo && appDetail.siteInfo.plugin_info
          "
        >
          <div class="block w-full overflow-x-auto p-5">
            <h6 class="PluginDetail__section-heading">Plugin Audit</h6>

            <div v-if="appDetail.siteInfo.plugin_info.analysis_result">
              <div class="d-flex mb-4">
                <span class="PluginDetail__label">Eval Safe</span>
                <span class="text-sm block text-gray-600 flex align-center">
                  <span
                    class="audit-chip d-inline-block"
                    :class="`${colors.evalSafe} mr-1`"
                  ></span>
                  {{ appDetail.siteInfo.plugin_info.analysis_result.EvalSafe }}
                </span>
              </div>
              <div class="d-flex mb-4">
                <span class="PluginDetail__label"
                  >External Network Requests</span
                >
                <span class="text-sm block text-gray-600 flex align-center">
                  <span :class="`${colors.insecureURL} audit-chip mr-1`"></span>
                  {{
                    appDetail.siteInfo.plugin_info.analysis_result
                      .ExternalNetworkRequestsCount
                  }}
                </span>
              </div>
              <div class="d-flex mb-4">
                <span class="PluginDetail__label">Network Request URLs</span>
                <span class="text-sm block tracking-wide text-gray-600">
                  <ul
                    v-if="
                      appDetail.siteInfo.plugin_info.analysis_result
                        .ExternalNetworkRequests.secureNetworkURLs
                    "
                    class="pl-0"
                  >
                    <li
                      v-for="(secureURL, i) in appDetail.siteInfo.plugin_info
                        .analysis_result.ExternalNetworkRequests
                        .secureNetworkURLs"
                      :key="i"
                      class="d-flex align-center mb-1"
                    >
                      <span
                        :class="`${colors.secureURL} audit-chip mr-1`"
                      ></span>
                      {{ secureURL }}
                    </li>
                  </ul>
                  <ul
                    v-if="
                      appDetail.siteInfo.plugin_info.analysis_result
                        .ExternalNetworkRequests.insecureNetworkURLs
                    "
                    class="pl-0"
                  >
                    <li
                      v-for="(insecureURL, i) in appDetail.siteInfo.plugin_info
                        .analysis_result.ExternalNetworkRequests
                        .insecureNetworkURLs"
                      :key="i"
                      class="d-flex align-center mb-1"
                    >
                      <span
                        :class="`${colors.insecureURL} audit-chip mr-1`"
                      ></span>
                      {{ insecureURL }}
                    </li>
                  </ul>
                </span>
              </div>
              <div class="d-flex mb-4">
                <span class="PluginDetail__label"
                  >Insecure Network Requests</span
                >
                <span class="text-sm block text-gray-600">
                  {{
                    appDetail.siteInfo.plugin_info.analysis_result
                      .ExternalNetworkRequests.insecureNetworkURLs.length || 0
                  }}
                </span>
              </div>
            </div>
            <div class="d-flex mb-4">
              <span class="PluginDetail__label">Manifest Audit</span>
              <span class="text-sm block text-gray-600 flex align-center">
                COMING SOON
              </span>
            </div>
            <div class="d-flex mb-4">
              <span class="PluginDetail__label">SSL</span>
              <span class="text-sm block text-gray-600 flex align-center">
                <span :class="`${colors.sslEnabled} audit-chip mr-1`"></span>
                {{ appDetail.siteInfo.ssl_enabled ? "Let's Encrypt" : '' }}
              </span>
            </div>
            <div class="d-flex mb-4">
              <span class="PluginDetail__label">SSL</span>
              <span class="text-sm block text-gray-600 flex align-center">
                <span
                  :class="`${colors.forceSSLEnabled} audit-chip mr-1`"
                ></span>
                {{
                  appDetail.siteInfo.force_ssl_enabled ? 'Enabled' : 'Disabled'
                }}
              </span>
            </div>
          </div>
        </div>
      </v-col>

      <v-col>
        <div
          class="section-block mb-6"
          v-if="
            appDetail && appDetail.siteInfo && appDetail.siteInfo.plugin_info
          "
        >
          <h6 class="PluginDetail__section-heading">Plugin Information</h6>
          <div class="d-flex mb-4">
            <span class="PluginDetail__label">Name</span>
            <span class="text-sm block tracking-wide text-gray-600">
              {{ appDetail.siteInfo.plugin_info.publisher_name }}
            </span>
          </div>
          <div class="d-flex mb-4">
            <span class="PluginDetail__label">Deploy Method</span>
            <span class="text-sm block tracking-wide text-gray-600">
              {{ appDetail.siteInfo.deployment_method }}
            </span>
          </div>
          <div class="d-flex mb-4" v-if="appDetail.siteInfo.github_path">
            <span class="PluginDetail__label">Repository</span>
            <span class="text-sm block tracking-wide text-gray-600">
              {{ appDetail.siteInfo.github_path }}
            </span>
          </div>
          <div class="d-flex mb-4">
            <span class="PluginDetail__label">Compilation Engine</span>
            <span class="text-sm block tracking-wide text-gray-600">
              {{ appDetail.siteInfo.compiler }}
            </span>
          </div>
          <div class="d-flex mb-4">
            <span class="PluginDetail__label">Turbo JS</span>
            <span class="text-sm block tracking-wide text-gray-600">
              {{
                appDetail.siteInfo.turbojs_enabled === true
                  ? 'Enabled'
                  : 'Disabled'
              }}
            </span>
          </div>
        </div>

        <div class="section-block mb-6" v-if="appDetail && appDetail.siteInfo">
          <h6 class="PluginDetail__section-heading">Plugin Usage</h6>
          <div class="d-flex mb-4">
            <span class="PluginDetail__label">Bandwidth - Day</span>
            <span class="text-sm block tracking-wide text-gray-600">
              {{ appDetail.siteInfo.bandwidth_usage_day }}
            </span>
          </div>
          <div class="d-flex mb-4">
            <span class="PluginDetail__label">Bandwidth - Week</span>
            <span class="text-sm block tracking-wide text-gray-600">
              {{ appDetail.siteInfo.bandwidth_usage_week }}
            </span>
          </div>
          <div v-if="appDetail.siteInfo.forms_enabled">
            <div class="d-flex mb-4">
              <span class="PluginDetail__label">Forms</span>
              <span class="text-sm block tracking-wide text-gray-600">
                {{ appDetail.siteInfo.forms_count }}
              </span>
            </div>
            <div class="d-flex mb-4">
              <span class="PluginDetail__label">Form Entries</span>
              <span class="text-sm block tracking-wide text-gray-600">
                {{ appDetail.developer.forms_entries }}
              </span>
            </div>
          </div>
        </div>

        <div
          class="section-block mb-6"
          v-if="appDetail && appDetail.developerSecurity"
        >
          <h6 class="PluginDetail__section-heading">Governance Policy</h6>
          <div class="d-flex mb-4">
            <div class="PluginDetail__policy-card px-4 mb-2">
              <div class="d-flex py-4 align-center">
                <div class="policy-icon-button pa-2 mr-4">
                  <the-icon icon="document" class="policy-icon" />
                </div>
                {{ appDetail.developerSecurity.name }}
              </div>
              <v-btn
                class="v-btn policy-button"
                @click="onViewReport"
                elevation="2"
              >
                <v-icon dark left> mdi-eye </v-icon>
                View
              </v-btn>
            </div>
          </div>
        </div>

        <div
          class="section-block mb-6 pr-0"
          v-if="schema.fields && schema.fields.length > 0"
        >
          <div class="d-flex justify-space-between">
            <h6 class="PluginDetail__section-heading">Install Params</h6>
            <v-btn icon @click="toggleParamPreview">
              <v-icon v-if="inParamPreview">mdi-code-json</v-icon>
              <the-icon :icon="'eye-on'" v-else />
            </v-btn>
          </div>
          <div class="install-params-section-block pr-6">
            <vue-form-generator
              v-if="inParamPreview"
              tag="div"
              :schema="schema"
              :model="param"
            ></vue-form-generator>
            <JSONPrint v-else :value="schema.fields" />
          </div>
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div
          class="section-block mb-6"
          v-if="appDetail && appDetail.developerData"
        >
          <div class="d-flex justify-center py-4 lg:pt-4 pt-8">
            <div class="mr-4 p-3 text-center">
              <div class="PluginDetail__statistics-label">Installs Count</div>
              <div class="PluginDetail__statistics-value">
                {{ appDetail.developerData.installsCount || 0 }}
              </div>
            </div>
            <div class="mr-4 p-3 text-center">
              <div class="PluginDetail__statistics-label">Rating</div>
              <div class="PluginDetail__statistics-value">
                <v-rating
                  v-if="appDetail.developerData.rating"
                  v-model="appDetail.developerData.rating"
                  readonly
                  background-color="red lighten-3"
                  color="red"
                  small
                  dense
                ></v-rating>
                <span v-else>N/A</span>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <v-col>
        <div
          class="section-block mb-6"
          v-if="appDetail && appDetail.developerData"
        >
          <div
            class="PluginDetail__section"
            v-if="appDetail && appDetail.developerData"
          >
            <h6 class="PluginDetail__section-title">Remove Plugin</h6>
            <div class="PluginDetail__error-message _last">
              Note: This action is not reversable. <br />
              The plugin will be immediately removed and unrecoverable.
            </div>
            <div class="PluginDetail__controls">
              <v-btn
                class="PluginDetail__delete-btn"
                id="no-background-hover"
                @click="onRemovePlugin"
                color="transparent"
                depressed
              >
                <v-icon>mdi-close-circle-outline</v-icon>
                <span class="ml-2">Remove {{ appDetail.name }}</span>
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <div v-if="loading" class="plugin-detail-spinner">
      <IntersectingCirclesSpinner
        :animation-duration="1200"
        :size="100"
        :color="'#496DDB'"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { IntersectingCirclesSpinner } from 'epic-spinners'
import {
  MODAL_STATUS_CHANGE_CONFIRMATION,
  MODAL_ALERT,
} from '@/components/Modals'
import StatusChip from '@/components/_Common/StatusChip'
import JSONPrint from '@/components/_Common/JSONPrint'
import { safeJSONParse } from '@/utils/common'

const colorMaps = {
  success: 'PluginDetail__audit-green',
  warning: 'PluginDetail__audit-orange',
  error: 'PluginDetail__audit-red',
}

const defaultColor = {
  status: colorMaps['warning'],
  evalSafe: colorMaps['warning'],
  secureURL: colorMaps['success'],
  insecureURL: colorMaps['error'],
  externalNetworkRequests: colorMaps['success'],
  sslEnabled: colorMaps['success'],
  forceSSLEnabled: colorMaps['success'],
}

const Parse = require('parse')
export default {
  name: 'PluginDetail',
  components: {
    JSONPrint,
    IntersectingCirclesSpinner,
    StatusChip,
  },
  data() {
    return {
      loading: false,
      isOwned: false,
      colors: defaultColor,
      mainImageStyle: { backgroundColor: 'gray' },
      appDetail: null,
      schema: {
        fields: [],
      },
      param: {
        component: '',
      },
      inParamPreview: true,
      options: {
        inline: false,
        button: true,
        navbar: true,
        title: false,
        toolbar: false,
        tooltip: false,
        movable: false,
        zoomable: true,
        rotatable: false,
        scalable: true,
        transition: true,
        fullscreen: true,
        keyboard: true,
        url: 'data-source',
      },
    }
  },
  computed: {
    ...mapState({
      currentPublisherInfo: ({ publisher_infos }) => publisher_infos.current,
    }),
  },
  mounted() {
    Parse.initialize(this.currentPublisherInfo.parse_server_app_id)
    Parse.serverURL = this.currentPublisherInfo.parse_server_url
    this.getPluginDetail()
  },
  methods: {
    // get app detail, called on page load
    // - get app detail common fields
    //  -- images
    //  -- color(Status color, eval safe color)
    //  -- Check if the current loggedin user is the owner
    async getPluginDetail() {
      this.loading = true
      const result = await Parse.Cloud.run('getPluginDetail', {
        appSlug: this.$route.params.pluginId,
        parseServerSiteId: this.currentPublisherInfo.parse_server_site_id,
      })
      if (result.status === 'success' && result.appDetail) {
        this.appDetail = result.appDetail
        if (result.appDetail.developerContent.keyImage) {
          this.mainImageStyle = {
            backgroundImage: `url('${result.appDetail.developerContent.keyImage}')`,
          }
        }
        this.schema.fields = safeJSONParse(
          result.appDetail.developerData?.installParams || '[]'
        )

        let color = defaultColor
        // Eval Safe Color
        const evalSafe =
          result.appDetail?.siteInfo?.plugin_info?.analysis_result?.EvalSafe
        const EvalSafe_Approve_Min = 10
        const EvalSafe_Warning_FailBelow = 5
        if (evalSafe >= EvalSafe_Approve_Min) {
          color['evalSafe'] = colorMaps['success']
        }
        if (evalSafe < EvalSafe_Warning_FailBelow) {
          color['evalSafe'] = colorMaps['error']
        }

        this.colors = color

        //  -- Check if the current loggedin user is the owner
        this.isOwned = false // TODO: temp code (result.appDetail.developer.id === developerData.id);
      }
      this.loading = false
    },

    // Status Color
    getStatusColors() {
      const status = this.appDetail.developerData.status
      if (status === 'Ready for Sale') {
        return 'sale-ready'
      }
      if (status === 'Declined' || status === 'Removed from Sale') {
        return 'declined'
      }
      if (status === 'Need Review') {
        return 'need-review'
      }
    },

    // TODO: Should update with real audit information
    getAuditClass() {
      const audit = Math.floor(Math.random() * 4)
      switch (audit) {
        case 0:
          return 'PluginDetail__audit-green'
        case 1:
          return 'PluginDetail__audit-red'
        case 2:
          return 'PluginDetail__audit-blue'
        case 3:
          return 'PluginDetail__audit-orange'
      }
    },

    onUpdateStatus(params) {
      this.$store.commit('application/openModal', {
        component: MODAL_STATUS_CHANGE_CONFIRMATION,
        props: {
          title: 'Are you sure you want to change status?',
          text: 'Status change affect its operation and display on other sites.',
          showCancelBtn: true,
          actionBtnLabel: 'Confirm',
          action: () => {
            this.updatePluginStatus(params)
          },
        },
      })
    },
    // Listener of Status Chip component update status
    async updatePluginStatus(params) {
      const { status } = params
      this.loading = true
      // Update status on Forge Ignite
      try {
        await Parse.Cloud.run('updateDeveloperAppData', {
          appDataId: this.appDetail.developerData.id,
          status,
          parseServerSiteId: this.currentPublisherInfo.parse_server_site_id,
        })
      } catch (error) {
        console.error('Error while Updating DeveloperAppData', error)
        this.loading = false
        return
      }
      this.appDetail.developerData.status = status
      this.loading = false
    },

    toggleParamPreview() {
      this.inParamPreview = !this.inParamPreview
    },

    onRemovePlugin() {
      const appId = this.$route.params.pluginId
      this.$store.commit('application/openModal', {
        component: MODAL_ALERT,
        props: {
          title: 'Remove Plugin',
          text: `You are going to remove ${this.appDetail.name}. Are you sure?`,
          actionBtnLabel: 'Remove',
          showCancelBtn: true,
          action: async () => {
            const res = await Parse.Cloud.run('removeApp', { appId })
            if (res.status === 'success')
              this.$router.push({ name: 'plugin_list' })
          },
        },
      })
    },

    onViewReport() {
      this.$router.push({
        name: 'publisher_policy_detail',
        params: { policyId: this.appDetail.developerSecurity.id },
      })
    },
  },
  watch: {},
}
</script>
<style>
.breadcrumb-link {
  text-decoration: none;
  font-size: 15px;
}
.breadcrumb-separator {
  margin-left: 5px;
  margin-right: 5px;
}
</style>
<style lang="scss" scoped>
.section-block {
  border: 1px solid $N5;
  background: $N4;
  border-radius: 4px;
  padding: 24px;
}
$style: PluginDetail;
.#{$style} {
  &__logo {
    width: 64px;
    height: 64px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 6px;
  }
  &__app-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: white;
    margin-bottom: 9px;
  }
  &__link {
    color: $N2;
    fill: $N2;
    text-decoration: none;
    cursor: pointer;
  }
  &__status-audit {
    width: 180px;
    text-align: center;
  }

  &__audit-wrapper {
    display: inline-flex;
    margin-top: 20px;
  }

  &__audit-green {
    background: $G1;
  }
  &__audit-red {
    background: $R1;
  }
  &__audit-blue {
    background: $B1;
  }
  &__audit-orange {
    background: $Y1;
  }

  &__section-heading {
    color: white;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 24px;
    font-weight: normal;
  }
  &__label {
    width: 150px;
    flex-shrink: 0;
    color: $N28;
    font-size: 14px;
  }

  &__screenshot-wrapper {
    width: 40%;
    margin-left: 24px;
    flex-shrink: 0;
  }

  &__statistics-value {
    font-size: 20px;
    font-weight: bold;
  }

  &__section-title {
    color: $white;
    font-weight: 500;
    font-size: $H16;
    margin-bottom: 14px;
    &._margin-more {
      margin-bottom: 28px;
    }
  }

  &__error-message {
    background: rgba($R1, 0.3);
    border-radius: 8px;
    padding: 12px 16px;
    margin-bottom: 26px;
    &._last {
      color: $N2;
    }
  }
  &__delete-btn {
    &.v-btn {
      color: $R1;
    }
  }
  &__policy-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: $N7;
    border-radius: 8px;
    .policy-button {
      color: white;
      background: $N5;
    }
    .policy-icon-button {
      background: $N5;
      height: 40px;
      border-radius: 6px;
    }
    .policy-icon {
      color: $B1;
      fill: $B1;
    }
  }
}

.plugin-detail-spinner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(100, 100, 100, 0.2);
  height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.install-params-section-block {
  max-height: 300px;
  overflow-y: auto;
}

.methods {
  margin-bottom: 1em;
  flex-wrap: wrap;
  & > * {
    margin-right: 0.75rem;
  }
}
.viewer {
  height: 100%;

  .image-wrapper {
    display: inline-block;
    width: calc(33% - 20px);
    height: 200px;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
</style>
